<template>
  <div>
    <loading
      :height="60"
      :width="60"
      color="#1873EE"
      background-color="white"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
    ></loading>

    <el-card shadow="always" style="border-radius:25px">
      <!-- <div slot="header" class="clearfix">
        <span
          >Total de teleconsultas:
          {{ total_general_teleconsultas }}</span
        >      
      </div> -->
      <div class="row">
        <div class="col-4">
          <span class="demonstration" style="font-size:13px"
            >Tipo de atención</span
          >
          <br />
          <el-select
            v-model="typeattention"
            placeholder="Tipo atención"
            size="small"
            style="width:100%"
            clearable
            @clear="limpiarTable"
          >
            <el-option
              v-for="item in typeattentionoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-4">
          <span class="demonstration" style="font-size:13px"
            >Fecha de Programación</span
          >
          <el-date-picker
            v-model="daterange"
            unlink-panels
            type="daterange"
            range-separator="-"
            start-placeholder="Desde"
            end-placeholder="Hasta"
            size="small"
            format="dd-MM-yyyy"
            clearable
            @change="limpiarTable"
          >
          </el-date-picker>
        </div>
        <div class="col-4">
          <span class="demonstration" style="font-size:13px">Empresa</span>
          <br />
          <el-select
            v-model="company"
            style="width:100%"
            placeholder="Empresa"
            size="small"
            clearable
            @clear="limpiarTable"
            filterable
          >
            <el-option
              v-for="item in companies"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="row" style="margin-top:10px">
        <div class="col-4">
          <span class="demonstration" style="font-size:13px">Especialidad</span>
          <el-input
            placeholder="Nombre"
            v-model="especialidad"
            size="small"
            clearable
            @clear="limpiarTable"
          ></el-input>
        </div>
        <div class="col-4">
          <span class="demonstration" style="font-size:13px"
            >Apellido del Especialista</span
          >
          <el-input
            placeholder="Apellido del Especialista"
            v-model="especialista"
            size="small"
            clearable
            @clear="limpiarTable"
          ></el-input>
        </div>
        <div class="col-4">
          <span class="demonstration" style="font-size:13px">Estado</span>
          <br />
          <el-select
            v-model="descriptionstatus"
            style="width:100%"
            placeholder="Estado"
            size="small"
            clearable
            @clear="limpiarTable"
          >
            <el-option
              v-for="item in status"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row" style="margin-top:10px">
        <div class="col-4">
          <span class="demonstration" style="font-size:13px">País</span>
          <br />
          <el-select
            v-model="country"
            style="width:100%"
            placeholder="País"
            size="small"
            filterable
            clearable
            @clear="limpiarTable"
          >
            <el-option
              v-for="item in countries"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-4">
          <span class="demonstration" style="font-size:13px">Titular</span>
          <br />
          <el-input
            :placeholder="placeholder"
            v-model="filter"
            class="input-with-select"
            clearable
            size="small"
            ref="filter"
            @clear="limpiarTable"
            suffix-icon="el-icon-search"
          >
            <template slot="prepend">
              <el-dropdown @command="handleCommand" placement="bottom">
                <span class="el-dropdown-link">
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="filtros"
                    ><b>Filtros</b></el-dropdown-item
                  >

                  <el-dropdown-item command="name" :style="stylename"
                    >Apellido</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="documentcode"
                    :style="styledocumentcode"
                    >Doc identidad</el-dropdown-item
                  >
                  <el-dropdown-item command="phone" :style="styledphone"
                    >Teléfono</el-dropdown-item
                  >
                  <el-dropdown-item command="email" :style="styleemail"
                    >Email</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>

            <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
          </el-input>
        </div>

        <div class="col-1">
          <br />
          <el-button
            style="background:#23CCEF; color:white;border:0px;"
            size="small"
            icon="el-icon-search"
            @click="buscar_teleconsultas"
          >
            Buscar</el-button
          >
        </div>
      </div>
    </el-card>

    <br />
    <el-card
      shadow="always"
      style="border-radius:25px"
      v-if="viewgetTeleconsultas"
    >
      <div slot="header" class="clearfix">
        <span>Listado de teleconsultas</span>

        <span style="float: right;" type="text"
          >Resultado encontrados: {{ pagination.total }}</span
        >
      </div>
      <div>
        <div>
          <el-table
            :data="teleconsultas"
            style="width: 100%"
            stripe
            border
            empty-text="No existe ninguna teleconsulta"
          >
            <!-- <el-table-column
              label="Tipo de Atención"
              min-width="135"
              prop="attention_type"
              align="center"
            >
            </el-table-column> -->
            <el-table-column
              min-width="135"
              label="Tipo de Atención"
              align="center"
            >
              <template slot-scope="props">
                <el-button type="text" @click="viewDraw(props.row)">{{
                  props.row.attention_type
                }}</el-button>
              </template>
            </el-table-column>
            <el-table-column
              label="Especialista"
              min-width="240"
              prop="medico"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="Paciente"
              min-width="300"
              prop="paciente"
              align="center"
            >
            </el-table-column>
            company
            <el-table-column
              label="Empresa"
              min-width="300"
              prop="company"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="Paciente Conectado"
              min-width="300"
              prop="patient_connect"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="Doctor Conectado"
              min-width="300"
              prop="doctor_connect"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="Estado"
              min-width="300"
              prop="status_label"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="Telf. Titular"
              min-width="150"
              prop="phone"
              align="center"
            >
            </el-table-column>

            <el-table-column
              label="Correo"
              min-width="280"
              prop="email"
              align="center"
            >
            </el-table-column>

            <el-table-column
              label="Fecha Agendada"
              width="180"
              prop="scheduled_date"
              align="center"
            >
            </el-table-column>

            <el-table-column
              label="Especialidad"
              min-width="170"
              prop="specialty"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="Empresa"
              min-width="190"
              prop="company"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="Titular"
              min-width="260"
              prop="titular"
              align="center"
            >
            </el-table-column>
            <el-table-column fixed="right" label="Acciones" width="120">
              <template slot-scope="scope">
                <el-button type="text" @click="viewDraw(scope.row)"
                  >Editar</el-button
                >
              </template>
            </el-table-column>
            <!-- <el-table-column min-width="120" fixed="right" label="Acciones">
              <template slot-scope="props_id">
                <div class="row">
                  <el-dropdown trigger="click">
                    <span class="el-dropdown-link" style="margin-left: 40px">
                      <i class="el-icon-caret-bottom el-icon-more"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item class="clearfix">
                        <el-button
                          type="text"
                          @click="
                            dropdownUpdateStatus(props_id.row.id, 'attended')
                          "
                          >Atendido</el-button
                        >
                      </el-dropdown-item>
                      <el-dropdown-item class="clearfix">
                        <el-button
                          type="text"
                          @click="
                            dropdownUpdateStatus(props_id.row.id, 'scheduled')
                          "
                          >Programado</el-button
                        >
                      </el-dropdown-item>
                      <el-dropdown-item class="clearfix">
                        <el-button
                          type="text"
                          @click="
                            dropdownUpdateStatus(props_id.row.id, 'pendient')
                          "
                          >Pendiente</el-button
                        >
                      </el-dropdown-item>
                      <el-dropdown-item class="clearfix">
                        <el-button
                          type="text"
                          @click="
                            dropdownUpdateStatus(props_id.row.id, 'in_progress')
                          "
                          >En progreso</el-button
                        >
                      </el-dropdown-item>
                      <el-dropdown-item class="clearfix">
                        <el-button
                          type="text"
                          @click="
                            dropdownUpdateStatus(props_id.row.id, 'cancelled')
                          "
                          >Cancelado</el-button
                        >
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
        <br />
        <div
          slot="footer"
          class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
        >
          Showing {{ pagination.currentPage2 }} to {{ pagination.perPage2 }} of
          {{ pagination.total }}

          <l-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </l-pagination>
        </div>
      </div>
    </el-card>
    <!-- <el-drawer
      title="Cambiar atención médica!"
      :visible.sync="drawer"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div class="demo-drawer__content">
        <ValidationObserver v-slot="{ handleSubmit }">
          <el-form ref="formUpdate">
            <el-form-item label="Estado" label-width="80px">
              <ValidationProvider
                rules="required"
                name="Nombre o Razón Social"
                vid="formCreate.name"
                v-slot="provider"
              >
                <el-select v-model="formUpdate.status" placeholder="Estado">
                  <el-option
                    v-for="item in status"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <div
                  style="color:red; font-size:10px;bottom:10px;position: relative"
                >
                  {{ provider.errors[0] }}
                </div>
              </ValidationProvider>
            </el-form-item>
            <div class="demo-drawer__footer">
              <el-button>Cancel</el-button>
              <el-button type="primary" @click="handleSubmit(updateStatus)"
                >Guardar</el-button
              >
            </div>
          </el-form>
        </ValidationObserver>
      </div>
    </el-drawer> -->
    <el-dialog title="" :visible.sync="dialogError" width="30%" top="1%">
      <div class="col">
        <el-result
          icon="error"
          title="Error"
          subTitle="Por favor siga las instrucciones"
        >
          <template slot="extra">
            <el-button type="primary" size="medium" @click="dialogError = false"
              >Cerrar</el-button
            >
          </template>
        </el-result>
        <div
          v-for="result in resulterrors"
          :key="result.id"
          style="font-size:12px;"
        >
          {{ result }}
        </div>
      </div>
    </el-dialog>
    <el-dialog title="" :visible.sync="dialogErrorStatus" width="30%" top="1%">
      <div class="col">
        <el-result
          icon="warning"
          title="¡ATENCIÓN!"
          subTitle="Hubo un error al procesar su solicitud"
        >
          <template slot="extra">
            <el-button
              type="primary"
              size="medium"
              @click="dialogErrorStatus = false"
              >Cerrar</el-button
            >
          </template>
        </el-result>
        <div style="font-size:16px;">
          {{ resulterrorsStatus }}
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="Cambiar Estado de Teleconsulta"
      :visible.sync="dialogActualizarEstado"
      width="35%"
      top="1%"
      class="height-card"
    >
      <div class="row" style="bottom:25px;position: relative">
        <div class="col-12">
          <div class="card stacked-form">
            <div class="card-body">
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <el-form label-position="top" ref="formUpdate">
                  <div class="row">
                    <div class="col">
                      <label>Tipo de Atención </label>
                      <el-input
                        placeholder="Tipo de Atención"
                        size="small"
                        style="margin-bottom:10px"
                        v-model="attention_type"
                        readonly
                      >
                      </el-input>
                    </div>
                    <div class="col">
                      <label>Servicio</label>
                      <el-input
                        placeholder="Servicio"
                        size="small"
                        style="margin-bottom:10px"
                        v-model="servicio"
                        readonly
                      >
                      </el-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <label>Doctor </label>
                      <el-input
                        placeholder="Doctor"
                        size="small"
                        style="margin-bottom:10px"
                        v-model="medico"
                        readonly
                      >
                      </el-input>
                    </div>
                    <div class="col">
                      <label>Usuario </label>
                      <el-input
                        placeholder="Usuario"
                        size="small"
                        style="margin-bottom:10px"
                        v-model="usuario"
                        readonly
                      >
                      </el-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <label>Empleado </label>
                      <el-input
                        placeholder="Empleado"
                        size="small"
                        style="margin-bottom:10px"
                        v-model="titular"
                        readonly
                      >
                      </el-input>
                    </div>
                    <div class="col">
                      <label>Paciente </label>
                      <el-input
                        placeholder="Paciente"
                        size="small"
                        style="margin-bottom:10px"
                        v-model="paciente"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <label>Fecha Programación </label>
                      <el-input
                        placeholder="Fecha Programación"
                        size="small"
                        readonly
                        v-model="scheduled_date"
                        style="margin-bottom:10px"
                      />
                    </div>
                    <div class="col">
                      <ValidationProvider
                        rules="required"
                        name="Estado"
                        vid="formUpdate.status"
                        v-slot="provider"
                      >
                        <label>Estado </label>
                        <el-select
                          v-model="formUpdate.status"
                          placeholder="Estado"
                          size="small"
                          style="margin-bottom:10px"
                        >
                          <el-option
                            v-for="item in status"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            :disabled="item.label=='En progreso'?true:false"
                          >
                          </el-option>
                        </el-select>

                        <div
                          style="color:red; font-size:10px;bottom:0px;position: relative"
                        >
                          {{ provider.errors[0] }}
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="form-group text-center">
                    <el-button
                      style="background:#23CCEF; color:white;border:0px;top:8px;position: relative"
                      size="small"
                      @click="handleSubmit(updateStatus)"
                    >
                      ACTUALIZAR TELECONSULTA</el-button
                    >
                  </div>
                </el-form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
      <!-- <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <el-form ref="formUpdate" label-width="120px" size="mini">
          <el-form-item label="Estado" label-width="80px">
            <ValidationProvider
              rules="required"
              name="Estado"
              vid="formUpdate.status"
              v-slot="provider"
            >
              <el-select
                v-model="formUpdate.status"
                placeholder="Estado"
                size="small"
              >
                <el-option
                  v-for="item in status"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <div
                style="color:red; font-size:10px;bottom:10px;position: relative"
              >
                {{ provider.errors[0] }}
              </div>
            </ValidationProvider>
          </el-form-item>
          
          <div class="demo-drawer__footer">
            <el-button @click="dialogActualizarEstado = false"
              >Cancelar</el-button
            >
            <el-button type="primary" @click="handleSubmit(updateStatus)"
              >Guardar</el-button
            >
          </div>
        </el-form>
      </ValidationObserver> -->
    </el-dialog>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  Popover,
  Tag,
  Descriptions,
  DescriptionsItem,
  Button,
  Dialog,
  Message,
  Card,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Drawer,
  Form,
  FormItem,
  Result
} from "element-ui";
import {
  Loading,
  Pagination as LPagination,
  FormGroupInput as FgInput,
  StatsCard
} from "src/components/index";
import axios from "axios";
import Swal from "sweetalert2";

//Librayr
import dayjs from "dayjs";
require("dayjs/locale/es");

export default {
  name: "Teleconsultas",
  components: {
    Loading,
    LPagination,
    FgInput,
    StatsCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Popover.name]: Popover,
    [Tag.name]: Tag,
    [Descriptions.name]: Descriptions,
    [DescriptionsItem.name]: DescriptionsItem,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Message.name]: Message,
    [Card.name]: Card,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Drawer.name]: Drawer,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Result.name]: Result
  },
  data() {
    return {
      medico: "",
      paciente: "",
      attention_type: "",
      scheduled_date: "",
      titular: "",
      servicio: "",
      usuario: "",

      dialogActualizarEstado: false,
      resulterrors: [],
      dialogError: false,
      resulterrorsStatus: "",
      dialogErrorStatus: false,
      medicalattentionid: 0,
      loading: false,
      //drawer: false,
      placeholder: "Apellido",
      filter: null,
      filtro_select: "",
      stylename: "color:#167FE2;border-radius:8px",
      styledocumentcode: "",
      styleemail: "",
      styledphone: "",
      status: [
        {
          value: "attended",
          label: "Atendido"
        },
        {
          value: "scheduled",
          label: "Programado"
        },
        {
          value: "pendient",
          label: "Pendiente"
        },
        {
          value: "in_progress",
          label: "En progreso"
        },
        {
          value: "cancelled",
          label: "Cancelado"
        }
      ],
      formUpdate: {
        status: null
      },
      descriptionstatus: "",
      total_general_teleconsultas: 0,
      viewgetTeleconsultas: false,
      companies: [],
      typeattention: null,
      daterange: null,
      company: null,
      especialidad: null,
      especialista: null,
      country: "",
      countries: [],
      typeattentionoptions: [
        {
          value: "1",
          label: "B2B"
        },
        {
          value: "4",
          label: "B2C"
        }
      ],
      isLoading: false,
      //placeholder: "Doctor",
      teleconsultas: [],
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [10, 15, 25, 50],
        total: 0,
        currentPage2: 1,
        perPage2: 20
      }
    };
  },
  watch: {
    "pagination.currentPage"() {
      this.getTeleconsultas();
      this.Showingpagination();
    }
  },
  mounted() {
    if (!sessionStorage.isLogged) {
      this.$router.go(-1);
      return;
    }

    this.getCompanies();
    this.getCountries();
  },
  methods: {
    reset() {
      this.formUpdate.status = null;
      this.$refs.observer.reset();
    },
    dropdownUpdateStatus(id, status) {
      Swal.fire({
        title: "¿Desea actualizar el estado de la Atención?",
        text: "Por favor verificar correctamente, los datos seleccionados.",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "CONFIRMAR",
        cancelButtonText: "CANCELAR",
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.medicalattentionid = id;
          this.formUpdate.status = status;
          this.updateStatus();
        }
      });
    },
    async updateStatus() {
      let medicalattentionid = this.medicalattentionid;
      let token = null;
      token = sessionStorage.token;

      try {
        await axios
          .put(
            `v1.1/pro/medical-attention/${medicalattentionid}`,
            this.formUpdate,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `token ${token}`
              },
              params: { "view-backoffice": "" }
            }
          )
          .then(response => {
            console.log(response);
            if (response.status === 200) {
              Swal.fire(
                "Actualizado!",
                "Registro fue cambiado con éxito.",
                "success"
              );
              this.reset();
              this.getTeleconsultas();
              this.dialogActualizarEstado = false;
            } else {
              this.dialogError = true;
              this.resulterrors = response.data;
              this.dialogActualizarEstado = false;
            }
          })
          .catch(error => {
            if (error.response.status === 400) {
              this.dialogError = true;
              this.resulterrors = error.response.data.errors;
            } else {
              this.dialogErrorStatus = true;
              this.dialogEditEmployeeVisible = false;
              this.resulterrorsStatus =
                "Estado de servicio: " + error.response.status;
            }
          });
      } catch (error) {
        Swal.fire("¡Error!", "Ocurrio el siguiente error: " + error, "error");
        console.error(error);
      }
    },
    viewDraw(rows) {
      this.dialogActualizarEstado = true;
      this.medicalattentionid = rows.id;

      this.medico = rows.medico;
      this.paciente = rows.paciente;
      this.attention_type = rows.attention_type;
      this.scheduled_date = rows.scheduled_date;
      this.titular = rows.titular;
      this.servicio = rows.service_detail.name;

      this.usuario =
        rows.user_detail == null
          ? ""
          : rows.user_detail.first_name + " " + rows.user_detail.last_name;
      //this.usuario = rows.user_detail.first_name + rows.user_detail.last_name;

      this.formUpdate.status = rows.status;
      console.log(rows);
    },
    handleCommand(filtro_select) {
      if (filtro_select == "documentcode") {
        this.styledocumentcode = "color:#167FE2;border-radius:8px";
        this.stylename = "";
        this.styleemail = "";
        this.styledphone = "";
        this.placeholder = "Doc identidad";
      } else if (filtro_select == "name") {
        this.stylename = "color:#167FE2;border-radius:8px";
        this.placeholder = "Apellido";
        this.styledocumentcode = "";
        this.styleemail = "";
        this.styledphone = "";
      } else if (filtro_select == "phone") {
        this.stylename = "";
        this.placeholder = "Teléfono";
        this.styledocumentcode = "";
        this.styleemail = "";
        this.styledphone = "color:#167FE2;border-radius:8px";
      } else if (filtro_select == "email") {
        this.styledocumentcode = "";
        this.styleemail = "color:#167FE2;border-radius:8px";
        this.styledphone = "";
        this.stylename = "";
        this.placeholder = "Email";
      }

      this.$refs.filter.focus();
      this.filtro_select = filtro_select;
    },
    async getCountries() {
      try {
        const res = await axios.get(`v1/countries`, {
          params: {}
        });
        this.countries = res.data.data.map(el => ({
          ...el
        }));
      } catch (error) {
        console.error(error);
      }
    },
    limpiarTable() {
      if (
        !this.typeattention &&
        !this.daterange &&
        !this.company &&
        !this.especialidad &&
        !this.especialista
      ) {
        this.viewgetTeleconsultas = false;
      }
    },
    async buscar_teleconsultas() {
      this.isLoading = true;
      this.pagination.currentPage = 1;
      await this.getTeleconsultas();
      this.isLoading = false;
      this.Showingpagination();
      this.viewgetTeleconsultas = true;
    },
    async getTeleconsultas() {
      try {
        let typeattention,
          company,
          doctor,
          specialty,
          date_from,
          date_to,
          document_code,
          name,
          email,
          phone = null;

        let token = null;
        token = sessionStorage.token;

        typeattention = this.typeattention;
        company = this.company;

        doctor = this.especialista;
        specialty = this.especialidad;

        if (this.daterange) {
          date_from = dayjs(this.daterange[0]).format("YYYY-MM-DD");
          date_to = dayjs(this.daterange[1]).format("YYYY-MM-DD");
        }

        if (this.filter) {
          if (this.filtro_select == "documentcode") {
            document_code = this.filter;
          } else if (this.filtro_select == "name") {
            name = this.filter;
          } else if (this.filtro_select == "email") {
            email = this.filter;
          } else if (this.filtro_select == "phone") {
            phone = this.filter;
          } else {
            name = this.filter;
          }
        }

        const res = await axios.get(`v1.1/pro/medical-attention`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: this.pagination.currentPage,
            limit: 20,
            "type-attention": typeattention,
            company: company,
            doctor: doctor,
            specialty: specialty,
            "date-start": date_from,
            "date-end": date_to,
            status: this.descriptionstatus,
            platform: "",
            country: this.country,
            document_code: document_code,
            "patient-or-employee": name,
            email: email,
            phone: phone,
            "view-backoffice": ""
          }
        });

        if (res.data.count > 0) {
          this.pagination.total = res.data.count;
          this.teleconsultas = res.data.results.map(el => ({
            ...el,
            scheduled_date: dayjs(el.scheduled_date).format(
              "DD-MM-YYYY, H:mm A"
            ),

            company: el.company == null ? "" : this.capitalize(el.company),

            created: dayjs(el.created).format("YYYY-MM-DD"),
            attention_type:
              el.attention_type == "Teleconsulta B2B" ? "B2B" : "B2C",
            medico:
              el.doctor_detail.first_name + " " + el.doctor_detail.last_name,
            // paciente:
            //   el.patient_detail == null
            //     ? el.user_detail.first_name + " " + el.user_detail.last_name
            //     : el.patient_detail.first_name +
            //       " " +
            //       el.patient_detail.last_name,
            paciente:
              el.patient_detail == null && el.user_detail != null
                ? el.user_detail.first_name + " " + el.user_detail.last_name
                : el.patient_detail == null && el.employee_detail != null
                ? el.employee_detail.first_name +
                  " " +
                  el.employee_detail.last_name
                : el.patient_detail.first_name +
                  " " +
                  el.patient_detail.last_name,
            titular:
              el.user_detail == null
                ? el.employee_detail.first_name +
                  " " +
                  el.employee_detail.last_name
                : el.user_detail.first_name + " " + el.user_detail.last_name,
            email:
              el.employee_detail == null
                ? el.user_detail.email
                : el.employee_detail.email,
            phone:
              el.employee_detail == null
                ? el.user_detail.phone
                : el.employee_detail.phone,
            relation:
              el.patient_detail == null ? "Titular" : el.patient_detail.relation
          }));
        } else {
          this.pagination.total = 0;
          this.teleconsultas = [];
        }
      } catch (error) {
        console.error(error);
      }
      if (this.pagination.total <= 20) {
        this.pagination.perPage2 = this.pagination.total;
      }
    },
    Showingpagination() {
      this.pagination.perPage2 =
        this.pagination.perPage * this.pagination.currentPage;

      if (this.pagination.currentPage == 1) {
        this.pagination.currentPage2 = 1;
      } else if (this.pagination.currentPage >= 2) {
        this.pagination.currentPage2 = this.pagination.perPage2 - 19;
      }

      if (this.pagination.perPage2 > this.pagination.total) {
        this.pagination.perPage2 = this.pagination.total;
      }
    },
    async getCompanies() {
      let country_id = null;
      let name = null;
      let code = null;

      let token = null;
      token = sessionStorage.token;

      try {
        const res = await axios.get(`v1/companies`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: 1,
            size: 100,
            name: name,
            code: code,
            country_id,
            "exclude-is-demo": ""
          }
        });
        this.companies = res.data.data.rows.map(el => ({
          ...el,
          name: this.capitalize(el.name)
        }));
      } catch (error) {
        console.error(error);
      }
    },
    capitalize: function(str) {
      try {
        let nombre = "";
        nombre = str.toLocaleLowerCase();
        return nombre
          .toLowerCase()
          .trim()
          .split(" ")
          .map(v => v[0].toUpperCase() + v.substr(1))
          .join(" ");
      } catch (error) {
        console.log(str);
        console.error(error);
      }
    }
  }
};
</script>
<style lang="css">
.height-card {
  height: 425px;
}
.total_teleconsultas {
  color: #409eff;
  background-color: #ecf5ff;
  border-color: #d9ecff;
  display: inline-block;
  height: 32px;
  padding: 0 5px;
  line-height: 30px;
  font-size: 14px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
}
.demo-drawer__footer {
  display: flex;
  padding: 5px;
  justify-content: center;
}
.demo-drawer__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.el-select-dropdown__item.selected {
  color: #C0C4CC;
}
</style>
