var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',[_c('user-menu'),_c('mobile-menu'),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{ name: 'Funciones Generales', icon: 'nc-icon nc-app' }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Programar teleconsulta',
            path: '/admin/programarteleconsulta'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Reprogramar teleconsulta',
            path: '/admin/reprogramarteleconsulta'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Programación Horario Mes',
            path: '/admin/programacionhorariomes'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Horarios Programados',
            path: '/admin/horariosprogramados'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Panel Estado Teleconsultas',
            path: '/admin/panelestadosteleconsultas'
          }}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'Gestionar Empresas', icon: 'nc-icon nc-bag' }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Registrar Empresas',
            path: '/admin/registrarempresas'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Empresas Registradas',
            path: '/admin/panelempresa'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Importar Colaboradores',
            path: '/admin/importartrabajadores'
          }}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'Reportes', icon: 'nc-icon nc-notes' }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Teleconsultas programadas',
            path: '/admin/teleconsultas'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Atenciones B2B Globales',
            path: '/admin/rptatencionesb2bglobales'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Atenciones B2B Médico',
            path: '/admin/rptatencionesb2b'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Reporte Kpis',
            path: '/admin/reportekpis'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'SD v1.1',
            path: '/admin/sd-v1-1'
          }}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'Bienestar', icon: 'nc-icon nc-bullet-list-67' }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Evaluación de bienestar',
            path: '/admin/listado-evaluacion-bienestar'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Reporte de bienestar',
            path: '/admin/listado-reporte-bienestar'
          }}})],1)],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{nativeOn:{"click":function($event){return _vm.toggleSidebar.apply(null, arguments)}}}),_c('content-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }