<template>
  <div>
    <div v-if="cabecera1">
      <!-- <div class="row">
        <div class="col-xl-5 col-md-6">
          <h6>Importar Trabajadores</h6>
          <p>El formato para adjuntar debe ser de excel</p>
        </div>
      </div> -->
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <p>Seleccione empresa</p>
          <div class="card">
            <select
              v-model="seleccionCompanies"
              @change="validarArchivoExcel"
              class="form-control"
            >
              <option value="0">Selecciona una opción</option>
              <option
                v-for="item in companies"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="cabecera2">
      <h4>VERIFICAR LA LISTA DE LOS COLABORADORES</h4>
      <div class="col-md-9">
        <div class="text-left">
          <small
            >Se agregaran {{ pagination.total }} Colaboradores a la Empresa
            {{ company_name }}
          </small>
        </div>
      </div>
      <div class="col-md-3">
        <div class="text-right">
          <a @click="regresarPasoAnterior" style="cursor:pointer;">
            <i class="nc-icon nc-stre-left"></i> Regresar al paso anterior</a
          >
        </div>
      </div>
      <br />
      <br />
    </div>
    <div class="row">
      <div class="col-md-12">
        <el-card shadow="always" style="border-radius:25px">
          <div class="text-center" v-if="show2">
            <card>
              <div class="row">
                <div class="col-md-9">
                  <div class="text-right">
                    <img src="image/imgexcel2.png" style="cursor:pointer;" />
                    {{ nombrefile.name }}
                  </div>
                </div>
                <div class="col-md-1">
                  <div class="text-right">
                    <img
                      src="image/imgcerrar.png"
                      style="cursor:pointer;"
                      @click="proceso1()"
                    />
                  </div>
                </div>
              </div>
            </card>
          </div>
          <div v-if="showerror">
            <p class="text-danger">
              Se produjo un error en la carga de la lista.
            </p>
          </div>
          <div v-if="showload">
            <div class="text-center">
              <div v-for="bar in bars" :key="bar">
                <progress
                  :value="bar.value"
                  variant="primary"
                  :max="bar.max"
                  :key="bar.value"
                ></progress>
              </div>
            </div>
            <div class="text-center">
              <p class="text-primary">{{ mensajeCargaArchivo }}</p>
            </div>
          </div>
          <div class="col-md-12">
            <div class="text-center">
              <div v-if="show1">
                <div class="table-responsive table-full-width">
                  <div class="scroll">
                    <el-table
                      stripe
                      style="width: 100%; overflow: auto"
                      border
                      :data="contactos"
                    >
                      <el-table-column
                        v-for="column in tableColumns"
                        :key="column.label"
                        :min-width="column.minWidth"
                        :prop="column.prop"
                        :label="column.label"
                      >
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
              <div v-if="showProgress">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-center">
                      <p>Registrando trabajadores en {{ company_name }}</p>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <img src="image/imgexcel2.png" style="cursor:pointer;" />
                    {{ nombrefile.name }}
                  </div>
                  <div class="col-md-1">
                    <el-progress
                      type="dashboard"
                      :percentage="percentage"
                      :width="45"
                      :color="colors"
                    ></el-progress>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-info text-center">
                      <span>Por favor espere a que el proceso se complete</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="show3">
                <form>
                  <div class="image-upload">
                    <!-- Subir entrada -->
                    <label for="fileInput">
                      <img
                        src="image/subir-archivo.png"
                        alt="Click aquí para subir su excel"
                        title="Click aquí para subir su excel"
                        class="q-mb-xl"
                      />
                    </label>
                    <br />
                    <small>Selecciona tu archivo en formato excel</small>

                    <input
                      type="file"
                      accept=".xlsx, .xls"
                      @change="changeFile"
                      id="fileInput"
                      ref="fileInput"
                    />
                    <!--  Área de visualización de la hoja de Excel -->
                    <!--<div id="result" contenteditable></div>-->
                    <!--  Botón para mostrar la hoja de trabajo -->
                  </div>
                </form>
              </div>

              <!--<div class="image-upload">
                <label for="file-input">
                  <img
                    src="image/importexcel.png"
                    alt="Click aquí para subir su excel"
                    title="Click aquí para subir su excel"
                    class="q-mb-xl"
                  />
                </label>
                <input
                  id="file-input"
                  name="excel"
                  type="file"
                  @change="onFileChange"
                />
              </div>-->
            </div>
          </div>
          <div class="col-md-12" v-if="btncontinuar">
            <div class="text-center">
              <button
                type="button"
                @click="continuarCarga()"
                class="btn btn-fill btn-info btn-wd"
              >
                REGISTRAR COLABORADORES
              </button>
            </div>
          </div>
          <div v-if="showlisterror" class="col-md-12">
            <table>
              <tr>
                <td>
                  <img
                    src="image/img_atras.png"
                    alt="atras"
                    width="35px"
                    class="q-mb-xl"
                    style="cursor:pointer;"
                    @click="regresarPasoAnterior()"
                  />
                  <b>Validación de archivo</b>
                </td>
              </tr>
            </table>

            <p>
              En la lista se mostraran los datos erroneos que no se puede
              cargar.
            </p>
            <small>Lista de datos erroneos</small>
            <br />
            <small> {{ totalerror }} errores</small>

            <div>
              <el-table :data="listerrors" style="width: 100%">
                <el-table-column prop="message" label="Descripción" width="650">
                </el-table-column>
              </el-table>
            </div>
            <br />
            <button
              type="submit"
              class="btn btn-fill btn-info btn-wd btn-sm"
              @click="download_excel"
            >
              DESCARGAR EXCEL
            </button>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  Popover,
  Tag,
  Descriptions,
  DescriptionsItem,
  Button,
  Dialog,
  Progress,
  Card
} from "element-ui";
import {
  Pagination as LPagination,
  FormGroupInput as FgInput,
  StatsCard
} from "src/components/index";
import axios from "axios";
import XLSX from "xlsx";
import Swal from "sweetalert2";
///import Notification from "../../../components/NotificationPlugin/Notification.vue";
//import Progress from "../../../components/Progress.vue";
///import readXlsxFile from "read-excel-file";
//Vue.use(XLSX)
export default {
  name: "Dashboard",
  components: {
    LPagination,
    FgInput,
    StatsCard,
    Button,
    Notification,
    Progress,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Popover.name]: Popover,
    [Tag.name]: Tag,
    [Descriptions.name]: Descriptions,
    [DescriptionsItem.name]: DescriptionsItem,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Progress.name]: Progress,
    [Card.name]: Card
  },
  data() {
    return {
      listerrors: [],
      totalerror: 0,
      datadetailimport: [],
      percentage: 10,
      company_name: "",
      importId: 0,
      colors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 }
      ],
      dialogVisible: true,
      bars: [{ max: 100, value: 50 }],
      timer: null,
      showlisterror: false,
      showProgress: false,
      show1: false,
      show2: false,
      show3: true,
      btncontinuar: false,
      showerror: false,
      showload: false,
      cabecera1: true,
      cabecera2: false,
      errorCargaArchivo: false,
      defaultIndex: 0, // Mostrar la primera hoja de trabajo por defecto
      wb: null, // objeto de hoja de trabajo
      companies: [],
      contactos: [],
      //importCompanies: [],
      seleccionCompanies: null,
      mensajeCargaArchivo: "Verificando el adjunto.....",
      nombrefile: "",
      pagination: {
        total: 0
      },
      tableColumns: [
        {
          prop: "CODIGO TRABAJADOR",
          label: "Código",
          minWidth: 100
        },
        {
          prop: "TIPO DOCUMENTO",
          label: "Tipo Doc",
          minWidth: 100
        },
        {
          prop: "NÚMERO DOCUMENTO",
          label: "Nº Doc",
          minWidth: 100
        },
        {
          prop: "APELLIDOS",
          label: "Apellidos",
          minWidth: 100
        },
        {
          prop: "NOMBRES",
          label: "Nombres",
          minWidth: 100
        },
        {
          prop: "FECHA DE NACIMIENTO",
          label: "F.Nac",
          minWidth: 100
        },
        {
          prop: "GÉNERO",
          label: "Género",
          minWidth: 100
        },
        {
          prop: "CORREO ELECTRÓNICO",
          label: "Correo",
          minWidth: 100
        },
        {
          prop: "TELÉFONO",
          label: "Teléfono",
          minWidth: 100
        },
        {
          prop: "DIRECCIÓN",
          label: "Dirección",
          minWidth: 100
        },
        {
          prop: "CANTIDAD HIJOS",
          label: "Hijos",
          minWidth: 100
        },
        {
          prop: "NOMBRE DE SEDE",
          label: "Sede",
          minWidth: 100
        }
      ]
    };
  },
  watch: {},
  mounted() {
    if (!sessionStorage.isLogged) {
      this.$router.go(-1);
      return;
    }
    this.seleccionCompanies = 0;
    this.getCompanies();

    this.validar_success_importacion();
  },
  methods: {
    validar_success_importacion() {
     
      if (localStorage.getItem("success_importacion") == "true") {
        Swal.fire(
          "La importación esta completa!",
          "La información que ingresaste se encuentra en proceso. Espera entre 5 a 15 minutos que se actualice en la base correspondiente.",
          "success"
        );
        localStorage.setItem("success_importacion", false);
      }
    },
    capitalize: function(str) {
      let razonSocial = "";
      razonSocial = str.toLocaleLowerCase();
      return razonSocial
        .toLowerCase()
        .trim()
        .split(" ")
        .map(v => v[0].toUpperCase() + v.substr(1))
        .join(" ");
    },
    showText(val) {
      for (var i = 0; i < this.companies.length; i++) {
        if (this.companies[i].id === val) {
          this.company_name = this.companies[i].name;
          return this.companies[i].name;
        }
      }
      return "";
    },
    // viewlisterror() {
    //   if (this.totalerror > 0) {
    //     this.showlisterror = true;
    //     this.showProgress = false;
    //   } else if (this.totalerror == 0) {
    //   }
    // },
    increase() {
      let interval = setInterval(() => {
        if (this.percentage < 100) {
          this.percentage += 10;
        } else clearInterval(interval);
      }, 3000);
    },
    randomValue() {
      this.timer = setInterval(() => {
        this.bars.forEach(
          bar => (
            (this.mensajeCargaArchivo = "Validación Completa!"),
            (this.show1 = true),
            (this.show2 = false),
            (this.show3 = false),
            (this.cabecera1 = false),
            (this.cabecera2 = true),
            (this.showload = false),
            (this.btncontinuar = true),
            Swal.fire(
              "Importante!",
              "Verifique si la informacion es correcta",
              "info"
            ),
            clearInterval(this.timer)((bar.value = bar.value * 5))
          )
        );
      }, 1000);
    },
    regresarPasoAnterior() {
      this.$router.go(0);
    },
    proceso1() {
      this.$router.go(0);
    },
    continuarCarga() {
      if (this.errorCargaArchivo) {
        this.showerror = true;
      } else {
        this.importEmpleados();

        this.show1 = false;
        this.show2 = false;
        this.show3 = false;
        this.cabecera1 = false;
        this.cabecera2 = false;
        this.showload = false;

        this.showProgress = true;
        this.btncontinuar = false;
      }

      /*if (!this.show1) {
        if (this.seleccionCompanies === null) {
          Swal.fire("Error!", "Seleccione una empresa", "warning");
        } else if (this.nombrefile === "") {
          Swal.fire("Error!", "Seleccione un archivo", "warning");
        } else {
          if (this.errorCargaArchivo) {
            this.showerror = true;
          } else {
            if (!this.showload) {
              this.showload = true;
              this.randomValue();
            } else {
              if (this.importId == 0) {
                this.importEmpresa();
                this.show1 = true;
                this.show2 = false;
                this.show3 = false;
                this.cabecera1 = false;
                this.cabecera2 = true;
                this.showload = false;
              }
            }
          }
        }
      } else {
        if (this.importId != 0) {
          this.importEmpleados();
          this.detailImport();
        }
      }*/
    },
    async importEmpresa() {
      let empresa_id = this.seleccionCompanies;
      let token = null;
      token = sessionStorage.token;

      try {
        await axios
          .get(
            `v1/company/${empresa_id}/import`,
            //`https://devapi.smartdoctor.pe/v1/company/${empresa_id}/import`,
            {
              headers: {
                //Authorization: "token 85ba358db6697d27c1487e1b2a8552852dbf16dd"
                Authorization: `token ${token}`
              },
              params: {}
            }
          )
          .then(data => {
            if (data.data.success === true) {
              ///console.log(data.data.data.rows[0].id);
              this.detailImport(data.data.data.rows[0].id);
              //this.importId = data.data.data.rows[0].id;
              //this.company_name = data.data.data.rows[0].company_name;
              ///localStorage.setItem("importId", data.data.data.rows[0].id);
            } else {
              //Swal.fire("Error!", data.data.message, "warning");
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    async detailImport(importId) {
      console.log(importId);
      let token = null;
      token = sessionStorage.token;
      try {
        await axios
          ///https://devapi.smartdoctor.pe/v1/import/
          .get(`v1/import/${importId}`, {
            headers: {
              //Authorization: "token 85ba358db6697d27c1487e1b2a8552852dbf16dd"
              Authorization: `token ${token}`
            },
            params: { status: 2 }
          })
          .then(data => {
            if (data.data.success === true) {
              this.totalerror = data.data.data.total_rows;
              this.datadetailimport = data.data.data.rows;
              /*console.log(this.totalerror);
              console.log(this.datadetailimport);*/
              /*data.data.data.rows.forEach(rows => {
                if (rows.status === "2") {
                  //this.showerror = true;
                  //Swal.fire("Error!", data.data.message, "error");
                  return;
                } else {
                  //Swal.fire("Exito!", data.data.message, "success");
                }
              });*/
            } else {
              Swal.fire("Error!", data.data.message, "error");
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    async employeeImportValidate() {
      let company = this.seleccionCompanies;
      let token = null;
      token = sessionStorage.token;

      try {
        let fd = new FormData();
        fd.append("attached", this.nombrefile);
        fd.append("company", company);

        await axios
          .post(`v1.1/business/employee-import-validate`, fd, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `token ${token}`
            }
          })
          .then(response => {
            if (response.status == 200) {
              (this.mensajeCargaArchivo = "Validación Completa!"),
                (this.show1 = false);
              this.show2 = false;
              this.show3 = false;
              this.cabecera1 = false;
              this.cabecera2 = false;
              this.showload = false;

              this.showProgress = true;
              this.btncontinuar = false;

              Swal.fire(
                "La validación esta completa!",
                "Tu archivo excel, no contiene errores.",
                "success"
              );

              let interval = setInterval(() => {
                if (this.percentage < 100) {
                  this.percentage += 10;
                } else {
                  clearInterval(interval);

                  //this.showProgress = false;
                  localStorage.setItem("success_importacion", true);
                  this.$router.go();
                  // this.showlisterror = true;

                  /*this.$router.push({
                      name: "Panel Empresa"
                    });       */
                }
              }, 3000);
            } else {
              console.log(response);
            }
          });
      } catch (error) {
        this.listerrors = error.response.data;
        this.showlisterror = true;
        this.showload = false;
        this.show2 = false;
        this.cabecera1 = false;
        this.totalerror = this.listerrors.length;
        Swal.fire("Error", "Su archivo contiene errores, a continuacion se detalla la lista de errores.", "error");
      }
    },
    async importEmpleados() {
      let empresa_id = this.seleccionCompanies;
      let token = null;
      token = sessionStorage.token;

      try {
        let formData = new FormData();
        formData.append("file", this.nombrefile);
        await axios
          .post(`v1/company/${empresa_id}/import`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `token ${token}`
            },
            params: {}
          })
          .then(response => {
            if (response.data.success === true) {
              let interval = setInterval(() => {
                if (this.percentage < 100) {
                  this.percentage += 10;
                } else {
                  clearInterval(interval);
                  Swal.fire(
                    "La importacion esta completa!",
                    "La informacion que ingresaste ya se encuentra validada. Espera entre 5 a 15 minutos que se actulice en la base correspondiente.",
                    "success"
                  );
                  this.showProgress = false;
                  this.$router.go();
                }
              }, 3000);
            } else {
              Swal.fire("Error", response.data.message, "error");
              this.showProgress = false;
              this.show1 = true;
              this.cabecera2 = true;
              this.btncontinuar = true;
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    validarEmpresa() {
      if (this.seleccionCompanies == 0) {
        Swal.fire("Error!", "Seleccione una empresa", "warning");
      }
    },
    validarArchivoExcel() {
      this.showText(this.seleccionCompanies);

      if (this.nombrefile !== "") {
        this.showload = true;
        //this.randomValue();

        this.employeeImportValidate();

        this.btncontinuar = false;
        this.show2 = true;
        this.show3 = false;
        //this.importEmpresa();
      }
    },
    // Subir archivo
    changeFile(ev) {
      // console.log(ev.target.files[0].type);
      if (
        ev.target.files[0].type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        ev.target.files[0].type == "application/vnd.ms-excel"
      ) {
        try {
          this.nombrefile = ev.target.files[0];
          let f = ev.target.files[0],
            reader = new FileReader();
          reader.onload = e => {
            let data = e.target.result;

            this.wb = XLSX.read(data, { type: "array" });

            var sheetNames = this.wb.SheetNames; // colección de nombres de hojas de trabajo

            var worksheet = this.wb.Sheets[sheetNames[this.defaultIndex]]; // Aquí solo leemos la primera hoja

            var json = XLSX.utils.sheet_to_json(worksheet);

            this.pagination.total = json.length;

            this.contactos = json;
            if (this.seleccionCompanies != 0) {
              this.showload = true;

              this.employeeImportValidate();
              //this.randomValue();

              this.btncontinuar = false;
              this.show2 = true;
              this.show3 = false;
              //this.importEmpresa();
            }
          };
          reader.readAsArrayBuffer(f);
        } catch (error) {
          this.errorCargaArchivo = true;
        }
        if (this.seleccionCompanies == 0) {
          Swal.fire(
            "Informacion!",
            "Debe seleccionar una empresa",
            "information"
          );
        }
      } else {
        Swal.fire(
          "Informacion!",
          "Solo se admite archivos Excel",
          "information"
        );
      }
    },
    selectSheet(num) {
      this.defaultIndex = num;
      var sheetNames = this.wb.SheetNames; // colección de nombres de hojas de trabajo
      var worksheet = this.wb.Sheets[sheetNames[this.defaultIndex]]; // Aquí solo leemos la primera hoja
      var csv = XLSX.utils.sheet_to_csv(worksheet);
      // document.getElementById("result").innerHTML = this.csv2table(csv);
      var txt = XLSX.utils.sheet_to_txt(worksheet);
      var html123 = XLSX.utils.sheet_to_html(worksheet);
      var json = XLSX.utils.sheet_to_json(worksheet);
      document.getElementById("result").innerHTML = html123;
    },
    /*onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        console.log("rows:", rows);
        this.contactos = rows;
      });
    },
    previewFiles(event) {
      console.log(event.target.files);
    },
    readExcel(e) {
      console.log(e.target.files);
      let file = e.target.files;
      const types = file.name.split(".")[1];
      const fileType = ["xlsx", "xlc", "xlm", "xls", "xlt", "xlw", "csv"].some(
        (item) => item == types
      );
      if (!fileType) {
        alert("¡Error de formato! Vuelva a seleccionar");
        return;
      }

      const reader = new FileReader();
      let result = [];
      reader.onload = function (e) {
        const data = e.target.result;
        const wb = XLSX.read(data, {
          type: "binary",
        });
        wb.SheetNames.forEach((sheetName) => {
          result.push({
            sheetName: sheetName,
            sheet: XLSX.utils.sheet_to_json(wb.Sheets[sheetName]),
          });
        });
      };
      reader.readAsBinaryString(file.raw);

      return result;
    },
    handleFileLoad(e) {
      console.log(e.target.files);

      if (e.target.files.length > 0) {
        try {
          this.setState({ operationInProgress: true, fileLoadProgress: 0 });
          let file = e.target.files[0];
          let reader = new FileReader();

          reader.onload = async function (e) {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            let worksheet = workbook.Sheets[workbook.SheetNames[0]];
            let sheet = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            let dataset = await this.parseFileContent(sheet);
            this.setState({
              dataset: dataset,
              fileLoaded: true,
              fileName: file.name,
              operationInProgress: false,
            });
          }.bind(this);
          reader.readAsArrayBuffer(file);
        } catch (exception) {
          this.setState({
            fileLoaded: false,
            fileName: "",
            operationInProgress: false,
          });
        }
        console.log(data);
      } else {
        toast("No files found", { type: "error" });
      }
    },*/
    async getCompanies() {
      let country_id = null;
      let name = null;
      let code = null;

      let token = null;
      token = sessionStorage.token;

      try {
        const res = await axios.get(
          `v1/companies`,
          //`https://devapi.smartdoctor.pe/v1/companies`,
          {
            headers: {
              Authorization: `token ${token}`
            },
            params: {
              page: 1,
              size: 100,
              name: name,
              code: code,
              country_id
            }
          }
        );
        ///this.companies = res.data.data.rows;
        this.companies = res.data.data.rows.map(el => ({
          ...el,
          name: this.capitalize(el.name)
        }));
      } catch (error) {
        console.error(error);
      }
    },
    download_excel() {
      /*let data = XLSX.utils.json_to_sheet(this.datadetailimport);
      const workbook = XLSX.utils.book_new();
      const filename = "detalleimportacion-smart";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);*/

      let data = XLSX.utils.json_to_sheet(this.listerrors, {
        header: ["message"]
      });
      data["A1"].v = "Lista de errores";
      const workbook = XLSX.utils.book_new();
      const filename = "detallevalidacion-smart";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);

      /* let data = XLSX.utils.json_to_sheet(this.dataToExport, {
        header: ["id", "created", "status", "status_display","number_row","content","validations"]
      });
      data["A1"].v = "Id";
      data["B1"].v = "Creacion";
      data["C1"].v = "Estado";
      data["D1"].v = "DescripcionEstado";
      data["E1"].v = "NumeroErrores";
      data["F1"].v = "Contenido";
      data["G1"].v = "Validaciones";
      const workbook = XLSX.utils.book_new();*/
    },
    encodeQueryData(data) {
      let result = [];
      for (let d in data) {
        if (data[d] != null && data[d] != "")
          result.get(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
      }

      return result.join("&");
    }
  }
};
</script>
<style lang="css">
.el-table__body-wrapper {
  height: auto;
  overflow: auto;
}
.image-upload > input {
  display: none;
}
.image-upload img {
  width: 70px;
  cursor: pointer;
}
div.scroll {
  width: auto;
  height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  padding: 20px;
}
</style>
