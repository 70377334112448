<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <side-bar>
      <user-menu></user-menu>
      <mobile-menu></mobile-menu>
      <template slot="links">
        <!-- <sidebar-item
          v-for="(menu, index) in sidebarLinks"
          :key="menu.name + index"
          :link="{ name: menu.name, icon: menu.icon }"
        >
          <sidebar-item
            v-for="(children, index) in menu.children"
            :key="children.name + index"
            :link="{
              name: children.name,
              path: '/' + children.url
            }"
            v-show="children.permission"
          >
          </sidebar-item>
        </sidebar-item> -->
        <sidebar-item
          :link="{ name: 'Funciones Generales', icon: 'nc-icon nc-app' }"
        >
          <sidebar-item
            :link="{
              name: 'Programar teleconsulta',
              path: '/admin/programarteleconsulta'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Reprogramar teleconsulta',
              path: '/admin/reprogramarteleconsulta'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Programación Horario Mes',
              path: '/admin/programacionhorariomes'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Horarios Programados',
              path: '/admin/horariosprogramados'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Panel Estado Teleconsultas',
              path: '/admin/panelestadosteleconsultas'
            }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{ name: 'Gestionar Empresas', icon: 'nc-icon nc-bag' }"
        >
          <sidebar-item
            :link="{
              name: 'Registrar Empresas',
              path: '/admin/registrarempresas'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Empresas Registradas',
              path: '/admin/panelempresa'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Importar Colaboradores',
              path: '/admin/importartrabajadores'
            }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{ name: 'Reportes', icon: 'nc-icon nc-notes' }">
          <sidebar-item
            :link="{
              name: 'Teleconsultas programadas',
              path: '/admin/teleconsultas'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Atenciones B2B Globales',
              path: '/admin/rptatencionesb2bglobales'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Atenciones B2B Médico',
              path: '/admin/rptatencionesb2b'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Reporte Kpis',
              path: '/admin/reportekpis'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'SD v1.1',
              path: '/admin/sd-v1-1'
            }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item :link="{ name: 'Bienestar', icon: 'nc-icon nc-bullet-list-67' }">
          <sidebar-item
            :link="{
              name: 'Evaluación de bienestar',
              path: '/admin/listado-evaluacion-bienestar'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Reporte de bienestar',
              path: '/admin/listado-reporte-bienestar'
            }"
          ></sidebar-item>
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import { Notification } from "element-ui";

import axios from "axios";
import dayjs from "dayjs";
require("dayjs/locale/es");

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu,
    [Notification.name]: Notification
  },
  data() {
    return {
      email: null,
      sidebarLinks: [],
      teleconsultasRows: 0,
      count_notification_warning: 0,
      count_notification_success: 0,
      count_notification: 0,
      interval: null,
      teleconsultas: [],
      teleconsultasNew: [],
      teleconsultasNotificadas: [],
      date: new Date(),
      currentDay: dayjs(this.date).format("YYYY-MM-DD"),
      currentHour: dayjs(this.date).format("H:mm:ss")
    };
  },
  methods: {
    async loadMenu() {
      let usuario_id = localStorage.getItem("usuario_id");
      try {
        let token = null;
        token = sessionStorage.token;

        const res = await axios.get(`v1.1/menu/profile`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            "user-id": usuario_id
          }
        });

        if (res.data.count > 0) {
          res.data.results.forEach(child => {
            child.menu.forEach(detalle => {
              detalle.menu.forEach(menu => {
                if (menu.permission) {
                  this.sidebarLinks.push(menu);
                }
              });
            });
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    obtenerMinutos: function(hora) {
      var spl = hora.split(":");
      return parseInt(spl[0]) * 60 + parseInt(spl[1]);
    },
    async notificationTeleconsultasWarning() {
      let notification_warning = false;
      this.teleconsultas.forEach(ct => {
        if (dayjs().isAfter(dayjs(ct.scheduled_date2))) {
          let date = new Date();
          let fecha_teleconsulta = new Date(ct.scheduled_date2);
          let diferenciaminutos = 0;
          diferenciaminutos = date - fecha_teleconsulta;
          let minutosTranscurridos = diferenciaminutos / 60000;
          minutosTranscurridos = Math.round(minutosTranscurridos);
          if (minutosTranscurridos == 2) {
            this.count_notification_warning =
              this.count_notification_warning + 1;
            if (this.count_notification_warning <= 1) {
              Notification.warning({
                duration: 0,
                title: "Hora Vencida",
                message:
                  "El Doctor " +
                  ct.medico +
                  " No ha iniciado aún su videollamada de las " +
                  ct.scheduled_hora_formato +
                  " la hora venció hace 2 minutos"
              });
              notification_warning = true;
            }
          } else if (
            minutosTranscurridos == 3 &&
            notification_warning == false
          ) {
            this.count_notification_warning = 0;
            this.count_notification_warning =
              this.count_notification_warning + 1;
            if (this.count_notification_warning <= 1) {
              Notification.warning({
                duration: 0,
                title: "Hora Vencida",
                message:
                  "El Doctor " +
                  ct.medico +
                  " No ha iniciado aún su videollamada de las " +
                  ct.scheduled_hora_formato +
                  " la hora venció hace 3 minutos"
              });
              notification_warning = true;
            }
          } else if (this.count_notification_warning == 20) {
            this.count_notification_warning = 0;
            notification_warning = false;
          }
        }
      });
    },
    async notificationTeleconsultas2() {
      try {
        let typeattention,
          company,
          doctor,
          specialty = null;

        let token = null;
        token = sessionStorage.token;

        const res = await axios.get(`v1.1/pro/medical-attention`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: 1,
            limit: 500,
            "type-attention": typeattention,
            company: company,
            doctor: doctor,
            specialty: specialty,
            "date-start": this.currentDay,
            "date-end": this.currentDay,
            status: "scheduled"
          }
        });

        if (res.data.count > 0) {
          this.teleconsultas = res.data.results.map(el => ({
            ...el,
            medico:
              el.doctor_detail.first_name + " " + el.doctor_detail.last_name,
            paciente:
              el.patient_detail == null && el.user_detail != null
                ? el.user_detail.first_name + " " + el.user_detail.last_name
                : el.patient_detail == null && el.employee_detail != null
                ? el.employee_detail.first_name +
                  " " +
                  el.employee_detail.last_name
                : el.patient_detail.first_name +
                  " " +
                  el.patient_detail.last_name
            // paciente:
            //   el.patient_detail == null
            //     ? el.user_detail.first_name + " " + el.user_detail.last_name
            //     : el.patient_detail.first_name +
            //       " " +
            //       el.patient_detail.last_name
          }));

          this.teleconsultas.forEach(ct => {
            let diff_min_scheduled_date = null;
            let diff_min_date = null;
            let date = new Date();

            if (dayjs().isBefore(dayjs(ct.scheduled_date))) {
              diff_min_scheduled_date = dayjs(ct.scheduled_date).diff(
                dayjs(),
                "minute",
                true
              );
              diff_min_scheduled_date = Math.round(diff_min_scheduled_date);

              diff_min_date = dayjs(date).diff(dayjs(), "minute", true);
              diff_min_date = diff_min_date + 60;
              diff_min_date = Math.round(diff_min_date);

              console.log(
                "openNotification",
                "diff_min_scheduled_date",
                diff_min_scheduled_date,
                "diff_min_date",
                diff_min_date,
                ct.scheduled_date,
                this.date
              );

              if (diff_min_scheduled_date <= diff_min_date) {
                let isNew;
                isNew = true;

                this.teleconsultasNotificadas.forEach(ctNotificaciones => {
                  if (parseInt(ct.id) == parseInt(ctNotificaciones.id)) {
                    isNew = false;
                  }
                });

                if (isNew) {
                  this.openNotification(
                    "Registro de Teleconsulta",
                    ct.medico,
                    ct.paciente,
                    ct.company,
                    ct.scheduled_date
                  );

                  let notificacion = {
                    id: ct.id
                  };
                  this.teleconsultasNotificadas.push(notificacion);
                }

                console.log(
                  "this.teleconsultasNotificadas",
                  this.teleconsultasNotificadas
                );
              }
            }
          });
        } else {
          this.teleconsultas = [];
        }
      } catch (error) {
        console.error(error);
      }

      ///this.notificationWarningTeleconsultas();
    },
    openNotification(title, medico, paciente, company, scheduled_date) {
      setTimeout(() => {
        Notification.success({
          duration: 0,
          title: title,
          message:
            "Se programó una Teleconsulta para el Doctor " +
            medico +
            " con el paciente " +
            paciente +
            " de la empresa " +
            company +
            ", agendada a las " +
            dayjs(scheduled_date).format("H:mm")
        });
      }, 1000);
    },
    async notificationWarningTeleconsultas() {
      let date = new Date();
      let currentHour = dayjs(date).format("H:mm:ss");

      this.teleconsultas.forEach(ct => {
        let hora1 = ct.scheduled_hora.split(":");
        let hora2 = currentHour.split(":");
        let t1 = new Date();
        let t2 = new Date();

        t1.setHours(hora1[0], hora1[1], hora1[2]);
        t2.setHours(hora2[0], hora2[1], hora2[2]);

        //Aquí hago la resta
        t1.setHours(
          t1.getHours() - t2.getHours(),
          t1.getMinutes() - t2.getMinutes(),
          t1.getSeconds() - t2.getSeconds()
        );
        // if (hora1 > hora2) {
        //60, 15, 10 y 5
        //console.log(t1.getMinutes());
        if (t1.getMinutes() == 0) {
          //notifications = true;
          // medico = ct.medico;
          // scheduled_hora = ct.scheduled_hora_formato;
          // console.log(ct.scheduled_hora);

          this.count_notification = this.count_notification + 1;
          if (this.count_notification <= 1) {
            Notification.warning({
              duration: 0,
              title: "Hora Vencida",
              message:
                "El Doctor " +
                ct.medico +
                " No ha iniciado aún su videollamada de las " +
                ct.scheduled_hora_formato
            });
          }
        }
        //}
      });
      // if (notifications == true) {
      //   this.count_notification = this.count_notification + 1;
      //   if (this.count_notification <= 1) {
      //     Notification.warning({
      //       duration: 0,
      //       title: "Hora Vencida",
      //       message:
      //         "El Doctor " +
      //         medico +
      //         " No ha iniciado aún su videollamada de las " +
      //         scheduled_hora
      //     });
      //   }
      // }
    },
    async notificationTeleconsultas() {
      let date = new Date();
      let currentHour = dayjs(date).format("H:mm:ss");
      let notifications = false;
      let medico,
        scheduled_hora_formato = "";

      this.teleconsultas.forEach(ct => {
        let hora1 = ct.scheduled_hora.split(":");
        let hora2 = currentHour.split(":");
        let t1 = new Date();
        let t2 = new Date();

        t1.setHours(hora1[0], hora1[1], hora1[2]);
        t2.setHours(hora2[0], hora2[1], hora2[2]);

        //Aquí hago la resta
        t1.setHours(
          t1.getHours() - t2.getHours(),
          t1.getMinutes() - t2.getMinutes(),
          t1.getSeconds() - t2.getSeconds()
        );
        if (hora1 > hora2) {
          //60, 15, 10 y 5

          if (
            t1.getHours() == 1 ||
            t1.getMinutes() == 15 ||
            t1.getMinutes() == 10 ||
            t1.getMinutes() == 5
          ) {
            notifications = true;
            medico = ct.medico;
            scheduled_hora_formato = ct.scheduled_hora_formato;
          }
        }
      });
      if (notifications == true) {
        this.count_notification = this.count_notification + 1;
        if (this.count_notification <= 3) {
          Notification.info({
            duration: 6500,
            title: "Info",
            message:
              "El especialista: " +
              medico +
              " tiene hoy a las: " +
              scheduled_hora_formato +
              "hrs" +
              " 01 tele-consulta"
          });
        }
      }
    },
    async notificationSuccessTeleconsultas() {
      let notification_success = false;
      let medico,
        scheduled_hora_formato,
        paciente,
        company = "";

      this.teleconsultas.forEach(ct => {
        if (dayjs().isAfter(dayjs(ct.created))) {
          console.log(ct.created);
          let date = new Date();
          let currentHour = dayjs(date).format("H:mm");
          let createdHour = dayjs(ct.created).format("H:mm");
          let createdHour2 = dayjs(ct.created).format("H");
          let createmin = dayjs(ct.created).format("mm");
          // console.log(
          //   "currentHour: " +
          // );
          if (currentHour == createdHour) {
            medico = ct.medico;
            scheduled_hora_formato = ct.scheduled_hora_formato;
            paciente = ct.paciente;
            company = ct.company;
            this.count_notification_success =
              this.count_notification_success + 1;
            if (this.count_notification_success <= 1) {
              Notification.success({
                duration: 0,
                title: "Registro de Teleconsulta",
                message:
                  "Se programó una Teleconsulta para el Doctor " +
                  medico +
                  " a las: " +
                  scheduled_hora_formato +
                  " con el paciente " +
                  paciente +
                  " de la empresa " +
                  company
              });
              notification_success = true;
            }
          } else if (
            currentHour == createdHour2 + ":" + (parseInt(createmin) + 1) &&
            notification_success == false
          ) {
            medico = ct.medico;
            scheduled_hora_formato = ct.scheduled_hora_formato;
            paciente = ct.paciente;
            company = ct.company;
            this.count_notification_success =
              this.count_notification_success + 1;
            if (this.count_notification_success <= 1) {
              Notification.success({
                duration: 0,
                title: "Registro de Teleconsulta",
                message:
                  "Se programó una Teleconsulta para el Doctor " +
                  medico +
                  " a las: " +
                  scheduled_hora_formato +
                  " con el paciente " +
                  paciente +
                  " de la empresa " +
                  company
              });
            }
          } else if (this.count_notification_success == 20) {
            this.count_notification_success = 0;
          }
        }
      });
      // if (notifications == true) {
      //   this.count_notification = this.count_notification + 1;
      //   if (this.count_notification <= 1) {
      //     Notification.success({
      //       duration: 0,
      //       title: "Registro de Teleconsulta",
      //       message:
      //         "Se programó una Teleconsulta para el Doctor " +
      //         medico +
      //         " a las: " +
      //         scheduled_hora_formato +
      //         " con el paciente " +
      //         paciente +
      //         " de la empresa " +
      //         company
      //     });
      //   }
      // }
    },
    async getTeleconsultas() {
      try {
        let typeattention,
          company,
          doctor,
          specialty = null;

        let token = null;
        token = sessionStorage.token;

        const res = await axios.get(`v1.1/pro/medical-attention`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: 1,
            limit: 500,
            "type-attention": typeattention,
            company: company,
            doctor: doctor,
            specialty: specialty,
            "date-start": this.currentDay,
            "date-end": this.currentDay,
            status: "scheduled"
          }
        });

        if (res.data.count > 0) {
          this.teleconsultas = res.data.results.map(el => ({
            ...el,
            scheduled_date: dayjs(el.scheduled_date).format(
              "DD-MM-YYYY, H:mm:ss"
            ),
            scheduled_date2: el.scheduled_date,
            scheduled_hora: dayjs(el.scheduled_date).format("H:mm:ss"),
            scheduled_hora_formato: dayjs(el.scheduled_date).format("H:mm"),
            created_hora: dayjs(el.created).format("H:mm:ss"),
            medico:
              el.doctor_detail.first_name + " " + el.doctor_detail.last_name,
            paciente:
              el.patient_detail == null
                ? el.user_detail.first_name + " " + el.user_detail.last_name
                : el.patient_detail.first_name +
                  " " +
                  el.patient_detail.last_name
          }));
        } else {
          this.teleconsultas = [];
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getTeleconsultasNew() {
      console.log("TelNew");
      try {
        let typeattention,
          company,
          doctor,
          specialty = null;

        let token = null;
        token = sessionStorage.token;

        const res = await axios.get(`v1.1/pro/medical-attention`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: 1,
            limit: 500,
            "type-attention": typeattention,
            company: company,
            doctor: doctor,
            specialty: specialty,
            // "date-start": this.currentDay,
            // "date-end": this.currentDay,
            status: "scheduled"
          }
        });

        if (res.data.count > 0) {
          if (
            this.teleconsultasRows != 0 &&
            res.data.count > this.teleconsultasRows
          ) {
            let isNew;
            res.data.results.forEach(ct => {
              isNew = true;
              this.teleconsultasNew.data.results.forEach(ctLocal => {
                if (parseInt(ct.id) == parseInt(ctLocal.id)) {
                  isNew = false;
                }
              });

              if (isNew) {
                let paciente = null;
                let doctor = null;
                let scheduled_hora = null;
                let scheduled_date = null;

                doctor =
                  ct.doctor_detail.first_name +
                  " " +
                  ct.doctor_detail.last_name;
                scheduled_hora = dayjs(ct.scheduled_date).format("HH:mm A");
                scheduled_date = dayjs(ct.scheduled_date).format("DD-MM-YYYY");
                if (ct.patient_detail == null) {
                  paciente =
                    ct.user_detail.first_name + " " + ct.user_detail.last_name;
                } else {
                  paciente =
                    ct.patient_detail.first_name +
                    " " +
                    ct.patient_detail.last_name;
                }

                Notification.success({
                  duration: 0,
                  title: "Registro de Teleconsulta",
                  message:
                    "Se programó una Teleconsulta para el Doctor " +
                    doctor +
                    " para el día " +
                    scheduled_date +
                    " a las " +
                    scheduled_hora +
                    " con el paciente " +
                    paciente +
                    " de la empresa " +
                    ct.company
                });
              }
            });
          }
          this.teleconsultasNew = res;
          this.teleconsultasRows = res.data.count;
        } else {
          this.teleconsultas = [];
        }
      } catch (error) {
        console.error(error);
      }
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("main-panel");

        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    }
  },
  mounted() {
    //this.loadMenu();
    this.initScrollbar();
    //this.getTeleconsultasNew();
    //this.getTeleconsultas();
    
    /*this.notificationTeleconsultas2();
    this.interval = setInterval(
      async function() {
        //await this.getTeleconsultasNew();
        //await this.getTeleconsultas();
        await this.notificationTeleconsultas2();
      }.bind(this),
      60000
    );*/
    
    if (localStorage.getItem("email")) {
      this.email = localStorage.getItem("email");
      console.log(this.email);
    }
  },
  beforeDestroy() {
    window.clearInterval(this.interval);
  }
};
</script>
