<template>
  <div>
    <loading
      :height="60"
      :width="60"
      color="#1873EE"
      background-color="white"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
    ></loading>
    <el-card shadow="always" style="border-radius:25px">
      <div slot="header">
        <span>DOCTOR</span>
        <el-input
          placeholder="Search"
          class="input-with-select"
          v-model="filterDoctors"
          size="small"
          style="float: right; padding: 3px 0;width:261px"
          clearable
          suffix-icon="el-icon-search"
        >
          <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
        </el-input>
      </div>
      <div>
        <el-table style="width: 100%" :data="doctors_schedule" stripe>
          <el-table-column
            :index="indexMethod"
            width="70"
            label="N°"
            type="index"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="doctor_name"
            min-width="250"
            label="Doctor"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="speciality"
            min-width="260"
            label="Especialidad"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="country"
            min-width="250"
            label="País"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="schedule"
            min-width="260"
            label="Horarios Programados"
            align="center"
          >
          </el-table-column>
          <el-table-column min-width="105" fixed="right" label="Acciones">
            <template slot-scope="props_id">
              <div class="row">
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link" style="margin-left: 40px">
                    <i class="el-icon-caret-bottom el-icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item class="clearfix">
                      <el-button
                        type="text"
                        @click="loadEditHorario(props_id.row)"
                        >Editar Horario</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item class="clearfix">
                      <el-button
                        type="text"
                        @click="deleteDoctorSchedule(props_id.row.id)"
                        >Eliminar Horario</el-button
                      >
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <br />
        <div
          slot="footer"
          class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
        >
          Showing {{ pagination.currentPage2 }} to {{ pagination.perPage2 }} of
          {{ pagination.total }}

          <l-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </l-pagination>
        </div>
      </div>
    </el-card>
    <el-dialog title="" :visible.sync="dialogError" width="30%" top="1%">
      <div class="col">
        <el-result
          icon="error"
          title="Error"
          subTitle="Por favor siga las instrucciones"
        >
          <template slot="extra">
            <el-button type="primary" size="medium" @click="dialogError = false"
              >Cerrar</el-button
            >
          </template>
        </el-result>
        <div
          v-for="result in resulterrors"
          :key="result.id"
          style="font-size:13px;"
        >
          {{ result.toString() }}
        </div>
      </div>
    </el-dialog>
    <el-dialog title="" :visible.sync="dialogErrorStatus" width="30%" top="1%">
      <div class="col">
        <el-result
          icon="warning"
          title="¡ATENCIÓN!"
          subTitle="Hubo un error al procesar su solicitud"
        >
          <template slot="extra">
            <el-button
              type="primary"
              size="medium"
              @click="dialogErrorStatus = false"
              >Cerrar</el-button
            >
          </template>
        </el-result>
        <div style="font-size:16px;">
          {{ resulterrorsStatus }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  Card,
  Input,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Dialog,
  Result
} from "element-ui";
import { Loading, Pagination as LPagination } from "src/components/index";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "HorariosProgramados",
  components: {
    Loading,
    LPagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Card.name]: Card,
    [Input.name]: Input,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Dialog.name]: Dialog,
    [Result.name]: Result
  },
  data() {
    return {
      resulterrors: [],
      dialogError: false,
      resulterrorsStatus: "",
      dialogErrorStatus: false,

      filterDoctors: null,
      doctors_schedule: [],
      isLoading: false,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [4, 15, 25, 50],
        total: 0,
        currentPage2: 1,
        perPage2: 20
      }
    };
  },
  watch: {
    "pagination.currentPage"() {
      this.asyncgetDoctorsSchedule();
      this.Showingpagination();
    },
    filterDoctors() {
      //this.pagination.currentPage = 1;
      this.asyncgetDoctorsSchedule();
      this.Showingpagination();
    }
  },
  mounted() {
    if (!sessionStorage.isLogged) {
      this.$router.go(-1);
      return;
    }
    this.asyncgetDoctorsSchedule();
  },
  methods: {
    loadEditHorario(row) {
      //console.log(row);
      this.$router.push({
        path: "editarhorariomes",
        query: {
          row: row
        }
      });
    },
    async deleteDoctorSchedule(id) {
      let token = null;
      token = sessionStorage.token;

      Swal.fire({
        title: "¿Desea eliminar el horario?",
        text: "Revise su información correctamente",
        ///icon: 'warning',
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "CONFIRMAR",
        cancelButtonText: "CANCELAR",
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          try {
            axios
              .delete(`v1.1/pro/doctor-schedule/${id}`, {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `token ${token}`
                },
                params: {}
              })
              .then(response => {
                if (response.status === 200) {
                  Swal.fire(
                    "¡Eliminado!",
                    "Horario se eliminó correctamente",
                    "success"
                  );
                  this.asyncgetDoctorsSchedule();
                } else {
                  this.dialogError = true;
                  this.resulterrors = response.data;
                }
              })
              .catch(error => {
                if (error.response.status === 400) {
                  this.dialogError = true;
                  this.resulterrors = error.response.data.errors;
                } else {
                  this.dialogErrorStatus = true;
                  this.dialogEditEmployeeVisible = false;
                  this.resulterrorsStatus =
                    "Estado de servicio: " + error.response.status;
                }
                // const { non_field_errors } = error.response.data.errors;
              });
          } catch (error) {
            Swal.fire(
              "¡Error!",
              "Ocurrio el siguiente error: " + error,
              "error"
            );
            console.error(error);
          }
        }
      });
    },
    async asyncgetDoctorsSchedule() {
      this.isLoading = true;
      await this.getDoctorsSchedule();
      this.isLoading = false;
    },
    async getDoctorsSchedule() {
      let doctor_name = null;
      let token = null;
      token = sessionStorage.token;

      if (this.filterDoctors) {
        doctor_name = this.filterDoctors;
      }

      try {
        const res = await axios.get(`v1.1/pro/doctor-schedule`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: this.pagination.currentPage,
            limit: 20,
            name: doctor_name,
            "report-a": ""
          }
        });
        if (res.data.count > 0) {
          this.pagination.total = res.data.count;
          /*paciente:
              el.patient_detail == null
                ? el.user_detail.first_name + " " + el.user_detail.last_name
                : el.patient_detail.first_name +
                  " " +
                  el.patient_detail.last_name,*/

          this.doctors_schedule = res.data.results.map(el => ({
            ...el,
            doctor_name:
              el.last_name == null
                ? el.first_name + " " + ""
                : el.first_name + " " + el.last_name
            ///doctor_name: el.first_name + " " + el.last_name
          }));
        }
      } catch (error) {
        console.error(error);
      }

      if (this.pagination.total <= 20) {
        this.pagination.perPage2 = this.pagination.total;
      }
    },
    Showingpagination() {
      this.pagination.perPage2 =
        this.pagination.perPage * this.pagination.currentPage;

      if (this.pagination.currentPage == 1) {
        this.pagination.currentPage2 = 1;
      } else if (this.pagination.currentPage >= 2) {
        this.pagination.currentPage2 = this.pagination.perPage2 - 19;
      }

      if (this.pagination.perPage2 > this.pagination.total) {
        this.pagination.perPage2 = this.pagination.total;
      }
    },
    indexMethod(index) {
      return (
        (this.pagination.currentPage - 1) * this.pagination.perPage +
        (index + 1)
      );
    }
  }
};
</script>

<style></style>
