<template>
  <div>
    <loading
      :height="60"
      :width="60"
      color="#1873EE"
      background-color="white"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
    ></loading>
    <div>
      <el-card shadow="always" style="border-radius:25px">
        <el-descriptions
          :title="`${panel_companie_name}`"
          :column="3"
          size="small"
          border
        >
          <!-- <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              Código
            </template>
            {{ panel_companie_code }}</el-descriptions-item
          > -->
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-tickets"></i>
              Tax ID
            </template>

            <el-link type="primary" @click="retornarPaginaEmpresa">
              {{ panel_companie_ruc }}</el-link
            >
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              País
            </template>
            {{ panel_companie_country_name }}</el-descriptions-item
          >
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-coin"></i>
              Total colaboradores
            </template>
            {{ pagination.total }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-office-building"></i>
              Categoría
            </template>
            {{ panel_companie_category_name }}</el-descriptions-item
          >
        </el-descriptions>
      </el-card>
    </div>
    <br />
    <div class="row">
      <div class="col-md-4">
        <div>
          <el-input
            :placeholder="placeholder"
            v-model="filter"
            class="input-with-select"
            clearable
            ref="filter"
          >
            <template slot="prepend">
              <el-dropdown @command="handleCommand" placement="bottom-start">
                <span class="el-dropdown-link">
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="filtros"
                    ><b>Filtros</b></el-dropdown-item
                  >
                  <el-dropdown-item command="code" :style="stylecode"
                    >Código</el-dropdown-item
                  >
                  <el-dropdown-item command="num_doc" :style="stylenum_doc"
                    >Número de Documento</el-dropdown-item
                  >
                  <el-dropdown-item command="name" :style="stylename"
                    >Nombres/Apellidos</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>

            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </div>
      <div class="col-md-8">
        <div class="text-right">
          <button
            type="submit"
            class="btn btn-fill btn-info btn-wd btn-sm"
            @click="
              dialogCreateEmployeeVisible = true;
              dialogEditEmployeeVisible = false;
            "
          >
            AGREGAR COLABORADOR
          </button>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <el-card shadow="always" style="border-radius:25px">
          <div>
            <!-- <div
              class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            ></div> -->

            <div class="table-responsive table-full-width">
              <el-table
                :data="empleado_detalles"
                border
                style="width: 100%;overflow: auto;"
                empty-text="Sin datos"
              >
                <!-- <el-table-column
                  v-if="show"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column> -->
                <el-table-column min-width="105" label="Código">
                  <template slot-scope="props">
                    <el-button
                      type="text"
                      @click="
                        getEmpleadoId(props.row.code, false, props.row.index)
                      "
                      >{{ props.row.code }}</el-button
                    >
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  v-for="column in tableColumns2"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column> -->
                </el-table-column>
                <el-table-column label="Nº Doc" min-width="130" prop="num_doc">
                </el-table-column>
                <el-table-column
                  label="Nombres"
                  min-width="150"
                  prop="first_name"
                >
                </el-table-column>
                <el-table-column
                  label="Apellidos"
                  min-width="180"
                  prop="last_name"
                >
                </el-table-column>
                <el-table-column
                  label="Sede/Filial"
                  min-width="140"
                  prop="campus_name"
                >
                </el-table-column>
                <!-- <el-table-column
                  label="Puesto"
                  min-width="100"
                  prop="position_name"
                >
                </el-table-column> -->
                <el-table-column min-width="105" fixed="right" label="Acciones">
                  <template slot-scope="props_id">
                    <div class="row">
                      <el-dropdown trigger="click">
                        <span
                          class="el-dropdown-link"
                          style="margin-left: 40px"
                        >
                          <i class="el-icon-caret-bottom el-icon-more"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item class="clearfix">
                            <el-button
                              type="text"
                              @click="
                                verFamiliares(
                                  props_id.row.id,
                                  panel_companie_id,
                                  props_id.row.code,
                                  panel_companie_code
                                )
                              "
                              >Familiares</el-button
                            >
                          </el-dropdown-item>
                          <el-dropdown-item class="clearfix">
                            <el-button
                              type="text"
                              @click="
                                verBeneficiarios(
                                  props_id.row.id,
                                  panel_companie_id,
                                  props_id.row.code,
                                  panel_companie_code
                                )
                              "
                              >Beneficiarios</el-button
                            >
                          </el-dropdown-item>
                          <el-dropdown-item class="clearfix">
                            <el-button
                              type="text"
                              @click="
                                getEmpleadoId(
                                  props_id.row.code,
                                  true,
                                  props_id.row.index
                                )
                              "
                              >Editar</el-button
                            >
                          </el-dropdown-item>
                          <el-dropdown-item class="clearfix">
                            <el-button
                              type="text"
                              @click="
                                deleteEmpleado(
                                  props_id.row.id,
                                  props_id.row.index
                                )
                              "
                              >Eliminar</el-button
                            >
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <el-dialog
                :title="`Código: ${formView.code}`"
                :visible.sync="dialogTableVisible"
                width="30%"
                top="1%"
              >
                <div class="header-text">
                  <!--<label for="Codigo">Código: {{ formView.code }}</label>-->
                  <h6>
                    <label for="first_name"></label>{{ formView.first_name }}
                    {{ formView.last_name }}
                  </h6>

                  <label for="genre_display"></label
                  >{{ formView.genre_display }}
                  <hr />
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <label for="num_doc"><b>Nº Doc:</b></label>
                  </div>
                  <div class="col-md-6">
                    <div class="text-right">
                      <small>{{ formView.num_doc }}</small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="birthdate"><b>Fecha Nacimiento:</b></label>
                  </div>
                  <div class="col-md-6">
                    <div class="text-right">
                      <small>{{ formView.birthdate }}</small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="checkbox"><b>Empresa:</b></label>
                  </div>
                  <div class="col-md-6">
                    <div class="text-right">
                      <small>{{ panel_companie_name }}</small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="campus_name"><b>Sede/Filial</b></label>
                  </div>
                  <div class="col-md-6">
                    <div class="text-right">
                      <small>{{ formView.campus_name }}</small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="position_name"><b>Puesto:</b></label>
                  </div>
                  <div class="col-md-6">
                    <div class="text-right">
                      <small>{{ formView.position_name }}</small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="profile_name"><b>Perfil:</b></label>
                  </div>
                  <div class="col-md-6">
                    <div class="text-right">
                      <small>{{ formView.profile_name }}</small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="number_of_children"
                      ><b>Número de Hijos:</b></label
                    >
                  </div>
                  <div class="col-md-6">
                    <div class="text-right">
                      <small>{{ formView.number_of_children }}</small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="phone"><b>Celular</b></label>
                  </div>
                  <div class="col-md-6">
                    <div class="text-right">
                      <small>{{ formView.phone }}</small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="email"><b>Correo:</b></label>
                  </div>
                  <div class="col-md-6">
                    <div class="text-right">
                      <small>{{ formView.email }}</small>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-6">
                    <button
                      type="submit"
                      class="btn-info btn-sm pull-left"
                      @click="getEmpleadoId(formView.code, true)"
                    >
                      EDITAR
                    </button>
                  </div>
                  <div class="col-md-6">
                    <div class="text-right">
                      <button
                        type="submit"
                        class="btn btn-default btn-sm pull-right"
                        @click="
                          deleteEmpleado(formView.id, formView.index);
                          dialogTableVisible = false;
                        "
                      >
                        ELIMINAR
                      </button>
                    </div>
                  </div>
                </div>
              </el-dialog>
              <el-dialog
                :title="`Editar Colaborador`"
                :visible.sync="dialogEditEmployeeVisible"
                width="50%"
                :close-on-click-modal="false"
                top="1%"
                style="height: 95%;overflow: hidden;"
              >
                <!-- <hr /> -->
                <div class="row">
                  <div class="col-md-12">
                    <div
                      class="card stacked-form"
                      style="position: relative;bottom: 30px;"
                    >
                      <div class="card-body">
                        <ValidationObserver v-slot="{ handleSubmit }">
                          <form @submit.prevent="handleSubmit(updateEmpleado)">
                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label>CÓDIGO</label>
                                  <input
                                    type="text"
                                    v-model="formUpdate.code"
                                    class="form-control"
                                    maxlength="30"
                                    placeholder="CÓDIGO"
                                    required
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label>SEDE</label>
                                  <select
                                    v-model="formUpdate.campus_id"
                                    clearable
                                    placeholder="SEDE"
                                    class="form-control"
                                  >
                                    <option
                                      v-for="item in campus"
                                      :key="item.id"
                                      :label="item.name"
                                      :value="item.id"
                                    >
                                    </option>
                                  </select>
                                </div>
                              </div>

                              
                              <div class="col">
                                <div class="form-group">
                                  <label>Nº DOC</label>
                                  <input
                                    type="text"
                                    v-model="formUpdate.num_doc"
                                    class="form-control"
                                    maxlength="30"
                                    @keypress="onlyNumberCaracterUpdate"
                                    required
                                    ref="num_doc_update"
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label>PERFIL</label>
                                  <select
                                    v-model="formUpdate.profile_id"
                                    clearable
                                    placeholder="PERFIL"
                                    class="form-control"
                                  >
                                    <option
                                      v-for="item in profiles"
                                      :key="item.id"
                                      :label="item.name"
                                      :value="item.id"
                                    >
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label>GÉNERO</label>
                                  <select
                                    clearable
                                    placeholder="Genero"
                                    v-model="formUpdate.genre"
                                    required
                                    class="form-control"
                                  >
                                    <option key="1" label="MUJER" value="1">
                                      MUJER
                                    </option>
                                    <option key="2" label="HOMBRE" value="2">
                                      HOMBRE
                                    </option>
                                    <option key="3" label="OTRO" value="3">
                                      OTRO
                                    </option>
                                    <option key="4" label="PREFIERO NO DECIRLO" value="4">
                                      PREFIERO NO DECIRLO
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <!-- <div class="col">
                                <div class="form-group">
                                  <label>Nº DE HIJOS</label>
                                  <input
                                    min="0"
                                    type="number"
                                    class="form-control"
                                    v-model="formUpdate.number_of_children"
                                    required="true"
                                  />
                                </div>
                              </div> -->
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label>NOMBRE</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="formUpdate.first_name"
                                    placeholder="NOMBRE"
                                    maxlength="100"
                                    @keypress="soloLetras"
                                    required
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label>APELLIDO</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="formUpdate.last_name"
                                    placeholder="APELLIDO"
                                    maxlength="100"
                                    @keypress="soloLetras"
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label>F. NACIMIENTO</label>
                                  <input
                                    type="date"
                                    class="form-control"
                                    v-model="formUpdate.birthdate"
                                    required
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label>Código país</label>
                                  <select
                                    clearable
                                    v-model="prefix"
                                    placeholder="Código país"
                                    class="form-control"
                                  >
                                    <option
                                      v-for="item in countries"
                                      :key="item.prefix"
                                      :label="'+' + item.prefix"
                                      :value="item.prefix"
                                    >
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label>CELULAR</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    maxlength="12"
                                    v-model="phone"
                                    @keypress="onlyNumber"
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label>CORREO</label>
                                  <!-- required -->
                                  <input
                                    type="email"
                                    maxlength="100"
                                    class="form-control"
                                    v-model="formUpdate.email"
                                    pattern="(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}" title="Correo electronico invalido"
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col">
                                <div class="form-group">
                                  <label>DIRECCIÓN</label>
                                  <input
                                    type="text"
                                    maxlength="200"
                                    class="form-control"
                                    v-model="formUpdate.address"
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="form-group">
                                  <label>IDIOMA</label>
                                  <select
                                    clearable
                                    v-model="formUpdate.language_id"
                                    placeholder="Idioma"
                                    required
                                    class="form-control"
                                  >
                                    <option
                                      v-for="item in languages"
                                      :key="item.id"
                                      :label="item.name"
                                      :value="item.id"
                                    >
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <!--
                              <div class="col">
                                <div class="form-group">
                                  <label>Timezone</label>
                                  <select
                                    clearable
                                    v-model="formUpdate.timezone"
                                    placeholder="Timezone"
                                    class="form-control"
                                  >
                                    <option
                                      v-for="item in timezones"
                                      :key="item.id"
                                      :label="item.name"
                                      :value="item.id"
                                    >
                                    </option>
                                  </select>
                                </div>
                              </div>
                              -->
                            </div>

                            <div class="form-group text-center">
                              <button
                                type="submit"
                                class="btn btn-fill btn-info btn-wd btn-sm"
                              >
                                GUARDAR
                              </button>
                            </div>
                          </form>
                        </ValidationObserver>
                      </div>
                    </div>
                  </div>
                </div>
              </el-dialog>
              <el-dialog
                :title="`Agregar Colaborador`"
                :visible.sync="dialogCreateEmployeeVisible"
                width="50%"
                :close-on-click-modal="false"
                top="1%"
                style="height: 95%;overflow: hidden;"
              >
                <div
                  class="card stacked-form"
                  style="position: relative;bottom: 30px;"
                >
                  <div class="card-body">
                    <ValidationObserver v-slot="{ handleSubmit }">
                      <form
                        @submit.prevent="handleSubmit(createEmpleado)"
                        id="formCreateEmploye"
                      >
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <label>CÓDIGO</label>
                              <input
                                type="text"
                                class="form-control"
                                maxlength="100"
                                v-model="formCreate.code"
                                placeholder="CODIGO"
                              />
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label>SEDE</label>
                              <select
                                v-model="formCreate.campus_id"
                                clearable
                                placeholder="SEDE"
                                required
                                class="form-control"
                              >
                                <option
                                  v-for="item in campus"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                                ></option>
                              </select>
                            </div>
                          </div>
                          
                          <div class="col">
                            <div class="form-group">
                              <label>Nº DOC</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="formCreate.num_doc"
                                placeholder="Nº DOC"
                                maxlength="30"
                                minlength="5"
                                @keypress="onlyNumberCaracterCreate"
                                required="true"
                                ref="num_doc_create"
                                id="num_doc_create"
                                @keyup="limpia()"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <label>PERFIL</label>
                              <select
                                v-model="formCreate.profile_id"
                                clearable
                                placeholder="PERFIL"
                                class="form-control"
                              >
                                <option
                                  v-for="item in profiles"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                                ></option>
                              </select>
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label>GÉNERO</label>
                              <select
                                clearable
                                placeholder="Genero"
                                required
                                v-model="formCreate.genre"
                                class="form-control"
                              >
                                <option key="1" label="MUJER" value="1">
                                  MUJER
                                </option>
                                <option key="2" label="HOMBRE" value="2">
                                  HOMBRE
                                </option>
                                <option key="3" label="OTRO" value="3">
                                  OTRO
                                </option>
                                <option key="4" label="PREFIERO NO DECIRLO" value="4">
                                  PREFIERO NO DECIRLO
                                </option>
                              </select>
                            </div>
                          </div>
                          <!-- <div class="col">
                            <div class="form-group">
                              <label>Nº HIJOS</label>
                              <input
                                min="0"
                                type="number"
                                class="form-control"
                                v-model="formCreate.number_of_children"
                                required="true"
                              />
                            </div>
                          </div> -->
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <label>NOMBRE</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="formCreate.first_name"
                                placeholder="NOMBRE"
                                maxlength="100"
                                @keypress="soloLetras"
                                required="true"
                              />
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label>APELLIDO</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="formCreate.last_name"
                                placeholder="APELLIDO"
                                maxlength="100"
                                @keypress="soloLetras"
                                required="true"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <label for="" class="form-label"
                                >F. NACIMIENTO</label
                              >
                              <input
                                type="date"
                                class="form-control"
                                v-model="formCreate.birthdate"
                                placeholder="FECHA NACIMIENTO"
                                min="1920-01-01"
                                max="2004-12-31"
                              />
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label>Código país</label>
                              <select
                                clearable
                                v-model="prefixCreate"
                                placeholder="Código país"
                                class="form-control"
                              >
                                <option
                                  v-for="item in countries"
                                  :key="item.prefix"
                                  :label="'+' + item.prefix"
                                  :value="item.prefix"
                                >
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label for="" class="form-label">CELULAR</label>
                              <input
                                type="text"
                                class="form-control"
                                maxlength="12"
                                v-model="phoneCreate"
                                placeholder="CELULAR"
                                @keypress="onlyNumber"
                              />
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label for="" class="form-label">CORREO</label>
                              <input
                                type="text"
                                class="form-control"
                                maxlength="100"
                                v-model="formCreate.email"
                                placeholder="CORREO"
                                pattern="(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}" title="Correo electronico invalido"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <label for="" class="form-label">DIRECCIÓN</label>
                              <input
                                type="text"
                                class="form-control"
                                maxlength="200"
                                v-model="formCreate.address"
                                placeholder="DIRECCIÓN"
                              />
                            </div>
                          </div>
                        
                          <div class="col">
                            <div class="form-group">
                              <label>Idioma</label>
                              <select
                                clearable
                                v-model="formCreate.language_id"
                                placeholder="Idioma"
                                required
                                class="form-control"
                              >
                                <option
                                  v-for="item in languages"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                                >
                                </option>
                              </select>
                            </div>
                          </div>
                          <!--
                          <div class="col">
                            <div class="form-group">
                              <label>Timezone</label>
                              <select
                                clearable
                                v-model="formCreate.timezone"
                                placeholder="Timezone"
                                class="form-control"
                              >
                                <option
                                  v-for="item in timezones"
                                  :key="item.id"
                                  :label="'+' + item.name"
                                  :value="item.id"
                                >
                                </option>
                              </select>
                            </div>
                          </div>
                          -->
                        </div>

                        <div class="form-group text-center">
                          <button
                            type="submit"
                            class="btn btn-fill btn-info btn-wd btn-sm"
                          >
                            GUARDAR
                          </button>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </el-dialog>
            </div>

            <div
              slot="footer"
              class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              Showing {{ pagination.currentPage2 }} to
              {{ pagination.perPage2 }} of
              {{ pagination.total }}
              <l-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="pagination.total"
              >
              </l-pagination>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <el-dialog title="" :visible.sync="dialogError" width="30%" top="1%">
      <div class="col">
        <el-result
          icon="error"
          title="Error"
        >
          <template slot="extra">
            <div
              style="font-size:14px; padding-bottom: 12px"
            >
              {{ resulterrors }}
            </div>
            <el-button type="primary" size="medium" @click="dialogError = false"
              >Cerrar</el-button
            >
          </template>
        </el-result>
      </div>
    </el-dialog>
    <el-dialog title="" :visible.sync="dialogErrorStatus" width="30%" top="1%">
      <div class="col">
        <el-result
          icon="warning"
          title="¡ATENCIÓN!"
          subTitle="Hubo un error al procesar su solicitud"
        >
          <template slot="extra">
            <el-button
              type="primary"
              size="medium"
              @click="dialogErrorStatus = false"
              >Cerrar</el-button
            >
          </template>
        </el-result>
        <div style="font-size:16px;">
          {{ resulterrorsStatus }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  Popover,
  Tag,
  Descriptions,
  DescriptionsItem,
  Button,
  Dialog,
  Notification,
  Message,
  Steps,
  Step,
  Tabs,
  TabPane,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Badge,
  Card,
  Link,
  Col,
  Result
} from "element-ui";
import {
  Loading,
  Pagination as LPagination,
  FormGroupInput as FgInput,
  StatsCard
} from "src/components/index";
import axios from "axios";
import Swal from "sweetalert2";

import { extend } from "vee-validate";
import { required, email, min, max } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);
extend("min", min);
extend("max", max);

export default {
  name: "Dashboard",
  components: {
    Loading,
    LPagination,
    FgInput,
    StatsCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Popover.name]: Popover,
    [Tag.name]: Tag,
    [Descriptions.name]: Descriptions,
    [DescriptionsItem.name]: DescriptionsItem,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Notification.name]: Notification,
    [Message.name]: Message,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Badge.name]: Badge,
    [Card.name]: Card,
    [Link.name]: Link,
    [Col.name]: Col,
    [Result.name]: Result
  },
  data() {
    return {
      resulterrors: [],
      dialogError: false,
      resulterrorsStatus: "",
      dialogErrorStatus: false,
      placeholder: "Nombres/Apellidos",
      stylename: "color:#167FE2;border-radius:8px",
      stylecode: "",
      stylenum_doc: "",
      activeName: "first",
      filtro_select: "",
      panel_companie_id: 0,
      panel_companie_code: "",
      panel_companie_name: "",
      panel_companie_country_name: "",
      panel_companie_category_name: "",
      panel_companie_ruc: "",
      nombre_empresa: "",
      isLoading: false,
      maxLength: null,
      filter: null,
      ddTestVm: {
        originalValue: [],
        ddTestSelectedOption: "",
        disabled: false,
        readonly: false,
        visible: true,
        color: "",
        options: [
          {
            value: "code",
            text: "Código"
          },
          {
            value: "num_doc",
            text: "Número de Documento"
          },
          {
            value: "name",
            text: "Nombres/Apellidos"
          }
        ]
      },
      show: false,
      prefix: null,
      prefixCreate: null,
      phone: null,
      phoneCreate: null,
      formUpdate: {
        id: "",
        code: "",
        campus_id: "",
        profile_id: "",
        position_id: "",
        tipo_doc: "",
        num_doc: "",
        first_name: "",
        last_name: "",
        genre: "",
        birthdate: "",
        phone: "",
        email: "",
        address: "",
        number_of_children: "0",
        language_id: "",
        timezone: ""
      },
      formCreate: {
        code: "",
        campus_id: "",
        profile_id: "",
        position_id: "",
        tipo_doc: "",
        num_doc: "",
        first_name: "",
        last_name: "",
        genre: "",
        birthdate: "",
        phone: "",
        email: "",
        address: "",
        number_of_children: "0",
        language_id: "",
        timezone: ""
      },
      formView: {
        index: "",
        id: "",
        code: "",
        campus_name: "",
        profile_name: "",
        position_name: "",
        tipo_doc_display: "",
        num_doc: "",
        first_name: "",
        last_name: "",
        genre_display: "",
        birthdate: "",
        phone: "",
        email: "",
        address: "",
        number_of_children: "0"
      },
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [10, 15, 25, 50],
        total: 0,
        currentPage2: 1,
        perPage2: 20
      },
      tableColumns: [
        {
          prop: "index",
          label: "index"
        }
      ],
      tableColumns2: [
        /*{
          prop: "code",
          label: "Código",
          minWidth: 90,
        },*/
        {
          prop: "num_doc",
          label: "Nº Doc",
          minWidth: 125
        },
        {
          prop: "first_name",
          label: "Nombres",
          minWidth: 150
        },
        {
          prop: "last_name",
          label: "Apellidos",
          minWidth: 180
        },
        {
          prop: "campus_name",
          label: "Sede/Filial",
          minWidth: 95
        },
        {
          prop: "position_name",
          label: "Puesto",
          minWidth: 100
        }
      ],
      countries: [],
      companies: [],
      filterCountry: null,
      companiename: null,
      dialogTableVisible: false,
      dialogEditEmployeeVisible: false,
      dialogCreateEmployeeVisible: false,
      empleado_detalles: [],
      campus: [],
      profiles: [],
      positions: [],
      listEmployees: [],
      languages: null,
      timezones: null,
    };
  },
  watch: {
    filtro_select(filtro_select) {
      let filtro = "";
      if (filtro_select == "code") {
        filtro = "código";
      } else if (filtro_select == "name") {
        filtro = "Nombres/Apellidos";
      } else if (filtro_select == "num_doc") {
        filtro = "Número de documento";
      }
      //Message("Seleccionó Filtro: " + filtro);
    },
    "pagination.currentPage"() {
      this.getEmpleadosDetalle();
      this.Showingpagination();
    },
    filterCountry() {
      this.pagination.currentPage = 1;
      this.getEmpleadosDetalle();
    },
    filter() {
      this.pagination.currentPage = 1;
      this.getEmpleadosDetalle();
      this.Showingpagination();
    }
    /*detailEmployees() {
      this.pagination2.currentPage = 1;
      this.getEmpleadosDetalle();
    },*/
  },
  mounted() {
    this.panel_companie_id = localStorage.getItem("panel_companie_id");
    this.panel_companie_code = localStorage.getItem("panel_companie_code");
    this.panel_companie_name = localStorage.getItem("panel_companie_name");
    this.panel_companie_country_name = localStorage.getItem(
      "panel_companie_country_name"
    );
    this.panel_companie_category_name = localStorage.getItem(
      "panel_companie_category_name"
    );
    this.panel_companie_ruc = localStorage.getItem("panel_companie_ruc");

    this.isLoading = true;
    setTimeout(() => (this.isLoading = false), 2 * 1000);

    this.getEmpleadosDetalle();
    this.getCountries();
    //this.getCompanie();
    this.getCampus();
    this.getProfile();
    this.getLanguage();
    this.getTimezone();

    if (!sessionStorage.isLogged) {
      this.$router.go(-1);
      return;
    }
  },
  methods: {
    limpia: function() {
      if (this.formCreate.tipo_doc == "3") {
        var val = document.getElementById("num_doc_create").value;
        var is_num = isNaN(val);
        if (is_num == true) {
          this.formCreate.num_doc = "";
        }
      }
      //console.log(is_num);
    },
    soloLetras($e) {
      var key = $e.keyCode || $e.which;
      var tecla = String.fromCharCode(key).toLowerCase();
      var letras = " áéíóúabcdefghijklmnñopqrstuvwxyz";
      var especiales = [8, 37];

      var tecla_especial = false;
      for (var i in especiales) {
        if (key == especiales[i]) {
          tecla_especial = true;
          break;
        }
      }

      if (letras.indexOf(tecla) == -1 && !tecla_especial) {
        $e.preventDefault();
        return false;
      }
    },
    handleCommand(filtro_select) {
      if (filtro_select == "code") {
        this.stylecode = "color:#167FE2;border-radius:8px";
        this.stylename = "";
        this.stylenum_doc = "";
        this.placeholder = "Código";
      } else if (filtro_select == "name") {
        this.stylecode = "";
        this.stylename = "color:#167FE2;border-radius:8px";
        this.stylenum_doc = "";
        this.placeholder = "Nombres/Apellidos";
      } else if (filtro_select == "num_doc") {
        this.stylecode = "";
        this.stylename = "";
        this.stylenum_doc = "color:#167FE2;border-radius:8px";
        this.placeholder = "Número de Documento";
      }

      this.$refs.filter.focus();
      this.filtro_select = filtro_select;
    },
    capitalize: function(str) {
      let razonSocial = "";
      razonSocial = str.toLocaleLowerCase();
      return razonSocial
        .toLowerCase()
        .trim()
        .split(" ")
        .map(v => v[0].toUpperCase() + v.substr(1))
        .join(" ");
    },
    retornarPaginaEmpresa() {
      this.$router.push({
        name: "PANEL EMPRESA"
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    Showingpagination() {
      this.pagination.perPage2 =
        this.pagination.perPage * this.pagination.currentPage;

      // if (this.pagination.perPage2 > this.pagination.total) {
      //   this.pagination.perPage2 = this.pagination.total;
      // }

      if (this.pagination.currentPage == 1) {
        this.pagination.currentPage2 = 1;
      } else if (this.pagination.currentPage >= 2) {
        ///9//
        this.pagination.currentPage2 = this.pagination.perPage2 - 19;
      }

      if (this.pagination.perPage2 > this.pagination.total) {
        this.pagination.perPage2 = this.pagination.total;
      }
    },
    verFamiliares(idEmpleado, idempresa, code, codempresa) {
      this.$router.push({
        name: "Familiares",
        params: {
          idempleado: idEmpleado,
          empresa: idempresa,
          code: code,
          codeempresa: codempresa
        }
      });
    },
    verBeneficiarios(idEmpleado, idempresa, code, codempresa) {
      this.$router.push({
        name: "Beneficiarios",
        params: {
          idempleado: idEmpleado,
          empresa: idempresa,
          code: code,
          codeempresa: codempresa
        }
      });
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    onlyNumberCaracterCreate($e) {
      if (this.formCreate.tipo_doc == 3) {
        let keyCode = $e.keyCode ? $e.keyCode : $e.which;
        if (keyCode < 48 || keyCode > 57) {
          $e.preventDefault();
        }
      } else {
        var key = $e.keyCode || $e.which;
        var tecla = String.fromCharCode(key).toLowerCase();
        var letras = " áéíóúabcdefghijklmnñopqrstuvwxyz1234567890";
        var especiales = [8, 37];

        var tecla_especial = false;
        for (var i in especiales) {
          if (key == especiales[i]) {
            tecla_especial = true;
            break;
          }
        }

        if (letras.indexOf(tecla) == -1 && !tecla_especial) {
          $e.preventDefault();
          return false;
        }
      }
    },
    onlyNumberCaracterUpdate($e) {
      if (this.formUpdate.tipo_doc == 3) {
        let keyCode = $e.keyCode ? $e.keyCode : $e.which;
        if (keyCode < 48 || keyCode > 57) {
          $e.preventDefault();
        }
      } else {
        var key = $e.keyCode || $e.which;
        var tecla = String.fromCharCode(key).toLowerCase();
        var letras = " áéíóúabcdefghijklmnñopqrstuvwxyz1234567890";
        var especiales = [8, 37];

        var tecla_especial = false;
        for (var i in especiales) {
          if (key == especiales[i]) {
            tecla_especial = true;
            break;
          }
        }

        if (letras.indexOf(tecla) == -1 && !tecla_especial) {
          $e.preventDefault();
          return false;
        }
      }
    },
    validaNumeroDocumento() {
      if (this.formCreate.tipo_doc == 1) {
        this.maxLength = 8;
      } else if (this.formCreate.tipo_doc == 2) {
        this.maxLength = 14;
      } else if (this.formCreate.tipo_doc == 3) {
        this.maxLength = 12;
      }
      this.formCreate.num_doc = "";
      this.$refs.num_doc_create.focus();
    },
    validaNumeroDocumentoEdit() {
      if (this.formUpdate.tipo_doc == 1) {
        this.maxLength = 8;
      } else if (this.formUpdate.tipo_doc == 2) {
        this.maxLength = 14;
      } else if (this.formUpdate.tipo_doc == 3) {
        this.maxLength = 12;
      }
      this.$refs.num_doc_update.focus();
    },
    resetFormCreate() {
      this.formCreate.code = "";
      this.formCreate.campus_id = "";
      this.formCreate.profile_id = "";
      this.formCreate.position_id = "";
      this.formCreate.tipo_doc = "";
      this.formCreate.num_doc = "";
      this.formCreate.first_name = "";
      this.formCreate.last_name = "";
      this.formCreate.genre = "";
      this.formCreate.birthdate = "";
      this.formCreate.phone = "";
      this.formCreate.email = "";
      this.formCreate.address = "";
      this.formCreate.number_of_children = "";
      this.formCreate.language_id = "";
      this.phoneCreate = "";

      //this.formCreateEmploye.reset;
    },
    async updateEmpleado() {
      let empleado_id = this.formUpdate.id;
      let token = null;
      token = sessionStorage.token;
      if (this.prefix && this.prefix != "") {
        this.formUpdate.phone = "+" + this.prefix + "-" + this.phone;
      } else {
        this.formUpdate.phone = this.phone;
      }

      try {
        await axios
          .put(
            `v1/employee/${empleado_id}`,           
            this.formUpdate,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",               
                Authorization: `token ${token}`
              },
              params: {}
            }
          )
          .then(response => {
            console.log(response.data);
            if (response.data.success === true) {
              Swal.fire("Actualizado!", response.data.message, "success");
              this.getEmpleadosDetalle();
              this.dialogEditEmployeeVisible = false;
              this.dialogCreateEmployeeVisible = false;
            } else {
              this.dialogError = true;
              console.log('response.data', response.data);
              this.resulterrors = response.data.message;
              console.log(this.resulterrors);
              this.dialogEditEmployeeVisible = false;
            }
          });
      } catch (error) {
        this.dialogErrorStatus = true;
        this.dialogEditEmployeeVisible = false;
        this.resulterrorsStatus =
          "Estado de servicio: " + error.response.status;
        console.error(error);
      }
    },
    async createEmpleado() {
      ///let empresa_id = this.$route.params.id;
      let empresa_id = this.panel_companie_id;
      let token = null;
      token = sessionStorage.token;

      if (this.prefixCreate && this.prefixCreate != "") {
        this.formCreate.phone = "+" + this.prefixCreate + "-" + this.phoneCreate;
      } else {
        this.formCreate.phone = this.phoneCreate;
      }

      try {
        await axios
          .post(
            `v1/company/${empresa_id}/employees/`,
            //`https://devapi.smartdoctor.pe/v1/company/${empresa_id}/employees/`,
            this.formCreate,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                //Authorization: "token 85ba358db6697d27c1487e1b2a8552852dbf16dd"
                Authorization: `token ${token}`
              },
              params: {}
            }
          )
          .then(response => {
            if (response.data.success === true) {
              Swal.fire("Registrado!", response.data.message, "success");

              this.resetFormCreate();
              this.getEmpleadosDetalle();
              this.dialogCreateEmployeeVisible = false;
              this.dialogEditEmployeeVisible = false;
            } else {
              Swal.fire("Error", response.data.message, "error");
              this.dialogCreateEmployeeVisible = false;
            }
          });
      } catch (error) {
        this.dialogErrorStatus = true;
        this.dialogCreateEmployeeVisible = false;
        this.resulterrorsStatus =
          "Estado de servicio: " + error.response.status;
        console.log(error.response.status);
      }
    },
    async deleteEmpleado(id, index) {
      let token = null;
      token = sessionStorage.token;

      let empleado_id = id;
      let indexArray = index - 1;
      try {
        Swal.fire({
          title: "¿Desea eliminar Colaborador?",
          text:
            "Una vez eliminado el colaborador, toda su informacion desaparecerá de la empresa",
          ///icon: 'warning',
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "CONFIRMAR",
          cancelButtonText: "CANCELAR",
          showCancelButton: true
        }).then(result => {
          if (result) {
            if (result.value) {
              axios
                .delete(
                  `v1/employee/${empleado_id}`,
                  ///`https://devapi.smartdoctor.pe/v1/employee/${empleado_id}`,
                  {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      //Authorization: "token 85ba358db6697d27c1487e1b2a8552852dbf16dd"
                      Authorization: `token ${token}`
                    },
                    params: {}
                  }
                )
                .then(response => {
                  if (response.data.success === true) {
                    this.empleado_detalles.splice(indexArray, 1);
                    Swal.fire("¡Eliminado!", response.data.message, "success");
                    this.getEmpleadosDetalle();
                  } else {
                    Swal.fire("Error!", response.data.message, "warning");
                  }
                });
            }
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getEmpleadosDetalle() {
      try {
        let empresa_id = null;
        //empresa_id = this.$route.params.id;
        empresa_id = this.panel_companie_id;
        //console.log(empresa_id);

        let country_id = null;
        let code = null;
        let name = null;
        let num_doc = null;

        let token = null;
        token = sessionStorage.token;

        if (this.filter) {
          if (this.filtro_select == "code") {
            code = this.filter;
          } else if (this.filtro_select == "name") {
            name = this.filter;
            //alert(name);
          } else if (this.filtro_select == "num_doc") {
            num_doc = this.filter;
          } else if (this.filtro_select == "") {
            name = this.filter;
            //Swal.fire("Advertencia!", "Debe seleccionar un filtro", "error");
          }
        }

        const res = await axios.get(
          `v1/company/${empresa_id}/employees`,
          //`https://devapi.smartdoctor.pe/v1/company/${empresa_id}/employees`,
          {
            headers: {
              Authorization: `token ${token}`
            },
            params: {
              page: this.pagination.currentPage,
              size: 20,
              code: code,
              name: name,
              num_doc: num_doc,
              country_id: country_id
            }
          }
        );
        this.pagination.total = res.data.data.total_rows;
        this.empleado_detalles = res.data.data.rows.map((el, index) => ({
          ...el,
          index: index + 1,
          campus_name: this.capitalize(el.campus_name)
        }));
      } catch (error) {
        console.error(error);
      }
      if (this.pagination.total <= 20) {
        this.pagination.perPage2 = this.pagination.total;
      }
    },
    async getCountries() {
      try {
        const res = await axios.get(
          `v1/countries`,
          //`https://devapi.smartdoctor.pe/v1/countries`,
          {
            params: {}
          }
        );
        this.countries = res.data.data.map(el => ({
          ...el
        }));
        console.log("this.countries", this.countries);
      } catch (error) {
        console.error(error);
      }
    },
    async getLanguage() {
      try {
        const res = await axios.get(
          `v1/general/languages`,
          {
            params: {}
          }
        );
        this.languages = res.data.data.rows.map(el => ({
          ...el
        }));
        console.log("this.languages", this.languages);
      } catch (error) {
        console.error(error);
      }
    },
    async getTimezone() {
      try {
        let token = null;
        token = sessionStorage.token;

        const res = await axios.get(
          `v1/user/timezone`,
          {
            headers: {
              Authorization: `token ${token}`
            },
            params: {}
          }
        );
        this.timezones = res.data.data.map(el => ({
          ...el
        }));
        console.log("this.timezones", this.timezones);
      } catch (error) {
        console.error(error);
      }
    },
    async getCompanie() {
      try {
        let code = null;
        code = this.$route.params.code;

        let token = null;
        token = sessionStorage.token;

        const res = await axios.get(
          `v1/companies`,
          //`https://devapi.smartdoctor.pe/v1/companies`,
          {
            headers: {
              Authorization: `token ${token}`
            },
            params: {
              code: code
            }
          }
        );
        this.companies = res.data.data.rows.map(el => ({
          ...el
        }));
        this.companiename = res.data.data.rows[0].name;
        localStorage.setItem("companiename", this.companiename);
      } catch (error) {
        console.error(error);
      }
    },
    async getCampus() {
      let empresa_id = null;
      empresa_id = this.panel_companie_id;
      //empresa_id = this.$route.params.id;

      let token = null;
      token = sessionStorage.token;

      try {
        const res = await axios.get(
          `v1/company/${empresa_id}/campus`,
          /// `https://devapi.smartdoctor.pe/v1/company//${empresa_id}/campus`,
          {
            headers: {
              //Authorization: "token 85ba358db6697d27c1487e1b2a8552852dbf16dd"
              Authorization: `token ${token}`
            },
            params: {}
          }
        );

        this.campus = res.data.data.rows.map(el => ({
          ...el,
          id: el.id,
          name: this.capitalize(el.name)
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async getProfile() {
      try {
        let token = null;
        token = sessionStorage.token;
        const res = await axios.get(
          `v1/company/profiles`,
          //`https://devapi.smartdoctor.pe/v1/company/profiles`,
          {
            headers: {
              //Authorization: "token 85ba358db6697d27c1487e1b2a8552852dbf16dd"
              Authorization: `token ${token}`
            },
            params: { size: 100 }
          }
        );
        ///this.profiles = res.data.data.rows;

        this.profiles = res.data.data.rows.map(el => ({
          ...el,
          id: el.id,
          name: this.capitalize(el.name)
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async getEmpleadoId(code, editView, index) {
      let token = null;
      token = sessionStorage.token;

      this.isLoading = true;
      setTimeout(() => (this.isLoading = false), 1 * 1000);

      try {
        let empresa_id = null;
        //empresa_id = this.$route.params.id;
        empresa_id = this.panel_companie_id;

        const res = await axios.get(
          `v1/company/${empresa_id}/employees`,
          ///`https://devapi.smartdoctor.pe/v1/company/${empresa_id}/employees`,
          {
            headers: {
              //Authorization: "token 85ba358db6697d27c1487e1b2a8552852dbf16dd"
              Authorization: `token ${token}`
            },
            params: {
              code: code
            }
          }
        );
        this.listEmployees = res.data.data.rows;

        if (editView) {
          this.formUpdate.id = this.listEmployees[0].id;
          this.formUpdate.code = this.listEmployees[0].code;
          this.formUpdate.campus_id = this.listEmployees[0].campus_id;
          this.formUpdate.profile_id = this.listEmployees[0].profile_id;
          this.formUpdate.position_id = this.listEmployees[0].position_id;
          this.formUpdate.tipo_doc = this.listEmployees[0].tipo_doc;
          this.formUpdate.num_doc = this.listEmployees[0].num_doc;
          this.formUpdate.first_name = this.listEmployees[0].first_name;
          this.formUpdate.last_name = this.listEmployees[0].last_name;
          this.formUpdate.genre = this.listEmployees[0].genre;
          this.formUpdate.birthdate = this.listEmployees[0].birthdate;
          this.formUpdate.phone = this.listEmployees[0].phone;
          this.formUpdate.email = this.listEmployees[0].email;
          this.formUpdate.address = this.listEmployees[0].address;
          this.formUpdate.language_id = this.listEmployees[0].language_id;
          this.formUpdate.number_of_children = this.listEmployees[0].number_of_children;
          this.dialogEditEmployeeVisible = true;
          this.dialogTableVisible = false;

          if (this.formUpdate.tipo_doc == 1) {
            this.maxLength = 8;
          } else if (this.formUpdate.tipo_doc == 2) {
            this.maxLength = 14;
          } else if (this.formUpdate.tipo_doc == 3) {
            this.maxLength = 12;
          }

          if (this.formUpdate.phone && this.formUpdate.phone.includes("-")) {
            this.prefix = this.formUpdate.phone.split("-")[0].replace("+","");
            this.phone = this.formUpdate.phone.split("-")[1];
          } else {
            this.prefix = null;
            this.phone = this.formUpdate.phone;
          }
        } else {
          this.formView.index = index;
          this.formView.id = this.listEmployees[0].id;
          this.formView.code = this.listEmployees[0].code;
          this.formView.campus_name = this.listEmployees[0].campus_name;
          this.formView.profile_name = this.listEmployees[0].profile_name;
          this.formView.position_name = this.listEmployees[0].position_name;
          this.formView.tipo_doc_display = this.listEmployees[0].tipo_doc_display;
          this.formView.num_doc = this.listEmployees[0].num_doc;
          this.formView.first_name = this.listEmployees[0].first_name;
          this.formView.last_name = this.listEmployees[0].last_name;
          this.formView.genre_display = this.listEmployees[0].genre_display;
          this.formView.birthdate = this.listEmployees[0].birthdate;
          this.formView.phone = this.listEmployees[0].phone;
          this.formView.email = this.listEmployees[0].email;
          this.formView.address = this.listEmployees[0].address;
          this.formView.number_of_children = this.listEmployees[0].number_of_children;
          this.dialogTableVisible = true;
        }
      } catch (error) {
        console.error(error);
      }
    },
    indexMethod2(index) {
      return index + 1;
    },
    encodeQueryData(data) {
      let result = [];
      for (let d in data) {
        if (data[d] != null && data[d] != "")
          result.push(
            encodeURIComponent(d) + "=" + encodeURIComponent(data[d])
          );
      }

      return result.join("&");
    }
  }
};
</script>
<style lang="css">
.ps__thumb-y {
  display: none;
}
.el-table__body-wrapper {
  height: auto;
  overflow: auto;
}
#searchinput {
  width: 75px;
}
#searchclear {
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  height: 14px;
  margin: auto;
  font-size: 14px;
  cursor: pointer;
  color: #ccc;
}
</style>
