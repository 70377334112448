/*!

=========================================================
* Vue Light Bootstrap Dashboard - v1.3.1
=========================================================

* Product Page: http://www.creative-tim.com/product/vue-light-bootstrap-dashboard-pro
* Copyright 2019 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import VueRouter from "vue-router";
import LightBootstrap from "./light-bootstrap-main";
// Plugins
import App from "./App.vue";

//axios
import axios from "axios";

// router setup
import routes from "./routes/routes";

import lang from "element-ui/lib/locale/lang/es";
import locale from "element-ui/lib/locale";

import es from 'vee-validate/dist/locale/es'
import * as VeeValidate from 'vee-validate';
import * as Validator from 'vee-validate';
Vue.use(VeeValidate);
Validator.localize("es", es);

axios.defaults.baseURL = "https://api.smartdoctor.pe/";

import {Loading } from 'element-ui'
Vue.use(Loading.directive);
// configure language
locale.use(lang);

// plugin setup

Vue.use(VueRouter);
Vue.use(LightBootstrap);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "active"
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: h => h(App),
  router
});
