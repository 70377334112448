<template>
  <div>
    <loading
      :height="60"
      :width="60"
      color="#1873EE"
      background-color="white"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
    ></loading>
    <el-card shadow="always" style="border-radius:25px">
      <div class="row">
        <div class="col-3">
          <span class="demonstration" style="font-size:13px">Año y Mes</span>

          <el-date-picker
            v-model="monthYear"
            type="month"
            placeholder="Selecciona el mes y año"
            size="small"
            @change="clearNameMonth"
            ref="monthYear"
          >
          </el-date-picker>
          <!-- <div style="color:red; font-size:10px;" v-if="!validateSelectYearMonth">
            Seleccione el año y mes.
          </div> -->
        </div>
        <!-- <div class="col-3">
          <span class="demonstration" style="font-size:13px">País</span>
          <br />
          <el-select
            v-model="country"
            style="width:100%"
            placeholder="País del Smarter"
            size="small"
            filterable
            clearable
            @input="showNameCountries"
          >
            <el-option
              v-for="item in countries"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div> -->
        <div class="col-3">
          <span class="demonstration" style="font-size:13px">Empresa</span>
          <br />
          <el-select
            v-model="company"
            style="width:100%"
            placeholder="Empresa"
            size="small"
            filterable
            clearable
            @input="showNameCompany"
            @clear="clearNameCompany"
          >
            <el-option
              v-for="item in companies"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-3">
          <br />
          <el-button
            :style="[buttonStyleSelectYearMonth]"
            size="small"
            icon="el-icon-search"
            @click="search"
            :disabled="!validateSelectYearMonth"
          >
            Buscar</el-button
          >
        </div>
      </div>
    </el-card>
    <br />
    <el-card shadow="always" style="border-radius:25px;width: 100%;">
      <div slot="header" class="clearfix">
        <div class="row">
          <div class="col">
            <span style="float: left;">Lista de indicadores operativos</span>
          </div>
          <!-- <div class="col">
            <el-button
              type="text"
              style="float: right; margin: 3px; padding: 1px; text-align: right;"
              @click="openPdfInformacion()"
              >Información</el-button
            >
          </div> -->
          <!-- <div class="col">
            
          </div> -->
          <div class="col">
            <el-button
              :style="[buttonStyleSelectYearMonth]"
              style="float: right; padding: 10px; text-align: center;"
              size="small"
              icon="el-icon-download"
              @click="download_excel"
              :disabled="!validateSelectYearMonth"
            >
              Descargar Excel</el-button
            >
            <!-- <el-button
              style="background:#23CCEF; color:white;border:0px;float: right; 
              margin: 0px 10px 10px 10px; padding: 10px; text-align: center;"
              size="small"
              icon="el-icon-link"
              @click="openPdfInformacion"
            >
              Información</el-button
            > -->
          </div>
        </div>
      </div>

      <div class="table-scroll">
        <table v-if="reporte_kpis.length > 0">
          <thead>
            <tr>
              <th scope="col" style="font-size:10px;font-weight: bold">
                INDICADORES OPERATIVOS BASES
              </th>
              <th
                scope="col"
                style="font-size:12px;text-align: center"
                v-for="date in dates_week"
                :key="date.day"
              >
                <span>{{ date }}</span>
              </th>
              <th scope="col" style="font-size:12px;text-align: center">
                Mes
              </th>
              <th scope="col" style="font-size:12px;text-align: center">
                Mes
              </th>
            </tr>
            <tr>
              <td style="font-size:12px;font-weight: bold;color:#909399">
                <!-- PAÍS: {{ nameCountry }} -->
              </td>
              <td
                style="font-size:12px;text-align: center;font-weight: bold;color:#606266"
                v-for="date in dates_number"
                :key="date.day"
                v-text="date"
              ></td>
              <td style="font-size:12px;text-align: center;color:#606266">
                Actual
              </td>
              <td style="font-size:12px;text-align: center;color:#606266">
                Anter
              </td>
            </tr>
          </thead>
          <tbody v-for="kpis in reporte_kpis" :key="kpis.group_kpi">
            <tr>
              <td
                style="font-size:12px;font-weight: bold;color:#696969;background:#E3E3E3;"
              >
                {{ kpis.label.toUpperCase() }}
              </td>
              <td
                style="font-size:12px;text-align: center;color:#696969;background:#E3E3E3;"
                v-for="date in dates_number"
                :key="date.day"
                v-text="''"
              ></td>
              <td style="font-size:12px;background:#E3E3E3;"></td>
              <td style="font-size:12px;background:#E3E3E3;"></td>
            </tr>
            <tr v-for="childs in kpis.childs" :key="childs.group_kpi">
              <td
                v-if="childs.label != 'Total'"
                style="font-size:12px; text-align: right;color:#606266"
              >
                {{ childs.label }}
              </td>
              <td
                v-else
                style="font-size:12px;font-weight: bold;text-align: right;color:black;background:#E3E3E3;"
              >
                {{ childs.label.toUpperCase() }}
              </td>
              <td
                style="font-size:12px;text-align: center;color:#606266"
                v-for="days in childs.days"
                :key="days.day"
                v-show="childs.label != 'Total'"
              >
                <span>{{ days.val }}</span>
              </td>
              <td
                style="font-size:12px;text-align: center;color:black;background:#E3E3E3;"
                v-for="days in childs.days"
                :key="days.day"
                v-show="childs.label == 'Total'"
              >
                <span>{{ days.val }}</span>
              </td>
              <td
                style="font-size:12px;text-align: center;color:#606266;font-weight: bold"
                v-if="childs.label != 'Total'"
              >
                <span>{{ childs.total_month.val }}</span>
              </td>
              <td
                style="font-size:12px;text-align: center;color:black;background:#E3E3E3;"
                v-else
              >
                <span>{{ childs.total_month.val }}</span>
              </td>

              <td
                style="font-size:12px;text-align: center;color:#606266;font-weight: bold"
                v-if="childs.label != 'Total'"
              >
                <span>{{ childs.total_month_before.val }}</span>
              </td>
              <td
                style="font-size:12px;text-align: center;color:black;background:#E3E3E3;"
                v-else
              >
                <span>{{ childs.total_month_before.val }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="text-center text-info" v-show="showNoData">
          LA EMPRESA SELECCIONADA NO CUENTA CON DATOS REGISTRADOS.
        </div>
      </div>
    </el-card>
    <el-dialog title="" :visible.sync="dialogWarning" width="30%" top="1%">
      <el-result
        icon="warning"
        title="Advertencia"
        subTitle="Debe seleccionar Año y Mes, gracias."
      >
        <template slot="extra">
          <el-button type="primary" size="medium" @click="dialogWarning = false"
            >Cerrar</el-button
          >
        </template>
      </el-result>
    </el-dialog>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  Popover,
  Tag,
  Descriptions,
  DescriptionsItem,
  Button,
  Dialog,
  Input,
  MessageBox,
  Card,
  Checkbox,
  Switch,
  Result
} from "element-ui";
import { Loading, Pagination as LPagination } from "src/components/index";

import axios from "axios";

import dayjs from "dayjs";
require("dayjs/locale/es");
export default {
  name: "ReporteKpis",
  components: {
    Loading,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Popover.name]: Popover,
    [Tag.name]: Tag,
    [Descriptions.name]: Descriptions,
    [DescriptionsItem.name]: DescriptionsItem,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [MessageBox.name]: MessageBox,
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [Switch.name]: Switch,
    [Result.name]: Result
  },
  data() {
    return {
      dialogWarning: false,
      showNoData: false,
      nameCountry: "",
      nameCompany: "",
      nameMonth: "",
      isLoading: false,
      monthYear: null,
      consultation_scheduled: [],
      consultation_attended: [],
      data_smarters: [],
      usage_level: [],
      reporte_kpis: [],
      membership_plan: [],
      smarters: [],
      utilization_plans: [],
      specialty: [],
      company: null,
      country: null,
      daterange: null,
      companies: [],
      countries: [],
      dates_week: [],
      dates_number: [],
      currentMonth: dayjs(this.date).format("MM"),
      currentYear: dayjs(this.date).format("YYYY"),
      currentYearMonth: dayjs(this.date).format("YYYY-DD-MMM"),
      date_kpi_year: null,
      date_kpi_month: null
    };
  },
  watch: {},
  mounted() {
    this.monthYear = this.currentYearMonth;
    this.getCompanies();
    this.getCountries();
    this.search();
  },
  computed: {
    validateSelectYearMonth() {
      return this.monthYear != null;
    },
    buttonStyleSelectYearMonth() {
      return this.validateSelectYearMonth
        ? {
            backgroundColor: "#23CCEF",
            color: "white",
            border: "0px"
          }
        : {
            backgroundColor: "#a0cfff",
            color: "#FFF",
            border: "0px",
            "border-color": "#a0cfff"
          };
    }
  },
  methods: {
    clearNameMonth() {
      if (this.monthYear == null) {
        //this.$refs.monthYear.focus();
        //this.dialogWarning = true;
      }
      this.nameMonth = "";
    },
    clearNameCompany() {
      this.nameCompany = "";
    },
    getNameMonth(mes) {
      let nameMonth;
      switch (mes) {
        case "01":
          nameMonth = "Enero";
          break;
        case "02":
          nameMonth = "Febrero";
          break;
        case "03":
          nameMonth = "Marzo";
          break;
        case "04":
          nameMonth = "Abril";
          break;
        case "05":
          nameMonth = "Mayo";
          break;
        case "06":
          nameMonth = "Junio";
          break;
        case "07":
          nameMonth = "Julio";
          break;
        case "08":
          nameMonth = "Agosto";
          break;
        case "09":
          nameMonth = "Septiembre";
          break;
        case "10":
          nameMonth = "Octubre";
          break;
        case "11":
          nameMonth = "Noviembre";
          break;
        case "12":
          nameMonth = "Diciembre";
          break;
      }
      return nameMonth;
    },
    openPdfInformacion() {
      window.open(
        "https://smartdoctor.la/wp-content/uploads/pdf/Criterios%20de%20aceptacion%20-%20KPI.pdf",
        "_blank"
      );
    },
    async download_excel() {
      if (this.monthYear == null) {
        this.date_kpi_month = dayjs(this.currentMonth).format("MM");
        this.date_kpi_year = dayjs(this.currentYear).format("YYYY");
      } else {
        this.date_kpi_month = dayjs(this.monthYear).format("MM");
        this.date_kpi_year = dayjs(this.monthYear).format("YYYY");
      }
      this.isLoading = true;
      await this.DownloadExcel();
    },
    async DownloadExcel() {
      try {
        let token = null;
        token = sessionStorage.token;
        axios({
          method: "get",
          url: "v1.1/kpi/download",
          params: {
            date_kpi_year: this.date_kpi_year,
            date_kpi_month: this.date_kpi_month,
            company: this.company,
            country: this.country
          },
          responseType: "blob",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `token ${token}`
          }
        }).then(res => {
          this.isLoading = false;

          if (this.nameCompany == "") {
            this.convertFile(
              res.data,
              "SD-" +
                "Indicadores" +
                "_" +
                this.getNameMonth(this.date_kpi_month) +
                "_Global.xlsx"
            );
          } else {
            this.convertFile(
              res.data,
              this.nameCompany +
                "-" +
                "Indicadores" +
                "_" +
                this.getNameMonth(this.date_kpi_month) +
                ".xlsx"
            );
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    convertFile(dataFile, fileName) {
      let newBlob = new Blob([dataFile], {
        type: "application/vnd.ms-excel; charset=UTF-8"
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = fileName;
      link.click();
      setTimeout(function() {
        window.URL.revokeObjectURL(data);
      }, 100);
    },
    showNameCountries() {
      this.countries.forEach(element => {
        if (element.id == this.country) {
          this.nameCountry = element.name;
        }
      });
    },
    showNameCompany() {
      this.companies.forEach(element => {
        if (element.id == this.company) {
          this.nameCompany = element.name;
        }
      });
    },
    async search() {
      if (this.monthYear == null) {
        this.date_kpi_month = dayjs(this.currentMonth).format("MM");
        this.date_kpi_year = dayjs(this.currentYear).format("YYYY");
      } else {
        this.date_kpi_month = dayjs(this.monthYear).format("MM");
        this.date_kpi_year = dayjs(this.monthYear).format("YYYY");
      }

      this.dates_week = [];
      this.dates_number = [];

      this.isLoading = true;
      await this.load_date_mes();
      await this.getReporteKpis();
      this.isLoading = false;
    },
    async getReporteKpis() {
      let token = null;
      token = sessionStorage.token;
      try {
        const res = await axios.get(`v1.1/kpi/kpi`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            date_kpi_year: this.date_kpi_year,
            date_kpi_month: this.date_kpi_month,
            company: this.company,
            country: this.country
          }
        });

        let result_kpis = res.data.group_kpi;

        if (Array.isArray(result_kpis)) {
          this.reporte_kpis = res.data.group_kpi;
          //await this.loadDataReport();
        } else {
          this.showNoData = true;
          this.reporte_kpis = [];
          // this.consultation_scheduled = [];
          // this.consultation_attended = [];
          // this.usage_level = [];
          // this.data_smarters = [];
          // this.smarters = [];
          // this.membership_plan = [];
          // this.utilization_plans = [];
          // this.specialty = [];
        }
      } catch (error) {
        console.error(error);
      }
    },
    async loadDataReport() {
      this.consultation_scheduled = [];
      this.consultation_attended = [];
      this.usage_level = [];
      this.data_smarters = [];
      this.smarters = [];
      this.membership_plan = [];
      this.utilization_plans = [];
      this.specialty = [];

      this.reporte_kpis.forEach(group_kpi => {
        if (group_kpi.label == "Consultas Programadas") {
          group_kpi.childs.forEach(childs => {
            this.consultation_scheduled.push(childs);
          });
        } else if (group_kpi.label == "Consultas Realizadas") {
          group_kpi.childs.forEach(childs => {
            this.consultation_attended.push(childs);
          });
        } else if (group_kpi.label == "Datos smarters") {
          group_kpi.childs.forEach(childs => {
            this.data_smarters.push(childs);
          });
        } else if (group_kpi.label == "Nivel de uso") {
          group_kpi.childs.forEach(childs => {
            this.usage_level.push(childs);
          });
        } else if (group_kpi.label == "Membresía y planes") {
          group_kpi.childs.forEach(childs => {
            this.membership_plan.push(childs);
          });
        } else if (group_kpi.label == "Smarters") {
          group_kpi.childs.forEach(childs => {
            this.smarters.push(childs);
          });
        } else if (group_kpi.label == "Utilización planes %") {
          group_kpi.childs.forEach(childs => {
            this.utilization_plans.push(childs);
          });
        } else if (group_kpi.label == "Especialidades") {
          group_kpi.childs.forEach(childs => {
            this.specialty.push(childs);
          });
        }
      });
    },
    async getCountries() {
      try {
        const res = await axios.get(`v1/countries`, {
          params: {}
        });
        this.countries = res.data.data.map(el => ({
          ...el
        }));
      } catch (error) {
        console.error(error);
      }
    },
    capitalize: function(str) {
      let nombre = "";
      nombre = str.toLocaleLowerCase();
      return nombre
        .toLowerCase()
        .trim()
        .split(" ")
        .map(v => v[0].toUpperCase() + v.substr(1))
        .join(" ");
    },
    async getCompanies() {
      let country_id = null;
      let name = null;
      let code = null;

      let token = null;
      token = sessionStorage.token;

      try {
        const res = await axios.get(`v1/companies`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: 1,
            size: 100,
            name: name,
            code: code,
            country_id,
            "exclude-is-demo": ""
          }
        });
        this.companies = res.data.data.rows.map(el => ({
          ...el,
          name: this.capitalize(el.name)
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async load_date_mes() {
      var año = this.date_kpi_year;
      var mes = this.date_kpi_month;

      var diasMes = new Date(año, mes, 0).getDate();
      var diasSemana = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];

      for (var dia = 1; dia <= diasMes; dia++) {
        // Ojo, hay que restarle 1 para obtener el mes correcto
        var indice = new Date(año, mes - 1, dia).getDay();
        // console.log(
        //   `El día número ${dia} del mes ${mes} del año ${año} es ${diasSemana[indice]}`
        // );
        //console.log

        this.dates_week.push(diasSemana[indice]);
        this.dates_number.push(dia);
      }
    },
    evento_checkbox(datos) {
      console.log(datos);
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}
td {
  border: 1px solid #e3e3e3;
  background: #ffffff;
}
tr {
  border: 1px solid #ebeef5;
}

th {
  border: 1px solid #ebeef5;
}
body {
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
}

.table-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  height: auto;
  max-height: 500px;
}
.table-scroll table {
  width: 100%;
  min-width: 1000px;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}
.table-wrap {
  position: relative;
}
// .table-scroll th,
// .table-scroll td {
//   padding: 5px 10px;
//   border: 1px solid #000;
//   background: #fff;
//   vertical-align: top;
// }
.table-scroll thead th {
  //background: #333;
  background: #fff;
  color: #606266;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.table-scroll thead td {
  //background: #333;
  background: #fff;
  color: #606266;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.table-scroll tfoot,
.table-scroll tfoot th,
.table-scroll tfoot td {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  /*background: #666;
  color: #fff;*/
  z-index: 4;
}

th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  background: #ffffff;
}
td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  background: #ffffff;
  ///NUVEO///
  width: 18em;
  min-width: 18em;
  max-width: 18em;
  word-break: break-all;
}
thead th:first-child,
thead td:first-child,
tfoot th:first-child {
  z-index: 5;
}

// table {
//   font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
//   font-size: 10px;
//   //margin: 45px;
//   //width: 480px;
//   text-align: left;
//   border-collapse: collapse;
// }

// th {
//   font-size: 10px;
//   font-weight: normal;
//   padding: 5px;
//   background: #b9c9fe;
//   border-top: 4px solid #aabcfe;
//   border-bottom: 1px solid #fff;
//   color: #039;
// }

// td {
//   padding: 8px;
//   background: #e8edff;
//   border-bottom: 1px solid #fff;
//   color: #669;
//   border-top: 1px solid transparent;
// }

tr:hover td {
  background: #ebeef5;
  color: #909399;
}
@media (max-width: 500px) {
  .table-scroll {
    position: relative;
    width: 100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
    height: 350px;
  }
  td:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    background: #ffffff;
    ///NUVEO///
    width: 10em;
    min-width: 10em;
    max-width: 10em;
    word-break: break-all;
  }
}
</style>
