<template>
  <div>
    <div class="row">
      <div class="col-xl-6 col-md-6">
        <div class="card">
          <el-date-picker
            v-model="filterDates"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="-"
            start-placeholder="Desde"
            end-placeholder="Hasta"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card">
          <el-select v-model="filterCountry" clearable placeholder="País">
            <el-option
              v-for="item in countries"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <card
          title="Profesionales de la salud Smart Doctor"
          :subTitle="
            `N° atenciones por profesional de la salud: ${pagination.total}`
          "
        >
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div
                class="flex cursor-pointer"
                style="cursor: pointer;"
                @click="download_excel_1"
              >
                <img src="static/icons/excel.svg" alt="download_excel" />
                <span style="margin-left:10px;">Exportar</span>
              </div>
              <!-- <fg-input>
                <el-date-picker
                  v-model="registrosDates"
                  type="daterange"
                  placeholder="Datetime picker here"
                >
                </el-date-picker>
              </fg-input> -->
            </div>
            <div class="table-responsive table-full-width">
              <el-table
                stripe
                style="width: 100%; overflow: auto;"
                :data="medicos"
                border
                empty-text="Sin datos"
              >
                <el-table-column
                  width="80"
                  type="index"
                  label="#"
                  :index="indexMethod"
                >
                </el-table-column>
                <el-table-column min-width="200">
                  <template slot-scope="props">
                    <el-button
                      type="text"
                      @click="
                        dialogTableVisible = true;
                        filterMedico = props.row.doctor_id;
                        medico = props.row.doctor_name;
                      "
                      >{{ props.row.doctor_name }}</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
              </el-table>
            </div>
            <el-dialog
              :title="`${medico}`"
              :visible.sync="dialogTableVisible"
              width="80%"
            >
              <div class="table-responsive table-full-width">
                <div
                  class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                >
                  <div
                    class="flex cursor-pointer"
                    style="cursor: pointer;"
                    @click="download_excel_2"
                  >
                    <img src="static/icons/excel.svg" alt="download_excel" />
                    <span style="margin-left:10px;">Exportar</span>
                  </div>
                </div>
                <div class="table-responsive table-full-width">
                  <el-table :data="medico_detalles">
                    <el-table-column
                      width="80"
                      type="index"
                      label="#"
                      :index="indexMethod2"
                    >
                    </el-table-column>
                    <el-table-column
                      v-for="column in tableColumns2"
                      :key="column.label"
                      :min-width="column.minWidth"
                      :prop="column.prop"
                      :label="column.label"
                    >
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <div
                slot="footer"
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <l-pagination
                  class="pagination-no-border"
                  v-model="pagination2.currentPage"
                  :per-page="pagination2.perPage"
                  :total="pagination2.total"
                >
                </l-pagination>
              </div>
            </el-dialog>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <!-- <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div> -->
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  Popover,
  Tag,
  Descriptions,
  DescriptionsItem,
  Button,
  Dialog
} from "element-ui";
import {
  Pagination as LPagination,
  FormGroupInput as FgInput,
  StatsCard
} from "src/components/index";
import axios from "axios";
var dayjs = require("dayjs");

export default {
  name: "Dashboard",
  components: {
    LPagination,
    FgInput,
    StatsCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Popover.name]: Popover,
    [Tag.name]: Tag,
    [Descriptions.name]: Descriptions,
    [DescriptionsItem.name]: DescriptionsItem,
    [Button.name]: Button,
    [Dialog.name]: Dialog
  },
  data() {
    return {
      pagination: {
        perPage: 15,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      tableColumns: [
        /*{
          prop: "id",
          label: "N°",
          minWidth: 80
        },*/
        /*{
          prop: "doctor_name",
          label: "Doctor",
          minWidth: 220
        },*/
        {
          prop: "doctor_speciality",
          label: "Especialidad",
          minWidth: 180
        },
        {
          prop: "doctor_email",
          label: "Correo",
          minWidth: 200
        },
        {
          prop: "doctor_phone",
          label: "Celular",
          minWidth: 100
        },
        {
          prop: "total_attentions",
          label: "N° atenciones",
          minWidth: 100
        },
        {
          prop: "total_amount",
          label: "Monto",
          minWidth: 120
        },
        {
          prop: "country",
          label: "País",
          minWidth: 100
        }
      ],
      pagination2: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      tableColumns2: [
        {
          prop: "scheduled_date",
          label: "Fecha teleconsulta",
          minWidth: 100
        },
        {
          prop: "user_email",
          label: "Correo usuario",
          minWidth: 180
        },
        {
          prop: "patient_first_name",
          label: "Paciente",
          minWidth: 150
        },
        {
          prop: "relation",
          label: "Parentesco",
          minWidth: 100
        },
        {
          prop: "user_phone",
          label: "Celular usuario",
          minWidth: 100
        },
        {
          prop: "payment_method",
          label: "Medio de pago",
          minWidth: 120
        },
        {
          prop: "doctor_amount",
          label: "Monto",
          minWidth: 100
        }
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "01 semana",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "01 mes",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "03 meses",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      medicos: [],
      filterDates: null,
      countries: [],
      filterCountry: null,
      dialogTableVisible: false,
      filterMedico: null,
      medico: "",
      medico_detalles: []
    };
  },
  watch: {
    "pagination.currentPage"() {
      this.getMedicos();
    },
    "pagination2.currentPage"() {
      this.getMedicoDetalle();
    },
    filterDates() {
      this.pagination.currentPage = 1;
      this.getMedicos();
    },
    filterCountry() {
      this.pagination.currentPage = 1;
      this.getMedicos();
    },
    filterMedico() {
      this.pagination2.currentPage = 1;
      this.getMedicoDetalle();
    }
  },
  mounted() {
    if (!sessionStorage.isLogged) {
      this.$router.go(-1);
      return;
    }
    this.getMedicos();
    this.getCountries();
  },
  methods: {
    async getMedicos() {
      let token = null;
      token = sessionStorage.token;
      try {
        let date_from = null;
        let date_to = null;
        if (this.filterDates) {
          date_from = dayjs(this.filterDates[0]).format("YYYY-MM-DD");
          date_to = dayjs(this.filterDates[1]).format("YYYY-MM-DD");
        }
        let country_id = null;
        if (this.filterCountry) {
          country_id = this.filterCountry;
        }
        const res = await axios.get(
          `https://api.smartdoctor.pe/v1/dashboard/doctors/payments`,
          {
            headers: {
              Authorization: `token ${token}`
            },
            params: {
              page: this.pagination.currentPage,
              size: 15,
              date_from,
              date_to,
              country_id
            }
          }
        );
        this.pagination.total = res.data.data.total_rows;
        this.medicos = res.data.data.rows.map(el => ({
          ...el
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async getMedicoDetalle() {
      try {
        let date_from = null;
        let date_to = null;
        if (this.filterDates) {
          date_from = dayjs(this.filterDates[0]).format("YYYY-MM-DD");
          date_to = dayjs(this.filterDates[1]).format("YYYY-MM-DD");
        }
        let doctor_id = null;
        if (this.filterMedico) {
          doctor_id = this.filterMedico;
        }
        const res = await axios.get(
          `https://api.smartdoctor.pe/v1/dashboard/doctor/${doctor_id}/payments`,
          {
            params: {
              page: this.pagination2.currentPage,
              size: 10,
              date_from,
              date_to,
              doctor_id
            }
          }
        );
        this.pagination2.total = res.data.data.total_rows;
        this.medico_detalles = res.data.data.rows.map(el => ({
          ...el,
          scheduled_date: el.scheduled_date
            ? dayjs(el.scheduled_date).format("YYYY-MM-DD")
            : "",
          patient_first_name: el.patient_first_name + " " + el.patient_last_name
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async getCountries() {
      try {
        const res = await axios.get(`https://api.smartdoctor.pe/v1/countries`, {
          params: {}
        });
        this.countries = res.data.data.map(el => ({
          ...el
        }));
      } catch (error) {
        console.error(error);
      }
    },
    indexMethod(index) {
      return (
        (this.pagination.currentPage - 1) * this.pagination.perPage +
        (index + 1)
      );
    },
    indexMethod2(index) {
      return (
        (this.pagination2.currentPage - 1) * this.pagination2.perPage +
        (index + 1)
      );
    },
    encodeQueryData(data) {
      let result = [];
      for (let d in data) {
        if (data[d] != null && data[d] != "")
          result.push(
            encodeURIComponent(d) + "=" + encodeURIComponent(data[d])
          );
      }

      return result.join("&");
    },
    download_excel_1() {
      try {
        let date_from = null;
        let date_to = null;
        if (this.filterDates) {
          date_from = dayjs(this.filterDates[0]).format("YYYY-MM-DD");
          date_to = dayjs(this.filterDates[1]).format("YYYY-MM-DD");
        }
        let country_id = null;
        if (this.filterCountry) {
          country_id = this.filterCountry;
        }
        var data = {
          date_from: date_from,
          date_to: date_to,
          country_id: country_id
        };
        var querystring = this.encodeQueryData(data);
        window.open(
          "https://api.smartdoctor.pe/v1/excel/doctors/payments?" + querystring,
          "_blank"
        );
      } catch (error) {
        console.error(error);
      }
    },
    download_excel_2() {
      try {
        let date_from = null;
        let date_to = null;
        if (this.filterDates) {
          date_from = dayjs(this.filterDates[0]).format("YYYY-MM-DD");
          date_to = dayjs(this.filterDates[1]).format("YYYY-MM-DD");
        }
        let doctor_id = null;
        if (this.filterMedico) {
          doctor_id = this.filterMedico;
        }
        var data = { date_from: date_from, date_to: date_to };
        var querystring = this.encodeQueryData(data);
        window.open(
          `https://api.smartdoctor.pe/v1/excel/doctor/${doctor_id}/payments?` +
            querystring,
          "_blank"
        );
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
<style lang="css">
.el-table__body-wrapper {
  height: auto;
  overflow: auto;
}
</style>
