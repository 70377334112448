<template>
  <div>
    <div class="row">
      <div class="col-12">
        <el-card shadow="always" style="border-radius:25px">
          <div slot="header">
            <span>LISTADO DE REPORTE DE BIENESTAR</span>
            
            <el-button
              style="background:#23CCEF; color:white;border:0px;float: right;"
              size="small"
              @click="viewRegisterBienestar"
            >
              REGISTRAR REPORTE</el-button
            >
          </div>
          <div>
            <el-table style="width: 100%" :data="reporte_bienestar" stripe>
              <el-table-column
                :index="indexMethod"
                width="70"
                label="N°"
                type="index"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="company_name"
                min-width="250"
                label="Empresa"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="name"
                min-width="250"
                label="Evaluación"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="start_date"
                min-width="260"
                label="Periodo"
                align="center"
              >
                <template slot-scope="props">
                  <span>{{props.row.start_date}} - {{props.row.ending_date}}</span>
                </template>
              </el-table-column>
              <el-table-column min-width="105" fixed="right" label="Reporte">
                <template slot-scope="propsx">
                  <a :href="propsx.row.attach_url" target="_blank">
                    <div class="icon">
                      <i class="nc-icon nc-cloud-download-93"></i>
                    </div>
                  </a>
                </template>
              </el-table-column>
              <el-table-column min-width="105" fixed="right" label="Status">
                <template slot-scope="props">
                  <div>{{props.row.is_active ? 'ACTIVE': 'BLOCK'}}</div>
                </template>
              </el-table-column>
              <el-table-column min-width="160" fixed="right" label="Acciones">
                <template slot-scope="props">
                  <div style="cursor: pointer; float: left; width: 50%" @click="editar(props.row)">
                    Editar
                  </div>
                  <div style="cursor: pointer; float: left; width: 50%" @click="eliminar(props.row, props.$index)">
                    Eliminar
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <br />
            <div
              slot="footer"
              class="
                  col-12
                  d-flex
                  justify-content-center justify-content-sm-between
                  flex-wrap
                "
            >
              Showing {{ pagination.currentPage2 }} to {{ pagination.perPage2 }} of
              {{ pagination.total }}

              <l-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="pagination.total"
              >
              </l-pagination>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Card,
  Form,
  Button,
  DatePicker,
  Popover,
  Select,
  Option,
  Calendar,
  Loading
} from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "ProgramacionHorarioMes",
  components: {
    Loading,
    LPagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Card.name]: Card,
    [Form.name]: Form,
    [Button.name]: Button,
    [DatePicker.name]: DatePicker,
    [Popover.name]: Popover,
    [Option.name]: Option,
    [Calendar.name]: Calendar
  },
  data() {
    return {
      reporte_bienestar: [],
      isLoading: false,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [4, 15, 25, 50],
        total: 0,
        currentPage2: 1,
        perPage2: 20
      },
    };
  },
  watch: {
    "pagination.currentPage"() {
      this.asyncgetReporteBienestar();
      this.Showingpagination();
    },
  },
  methods: {
    async asyncgetReporteBienestar() {
      this.isLoading = true;
      await this.getReporteBienestar();
      this.isLoading = false;
    },
    async getReporteBienestar() {
      let token = null;
      token = sessionStorage.token;

      try {
        const res = await axios.get(`/v1/company/report`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: this.pagination.currentPage,
            size: 20
          }
        });
        
        if (res.data.data.total_rows > 0) {
          this.pagination.total = res.data.data.total_rows;

          this.reporte_bienestar = res.data.data.rows;
          console.log('this.reporte_bienestar', this.reporte_bienestar);
        }
      } catch (error) {
        console.error(error);
      }

      if (this.pagination.total <= 20) {
        this.pagination.perPage2 = this.pagination.total;
      }
    },
    editar(reporte) {
      console.log('reporte', reporte);
      this.$router.push({
        name: "bienestar-reporte",
        params: {
          reporte: reporte
        }
      });
      //this.$router.push({ path: "evaluacion-bienestar" });
    },
    eliminar (reporte, index) {
      console.log('reporte', reporte);
      let token = null;
      token = sessionStorage.token;

      //let indexArray = index - 1;
      //console.log('array del', indexArray);
      
      let fd = new FormData();
      fd.append("id", reporte.id);
      try {
        Swal.fire({
          title: "¿Desea eliminar reporte de bienestar?",
          text:
            "Una vez eliminado el reporte, toda la informacion desaparecerá",
          ///icon: 'warning',
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "CONFIRMAR",
          cancelButtonText: "CANCELAR",
          showCancelButton: true
        }).then(result => {
          if (result.value) {
            axios
              .delete(
                `v1/company/report`,
                {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    //Authorization: "token 85ba358db6697d27c1487e1b2a8552852dbf16dd"
                    Authorization: `token ${token}`
                  },
                  params: {},
                  data: {
                    id: reporte.id
                  }
                }
              )
              .then(response => {
                if (response.data.success) {
                  this.reporte_bienestar.splice(index, 1);
                  Swal.fire("¡Eliminado!", response.data.message, "success");
                } else {
                  Swal.fire("Error!", response.data.message, "warning");
                }
              });
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    Showingpagination() {
      this.pagination.perPage2 =
        this.pagination.perPage * this.pagination.currentPage;

      if (this.pagination.currentPage == 1) {
        this.pagination.currentPage2 = 1;
      } else if (this.pagination.currentPage >= 2) {
        this.pagination.currentPage2 = this.pagination.perPage2 - 19;
      }

      if (this.pagination.perPage2 > this.pagination.total) {
        this.pagination.perPage2 = this.pagination.total;
      }
    },
    indexMethod(index) {
      return (
        (this.pagination.currentPage - 1) * this.pagination.perPage +
        (index + 1)
      );
    },
    viewRegisterBienestar() {
      this.$router.push({ 
        name: "bienestar-reporte",
        params: {
          reporte: "nuevo"
        } });
    },
  },
  mounted() {
    this.asyncgetReporteBienestar();
  },
}
</script>
<style lang="css" scope>
.el-button
  el-picker-panel__link-btn
  el-button--default
  el-button--mini
  is-plain {
  background: red;
}
.div_top {
  bottom: 30px;
  position: relative;
}
.el-date-picker_el-popper {
  position: absolute;
  top: 100px;
  right: 68px;
}
.horarios_mes {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 12px;
}
.dias_mes {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 12px;
}
.box-card {
  width: 480px;
}
</style>