<template>
  <div>
    <loading
      :height="60"
      :width="60"
      color="#1873EE"
      style="color: gray; font-weight: 500;"
      background-color="white"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
    >{{cargando}}</loading>
    <el-card shadow="always" style="border-radius:25px">
      <div class="row">
        <div class="col-12">
          <el-button
          :disabled="show_boton"
          :style="buttonStyleDownload"
          size="small"
          icon="el-icon-download"
          @click="download_excel"
        >
          Descargar Excel</el-button
        >
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  Popover,
  Tag,
  Descriptions,
  DescriptionsItem,
  Button,
  Dialog,
  Input,
  MessageBox,
  Card,
  Checkbox,
  Switch,
  Result
} from "element-ui";
import { Loading, Pagination as LPagination } from "src/components/index";
import axios from "axios";
import XLSX from "xlsx";
import dayjs from "dayjs";
require("dayjs/locale/es");
export default {
  name: "Sdv11",
  components: {
    Loading,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Popover.name]: Popover,
    [Tag.name]: Tag,
    [Descriptions.name]: Descriptions,
    [DescriptionsItem.name]: DescriptionsItem,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [MessageBox.name]: MessageBox,
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [Switch.name]: Switch,
    [Result.name]: Result
  },
  data() {
    return {
      isLoading: false,
      cargando: "",
      limit_down: null,
      show_boton: true
    };
  },
  watch: {},
  computed: {
    buttonStyleDownload() {
      return !this.show_boton
        ? {
            backgroundColor: "#11AAFD",
            "border-radius": "63px",
            color: "white",
          }
        : {
            backgroundColor: "#DBDDE8",
            "border-radius": "63px",
            color: "#A3A3A3",
          };
    },
  },
  mounted() {
    if (!sessionStorage.isLogged) {
      this.$router.go(-1);
      return;
    }
    this.getInitialConsult();
  },
  methods: {
    async download_excel() {
      this.isLoading = true;
      await this.getTeleconsultasDownload();
    },

    async getInitialConsult() {
      try {
        let token = null;
        token = sessionStorage.token;

        const res = await axios.get(`v1.1/pro/medical-attention`, {
            params: {
              page: 1,
              limit: 10,
              status: "attended",
              "type-attention": 1,
              "report-e": ""
            },
            headers: {
              Authorization: `token ${token}`
            }
          });
          this.limit_down = res.data.count;
          this.show_boton = false;
          console.log(this.limit_down);
        } catch (error) {
        console.error(error);
      }
    },

    async getTeleconsultasDownload() {
      try {
        let token = null;
        token = sessionStorage.token;

        console.log("this.limit_down", this.limit_down);

        let limit_down = this.limit_down;
        let page_down = 200;
        let json_down = [];
        let percentage = 0;
        for (let index = 1; index <= Math.ceil(limit_down/page_down); index++) {
          let interval = setInterval(() => {
            if (percentage < (index * 100) / Math.ceil(limit_down/page_down)) {
              percentage += 1;
              this.cargando = "Cargando " + percentage + "%";
            } else clearInterval(interval);
          }, 400);
          const res = await axios.get(`v1.1/pro/medical-attention`, {
            params: {
              page: index,
              limit: page_down,
              status: "attended",
              "type-attention": 1,
              "report-e": ""
            },
            headers: {
              Authorization: `token ${token}`
            }
          });

          console.log("res.data.results", res.data.results);

          json_down = [...json_down, ...res.data.results];
          percentage = Math.ceil((index * 100) / (limit_down/page_down));
          console.log('json_down', json_down);
          if (index == Math.ceil(limit_down/page_down)) {
            this.download_json_excel(json_down, "reporte-sd-v1.1");
            this.isLoading = false;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    download_json_excel(dataFile, fileName) {
      let data = XLSX.utils.json_to_sheet(dataFile);
      data["A1"].v = "ID";
      data["B1"].v = "ID Smarter";
      data["C1"].v = "ID Empresa";
      data["D1"].v = "Nombre Empresa";
      data["E1"].v = "Genero";
      data["F1"].v = "Sede Smarter";
      data["G1"].v = "Pais Origen Smarter";
      data["H1"].v = "Fecha Nacimiento Smarter";
      data["I1"].v = "Area";
      data["J1"].v = "Fecha Ingreso Empresa";
      data["K1"].v = "Fecha Ingreso Smarter";
      data["L1"].v = "Servicio Contratado";
      data["M1"].v = "Usuario TC1";
      data["N1"].v = "Fecha - Hora TC1";
      data["O1"].v = "Tipo TC1";
      data["P1"].v = "Especialidad";
      data["Q1"].v = "Diagnostico TC1";
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, data, fileName);
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    },
  }
};
</script>
