<template>
  <div>
    <div class="row">
      <div class="col-7">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span style="font-size:15px;font-weight: bold;"
              >{{is_edit ? 'EDITAR EVALUACIÓN DE BIENESTAR':'AÑADIR EVALUACIÓN DE BIENESTAR'}}</span
            >
          </div>
          <div class="card stacked-form">
            <div class="card-body ">

                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                  <el-form label-position="top" ref="formCreate">


                    <ValidationProvider
                      rules="required"
                      name="EMPRESA"
                      vid="formCreate.company_id"
                      v-slot="provider"
                    >
                      <label
                        >EMPRESA
                        <label class="text-danger"> * </label></label
                      >
                      <el-select
                        v-model="formCreate.company_id"
                        style="width:100%;margin-bottom:10px"
                        placeholder="Empresa"
                        size="small"
                        clearable
                        filterable
                      >
                        <el-option
                          v-for="item in companies"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                      <div
                        style="color:red; font-size:10px;bottom:10px;position: relative"
                      >
                        {{ provider.errors[0] }}
                      </div>
                    </ValidationProvider>
                    
                    <ValidationProvider
                      rules="required"
                      name="NOMBRE DE EVALUACIÓN DE BIENESTAR"
                      vid="formCreate.name"
                      v-slot="provider"
                    >
                      <label
                        >NOMBRE DE REPORTE
                        <label class="text-danger"> * </label></label
                      >
                      <el-input
                        v-model="formCreate.name"
                        size="small"
                        style="margin-bottom:10px"
                        ref="formCreate.name"
                      >
                      </el-input>
                      <div
                        style="color:red; font-size:10px;bottom:10px;position: relative"
                      >
                        {{ provider.errors[0] }}
                      </div>
                    </ValidationProvider>

                    <ValidationProvider
                      rules="required"
                      name="FECHA DE EVALUACIÓN"
                      vid="formCreate.date"
                      v-slot="provider"
                    >
                      <label>FECHA DE EVALUACIÓN <label class="text-danger"> * </label></label>
                      
                      <el-date-picker
                        v-model="daterange"
                        unlink-panels
                        type="daterange"
                        range-separator="-"
                        start-placeholder="Desde"
                        end-placeholder="Hasta"
                        size="small"
                        format="dd-MM-yyyy"
                        clearable
                        style="margin-bottom:10px"
                        @change="leerDate"
                      >
                      </el-date-picker>

                      <div
                        style="color:red; font-size:10px;bottom:10px;position: relative"
                      >
                        {{ provider.errors[0] }}
                      </div>
                    </ValidationProvider>

                    <ValidationProvider
                      rules="required"
                      name="ADJUNTAR ARCHIVO"
                      vid="validAdjuntarFile"
                      v-slot="provider"
                    >
                    <label>Adjuntar archivo</label>
                    <input
                      type="file"
                      id="files"
                      name="empresa_files[]"
                      style="display: none"
                      accept=".pdf"
                    />
                    <el-input
                      type="text"
                      v-model="validAdjuntarFile"
                      style="display: none"
                    />
                    <el-button
                      size="small"
                      @click="addNewFile"
                      style="width:100%;margin-bottom:10px;color:gray"
                    >
                      Agregar archivo</el-button
                    >
                    <div
                      class="row text-center"
                      v-for="(file, index) in empresa_files"
                      :key="'file-' + index"
                    >
                      <div class="col-xl-10 col-md-6">
                        <span style="font-size: 12px">{{ file.name }}</span>
                      </div>
                      <div class="col-xl-2 col-md-6">
                        <el-button
                          size="mini"
                          type="text"
                          title="Eliminar"
                          @click="deleteFile(index)"
                          ><i class="el-icon-delete"></i
                        ></el-button>
                      </div>
                    </div>
                    <div
                        style="color:red; font-size:10px;bottom:10px;position: relative"
                      >
                        {{ provider.errors[0] }}
                      </div>
                    </ValidationProvider>
                  </el-form>

                  <div class="form-group text-center">
                    <el-button
                      style="background:#23CCEF; color:white;border:0px;margin-top:20px"
                      size="small"
                      @click="handleSubmit(saveBienestar)"
                    >
                      REGISTRAR EVALUACIÓN</el-button
                    >
                  </div>
                </ValidationObserver>

            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Card,
  Form,
  Button,
  DatePicker,
  Popover,
  Select,
  Option,
  Calendar,
  Loading
} from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "bienestar-reporte",
  components: {
    Loading,
    LPagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Card.name]: Card,
    [Form.name]: Form,
    [Button.name]: Button,
    [DatePicker.name]: DatePicker,
    [Popover.name]: Popover,
    [Option.name]: Option,
    [Calendar.name]: Calendar
  },
  data() {
    return {
      dateSelect: "",
      empresa_files: [],
      formCreate: {
        company_id: null,
        name: "",
        start_date: "",
        ending_date: null,
        attach: null
      },
      companies: [],
      pickerOptions: {
        disabledDate(time) {
          var date = new Date();
          return time.getTime() < date.setDate(date.getDate() - 1);
        }
      },
      is_edit: false,
      daterange: [],
      validAdjuntarFile: "",
    };
  },
  watch: {
    "pagination.currentPage"() {
      this.asyncgetEvaluacionBienestar();
      this.Showingpagination();
    },
  },
  methods: {
    async getCompanies() {
      let country_id = null;
      let name = null;
      let code = null;

      let token = null;
      token = sessionStorage.token;

      try {
        const res = await axios.get(`v1/companies`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: 1,
            size: 100,
            name: name,
            code: code,
            country_id,
            "exclude-is-demo": ""
          }
        });
        this.companies = res.data.data.rows.map(el => ({
          ...el,
          name: this.capitalize(el.name)
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async saveBienestar() {
      let token = null;
      token = sessionStorage.token;

      let loadingInstance1 = Loading.service({
        fullscreen: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      try {
        console.log('this.formCreate', this.formCreate);
        let fd = new FormData();
        fd.append("company_id", this.formCreate.company_id);
        fd.append("name", this.formCreate.name);
        fd.append("start_date", this.formCreate.start_date);
        fd.append("ending_date", this.formCreate.ending_date);

        if (this.formCreate.results) {
          fd.append("attach", this.formCreate.results);
        }

        if (this.is_edit) {
          fd.append("id", this.formCreate.id);
          //console.log('this.formCreate', this.formCreate);
          console.log('this.daterange', this.daterange);
          axios
          .put(
            `v1/company/report`, fd, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `token ${token}`
              }
            }
          )
          .then(response => {
            if (response.data.success) {
              loadingInstance1.close();
              Swal.fire({
                title: response.data.message,
                confirmButtonColor: "#23CCEF",
              }).then(result => {
                this.$router.push({ name: "listado-reporte-bienestar" });
              });
            } else {
              console.log(response.data);
              loadingInstance1.close();
              Swal.fire("Error", response.data.message, "error");
            }
          })
          .catch(error => {
            loadingInstance1.close();
            
            console.log(error.message);
            Swal.fire("Error", error.message, "error");
          });

        } else {
          axios
          .post(
            `v1/company/report`, fd, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `token ${token}`
              }
            }
          )
          .then(response => {
            if (response.data.success) {
              loadingInstance1.close();
              Swal.fire({
                title: response.data.message,
                confirmButtonColor: "#23CCEF",
              }).then(result => {
                this.$router.push({ name: "listado-reporte-bienestar" });
              });
              
            } else {
              console.log(response.data);
              loadingInstance1.close();
              Swal.fire("Error", response.data.message, "error");
            }
          })
          .catch(error => {
            loadingInstance1.close();
            
            console.log(error.message);
            Swal.fire("Error", error.message, "error");
          });
        }
        
      } catch (error) {
        console.error(error);
      }
    },
    addNewFile() {
      const inputFile = document.getElementById("files");
      inputFile.click();
      
    },
    setupFileInput() {
      document
        .getElementById("files")
        .addEventListener("change", this.fileUpload, false);
    },
    fileUpload(evt) {
      console.log(evt.target.files[0].type);
      if (
        evt.target.files[0].type == "application/pdf"
      ) {
        if (this.empresa_files.length == 0) {
          if (evt.target.files[0].size > 0) {
            this.empresa_files.push(evt.target.files[0]);
            this.formCreate.results = evt.target.files[0];
            this.validAdjuntarFile = "adjunto";
          } else {
            Swal.fire(
              "Error",
              "Lo sentimos, se detecto un problema con el archivo.",
              "error"
            );
          }
        } else {
          Swal.fire(
            "Error",
            "Lo sentimos, solo se permite cargar un archivo por empresa.",
            "error"
          );
        }
      } else {
        Swal.fire(
          "Error",
          "Lo sentimos, este tipo de archivo no está permitido por motivos de seguridad",
          "error"
        );
      }
      console.log(this.empresa_files);
    },
    deleteFile(index) {
      this.empresa_files.splice(index, 1);
      this.validAdjuntarFile = "";
    },
    capitalize: function(str) {
      try {
        let nombre = "";
        nombre = str.toLocaleLowerCase();
        return nombre
          .toLowerCase()
          .trim()
          .split(" ")
          .map(v => v[0].toUpperCase() + v.substr(1))
          .join(" ");
      } catch (error) {
        console.log(str);
        console.error(error);
      }
    },
    leerDate() {
      this.formCreate.start_date = dayjs(this.daterange[0]).format("YYYY-MM-DD");
      this.formCreate.ending_date = dayjs(this.daterange[1]).format("YYYY-MM-DD");
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    indexMethod(index) {
      return (
        (this.pagination.currentPage - 1) * this.pagination.perPage +
        (index + 1)
      );
    },
    parseFileName(name) {
      const nombre = String(name).split("?")[0];
      const nombre_full = String(nombre).split("/")[(String(nombre).split("/")).length - 1];
      if( nombre_full.length <= 44 ) {
        return nombre_full;
      } else {
        return nombre_full.substring(0, 24) + "...";  
      }
    },
  },
  mounted() {
    this.getCompanies();
    this.setupFileInput();

    if (this.$route.params.reporte.id) {
      this.is_edit = true;
      this.formCreate = this.$route.params.reporte;
      
      this.daterange.push(dayjs(this.$route.params.reporte.start_date).add(1, "day").format("YYYY-MM-DD"));
      this.daterange.push(dayjs(this.$route.params.reporte.ending_date).add(1, "day").format("YYYY-MM-DD"));
      
      this.formCreate.start_date = dayjs(this.$route.params.reporte.start_date).format("YYYY-MM-DD");
      this.formCreate.ending_date = dayjs(this.$route.params.reporte.ending_date).format("YYYY-MM-DD");

      this.empresa_files.push({name: this.parseFileName(this.$route.params.reporte.attach_url)});
      this.validAdjuntarFile = "editar";
    }
  },
}
</script>
<style lang="css" scope>
.el-button
  el-picker-panel__link-btn
  el-button--default
  el-button--mini
  is-plain {
  background: red;
}
.div_top {
  bottom: 30px;
  position: relative;
}
.el-date-picker_el-popper {
  position: absolute;
  top: 100px;
  right: 68px;
}
.horarios_mes {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 12px;
}
.dias_mes {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 12px;
}
.box-card {
  width: 480px;
}
</style>