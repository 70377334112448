<template>
  <div style="height:auto">
    <loading
      :height="60"
      :width="60"
      color="#1873EE"
      background-color="white"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
    ></loading>
    <!-- <div class="row">
      <div class="col-xl-3 col-md-6">
        <h6>Empresas</h6>
      </div>
    </div>
    <br /> -->
    <div>
      <el-card shadow="always" style="border-radius:25px">
        <div slot="header" class="clearfix">
          <span>Filtros Empresa</span>
          <el-button
            style="background:#23CCEF; color:white;border:0px;float: right;"
            size="small"
            icon="el-icon-school"
            @click="viewRegisterCompany"
          >
            Registrar Empresa</el-button
          >
        </div>
        <div class="row">
          <div class="col-6">
            <span class="demonstration" style="font-size:13px">Empresa</span>
            <div>
              <el-input
                :placeholder="placeholder"
                v-model="filter"
                class="input-with-select"
                clearable
                size="small"
                ref="filter"
                suffix-icon="el-icon-search"
              >
                <template slot="prepend">
                  <el-dropdown @command="handleCommand" placement="bottom">
                    <span class="el-dropdown-link">
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="filtros"
                        ><b>Filtros</b></el-dropdown-item
                      >
                      <el-dropdown-item command="code" :style="stylecode"
                        >Código</el-dropdown-item
                      >
                      <el-dropdown-item command="name" :style="stylename"
                        >Nombre</el-dropdown-item
                      >
                      <!-- <el-dropdown-item
                        command="country_id"
                        :style="stylecountry"
                        >País</el-dropdown-item
                      >
                      <el-dropdown-item
                        command="category_id"
                        :style="stylecategory"
                        >Categoría</el-dropdown-item
                      > -->
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>

                <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
              </el-input>
            </div>
          </div>
          <div class="col-6">
            <span class="demonstration" style="font-size:13px">Empleado</span>
            <div>
              <el-input
                :placeholder="placeholder2"
                v-model="filter2"
                class="input-with-select"
                clearable
                size="small"
                ref="filter2"
                suffix-icon="el-icon-search"
              >
                <template slot="prepend">
                  <el-dropdown @command="handleCommand2" placement="bottom">
                    <span class="el-dropdown-link">
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="filtros"
                        ><b>Filtros</b></el-dropdown-item
                      >
                      <el-dropdown-item
                        command="code_employee"
                        :style="stylecodeemployee"
                        >Código</el-dropdown-item
                      >
                      <el-dropdown-item command="email" :style="styleemail"
                        >Email</el-dropdown-item
                      >
                      <el-dropdown-item
                        command="last_name"
                        :style="stylelastname"
                        >Apellido</el-dropdown-item
                      >
                      <el-dropdown-item command="num_doc" :style="stylenumdoc"
                        >Doc identidad</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>

                <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
              </el-input>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <el-card shadow="always" style="border-radius:25px">
          <div>
            <div class="table-responsive table-full-width">
              <el-table
                style="width: 100%; overflow: auto;"
                :data="companies"
                empty-text="Sin datos"
                border
              >
                <!-- <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column> -->
                <el-table-column min-width="70" label="Código" align="center">
                  <template slot-scope="props">
                    <el-button
                      type="text"
                      @click="
                        ver_detalle_empresa(
                          props.row.id,
                          props.row.code,
                          props.row.name,
                          props.row.country_name,
                          props.row.category_name,
                          props.row.ruc
                        )
                      "
                      >{{ props.row.code }}</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="Empresa"
                  min-width="150"
                  prop="name"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  label="Tax ID"
                  min-width="150"
                  prop="ruc"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  label="Categoría"
                  min-width="120"
                  prop="category_name"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  label="País"
                  min-width="100"
                  prop="country_name"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  label="Nº Empleados"
                  min-width="120"
                  prop="num_employee"
                  align="center"
                >
                </el-table-column>
                <el-table-column min-width="105" fixed="right" label="Acciones">
                  <template slot-scope="props_id">
                    <div class="row">
                      <el-dropdown trigger="click">
                        <span
                          class="el-dropdown-link"
                          style="margin-left: 40px"
                        >
                          <i class="el-icon-caret-bottom el-icon-more"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item class="clearfix">
                            <el-button
                              type="text"
                              @click="
                                download_excel(
                                  props_id.row.id,
                                  props_id.row.name
                                )
                              "
                              >Descargar Excel</el-button
                            >
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div
              slot="footer"
              class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
            >
              Showing {{ pagination.currentPage2 }} to
              {{ pagination.perPage2 }} of
              {{ pagination.total }}

              <l-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="pagination.total"
              >
              </l-pagination>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  Popover,
  Tag,
  Descriptions,
  DescriptionsItem,
  Button,
  Dialog,
  Message,
  Card,
  Dropdown,
  DropdownMenu,
  DropdownItem
} from "element-ui";
import {
  Loading,
  Pagination as LPagination,
  FormGroupInput as FgInput,
  StatsCard
} from "src/components/index";
import axios from "axios";

export default {
  name: "Dashboard",
  components: {
    Loading,
    LPagination,
    FgInput,
    StatsCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Popover.name]: Popover,
    [Tag.name]: Tag,
    [Descriptions.name]: Descriptions,
    [DescriptionsItem.name]: DescriptionsItem,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Message.name]: Message,
    [Card.name]: Card,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem
  },
  data() {
    return {
      isLoading: false,
      placeholder: "Nombre",
      stylename: "color:#167FE2;border-radius:8px",
      stylecode: "",
      stylecountry: "",
      stylecategory: "",
      filtro_select: "",

      placeholder2: "Código de empleado",
      stylecodeemployee: "color:#167FE2;border-radius:8px",
      styleemail: "",
      stylelastname: "",
      stylenumdoc: "",
      filtro_select2: "",
      someOtherProperty: null,
      ddTestVm: {
        originalValue: [],
        ddTestSelectedOption: "",
        disabled: false,
        readonly: false,
        visible: true,
        color: "",
        options: [
          {
            value: "code",
            text: "Codigo"
          },
          {
            value: "name",
            text: "Nombre"
          },
          {
            value: "country_id",
            text: "Pais"
          },
          {
            value: "category_id",
            text: "Categoria"
          }
        ]
      },
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [10, 15, 25, 50],
        total: 0,
        currentPage2: 1,
        perPage2: 20
      },
      // tableColumns: [
      //   {
      //     prop: "name",
      //     label: "Empresa",
      //     minWidth: 100
      //   },
      //   {
      //     prop: "ruc",
      //     label: "RUC",
      //     minWidth: 78
      //   },
      //   {
      //     prop: "category_name",
      //     label: "Categoría",
      //     minWidth: 80
      //   },
      //   {
      //     prop: "country_name",
      //     label: "País",
      //     minWidth: 50
      //   }
      // ],
      companies: [],
      countries: [],
      filter: null,
      filter2: null,
      filterCountry: null,
      dialogTableVisible: false,
      //companies: "",
      companies_detalles: []
    };
  },
  watch: {
    filtro_select(filtro_select) {
      // let filtro = "";
      if (filtro_select == "code") {
        filtro = "código";
      } else if (filtro_select == "name") {
        filtro = "Nombre";
      } else if (filtro_select == "category_id") {
        filtro = "Categoría";
      } else if (filtro_select == "country_id") {
        filtro = "País";
      }

      //Message("Seleccionó Filtro: " + filtro);
    },
    "pagination.currentPage"() {
      this.getCompanies();
      this.Showingpagination();
    },
    // filterCountry() {
    //   this.pagination.currentPage = 1;
    //   this.getCompanies();
    // },
    filter() {
      this.pagination.currentPage = 1;
      this.getCompanies();
      this.Showingpagination();
    },
    filter2() {
      this.pagination.currentPage = 1;
      this.getCompanies();
      this.Showingpagination();
    }
  },
  mounted() {
    if (!sessionStorage.isLogged) {
      this.$router.go(-1);
      return;
    }
    this.isLoading = true;
    setTimeout(() => (this.isLoading = false), 3 * 1000);

    this.getCompanies();
    //this.getCountries();
  },
  methods: {
    viewRegisterCompany() {
      this.$router.push({ path: "registrarempresas" });
    },
    async download_excel(companyId, companyName) {     
      this.isLoading = true;
      await this.downloadEmployeesCompany(companyId, companyName);
    },
    async downloadEmployeesCompany(companyId, companyName) {
      try {
        let token = null;
        token = sessionStorage.token;

        axios({
          method: "get",
          url: "/v1.1/business/employee",
          params: {
            page: 1,
            limit: 10000,
            company: companyId
          },
          responseType: "blob",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `token ${token}`
          }
        }).then(res => {
          this.isLoading = false;
          this.convertFile(
            res.data,
            "smarters-registrados-" + companyName + ".xlsx"
          );
        });
      } catch (error) {
        console.error(error);
      }
    },
    convertFile(dataFile, fileName) {
      let newBlob = new Blob([dataFile], {
        type: "application/vnd.ms-excel; charset=UTF-8"
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = fileName;
      link.click();
      setTimeout(function() {
        window.URL.revokeObjectURL(data);
      }, 100);
    },
    handleCommand(filtro_select) {
      if (filtro_select == "code") {
        this.stylecode = "color:#167FE2;border-radius:8px";
        this.stylename = "";
        this.stylecountry = "";
        this.stylecategory = "";
        this.placeholder = "Código";
      } else if (filtro_select == "name") {
        this.stylecode = "";
        this.stylename = "color:#167FE2;border-radius:8px";
        this.stylecountry = "";
        this.stylecategory = "";
        this.placeholder = "Nombre";
      } else if (filtro_select == "country_id") {
        this.stylecode = "";
        this.stylename = "";
        this.stylecountry = "color:#167FE2;border-radius:8px";
        this.stylecategory = "";
        this.placeholder = "País";
      } else if (filtro_select == "category_id") {
        this.stylecode = "";
        this.stylename = "";
        this.stylecountry = "";
        this.stylecategory = "color:#167FE2;border-radius:8px";
        this.placeholder = "Categoría";
      }

      this.$refs.filter.focus();
      this.filtro_select = filtro_select;
    },
    handleCommand2(filtro_select) {
      if (filtro_select == "code_employee") {
        this.stylecodeemployee = "color:#167FE2;border-radius:8px";
        this.styleemail = "";
        this.stylelastname = "";
        this.stylenumdoc = "";
        this.placeholder2 = "Código de empleado";
      } else if (filtro_select == "email") {
        this.stylecodeemployee = "";
        this.styleemail = "color:#167FE2;border-radius:8px";
        this.stylelastname = "";
        this.stylenumdoc = "";
        this.placeholder2 = "Email del empleado o pariente";
      } else if (filtro_select == "last_name") {
        this.stylecodeemployee = "";
        this.styleemail = "";
        this.stylelastname = "color:#167FE2;border-radius:8px";
        this.stylenumdoc = "";
        this.placeholder2 = "Apellido del empleado o pariente";
      } else if (filtro_select == "num_doc") {
        this.stylecodeemployee = "";
        this.styleemail = "";
        this.stylelastname = "";
        this.stylenumdoc = "color:#167FE2;border-radius:8px";
        this.placeholder2 = "Documento de identidad de empleado o pariente";
      }

      this.$refs.filter2.focus();
      this.filtro_select2 = filtro_select;
    },
    capitalize: function(str) {
      let razonSocial = "";
      razonSocial = str.toLocaleLowerCase();
      return razonSocial
        .toLowerCase()
        .trim()
        .split(" ")
        .map(v => v[0].toUpperCase() + v.substr(1))
        .join(" ");
    },
    ver_detalle_empresa(id, code, name, country_name, category_name, ruc) {
      localStorage.setItem("panel_companie_id", id);
      localStorage.setItem("panel_companie_code", code);
      localStorage.setItem("panel_companie_name", name);
      localStorage.setItem("panel_companie_country_name", country_name);
      localStorage.setItem("panel_companie_category_name", category_name);
      localStorage.setItem("panel_companie_ruc", ruc);

      this.$router.push({ path: "empleadosempresa" });
    },
    Showingpagination() {
      this.pagination.perPage2 =
        this.pagination.perPage * this.pagination.currentPage;

      // if (this.pagination.perPage2 > this.pagination.total) {
      //   this.pagination.perPage2 = this.pagination.total;
      // }

      if (this.pagination.currentPage == 1) {
        this.pagination.currentPage2 = 1;
      } else if (this.pagination.currentPage >= 2) {
        ///9//
        this.pagination.currentPage2 = this.pagination.perPage2 - 19;
      }

      if (this.pagination.perPage2 > this.pagination.total) {
        this.pagination.perPage2 = this.pagination.total;
      }
    },
    async getCompanies() {
      try {
        let country_id = null;
        let name = null;
        let code = null;

        let code_employee = null;
        let email = null;
        let last_name = null;
        let num_doc = null;

        let token = null;
        token = sessionStorage.token;

        if (this.filter) {
          if (this.filtro_select == "code") {
            code = this.filter;
          } else if (this.filtro_select == "name") {
            name = this.filter;
          } else if (this.filtro_select == "") {
            name = this.filter;
          }
        }

        if (this.filter2) {
          if (this.filtro_select2 == "code_employee") {
            code_employee = this.filter2;
          } else if (this.filtro_select2 == "email") {
            email = this.filter2;
          } else if (this.filtro_select2 == "last_name") {
            last_name = this.filter2;
          } else if (this.filtro_select2 == "num_doc") {
            num_doc = this.filter2;
          } else {
            code_employee = this.filter2;
          }
        }

        const res = await axios.get(`v1/companies`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: this.pagination.currentPage,
            size: 20,
            name: name,
            code: code,
            country_id,
            "code-employee": code_employee,
            email: email,
            "last-name": last_name,
            "num-doc": num_doc
          }
        });
        if (res.data.data.total_rows > 0) {
          this.pagination.total = res.data.data.total_rows;
          this.companies = res.data.data.rows.map(el => ({
            ...el,
            name: this.capitalize(el.name)
          }));
          ///console.log(res.data.data.total_rows);
        }
      } catch (error) {
        console.error(error);
      }
      if (this.pagination.total <= 20) {
        this.pagination.perPage2 = this.pagination.total;
      }
    },
    async getCountries() {
      try {
        const res = await axios.get(
          //`https://devapi.smartdoctor.pe/v1/countries`,
          `v1/countries`,
          {
            params: {}
          }
        );
        this.countries = res.data.data.map(el => ({
          ...el
        }));
      } catch (error) {
        console.error(error);
      }
    },
    // indexMethod(index) {
    //   return (
    //     (this.pagination.currentPage - 1) * this.pagination.perPage +
    //     (index + 1)
    //   );
    // },
    /*indexMethod2(index) {
      return (this.pagination2.currentPage - 1) * this.pagination2.perPage + (index + 1);
    },*/
    encodeQueryData(data) {
      let result = [];
      for (let d in data) {
        if (data[d] != null && data[d] != "")
          result.push(
            encodeURIComponent(d) + "=" + encodeURIComponent(data[d])
          );
      }

      return result.join("&");
    }
  }
};
</script>
<style lang="css">
#searchinput {
  width: 75px;
}
#searchclear {
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  height: 14px;
  margin: auto;
  font-size: 14px;
  cursor: pointer;
  color: #ccc;
}
.ps__thumb-y {
  display: none;
}
/*.no-border {
  border: 0;
  box-shadow: none;
  outline: none;
}*/
</style>
