import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";
// Dashboard pages
import Overview from "src/pages/Dashboard/Dashboard/Overview.vue";
import B2C from "src/pages/Dashboard/Dashboard/B2C.vue";
import Medicos from "src/pages/Dashboard/Dashboard/Medicos.vue";
import PanelEmpresa from "src/pages/Dashboard/Dashboard/PanelEmpresa.vue";
import EmpleadosEmpresa from "src/pages/Dashboard/Dashboard/EmpleadosEmpresa.vue";
import FamiliaresEmpleados from "src/pages/Dashboard/Dashboard/FamiliaresEmpleados.vue";
import BeneficiariosEmpleados from "src/pages/Dashboard/Dashboard/BeneficiariosEmpleados.vue";
import ImportarTrabajadores from "src/pages/Dashboard/Dashboard/ImportarTrabajadores.vue";
import ProgramarTeleconsulta from "src/pages/Dashboard/Dashboard/ProgramarTeleconsulta.vue";
import RegistrarEmpresas from "src/pages/Dashboard/Dashboard/RegistrarEmpresas.vue";
import Teleconsultas from "src/pages/Dashboard/Dashboard/Teleconsultas.vue";
import Reprogramarteleconsulta from "src/pages/Dashboard/Dashboard/Reprogramarteleconsulta.vue";
import RptAtencionesB2BGlobales from "src/pages/Dashboard/Dashboard/RptAtencionesB2BGlobales.vue";
import RptAtencionesB2B from "src/pages/Dashboard/Dashboard/RptAtencionesB2B.vue";
import ProgramacionHorarioMes from "src/pages/Dashboard/Dashboard/ProgramacionHorarioMes.vue";
import HorariosProgramados from "src/pages/Dashboard/Dashboard/HorariosProgramados.vue";
import InhabilitarHorario from "src/pages/Dashboard/Dashboard/InhabilitarHorario.vue";
import ReporteKpis from "src/pages/Dashboard/Dashboard/ReporteKpis.vue";
import Sdv11 from "src/pages/Dashboard/Dashboard/Sdv11.vue";
import PanelEstadosTeleconsultas from "src/pages/Dashboard/Dashboard/PanelEstadosTeleconsultas.vue";
import EditarHorarioMes from "src/pages/Dashboard/Dashboard/EditarHorarioMes.vue";
//import Stats from 'src/pages/Dashboard/Dashboard/Stats.vue'

import EvaluaciondeBienestar from "src/pages/Dashboard/Dashboard/EvaluaciondeBienestar.vue";
import ListadoEvaluaciondeBienestar from "src/pages/Dashboard/Dashboard/ListadoEvaluaciondeBienestar.vue";
import ReporteBienestar from "src/pages/Dashboard/Dashboard/ReporteBienestar.vue";
import ListadoReporteBienestar from "src/pages/Dashboard/Dashboard/ListadoReporteBienestar";

// Pages
import User from "src/pages/Dashboard/Pages/UserProfile.vue";
import TimeLine from "src/pages/Dashboard/Pages/TimeLinePage.vue";
import Login from "src/pages/Dashboard/Pages/Login.vue";
import Register from "src/pages/Dashboard/Pages/Register.vue";
import Lock from "src/pages/Dashboard/Pages/Lock.vue";

// Components pages
import Buttons from "src/pages/Dashboard/Components/Buttons.vue";
import GridSystem from "src/pages/Dashboard/Components/GridSystem.vue";
import Panels from "src/pages/Dashboard/Components/Panels.vue";
const SweetAlert = () =>
  import("src/pages/Dashboard/Components/SweetAlert.vue");
import Notifications from "src/pages/Dashboard/Components/Notifications.vue";
import Icons from "src/pages/Dashboard/Components/Icons.vue";
import Typography from "src/pages/Dashboard/Components/Typography.vue";

// Forms pages
const RegularForms = () => import("src/pages/Dashboard/Forms/RegularForms.vue");
const ExtendedForms = () =>
  import("src/pages/Dashboard/Forms/ExtendedForms.vue");
const ValidationForms = () =>
  import("src/pages/Dashboard/Forms/ValidationForms.vue");
const Wizard = () => import("src/pages/Dashboard/Forms/Wizard.vue");

// TableList pages
const RegularTables = () =>
  import("src/pages/Dashboard/Tables/RegularTables.vue");
const ExtendedTables = () =>
  import("src/pages/Dashboard/Tables/ExtendedTables.vue");
const PaginatedTables = () =>
  import("src/pages/Dashboard/Tables/PaginatedTables.vue");
// Maps pages
const GoogleMaps = () => import("src/pages/Dashboard/Maps/GoogleMaps.vue");
const FullScreenMap = () =>
  import("src/pages/Dashboard/Maps/FullScreenMap.vue");
const VectorMaps = () => import("src/pages/Dashboard/Maps/VectorMapsPage.vue");

// Calendar
const Calendar = () => import("src/pages/Dashboard/Calendar/CalendarRoute.vue");
// Charts
const Charts = () => import("src/pages/Dashboard/Charts.vue");

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      component: Buttons
    },
    {
      path: "grid-system",
      name: "Grid System",
      component: GridSystem
    },
    {
      path: "panels",
      name: "Panels",
      component: Panels
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      component: SweetAlert
    },
    {
      path: "notifications",
      name: "Notifications",
      component: Notifications
    },
    {
      path: "icons",
      name: "Icons",
      component: Icons
    },
    {
      path: "typography",
      name: "Typography",
      component: Typography
    }
  ]
};
let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      component: RegularForms
    },
    {
      path: "extended",
      name: "Extended Forms",
      component: ExtendedForms
    },
    {
      path: "validation",
      name: "Validation Forms",
      component: ValidationForms
    },
    {
      path: "wizard",
      name: "Wizard",
      component: Wizard
    }
  ]
};

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      component: RegularTables
    },
    {
      path: "extended",
      name: "Extended Tables",
      component: ExtendedTables
    },
    {
      path: "paginated",
      name: "Paginated Tables",
      component: PaginatedTables
    }
  ]
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      component: GoogleMaps
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      component: FullScreenMap
    },
    {
      path: "vector-map",
      name: "Vector Map",
      component: VectorMaps
    }
  ]
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      component: User
    },
    {
      path: "timeline",
      name: "Timeline Page",
      component: TimeLine
    }
  ]
};

let loginPage = {
  path: "/login",
  name: "Login",
  component: Login
};

let registerPage = {
  path: "/register",
  name: "Register",
  component: Register
};

let lockPage = {
  path: "/lock",
  name: "Lock",
  component: Lock
};

const routes = [
  {
    path: "/",
    ///redirect: '/admin/b2c'
    redirect: "/login"
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  loginPage,
  registerPage,
  lockPage,
  {
    path: "/admin",
    component: DashboardLayout,
    redirect: "/admin/overview",
    children: [
      {
        path: "b2b",
        name: "B2B",
        component: Overview
      },
      {
        path: "b2c",
        name: "B2C",
        component: B2C
      },
      {
        path: "medicos",
        name: "Medicos",
        component: Medicos
      },
      {
        path: "panelempresa",
        name: "PANEL EMPRESA",
        component: PanelEmpresa
      },
      {
        //path: 'empleadosempresa/:id/:code',
        path: "empleadosempresa",
        name: "Empleados",
        component: EmpleadosEmpresa
      },
      {
        path: "familiaresempleados/:idempleado/:empresa/:code/:codeempresa",
        name: "Familiares",
        component: FamiliaresEmpleados
      },
      {
        path: "beneficiariosEmpleados/:idempleado/:empresa/:code/:codeempresa",
        name: "Beneficiarios",
        component: BeneficiariosEmpleados
      },
      {
        path: "importartrabajadores",
        name: "IMPORTAR COLABORADORES",
        component: ImportarTrabajadores
      },
      {
        path: "programarteleconsulta",
        name: "PROGRAMAR TELECONSULTA B2B",
        component: ProgramarTeleconsulta
      },
      {
        path: "registrarempresas",
        name: "REGISTRAR EMPRESAS",
        component: RegistrarEmpresas
      },
      {
        path: "reprogramarteleconsulta",
        name: "REPROGRAMAR TELECONSULTAS",
        component: Reprogramarteleconsulta
      },
      {
        path: "teleconsultas",
        name: "TELECONSULTAS PROGRAMADAS",
        component: Teleconsultas
      },
      {
        path: "rptatencionesb2bglobales",
        name: "REPORTE ATENCIONES REALIZADAS B2B GLOBALES",
        component: RptAtencionesB2BGlobales
      },
      {
        path: "rptatencionesb2b",
        name: "REPORTE ATENCIONES REALIZADAS B2B",
        component: RptAtencionesB2B
      },
      {
        path: "programacionhorariomes",
        name: "PROGRAMACIÓN DE HORARIOS POR MES",
        component: ProgramacionHorarioMes
      },
      {
        path: "horariosprogramados",
        name: "HORARIOS PROGRAMADOS",
        component: HorariosProgramados
      },
      {
        path: "inhabilitarhorario",
        name: "INHABILITAR HORARIOS PROGRAMADOS",
        component: InhabilitarHorario
      },
      {
        path: "reportekpis",
        name: "REPORTE KPIS",
        component: ReporteKpis
      },
      {
        path: "panelestadosteleconsultas",
        name: "PANEL ESTADO TELECONSULTAS",
        component: PanelEstadosTeleconsultas
      },
      {
        path: "editarhorariomes",
        name: "ACTUALIZAR HORARIOS",
        component: EditarHorarioMes
      },
      {
        path: "sd-v1-1",
        name: "SD v1.1",
        component: Sdv11
      },
      {
        //path: "familiaresempleados/:idempleado/:empresa/:code/:codeempresa",
        path: "evaluacion-bienestar/:evaluacion",
        name: "evaluacion-bienestar",
        component: EvaluaciondeBienestar
      },
      {
        path: "listado-evaluacion-bienestar",
        name: "listado-evaluacion-bienestar",
        component: ListadoEvaluaciondeBienestar
      },
      {
        path: "bienestar-reporte/:reporte",
        name: "bienestar-reporte",
        component: ReporteBienestar
      },
      {
        path: "listado-reporte-bienestar",
        name: "listado-reporte-bienestar",
        component: ListadoReporteBienestar
      },
    ]
  },
  { path: "*", component: NotFound }
];

export default routes;
