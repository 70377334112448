<template>
  <div>
    <div class="row">
      <div class="col-md-9">
        <h6>PROGRAMAR TELECONSULTA B2B</h6>
        <!-- <small>Completa los siguientes datos</small> -->
      </div>
    </div>
    <!-- <br /> -->
    <div class="row">
      <div class="col-md-6">
        <div class="card stacked-form">
          <!-- <div class="card-header">
            <h5 class="card-title text-center">INFORMACIÓN DE TELECONSULTA</h5>
          </div>
          <br /> -->
          <div class="card-body">
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <el-form label-position="top" ref="formCreate">
                <ValidationProvider
                  rules="required"
                  name="Empleado"
                  vid="information_user"
                  v-slot="provider"
                >
                  <label> Datos Empleado</label>
                  <el-input
                    placeholder="Datos Empleado"
                    v-model="information_user"
                    class="input-with-select"
                    size="small"
                    style="margin-bottom:10px"
                    ref="information_user"
                    readonly
                  >
                    <el-button
                      slot="append"
                      icon="el-icon-user-solid"
                      @click="searchUsers"
                    ></el-button>
                  </el-input>
                  <div
                    style="color:red; font-size:10px;bottom:10px;position: relative"
                  >
                    {{ provider.errors[0] }}
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  name="Especialista"
                  vid="doctor"
                  v-slot="provider"
                >
                  <label>Especialista</label>
                  <el-input
                    placeholder="Especialista"
                    v-model="doctor"
                    class="input-with-select"
                    size="small"
                    style="margin-bottom:10px"
                    readonly
                  >
                    <el-button
                      slot="append"
                      icon="el-icon-s-custom"
                      @click="searchDoctors"
                    ></el-button>
                  </el-input>
                  <div
                    style="color:red; font-size:10px;bottom:10px;position: relative"
                  >
                    {{ provider.errors[0] }}
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  name="Fecha"
                  vid="fecha"
                  v-slot="provider"
                >
                  <label>Fecha</label>
                  <el-input
                    placeholder="Fecha"
                    v-model="fecha"
                    size="small"
                    style="margin-bottom:10px"
                    readonly
                  >
                    <template slot="append"
                      ><el-popover
                        placement="right"
                        width="450"
                        trigger="manual"
                        v-model="popover_visible"
                      >
                        <el-calendar
                          id="calendar"
                          style="height:auto"
                          :first-day-of-week="firstDayOfWeek"
                        >
                          <template slot="dateCell" slot-scope="{ data }">
                            <div @click="calendar_mes(data)">
                              <div
                                style=" text-align: center;                                         
                                          font-size: 12px;"
                                :class="remark(data)"
                              >
                                {{
                                  data.day
                                    .split("-")
                                    .slice(2)
                                    .join("-")
                                }}
                                <div
                                  v-if="
                                    data.day
                                      .split('-')
                                      .slice(1)
                                      .join('-') == currentDay
                                  "
                                ></div>
                              </div>

                              <div
                                v-for="item in schedules_map"
                                :key="item.day"
                              >
                                <div
                                  v-if="
                                    item.months.indexOf(
                                      data.day.split('-').slice(1)[0]
                                    ) != -1
                                  "
                                >
                                  <div
                                    v-if="
                                      item.days.indexOf(
                                        data.day
                                          .split('-')
                                          .slice(2)
                                          .join('-')
                                      ) != -1
                                    "
                                  >
                                    <div class="text-center">
                                      <el-button
                                        type="text"
                                        @click="calendarClick(data)"
                                      >
                                        <div
                                          style="bottom:10px;position:relative"
                                        >
                                          🔴
                                        </div>
                                      </el-button>
                                    </div>
                                  </div>
                                  <div v-else></div>
                                </div>
                                <div v-else></div>
                              </div>
                            </div>
                          </template>
                        </el-calendar>
                        <!-- <i
                          reanonly="true"
                          slot="reference"
                          @click="validatePopover"
                          class="el-icon-date"
                          style="cursor:pointer"
                        ></i>  -->
                        <el-button
                          slot="reference"
                          icon="el-icon-date"
                          @click="validatePopover"
                        ></el-button> </el-popover
                    ></template>
                  </el-input>
                  <div
                    style="color:red; font-size:10px;bottom:10px;position: relative"
                  >
                    {{ provider.errors[0] }}
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  name="Horario"
                  vid="horario"
                  v-slot="provider"
                >
                  <label> HORARIO</label>
                  <el-input
                    placeholder="Horario"
                    v-model="horario"
                    size="small"
                    style="margin-bottom:10px"
                    readonly
                  >
                    <template slot="append"
                      ><el-popover
                        title="Horarios"
                        placement="right"
                        width="auto"
                        trigger="manual"
                        v-model="popover_horarios"
                      >
                        <div :style="stylehorarios">
                          <div
                            v-for="(horario, index) in horarios"
                            :key="'horario-' + index"
                          >
                            <!-- <span style="font-size: 12px">{{ horario }}</span> -->
                            <!-- <el-button
                            type="text"
                            @click="selecthorario(horario)"
                            >{{ horario }}</el-button
                          > -->
                            <el-button
                              type="primary"
                              plain
                              @click="selecthorario(horario)"
                              size="mini"
                              >{{ horario }}</el-button
                            >
                          </div>
                        </div>
                        <!-- <i
                          reanonly="true"
                          slot="reference"
                          @click="validatePopoverHorarios"
                          class="el-icon-time"
                          style="cursor:pointer"
                        ></i>  -->
                        <el-button
                          slot="reference"
                          icon="el-icon-time"
                          @click="validatePopoverHorarios"
                        ></el-button> </el-popover
                    ></template>
                  </el-input>
                  <div
                    style="color:red; font-size:10px;bottom:10px;position: relative"
                  >
                    {{ provider.errors[0] }}
                  </div>
                </ValidationProvider>
                <!-- <br />
                <el-select
                  v-model="horario"
                  placeholder="Horario"
                  style="margin-bottom:10px;width:100%"
                  size="small"
                >
                  <el-option
                    v-for="item in horarios"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <br /> -->
                <ValidationProvider
                  rules="required"
                  name="Sintomas / Motivo teleconsulta"
                  vid="formCreate.subject"
                  v-slot="provider"
                >
                  <label>SINTOMAS / MOTIVO TELECONSULTA</label>
                  <el-input
                    style="margin-bottom:10px;width:100%"
                    size="small"
                    placeholder="Sintomas / Motivo teleconsulta"
                    v-model="formCreate.subject"
                  ></el-input>
                  <div
                    style="color:red; font-size:10px;bottom:10px;position: relative"
                  >
                    {{ provider.errors[0] }}
                  </div>
                </ValidationProvider>
                <!-- <ValidationProvider
                  rules="required"
                  name="Código de Pago"
                  vid="formCreate.payment_id"
                  v-slot="provider"
                >
                  <label> CÓDIGO DE PAGO</label>
                  <el-input
                    style="width:100%;margin-bottom:10px"
                    size="small"
                    placeholder="Código de Pago"
                    v-model="formCreate.payment_id"
                  ></el-input>
                  <div
                    style="color:red; font-size:10px;bottom:10px;position: relative"
                  >
                    {{ provider.errors[0] }}
                  </div>
                </ValidationProvider> -->
                <label> ARCHIVO ADJUNTO</label>

                <input
                  type="file"
                  id="files"
                  name="teleconsulta_files[]"
                  style="display: none"
                />
                <br />
                <el-button
                  size="small"
                  @click="addNewFile"
                  style="width:100%;margin-bottom:10px"
                  >ADJUNTAR ARCHIVO</el-button
                >
                <div
                  class="row text-center"
                  v-for="(file, index) in teleconsulta_files"
                  :key="'file-' + index"
                >
                  <div class="col-xl-10 col-md-6">
                    <span style="font-size: 12px">{{ file.name }}</span>
                  </div>
                  <div class="col-xl-2 col-md-6">
                    <el-button
                      size="mini"
                      type="text"
                      title="Eliminar"
                      @click="deleteFile(index)"
                      ><i class="el-icon-delete"></i
                    ></el-button>
                  </div>
                </div>
                <el-form-item size="small" class="text-center">
                  <el-button
                    style="background:#23CCEF; color:white;border:0px"
                    size="small"
                    @click="handleSubmit(saveAtencion)"
                  >
                    PROGRAMAR TELECONSULTA</el-button
                  >
                </el-form-item>
              </el-form>
            </ValidationObserver>
          </div>
          <!-- <div class="card-body ">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(saveAtencion)">
                <div class="form-group">
                  <label>Datos Usuario</label>

                  <div class="input-group">
                    <input
                      v-model="information_user"
                      type="text"
                      placeholder="Datos Usuario"
                      class="form-control"
                      required="true"
                      readonly
                      style="background:white"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i
                          class="nc-icon nc-zoom-split"
                          style="cursor:pointer"
                          @click="searchUsers"
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Especialista</label>
                  <div class="input-group">
                    <input
                      v-model="doctor"
                      type="text"
                      placeholder="Especialista"
                      class="form-control"
                      readonly
                      style="background:white"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i
                          class="nc-icon nc-zoom-split"
                          style="cursor:pointer"
                          @click="searchDoctors"
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Fecha</label>
                  <div class="input-group">
                    <input
                      v-model="fecha"
                      type="text"
                      placeholder="Pick a day"
                      required
                      class="form-control"
                      readonly
                      style="background:white"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <el-popover
                          placement="right"
                          width="450"
                          trigger="manual"
                          v-model="popover_visible"
                        >
                          <el-calendar id="calendar">
                            <template slot="dateCell" slot-scope="{ data }">
                              <div @click="calendar_mes(data)">
                                <div
                                  style=" text-align: center;                                         
                                          font-size: 12px;"
                                  :class="remark(data)"
                                >
                                  {{
                                    data.day
                                      .split("-")
                                      .slice(2)
                                      .join("-")
                                  }}
                                  <div
                                    class="currentDay"
                                    v-if="
                                      data.day
                                        .split('-')
                                        .slice(1)
                                        .join('-') == currentDay
                                    "
                                  ></div>
                                </div>

                                <div
                                  v-for="item in schedules_map"
                                  :key="item.day"
                                >
                                  <div
                                    v-if="
                                      item.months.indexOf(
                                        data.day.split('-').slice(1)[0]
                                      ) != -1
                                    "
                                  >
                                    <div
                                      v-if="
                                        item.days.indexOf(
                                          data.day
                                            .split('-')
                                            .slice(2)
                                            .join('-')
                                        ) != -1
                                      "
                                    >
                                      <div class="text-center">
                                        <el-button
                                          type="text"
                                          @click="calendarClick(data)"
                                        >
                                          🔴
                                        </el-button>
                                      </div>
                                    </div>
                                    <div v-else></div>
                                  </div>
                                  <div v-else></div>
                                </div>
                              </div>
                            </template>
                          </el-calendar>
                          <i
                            reanonly="true"
                            slot="reference"
                            @click="validatePopover"
                            class="el-icon-date"
                            style="cursor:pointer"
                          ></i>
                        </el-popover>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Horario</label>
                  <el-time-select
                    v-if="horarios == 0"
                    :picker-options="{
                      start: null,
                      step: null,
                      end: null
                    }"
                  >
                  </el-time-select>
                  <select
                    v-if="horarios != 0"
                    v-model="horario"
                    placeholder="Horario"
                    class="form-control"
                    required
                  >
                    <option
                      v-for="item in horarios"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Sintomas / Motivo teleconsulta</label>

                  <input
                    v-model="formCreate.subject"
                    type="text"
                    placeholder="Sintomas / Motivo teleconsulta"
                    class="form-control"
                    required
                  />
                </div>
                <div class="form-group">
                  <label>Código de Pago</label>

                  <input
                    v-model="formCreate.payment_id"
                    type="text"
                    placeholder="Código de Pago"
                    class="form-control"
                    required
                  />
                </div>
                <div class="form-group">
                  <label>Archivo Adjunto</label>
                 
                  <input
                    type="file"
                    id="files"
                    name="teleconsulta_files[]"
                    style="display: none"
                  />

                  <button
                    type="button"
                    class="form-control"
                    @click="addNewFile"
                  >
                    AGREGA TU ARCHIVO
                  </button>
                  <br />
                  <div
                    class="row text-center"
                    v-for="(file, index) in teleconsulta_files"
                    :key="'file-' + index"
                  >
                    <div class="col-xl-10 col-md-6">
                      <span style="font-size: 12px">{{ file.name }}</span>
                    </div>
                    <div class="col-xl-2 col-md-6">
                      <el-button
                        size="mini"
                        type="text"
                        title="Eliminar"
                        @click="deleteFile(index)"
                        ><i class="el-icon-delete"></i
                      ></el-button>
                    </div>
                  </div>
                </div>
               
                <div class="form-group text-center">
                  <button type="submit" class="btn btn-fill btn-info">
                    PROGRAMAR TELECONSULTA
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div> -->
        </div>
      </div>
    </div>
    <!--:before-close="handleClose"-->
    <el-dialog
      title="Lista de Empleados"
      :visible.sync="dialogTableEmpleados"
      width="75%"
      :close-on-click-modal="false"
      top="1%"
    >
      <div class="row">
        <div class="col-6">
          <form>
            <span class="demonstration" style="font-size:13px">Empleado</span>
            <br />
            <div class="form-group">
              <el-input
                :placeholder="placeholder"
                v-model="filterUsers"
                clearable
                ref="filterUsers"
                suffix-icon="el-icon-search"
              >
                <template slot="prepend">
                  <el-dropdown
                    @command="handleCommand"
                    placement="bottom-start"
                  >
                    <span class="el-dropdown-link">
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="filtros"
                        ><b>Filtros</b></el-dropdown-item
                      >
                      <el-dropdown-item command="name"
                        >Nombre o Apellido</el-dropdown-item
                      >
                      <el-dropdown-item command="num_doc"
                        >Num Doc</el-dropdown-item
                      >
                      <!-- <el-dropdown-item command="email">Email</el-dropdown-item>
                      <el-dropdown-item command="code_employee"
                        >Código Empleado</el-dropdown-item
                      >
                      <el-dropdown-item command="company"
                        >Nombre Empresa</el-dropdown-item
                      > -->
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>

                <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
              </el-input>
              <!-- <div class="input-group">
                <input
                  v-model="filterUsers"
                  type="text"
                  placeholder="Escribe aquí.."
                  class="form-control"
                />
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="nc-icon nc-zoom-split"></i>
                  </span>
                  <drop-down
                    icon="nc-icon nc-bullet-list-67"
                    id="ddCommodity"
                    name="ddCommodity"
                    v-model="ddTestVm.ddTestSelectedOption"
                    text="Select Item"
                    variant="primary"
                  >
                    <li class="dropdown-item disabled" value="0">
                      <b>Filtros</b>
                    </li>
                    <li
                      class="dropdown-item"
                      v-for="option in ddTestVm.options"
                      :key="option.value"
                      :value="option.value"
                      @click="ddTestVm.ddTestSelectedOption = option.value"
                    >
                      {{ option.text }}
                    </li>
                  </drop-down>
                </div>
              </div> -->
            </div>
          </form>
        </div>

        <!--<div class="col-6">
          <span class="demonstration" style="font-size:13px">Empresa</span>
          <br />
          <el-select
            v-model="companyname"
            style="width:100%"
            placeholder="Empresa"
            clearable
            filterable
            @input="searchEmployee"
          >
            <el-option
              v-for="item in companies"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>-->
      </div>
      <el-table
        :data="users"
        style="width: 100%; overflow: auto;"
        height="300"
        v-loading="loading"
        @expand-change="expandChange"
      >
        <!-- <el-table-column label="FECHA" width="117" prop="date_birthday">
        </el-table-column> -->
        <el-table-column label="CORREO" min-width="200" prop="email">
        </el-table-column>
        <el-table-column label="NOMBRE" min-width="150" prop="first_name">
        </el-table-column>
        <el-table-column label="APELLIDO" min-width="150" prop="last_name">
        </el-table-column>
        <el-table-column label="DOCUMENTO" min-width="130" prop="num_doc">
        </el-table-column>
        
        
        
        <el-table-column min-width="100" label="" align="center">
          <template slot-scope="props">
            <el-button
              size="mini"
              type="primary"
              :disabled="!props.row.have_package_active"
              @click="
                handleSelectEmployee(
                  props.row.employee_id,
                  props.row.first_name,
                  props.row.last_name,
                  props.row.email,
                  props.row.company,
                  props.row.user_id,
                )
              "
              >Elegir</el-button
            >
          </template>
        </el-table-column>



        <!--<el-table-column type="expand" align="right" width="70">
          <template slot-scope="props">
            <table style="width: 100%">
              <tr>
                <td style="width:100px"><b>TITULAR</b></td>
                <td style="width:100px">{{ props.row.first_name }}</td>
                <td style="width:100px">{{ props.row.last_name }}</td>
                <td style="width:100px">{{ props.row.num_doc }}</td>

                <td style="width:100px">
                  <el-button
                    size="mini"
                    type="primary"
                    :disabled="!props.row.have_package_active"
                    @click="
                      handleSelectEmployee(
                        props.row.id,
                        props.row.first_name,
                        props.row.last_name,
                        props.row.email,
                        props.row.company
                      )
                    "
                    >Elegir</el-button
                  >
                </td>
              </tr>
            </table>

            <div v-for="item in patient" :key="item.id">
              <table style="width: 100%" border="0px">
                <tr>
                  <td style="width:100px">
                    <b>{{ item.relation_name }}</b>
                  </td>
                  <td style="width:100px">{{ item.first_name }}</td>
                  <td style="width:100px">{{ item.last_name }}</td>
                  <td style="width:100px">{{ item.num_doc }}</td>

                  <td style="width:100px">
                    <el-button
                      size="mini"
                      type="primary"
                      :disabled="!props.row.have_package_active"
                      @click="
                        handleSelectFamily(
                          props.row.id,
                          item.id,
                          item.first_name,
                          item.last_name,
                          props.row.company
                        )
                      "
                      >Elegir</el-button
                    >
                  </td>
                </tr>
              </table>
            </div>
          </template>
        </el-table-column>-->




        <!--<el-table-column align="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleSelectEmployee(scope.$index, scope.row)"
              >Elegir</el-button
            >
          </template>
        </el-table-column>--->
      </el-table>
      <div
        slot="footer"
        class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
      >
        Showing {{ pagination.currentPage2 }} to {{ pagination.perPage2 }} of
        {{ pagination.total }}

        <l-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="pagination.total"
        >
        </l-pagination>
      </div>
    </el-dialog>
    <el-dialog
      title="Especialistas"
      :visible.sync="dialogTableDoctor"
      width="52%"
      :close-on-click-modal="false"
    >
      <div class="row">
        <div class="col-md-6">
          <el-input
            placeholder="Datos Especialista"
            v-model="filterDoctors"
            class="input-with-select"
            size="small"
            style="margin-bottom:10px"
            clearable
          >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
          <!-- <form>
            <div class="form-group">
              <div class="input-group">
                <input
                  v-model="filterDoctors"
                  type="text"
                  placeholder="Escribe aquí.."
                  class="form-control"
                />
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="nc-icon nc-zoom-split"></i>
                  </span>
                </div>
              </div>
            </div>
          </form> -->
        </div>
      </div>
      <el-table
        :data="doctors_appointment"
        style="width: 100%"
        height="250"
        border
      >
        <el-table-column label="DOCTOR" width="200" prop="doctor_name">
        </el-table-column>
        <el-table-column label="PAÍS" width="150" prop="country">
        </el-table-column>
        <el-table-column label="ESPECIALIDAD" width="250" prop="speciality">
        </el-table-column>
        <el-table-column label="" width="100" align="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleSelectDoctor(scope.$index, scope.row)"
              >Elegir</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div
        slot="footer"
        class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
      >
        Showing {{ pagination_esp.currentPage2 }} to {{ pagination_esp.perPage2 }} of
        {{ pagination_esp.total }}

        <l-pagination
          class="pagination-no-border"
          v-model="pagination_esp.currentPage"
          :per-page="pagination_esp.perPage"
          :total="pagination_esp.total"
        >
        </l-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  TimeSelect,
  Autocomplete,
  Popover,
  Tag,
  Descriptions,
  DescriptionsItem,
  Button,
  Dialog,
  Calendar,
  Tooltip,
  Input,
  //MessageBox,
  Dropdown,
  Loading,
  DropdownMenu,
  DropdownItem,
  Form,
  FormItem,
  Checkbox,
  Message
} from "element-ui";
import {
  Pagination as LPagination,
  FormGroupInput as FgInput,
  StatsCard
} from "src/components/index";
import axios from "axios";
import Swal from "sweetalert2";

//Librayr
import dayjs from "dayjs";
require("dayjs/locale/es");

// import { extend } from "vee-validate";
// import { required, email, min, max } from "vee-validate/dist/rules";

// extend("email", email);
// extend("required", required);
// extend("min", min);
// extend("max", max);

export default {
  name: "ProgramarTeleconsulta",
  components: {
    LPagination,
    FgInput,
    StatsCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Popover.name]: Popover,
    [Tag.name]: Tag,
    [Descriptions.name]: Descriptions,
    [DescriptionsItem.name]: DescriptionsItem,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [TimeSelect.name]: TimeSelect,
    [Autocomplete.name]: Autocomplete,
    [Calendar.name]: Calendar,
    [Tooltip.name]: Tooltip,
    [Input.name]: Input,
    //[MessageBox.name]: MessageBox,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Checkbox.name]: Checkbox,
    [Message.name]: Message
  },
  data() {
    return {
      loading: false,
      companies: [],
      companyname: null,
      listError: [],
      firstDayOfWeek: 0,
      company: 0,
      stylehorarios: "",
      placeholder: "Nombres",
      filtro_select: "",
      number_mes: "",
      medical_attention_id: 0,
      teleconsulta_files: [],
      popover_visible: false,
      popover_horarios: false,
      date: new Date(),
      currentDay: dayjs(this.date).format("MM-DD"),
      currentMonth: dayjs(this.date).format("MM"),
      currentYear: dayjs(this.date).format("YYYY"),
      doctor: "",
      information_user: "",
      fecha: "",
      horario: "",
      filterUsers: null,
      filterDoctors: null,
      dialogTableDoctor: false,
      dialogTableEmpleados: false,
      //search: "",
      //state: "",
      //timeout: null,
      countries: [],
      patient: [],
      schedules: [],
      schedules_map: [],
      currentSchedules: [],
      currentSchedulesSelectedDay: [],
      horarios: [],
      doctors_appointment: [],
      users: [],
      formCreate: {
        doctor: 0,
        patient: null,
        room: "",
        scheduled_date: "",
        service: "002",
        status: "scheduled",
        subject: "",
        employee: 0,
        channel: "SD",
        // payment_id: "",
        // doctor_id: 0,
        user: 0,
        // patient_id: null,
        // scheduled_date: "",
        // subject: "",
        // service_code: "002",
        // status: "scheduled",
        // room: ""
      },

      formGetSchedules: {
        doctor_id: "",
        date: null,
        employee_id: ""
      },
      ddTestVm: {
        originalValue: [],
        ddTestSelectedOption: "",
        disabled: false,
        readonly: false,
        visible: true,
        color: "",
        options: [
          {
            value: "name",
            text: "Nombre o Apellido"
          },
          {
            value: "num_doc",
            text: "Num Doc"
          },
          {
            value: "email",
            text: "Email"
          }
        ]
      },
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [4, 15, 25, 50],
        total: 0,
        currentPage2: 1,
        perPage2: 20
      },
      pagination_esp: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [4, 15, 25, 50],
        total: 0,
        currentPage2: 1,
        perPage2: 20
      },
      limitFile: 15
    };
  },
  created: function() {
    this.$nextTick(() => {
      //  Click on the previous month
      let title_calendar_prev = document.querySelector(".el-calendar__title");
      let cadena_previous = "";
      let prevBtn = document.querySelector(
        ".el-calendar__button-group .el-button-group>button:nth-child(1)"
      );
      prevBtn.addEventListener("click", () => {
        cadena_previous = title_calendar_prev.innerHTML;
        let mes = cadena_previous.substr(11, 15);
        this.getNumberMesCalendar(mes.replace(/\s+/g, ""));

        let day =
          cadena_previous.substr(4, 7) + "-" + this.number_mes + "-" + "01";
        this.formGetSchedules.date = day.replace(/\s+/g, "");
        this.getSchedulesSiguienteMes(day.replace(/\s+/g, ""));
      });

      //Click Next
      let title_calendar_next = document.querySelector(".el-calendar__title");
      let cadena_next = "";
      let nextBtn = document.querySelector(
        ".el-calendar__button-group .el-button-group>button:nth-child(3)"
      );
      nextBtn.addEventListener("click", () => {
        cadena_next = title_calendar_next.innerHTML;

        let mes = cadena_next.substr(11, 15);
        this.getNumberMesCalendar(mes.replace(/\s+/g, ""));

        let day = cadena_next.substr(4, 7) + "-" + this.number_mes + "-" + "01";
        this.formGetSchedules.date = day.replace(/\s+/g, "");
        this.getSchedulesSiguienteMes(day.replace(/\s+/g, ""));
      });

      //Click today
      let title_calendar_today = document.querySelector(".el-calendar__title");
      let cadena_today = "";
      let todayBtn = document.querySelector(
        ".el-calendar__button-group .el-button-group>button:nth-child(2)"
      );
      todayBtn.addEventListener("click", () => {
        cadena_today = title_calendar_today.innerHTML;

        let mes = cadena_today.substr(11, 15);
        this.getNumberMesCalendar(mes.replace(/\s+/g, ""));

        let day =
          cadena_today.substr(4, 7) + "-" + this.number_mes + "-" + "01";
        this.formGetSchedules.date = day.replace(/\s+/g, "");
        this.getSchedulesSiguienteMes(day.replace(/\s+/g, ""));
      });
    });
  },
  watch: {
    "pagination.currentPage"() {
      this.searchEmployee();
      //this.Showingpagination();
    },
    "pagination_esp.currentPage"() {
      this.searchEspecialista();
      //this.Showingpagination();
    },
    filterUsers() {
      this.pagination.currentPage = 1;
      this.searchEmployee();
      //this.Showingpagination();
    },
    filterDoctors() {
      this.pagination_esp.currentPage = 1;
      this.getDoctorsForAppointment();
    }
  },
  computed: {},
  mounted() {
    this.getCompanies();

    this.setupFileInput();
    this.getCountries();
    this.getDoctorsForAppointment();
    this.searchEmployee();
    this.searchEspecialista();

    if (!sessionStorage.isLogged) {
      this.$router.go(-1);
      return;
    }
  },
  methods: {
    async searchEmployee() {
      this.loading = true;
      await this.getUsersPatients();
      this.loading = false;
      this.Showingpagination();
    },
    async searchEspecialista() {
      this.loading = true;
      await this.getDoctorsForAppointment();
      this.loading = false;
      this.ShowingpaginationEsp();
    },
    Showingpagination() {
      this.pagination.perPage2 =
        this.pagination.perPage * this.pagination.currentPage;

      if (this.pagination.currentPage == 1) {
        this.pagination.currentPage2 = 1;
      } else if (this.pagination.currentPage >= 2) {
        this.pagination.currentPage2 = this.pagination.perPage2 - 19;
      }

      if (this.pagination.perPage2 > this.pagination.total) {
        this.pagination.perPage2 = this.pagination.total;
      }
    },
    ShowingpaginationEsp() {
      this.pagination_esp.perPage2 =
        this.pagination_esp.perPage * this.pagination_esp.currentPage;

      if (this.pagination_esp.currentPage == 1) {
        this.pagination_esp.currentPage2 = 1;
      } else if (this.pagination_esp.currentPage >= 2) {
        this.pagination_esp.currentPage2 = this.pagination_esp.perPage2 - 19;
      }

      if (this.pagination_esp.perPage2 > this.pagination_esp.total) {
        this.pagination_esp.perPage2 = this.pagination_esp.total;
      }
    },
    async getCompanies() {
      let country_id = null;
      let name = null;
      let code = null;

      let token = null;
      token = sessionStorage.token;

      try {
        const res = await axios.get(`v1/companies`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: 1,
            size: 100,
            name: name,
            code: code,
            country_id,
            "exclude-is-demo": ""
          }
        });
        this.companies = res.data.data.rows.map(el => ({
          ...el,
          name: this.capitalize(el.name)
        }));
      } catch (error) {
        console.error(error);
      }
    },
    capitalize: function(str) {
      try {
        let nombre = "";
        nombre = str.toLocaleLowerCase();
        return nombre
          .toLowerCase()
          .trim()
          .split(" ")
          .map(v => v[0].toUpperCase() + v.substr(1))
          .join(" ");
      } catch (error) {
        console.log(str);
        console.error(error);
      }
    },
    reset() {
      this.information_user = "";
      this.doctor = "";
      this.fecha = "";
      this.horario = "";

      this.formCreate.doctor = 0;
      this.formCreate.patient = null;
      this.formCreate.room = "";
      this.formCreate.scheduled_date = "";
      this.formCreate.subject = "";
      this.formCreate.employee = 0;

      this.teleconsulta_files = [];

      this.$refs.observer.reset();
    },
    selecthorario(horario) {
      this.horario = horario;
      this.popover_horarios = false;
      console.log(horario);
    },
    handleCommand(filtro_select) {
      if (filtro_select == "name") {
        this.placeholder = "Nombre o Apellido";
      } else if (filtro_select == "num_doc") {
        this.placeholder = "Num Doc";
      } else if (filtro_select == "email") {
        this.placeholder = "Email";
      } else if (filtro_select == "code_employee") {
        this.placeholder = "Código Empleado";
      } else if (filtro_select == "company") {
        this.placeholder = "Nombre de la Empresa";
      }

      this.$refs.filterUsers.focus();
      this.filtro_select = filtro_select;
    },
    getNumberMesCalendar(mes) {
      switch (mes) {
        case "enero":
          this.number_mes = "01";
          break;
        case "febrero":
          this.number_mes = "02";
          break;
        case "marzo":
          this.number_mes = "03";
          break;
        case "abril":
          this.number_mes = "04";
          break;
        case "mayo":
          this.number_mes = "05";
          break;
        case "junio":
          this.number_mes = "06";
          break;
        case "julio":
          this.number_mes = "07";
          break;
        case "agosto":
          this.number_mes = "08";
          break;
        case "septiembre":
          this.number_mes = "09";
          break;
        case "octubre":
          this.number_mes = "10";
          break;
        case "noviembre":
          this.number_mes = "11";
          break;
        case "diciembre":
          this.number_mes = "12";
          break;
      }
      //return number_mes;
    },
    remark(data) {
      let str = "";
      if (data.isSelected) {
        str = "is-selected";
      }
      return str;
    },
    calendar_mes(data) {
      this.formGetSchedules.date = data.day;
      this.getSchedulesSiguienteMes(data.day);
    },
    addNewFile() {
      const inputFile = document.getElementById("files");
      inputFile.click();
    },
    setupFileInput() {
      document
        .getElementById("files")
        .addEventListener("change", this.fileUpload, false);
    },
    fileUpload(evt) {
      if (
        evt.target.files[0].type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        evt.target.files[0].type == "application/vnd.ms-excel" ||
        evt.target.files[0].type == "image/png" ||
        evt.target.files[0].type == "application/pdf" ||
        evt.target.files[0].type ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        evt.target.files[0].type == "application/msword" ||
        evt.target.files[0].type == "image/jpeg"
      ) {
        this.teleconsulta_files.push(evt.target.files[0]);
        this.validLimitFiles(evt);
      } else {
        Swal.fire(
          "Error",
          "Lo sentimos, este tipo de archivo no está permitido por motivos de seguridad",
          "error"
        );
      }
    },
    validLimitFiles(event) {
      console.log('event', event);
        var limit = this.limitFile * 1000000; //8megas
        var size = 0;

        size = event.target.files[0].size;
        if (size > limit) {
            this.teleconsulta_files.pop();

            Swal.fire(
              "Error",
              `¡Error, el archivo supera el limite de ${this.limitFile} MB`,
              "error"
            );

        }
    },
    deleteFile(index) {
      this.teleconsulta_files.splice(index, 1);
    },
    async uploadFile(file) {
      try {
        let token = null;
        token = sessionStorage.token;

        let fd = new FormData();
        fd.append("file", file);
        fd.append("medical_attention_id", this.medical_attention_id);
        const res = await axios.post(
          `v1/pro/medical_attention/file?for-dashboard`,
          // `https://devapi.smartdoctor.pe/v1/pro/medical_attention/file`,
          fd,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `token ${token}`
            }
          }
        );
      } catch (error) {
        console.dir(error);
        return false;
      }
    },
    async loadFiles() {
      try {
        this.teleconsulta_files.forEach(file => {
          this.uploadFile(file);
        });
      } catch (error) {
        console.dir(error);
        return false;
      }
    },
    addSchedules() {
      if (this.horarios.length != 0) {
        this.horarios = [];

        this.currentSchedulesSelectedDay.forEach(child => {
          child.schedule.forEach(detalle => {
            this.horarios.push(detalle);
          });
        });
      } else {
        this.currentSchedulesSelectedDay.forEach(child => {
          child.schedule.forEach(detalle => {
            this.horarios.push(detalle);
          });
        });
      }

      if (this.horarios.length <= 5) {
        this.stylehorarios =
          "display: grid;  grid-template-columns: repeat(" +
          this.horarios.length +
          ", 1fr); grid-gap: 12px;";
      } else {
        this.stylehorarios =
          "display: grid;  grid-template-columns: repeat(6, 1fr); grid-gap: 12px;";
      }

      //console.log("Cantidad Horarios: " + this.horarios.length);
    },
    validatePopover() {
      if (this.formCreate.doctor != 0) {
        if (this.popover_visible) {
          this.popover_visible = false;
        } else {
          this.popover_visible = true;
        }
      } else {
        Message({
          showClose: true,
          message: "Debe seleccionar un Especialista, Gracias.",
          type: "warning"
        });
      }
    },
    validatePopoverHorarios() {
      if (this.fecha) {
        if (this.formCreate.doctor != 0) {
          if (this.popover_horarios) {
            this.popover_horarios = false;
          } else {
            if (this.horarios != 0) {
              this.popover_horarios = true;
            }
          }
        }
      } else {
        Message({
          showClose: true,
          message: "Debe seleccionar una Fecha, Gracias.",
          type: "warning"
        });
      }
    },
    handleClose(done) {
      MessageBox.confirm("¿Estás segura de cerrar este diálogo?")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    expandChange(row, expandedRows) {
      if (expandedRows.length > 0) {
        this.getFamiliaresByUsuario(row.id);
      }
    },
    currentSchedulesList(date) {
      var currentSchedules = this.currentSchedules;
      var results = dayjs(date).format("DD")
        ? currentSchedules.filter(this.createFilter(dayjs(date).format("DD")))
        : currentSchedules;
      this.currentSchedulesSelectedDay = results;
    },
    calendarClick(event) {
      this.popover_visible = false;
      this.horario = "";
      this.evento_horario();
      this.currentSchedulesList(event.day);
      this.addSchedules();
      this.fecha = event.day;
    },
    handleSelectEmployee(id, nombre, apellido, email, company, user_id) {
      // this.company = company;
      this.formGetSchedules.employee_id = id;
      this.formCreate.employee = id;
      this.formCreate.user = user_id;
      if (nombre != "" && apellido != "") {
        this.information_user = nombre + " " + apellido;
      } else if (nombre != "") {
        this.information_user = nombre;
      } else if (apellido != "") {
        this.information_user = apellido;
      } else {
        this.information_user = email;
      }
      this.dialogTableEmpleados = false;

      if (this.company == 0) {
        this.company = company;
      } else {
        if (this.company != company) {
          this.doctor = "";
          this.fecha = "";
          this.horario = "";
          this.company = company;
        }
      }
      //console.log("this.company:", this.company, "company:", company);

      this.getDoctorsForAppointment();
    },
    handleSelectFamily(employee, id, nombre, apellido, company) {
      //this.company = company;
      this.formGetSchedules.employee_id = employee;
      this.formCreate.employee = employee;
      this.formCreate.patient = id;
      this.information_user = nombre + " " + apellido;
      this.dialogTableEmpleados = false;

      if (this.company == 0) {
        this.company = company;
      } else {
        if (this.company != company) {
          this.doctor = "";
          this.fecha = "";
          this.horario = "";
          this.company = company;
        }
      }

      this.getDoctorsForAppointment();
    },
    handleSelectDoctor(index, row) {
      this.horario = "";
      this.fecha = "";

      if (this.horarios.length != 0) {
        this.horarios = [];
      }

      this.formCreate.doctor = row.id;
      this.doctor = row.doctor_name;
      this.dialogTableDoctor = false;

      let date_shedules = dayjs(this.date).format("YYYY-MM-DD");

      this.formGetSchedules.doctor_id = row.id;
      this.formGetSchedules.date = date_shedules;

      this.getSchedules();

      console.log(index, this.formCreate.doctor);
    },
    searchUsers() {
      this.dialogTableEmpleados = true;
    },
    searchDoctors() {
      this.popover_horarios = false;
      this.popover_visible = false;

      if (this.company != 0) {
        this.dialogTableDoctor = true;
      } else {
        this.dialogTableDoctor = false;

        Message({
          showClose: true,
          message: "Debe seleccionar un Empleado, Gracias.",
          type: "warning"
        });
      }
    },
    createFilter(queryString) {
      return currentSchedules => {
        return (
          currentSchedules.day
            .toString()
            .padStart(2, "0")
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      console.log(item.doctor);
    },

    async getCountries() {
      try {
        const res = await axios.get(
          `v1/countries`,
          ///`https://devapi.smartdoctor.pe/v1/countries`,
          {
            params: {}
          }
        );
        this.countries = res.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getSchedules() {
      let token = null;
      token = sessionStorage.token;

      try {
        const res = await axios.post(
          `v1/pro/user/doctors/schedule`,
          //`https://devapi.smartdoctor.pe/v1/pro/user/doctors/schedule`,
          this.formGetSchedules,
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              authorization: `token ${token}`
            }
          }
        );
        this.schedules = res.data.data;

        if (!res.data.success) {
          Swal.fire(
            "Alert",
            res.data.message,
          );
        }
        this.evento_calendario();
      } catch (error) {
        console.error(error);
      }
    },
    evento_calendario() {
      let date = new Date();
      let mes_actual = dayjs(date).format("MM");

      const schedulesMap = this.schedules;
      this.schedules_map = schedulesMap.map(sch => {
        return {
          months: mes_actual,
          days: sch.day.toString().padStart(2, "0"),
          things: "Horarios"
        };
      });
    },
    async getSchedulesSiguienteMes(day) {
      let token = null;
      token = sessionStorage.token;

      try {
        const res = await axios.post(
          `v1/pro/user/doctors/schedule`,
          //`https://devapi.smartdoctor.pe/v1/pro/user/doctors/schedule`,
          this.formGetSchedules,
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              authorization: `token ${token}`
            }
          }
        );

        if (!res.data.success) {
          Swal.fire(
            "Alert",
            res.data.message,
          );
        }
        this.schedules = res.data.data;
        this.evento_calendario_Siguiente_Mes(day);
      } catch (error) {
        console.error(error);
      }
    },
    evento_calendario_Siguiente_Mes(date) {
      console.log(date);
      let mes_actual = dayjs(date).format("MM");

      console.log("Mes Siguiente: " + date);
      console.log(mes_actual);

      const schedulesMap = this.schedules;
      this.schedules_map = schedulesMap.map(sch => {
        return {
          months: mes_actual,
          days: sch.day.toString().padStart(2, "0"),
          things: "Horarios"
        };
      });
    },
    evento_horario() {
      const daily_schedule = this.schedules;
      this.currentSchedules = daily_schedule;
    },
    async getDoctorsForAppointment() {
      let doctor_name = null;
      let token = null;
      token = sessionStorage.token;

      if (this.filterDoctors) {
        doctor_name = this.filterDoctors;
      }

      try {
        const res = await axios.get(
          `v1.1/pro/doctor`,
          //`https://devapi.smartdoctor.pe/v1/pro/user/doctors/appointment`,
          {
            headers: {
              Authorization: `token ${token}`
            },
            params: {
              page: this.pagination_esp.currentPage,
              limit: 20,
              employee: this.formCreate.employee,
              company: this.company,
              specialty: "",
              name: doctor_name,
              "report-a": ""
            }
          }
        );
        if (res.data.count > 0) {
          this.doctors_appointment = res.data.results.map(el => ({
            ...el,
            doctor_name: el.first_name + " " + el.last_name
          }));
          this.pagination_esp.total = res.data.count;
        } else {
          this.pagination_esp.total = 0;
          this.doctors_appointment = [];
        }
      } catch (error) {
        console.error(error);
      }

      if (this.pagination_esp.total <= 20) {
        this.pagination_esp.perPage2 = this.pagination_esp.total;
      }

      console.log(this.pagination_esp.perPage2);
    },
    async getFamiliaresByUsuario(EmployeeId) {
      let token = null;
      token = sessionStorage.token;
      try {
        const res = await axios.get(
          `v1/employee/${EmployeeId}/family`,
          //`v1/user/${id_usuario}/family`,
          //`api.get(`${urlBase3}/${id}/family/${a}`);`,
          {
            headers: {
              Authorization: `token ${token}`
            },
            params: {
              //id: id_usuario
            }
          }
        );
        //console.log(res.data.data.rows);
        this.patient = res.data.data.rows;
      } catch (error) {
        console.error(error);
      }
    },
    async getUsersPatients() {
      let token = null;
      let email = null;
      let num_doc = null;
      let full_name = "";
      let code_employee = null;
      let company = null;

      token = sessionStorage.token;

      if (this.filterUsers) {
        if (this.filtro_select == "email") {
          email = this.filterUsers;
        } else if (this.filtro_select == "num_doc") {
          num_doc = this.filterUsers;
        } else if (this.filtro_select == "name") {
          full_name = this.filterUsers;
        } else if (this.filtro_select == "code_employee") {
          code_employee = this.filterUsers;
        }
        // else if (this.filtro_select == "company") {
        //   company = this.filterUsers;
        // }
        else {
          full_name = this.filterUsers;
        }
      }

      try {
        const res = await axios.get(`v1.1/account/user`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: this.pagination.currentPage,
            is_b2b: "",
            limit: 20,
            full_name: full_name,
            num_doc: num_doc,
            email: email,
            code_employee: code_employee,
            "format-create-teleconsult": "",
            company: this.companyname
          }
        });
        if (res.data.count > 0) {
          this.pagination.total = res.data.count;
          this.users = res.data.results;
        } else {
          this.pagination.total = 0;
          this.users = [];
        }
      } catch (error) {
        console.error(error);
      }

      if (this.pagination.total <= 20) {
        this.pagination.perPage2 = this.pagination.total;
      }

      console.log(this.pagination.perPage2);
    },
    async PayServiceActualizado() {
      let token = null;
      token = sessionStorage.token;
      try {
        const res = await axios.get(
          `v1/pro/medical_attention/pay/`,
          ///`https://devapi.smartdoctor.pe/v1/pro/medical_attention/pay/`,
          {
            headers: {
              Authorization: `token ${token}`
            },
            params: {
              token: 1,
              service_code: "002",
              status: "scheduled"
            }
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    saveAtencion() {
      this.RegisterMedicalAttention();
    },
    async RegisterMedicalAttention() {
      let token = null;
      token = sessionStorage.token;

      this.formCreate.scheduled_date = this.fecha + " " + this.horario;

      let date = new Date();
      let now = date.getTime();

      //VIDEO_SESSION_ + System.currentTimeMillis(13 DIGITOS) + doctorId + "_" + userID
      //VIDEO_SESSION_1641306256151855_47073

      if (this.formCreate.patient == null) {
        this.formCreate.room =
          "VIDEO_SESSION_" +
          now +
          this.formCreate.doctor +
          "_" +
          this.formCreate.employee;
      } else {
        this.formCreate.room =
          "VIDEO_SESSION_" +
          now +
          this.formCreate.doctor +
          "_" +
          this.formCreate.patient;
      }
      try {
        Swal.fire({
          title: "¿Desea programar la cita?",
          text:
            "Una vez programada la cita, la fecha y horario serán visibles para el paciente.",
          ///icon: 'warning',
          confirmButtonColor: "#23CCEF",
          cancelButtonColor: "#AEB6BF",
          cancelButtonText: "CANCELAR",
          confirmButtonText: "CONFIRMAR",

          showCancelButton: true
        }).then(result => {
          if (result) {
            if (result.value) {
              let loadingInstance1 = Loading.service({
                fullscreen: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
              });
              axios
                .post(
                  `v1.1/pro/medical-attention`,
                  //`https://devapi.smartdoctor.pe/v1/pro/medical_attention/`,
                  this.formCreate,
                  {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `token ${token}`
                    }
                  }
                )
                .then(response => {
                  if (response.data.status == "ok") {
                    this.medical_attention_id = response.data.id;
                    this.loadFiles();

                    // setTimeout(() => {
                    //   loadingInstance1.close();
                    //   Swal.fire(
                    //     "Cita programada",
                    //     "La teleconsulta fue programada. Por favor, confirmar con el usuario la cita",
                    //     "success"
                    //   );

                    //   this.reset();
                    // }, 6000);
                    loadingInstance1.close();
                    Swal.fire(
                      "Cita programada",
                      "La teleconsulta fue programada. Por favor, confirmar con el usuario la cita",
                      "success"
                    );

                    this.reset();
                  } else {
                    loadingInstance1.close();
                    Swal.fire("Error", response.data.message, "error");
                  }
                })
                .catch(error => {
                  loadingInstance1.close();
                  console.log('error', error);
                  if (error.response.data.errors.Error) {
                    this.listError = error.response.data.errors.Error;
                    Swal.fire("Error", this.listError.toString(), "error");
                  } else if (error.response.data.errors.user) {
                    this.listError = error.response.data.errors.user;
                    Swal.fire("Error", this.listError.toString(), "error");
                  }
                  
                  this.reset();
                });
            }
          }
        });
      } catch (error) {
        loadingInstance1.close();
        console.error(error);
      }
    }
  }
};
</script>
<style lang="css">
.horarios_programar {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 12px;
}
.el-calendar-table__row .current .calendar-day {
  text-align: center;
  color: #202535;
  line-height: 30px;
  font-size: 36px;
  font-family: " Ses blackbody CN Bold", " Ses blackbody CN Regular",
    " Ses blackbody CN";
  font-weight: 700;
  margin-top: 15px;
}
.el-calendar-table__row .prev .calendar-day,
.el-calendar-table__row .next .calendar-day {
  text-align: center;
  /* color: #202535; */
  line-height: 30px;
  font-size: 36px;
  font-family: " Ses blackbody CN Bold", " Ses blackbody CN Regular",
    " Ses blackbody CN";
  font-weight: 700;
  margin-top: 15px;
}
/* .el-calendar-table__row .current,.el-calendar-table__row .prev,.el-calendar-table__row .next{
height: 100px;
} */
​ .is-selected {
  font-family: " Ses blackbody CN Bold", " Ses blackbody CN Regular",
    " Ses blackbody CN";
  font-weight: 700;
  font-size: 24px;
  margin-top: 5px;
  text-decoration: none;
  /* color: rgb(104, 143, 235); */
}
.is-selected-select {
  color: rgb(104, 143, 235);
}
/* .el-calendar-table tr td:first-child {
   border-left: none;
}
.el-calendar-table tr:first-child td {
   border-top: none;
} */
.el-calendar-table td {
  vertical-align: top;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
}
.el-calendar-table .el-calendar-day {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px 0 15px 0;
  height: 50px;
}
.el-calendar-table .current .el-calendar-day:hover {
  cursor: pointer;
  background-color: rgb(104, 143, 235);
  color: #fff;
  border-radius: 10px;
}
.el-calendar-table .prev .el-calendar-day:hover,
.el-calendar-table .next .el-calendar-day:hover {
  background-color: #fff;
}
.el-calendar-table .current .el-calendar-day:hover .calendar-day,
.el-calendar-table .current .el-calendar-day:hover .is-selected {
  color: #fff;
}
/* .currentDay {
  border: 1px solid rgb(20, 20, 20);

  width: 5px;
  text-align: center;
} */
/* .calendar-day {
  text-align: center;
  color: #202535;
  line-height: 30px;
  font-size: 12px;
}
.is-selected {
  color: #f8a535;
  font-size: 10px;
  margin-top: 5px;
}
#calendar {
  height: 600px;
}
.currentDay {
  border: 1px solid rgb(20, 20, 20);
  border-radius: 50px;
}
.orange {
  background-color: #ee915c;
} */
.lista {
  background-color: #f1ece9;
}
.el-table__expand-column .cell {
  border: 0px solid;
  background-color: #f1ece9;
  border-radius: 50px;
}
</style>
