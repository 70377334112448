<template>
  <!-- <div>
    <card>
      <h3>Bienvenidos</h3>
      <h4>
        Esta sección está en construcción. Próximamente estará disponible.
      </h4>
      <div class="text-left">
        <img src="image/img_logo1.png" style="width:100px" />
      </div>
    </card>
  </div> -->
  <div>
    <loading
      :height="60"
      :width="60"
      color="#1873EE"
      background-color="white"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
    ></loading>
    <div v-if="div_list_teleconsulta">
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <h6>Reprogramar Teleconsultas</h6>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-5">
          <div>
            <el-input
              :placeholder="placeholder"
              v-model="filter"
              clearable
              ref="filter"
              v-if="filtro_select != 'Fecha'"
            >
              <template slot="prepend">
                <el-dropdown @command="handleCommand" placement="bottom">
                  <span class="el-dropdown-link">
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="filtros"
                      ><b>Filtros</b></el-dropdown-item
                    >
                    <el-dropdown-item command="Paciente"
                      >Paciente</el-dropdown-item
                    >
                    <el-dropdown-item command="Usuario"
                      >Usuario</el-dropdown-item
                    >
                    <el-dropdown-item command="Empleado"
                      >Empleado</el-dropdown-item
                    >
                    <el-dropdown-item command="Doctor">Doctor</el-dropdown-item>
                    <el-dropdown-item command="Empresa"
                      >Empresa</el-dropdown-item
                    >
                    <el-dropdown-item command="Fecha">Fecha</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>

              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>

            <el-input
              :placeholder="placeholder"
              v-model="filter_scheduleddate"
              ref="filter_scheduleddate"
              clearable
              onkeyup="
              var v = this.value;
              if (v.match(/^\d{2}$/) !== null) {
                  this.value = v + '/';
              } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
                  this.value = v + '/';
              }"
              maxlength="10"
              v-if="filtro_select == 'Fecha'"
            >
              <template slot="prepend">
                <el-dropdown
                  @command="handleCommandFilterScheduleddate"
                  placement="bottom"
                >
                  <span class="el-dropdown-link">
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="filtros"
                      ><b>Filtros</b></el-dropdown-item
                    >
                    <el-dropdown-item command="Paciente"
                      >Paciente</el-dropdown-item
                    >
                    <el-dropdown-item command="Usuario"
                      >Usuario</el-dropdown-item
                    >
                    <el-dropdown-item command="Empleado"
                      >Empleado</el-dropdown-item
                    >
                    <el-dropdown-item command="Doctor">Doctor</el-dropdown-item>
                    <el-dropdown-item command="Empresa"
                      >Empresa</el-dropdown-item
                    >
                    <el-dropdown-item command="Fecha">Fecha</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>

              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </div>
        </div>
      </div>
      <br />
      <div>
        <el-card shadow="always" style="border-radius:25px">
          <div>
            <div class="table-responsive table-full-width">
              <el-table
                :data="teleconsultas"
                style="width: 100%"
                stripe
                empty-text="No existe ninguna teleconsulta"
              >
                <el-table-column
                  label="Tipo Atención"
                  min-width="240"
                  prop="attention_type"
                >
                </el-table-column>
                <el-table-column label="Doctor" min-width="250" prop="medico">
                </el-table-column>
                <el-table-column
                  label="Paciente"
                  min-width="300"
                  prop="paciente"
                >
                </el-table-column>
                <el-table-column
                  label="Fecha Creación"
                  width="130"
                  prop="created"
                >
                </el-table-column>
                <el-table-column
                  label="Fecha Agendada"
                  min-width="180"
                  prop="scheduled_date2"
                >
                </el-table-column>
                <el-table-column
                  label="Correo Paciente"
                  min-width="250"
                  prop="email"
                >
                </el-table-column>
                <el-table-column label="Teléfono" min-width="150" prop="phone">
                </el-table-column>
                <el-table-column
                  label="Relación"
                  min-width="115"
                  prop="relation"
                >
                </el-table-column>
                <el-table-column label="Titular" min-width="250" prop="titular">
                </el-table-column>

                <el-table-column min-width="105" fixed="right" label="Acciones">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="small"
                      @click="handleSelectTeleconsulta(scope.$index, scope.row)"
                      >Editar</el-button
                    >
                    <!-- <div class="row">
                      <el-dropdown trigger="click">
                        <span
                          class="el-dropdown-link"
                          style="margin-left: 40px"
                        >
                          <i class="el-icon-caret-bottom el-icon-more"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item class="clearfix">
                            <el-button
                              type="text"
                              @click="
                                handleSelectTeleconsulta(
                                  scope.$index,
                                  scope.row
                                )
                              "
                              >Editar</el-button
                            >
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div> -->
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div
              slot="footer"
              class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
            >
              Showing {{ pagination.currentPage2 }} to
              {{ pagination.perPage2 }} of
              {{ pagination.total }}

              <l-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="pagination.total"
              >
              </l-pagination>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <el-page-header
      @back="goBack"
      content="Información de teleconsulta"
      v-if="div_edit_teleconsulta"
    >
    </el-page-header>
    <div v-if="div_edit_teleconsulta">
      <editar-teleconsulta :edit_teleconsulta="edit_teleconsulta" />
    </div>
  </div>
</template>
<script>
//Components
import EditarTeleconsulta from "src/pages/Dashboard/Components/Teleconsulta/EditarTeleconsulta";
import Swal from "sweetalert2";
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  TimeSelect,
  Autocomplete,
  Popover,
  Tag,
  Descriptions,
  DescriptionsItem,
  Button,
  Dialog,
  Calendar,
  Tooltip,
  Input,
  MessageBox,
  Dropdown,
  Card,
  DropdownMenu,
  DropdownItem,
  Message,
  PageHeader
} from "element-ui";
import {
  Pagination as LPagination,
  Loading,
  StatsCard
} from "src/components/index";
import axios from "axios";
// import Swal from "sweetalert2";
//Librayr
import dayjs from "dayjs";
require("dayjs/locale/es");

export default {
  name: "Reprogramarteleconsulta",
  components: {
    Loading,
    EditarTeleconsulta,
    LPagination,
    StatsCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Popover.name]: Popover,
    [Tag.name]: Tag,
    [Descriptions.name]: Descriptions,
    [DescriptionsItem.name]: DescriptionsItem,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [TimeSelect.name]: TimeSelect,
    [Autocomplete.name]: Autocomplete,
    [Calendar.name]: Calendar,
    [Tooltip.name]: Tooltip,
    [Input.name]: Input,
    [MessageBox.name]: MessageBox,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Card.name]: Card,
    [Message.name]: Message,
    [PageHeader.name]: PageHeader
  },
  data() {
    return {
      isLoading: false,
      //teleconsultascount: false,
      scheduleddate: null,
      success_teleconsulta: localStorage.getItem("success_teleconsulta"),
      placeholder: "Doctor",
      filter: null,
      filter_scheduleddate: null,
      filtro_select: "",
      teleconsultas: [],
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [10, 15, 25, 50],
        total: 0,
        currentPage2: 1,
        perPage2: 20
      },
      div_list_teleconsulta: true,
      div_edit_teleconsulta: false,
      pagination_scheduled_date: false
    };
  },
  watch: {
    filtro_select(filtro_select) {
      let filtro = "";

      if (filtro_select == "Paciente") {
        filtro = "Paciente";
        this.placeholder = "Paciente";
      } else if (filtro_select == "Empresa") {
        filtro = "Empresa";
        this.placeholder = "Empresa";
      } else if (filtro_select == "Especialidad") {
        filtro = "Especialidad";
        this.placeholder = "Especialidad";
      } else if (filtro_select == "Doctor") {
        filtro = "Doctor";
        this.placeholder = "Doctor";
      } else if (filtro_select == "Usuario") {
        filtro = "Usuario";
        this.placeholder = "Usuario";
      } else if (filtro_select == "Empleado") {
        filtro = "Empleado";
        this.placeholder = "Empleado";
      } else if (filtro_select == "Fecha") {
        this.placeholder = "DD/MM/YYYY";
        filtro = "Fecha";
      }

      ///Message("Seleccionó Filtro: " + filtro);
    },
    "pagination.currentPage"() {
      if (this.pagination_scheduled_date == false) {
        this.getTeleconsultas();
      } else {
        this.getTeleconsultasScheduledDate();
      }

      this.Showingpagination();
    },
    filter() {
      this.pagination_scheduled_date = false;
      this.pagination.currentPage = 1;
      this.getTeleconsultas();
      this.Showingpagination();
      //this.filter_scheduleddate = null;
    },
    filter_scheduleddate() {
      this.pagination_scheduled_date = true;
      this.pagination.currentPage = 1;
      this.getTeleconsultasScheduledDate();
      this.Showingpagination();
      //this.filter = null;
    }
  },
  mounted() {
    if (!sessionStorage.isLogged) {
      this.$router.go(-1);
      return;
    }
    this.validar_success_teleconsulta();
    this.getTeleconsultas();
  },
  methods: {
    validar_success_teleconsulta() {
      console.log(this.success_teleconsulta);
      if (this.success_teleconsulta == "true") {
        Swal.fire(
          "Cita reprogramada",
          "La teleconsulta fue reprogramada. Por favor, confirmar con el usuario la cita",
          "success"
        );
        localStorage.setItem("success_teleconsulta", false);
      } else {
      }
    },
    goBack() {
      this.div_list_teleconsulta = true;
      this.div_edit_teleconsulta = false;
    },
    handleSelectTeleconsulta(index, row) {
      this.div_edit_teleconsulta = true;
      this.div_list_teleconsulta = false;
      this.div_create_teleconsulta = false;

      console.log(index, row);
      this.edit_teleconsulta = row;
    },
    handleCommand(filtro_select) {
      this.$refs.filter.focus();
      this.filtro_select = filtro_select;
    },
    handleCommandFilterScheduleddate(filtro_select) {
      this.$refs.filter_scheduleddate.focus();
      this.filtro_select = filtro_select;
    },
    async getTeleconsultas() {
      this.isLoading = true;
      try {
        let patient,
          company,
          specialty,
          doctor,
          user,
          employee = null;

        let token = null;
        token = sessionStorage.token;

        if (this.filter) {
          if (this.filtro_select == "Paciente") {
            patient = this.filter;
          } else if (this.filtro_select == "Empresa") {
            company = this.filter;
          } else if (this.filtro_select == "specialty") {
            specialty = this.filter;
          } else if (this.filtro_select == "Doctor") {
            doctor = this.filter;
          } else if (this.filtro_select == "Usuario") {
            user = this.filter;
          } else if (this.filtro_select == "Empleado") {
            employee = this.filter;
          } else if (this.filtro_select == "") {
            doctor = this.filter;
          }
        }

        const res = await axios.get(`v1.1/pro/medical-attention`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: this.pagination.currentPage,
            limit: 20,
            patient: patient,
            company: company,
            specialty: specialty,
            doctor: doctor,
            user: user,
            employee: employee,
            status: "scheduled",
            "view-reprogrammed": ""
          }
        });
        //this.teleconsultascount = true;
        if (res.data.count > 0) {
          this.pagination.total = res.data.count;
          this.teleconsultas = res.data.results.map(el => ({
            ...el,
            scheduled_date: dayjs(el.scheduled_date).format("YYYY-MM-DD"),
            scheduled_date2: dayjs(el.scheduled_date).format(
              "DD-MM-YYYY, H:mm A"
            ),
            created: dayjs(el.created).format("DD-MM-YYYY"),
            hora: dayjs(el.scheduled_date).format("H:mm a"),
            hora2: dayjs(el.scheduled_date).format("H:mm"),
            medico:
              el.doctor_detail.first_name + " " + el.doctor_detail.last_name,
            // Cambio Alan paciente: !el.patient_detail
            //   ? el.user_detail
            //     ? el.user_detail.first_name + " " + el.user_detail.last_name
            //     : ""
            //   : el.patient_detail.first_name +
            //     " " +
            //     el.patient_detail.last_name,
            paciente:
              el.patient_detail == null && el.user_detail != null
                ? el.user_detail.first_name + " " + el.user_detail.last_name
                : el.patient_detail == null && el.employee_detail != null
                ? el.employee_detail.first_name +
                  " " +
                  el.employee_detail.last_name
                : el.patient_detail.first_name +
                  " " +
                  el.patient_detail.last_name,
            titular: !el.user_detail
              ? el.employee_detail.first_name +
                " " +
                el.employee_detail.last_name
              : el.user_detail.first_name + " " + el.user_detail.last_name,
            email: !el.patient_detail
              ? el.user_detail
                ? el.user_detail.email
                : ""
              : el.patient_detail.email,
            phone: !el.patient_detail
              ? el.user_detail
                ? el.user_detail.phone
                : ""
              : el.patient_detail.phone,
            relation: !el.patient_detail
              ? "Titular"
              : el.patient_detail.relation
          }));
        } else {
          this.pagination.total = 0;
          this.teleconsultas = [];
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
      if (this.pagination.total <= 20) {
        this.pagination.perPage2 = this.pagination.total;
      }
    },
    async getTeleconsultasScheduledDate() {
      this.isLoading = true;
      try {
        let formatDate = null;
        let tamaño_fecha = false;

        let token = null;
        token = sessionStorage.token;

        if (this.filter_scheduleddate) {
          if (this.filtro_select == "Fecha") {
            var customParseFormat = require("dayjs/plugin/customParseFormat");
            dayjs.extend(customParseFormat);
            formatDate = dayjs(this.filter_scheduleddate, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            );

            if (formatDate.length == 10) {
              this.scheduleddate = formatDate;
              tamaño_fecha = true;
            }
          }
        } else {
          const res = await axios.get(`v1.1/pro/medical-attention`, {
            headers: {
              Authorization: `token ${token}`
            },
            params: {
              page: this.pagination.currentPage,
              limit: 20,
              patient: null,
              company: null,
              specialty: null,
              doctor: null,
              "scheduled-date": null,
              user: null,
              employee: null,
              status: "scheduled"
            }
          });

          if (res.data.count > 0) {
            this.pagination.total = res.data.count;
            this.teleconsultas = res.data.results.map(el => ({
              ...el,
              scheduled_date: dayjs(el.scheduled_date).format("YYYY-MM-DD"),
              scheduled_date2: dayjs(el.scheduled_date).format(
                "DD-MM-YYYY, H:mm A"
              ),
              created: dayjs(el.created).format("DD-MM-YYYY"),
              hora: dayjs(el.scheduled_date).format("H:mm a"),
              hora2: dayjs(el.scheduled_date).format("H:mm"),
              medico:
                el.doctor_detail.first_name + " " + el.doctor_detail.last_name,
              paciente:
                el.patient_detail == null && el.user_detail != null
                  ? el.user_detail.first_name + " " + el.user_detail.last_name
                  : el.patient_detail == null && el.employee_detail != null
                  ? el.employee_detail.first_name +
                    " " +
                    el.employee_detail.last_name
                  : el.patient_detail.first_name +
                    " " +
                    el.patient_detail.last_name,
              // paciente:
              //   el.patient_detail == null
              //     ? el.user_detail.first_name + " " + el.user_detail.last_name
              //     : el.patient_detail.first_name +
              //       " " +
              //       el.patient_detail.last_name,
              titular:
                el.user_detail == null
                  ? el.employee_detail.first_name +
                    " " +
                    el.employee_detail.last_name
                  : el.user_detail.first_name + " " + el.user_detail.last_name,
              email:
                el.patient_detail == null
                  ? el.user_detail.email
                  : el.patient_detail.email,
              phone:
                el.patient_detail == null
                  ? el.user_detail.phone
                  : el.patient_detail.phone,
              relation:
                el.patient_detail == null
                  ? "Titular"
                  : el.patient_detail.relation
            }));
          } else {
            this.pagination.total = 0;
            this.teleconsultas = [];
          }
        }

        if (tamaño_fecha == true) {
          const res = await axios.get(`v1.1/pro/medical-attention`, {
            headers: {
              Authorization: `token ${token}`
            },
            params: {
              page: this.pagination.currentPage,
              limit: 20,
              patient: null,
              company: null,
              specialty: null,
              doctor: null,
              "scheduled-date": this.scheduleddate,
              user: null,
              employee: null,
              status: "scheduled"
            }
          });

          if (res.data.count > 0) {
            this.pagination.total = res.data.count;
            this.teleconsultas = res.data.results.map(el => ({
              ...el,
              scheduled_date: dayjs(el.scheduled_date).format("YYYY-MM-DD"),
              scheduled_date2: dayjs(el.scheduled_date).format(
                "DD-MM-YYYY, H:mm A"
              ),
              created: dayjs(el.created).format("DD-MM-YYYY"),
              hora: dayjs(el.scheduled_date).format("H:mm a"),
              hora2: dayjs(el.scheduled_date).format("H:mm"),
              medico:
                el.doctor_detail.first_name + " " + el.doctor_detail.last_name,
              paciente:
                el.patient_detail == null && el.user_detail != null
                  ? el.user_detail.first_name + " " + el.user_detail.last_name
                  : el.patient_detail == null && el.employee_detail != null
                  ? el.employee_detail.first_name +
                    " " +
                    el.employee_detail.last_name
                  : el.patient_detail.first_name +
                    " " +
                    el.patient_detail.last_name,
              // paciente:
              //   el.patient_detail == null
              //     ? el.user_detail.first_name + " " + el.user_detail.last_name
              //     : el.patient_detail.first_name +
              //       " " +
              //       el.patient_detail.last_name,
              titular:
                el.user_detail == null
                  ? el.employee_detail.first_name +
                    " " +
                    el.employee_detail.last_name
                  : el.user_detail.first_name + " " + el.user_detail.last_name,
              email:
                el.patient_detail == null
                  ? el.user_detail.email
                  : el.patient_detail.email,
              phone:
                el.patient_detail == null
                  ? el.user_detail.phone
                  : el.patient_detail.phone,
              relation:
                el.patient_detail == null
                  ? "Titular"
                  : el.patient_detail.relation
            }));
          } else {
            this.pagination.total = 0;
            this.teleconsultas = [];
          }
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
      if (this.pagination.total <= 20) {
        this.pagination.perPage2 = this.pagination.total;
      }
    },
    Showingpagination() {
      this.pagination.perPage2 =
        this.pagination.perPage * this.pagination.currentPage;

      if (this.pagination.currentPage == 1) {
        this.pagination.currentPage2 = 1;
      } else if (this.pagination.currentPage >= 2) {
        this.pagination.currentPage2 = this.pagination.perPage2 - 19;
      }

      if (this.pagination.perPage2 > this.pagination.total) {
        this.pagination.perPage2 = this.pagination.total;
      }
    }
  }
};
</script>
<style scoped>
.el-dropdown {
  vertical-align: bottom;
}
.el-dropdown + .el-dropdown {
  margin-left: 15px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
