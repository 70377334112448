<template>
  <div>
    <loading
      :height="60"
      :width="60"
      color="#1873EE"
      background-color="white"
      style="color: gray; font-weight: 500;"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
    >{{cargando}}</loading>
    <el-card shadow="always" style="border-radius:25px">
      <div class="row">
        <div class="col-4">
          <span class="demonstration" style="font-size:13px"
            >Fecha de Programación</span
          >
          <el-date-picker
            unlink-panels
            v-model="daterange"
            type="daterange"
            range-separator="-"
            start-placeholder="Desde"
            end-placeholder="Hasta"
            size="small"
            :picker-options="pickerOptions"
            format="dd-MM-yyyy"
            @change="changePrecarga"
          >
          </el-date-picker>
        </div>
        <div class="col-4">
          <span class="demonstration" style="font-size:13px">Empresa</span>
          <br />
          <el-select
            v-model="company"
            style="width:100%"
            placeholder="Empresa"
            size="small"
            clearable
            @clear="changePrecarga"
            filterable
          >
            <el-option
              v-for="item in companies"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-4">
          <span class="demonstration" style="font-size:13px"
            >Nombre y Apellido del Especialista</span
          >
          <el-input
            placeholder="Nombre y Apellido del Especialista"
            v-model="doctor"
            size="small"
            clearable
            @clear="changePrecarga"
          ></el-input>
        </div>
      </div>
      <div class="row" style="margin-top:10px">
        <div class="col-4">
          <span class="demonstration" style="font-size:13px"
            >Canal de Atención</span
          >
          <br />
          <el-select
            v-model="channel"
            style="width:100%"
            placeholder="Canal de Atención"
            size="small"
            clearable
            @clear="changePrecarga"
          >
            <el-option
              v-for="item in channels"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-1">
          <br />
          <el-button
            style="background:#23CCEF; color:white;border:0px;"
            size="small"
            icon="el-icon-search"
            @click="buscar_teleconsultas"
          >
            Buscar</el-button
          >
        </div>
        <div class="col-3">
          <br />
          <!-- <el-checkbox
            v-model="checkedPrecarga"
            v-if="visible_checkbox_precarga"
            @change="changePrecarga"
            size="mini"
            >Precarga</el-checkbox
          > -->
          <el-switch
            v-model="checkedPrecarga"
            v-if="visible_checkbox_precarga"
            active-text="Precarga"
            @change="changePrecarga"
          >
          </el-switch>
        </div>
      </div>
    </el-card>
    <br />
    <el-card shadow="always" style="border-radius:25px">
      <div slot="header" class="clearfix">
        <span
          >Lista de atenciones realizadas <b>{{ etiqueta_text }}</b></span
        >

        <el-button
          style="background:#23CCEF; color:white;border:0px;float: right;"
          size="small"
          icon="el-icon-download"
          @click="download_excel"
        >
          Descargar Excel</el-button
        >
      </div>
      <div>
        <el-table style="width: 100%" :data="teleconsultas" stripe border>
          <el-table-column
            prop="name"
            min-width="310"
            label="Paciente"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="document_code"
            min-width="135"
            label="DNI"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="age"
            min-width="80"
            label="Edad"
            align="center"
          >
          </el-table-column>

          <el-table-column
            min-width="200"
            label="Especialista"
            align="center"
          >
            <template slot-scope="props">
              <span class="ellipsis">{{ props.row.doctor }}</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="date_attention"
            min-width="160"
            label="F. Atención"
            align="center"
          >
          </el-table-column>

          <el-table-column
            min-width="200"
            label="Antec. Familiares"
            align="center"
          >
            <template slot-scope="props">
              <span class="ellipsis">{{ props.row.antecedent_family }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="200"
            label="Antec. Personales"
            align="center"
          >
            <template slot-scope="props">
              <span class="ellipsis">{{ props.row.antecedent_personal }}</span>
            </template>
          </el-table-column>

          <el-table-column min-width="200" label="Alergias" align="center">
            <template slot-scope="props">
              <span class="ellipsis">{{ props.row.allergy }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="imc" min-width="80" label="IMC" align="center">
          </el-table-column>

          <el-table-column
            min-width="250"
            label="Diagnóstico Cie10"
            align="center"
          >
            <template slot-scope="props">
              <el-popover trigger="click" placement="left">
                <el-descriptions
                  class=""
                  title="Diagnóstico Cie10"
                  :column="1"
                  border
                  size="small"
                >
                  <el-descriptions-item>
                    <template slot="label">
                      <i class="el-icon-tickets"></i>
                    </template>
                    <span style="font-size:11px">
                      {{ props.row.medical_presumption }}
                    </span>
                  </el-descriptions-item>
                </el-descriptions>
                <div slot="reference" class="name-wrapper">
                  <span class="ellipsis" style="cursor:pointer;">{{
                    props.row.medical_presumption
                  }}</span>
                </div>
              </el-popover>
            </template>
          </el-table-column>

          <el-table-column
            fixed="right"
            prop="patient_destiny"
            min-width="145"
            label="Destino Paciente"
            align="center"
          >
          </el-table-column>
        </el-table>
        <br />
        <div
          slot="footer"
          class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
        >
          Showing {{ pagination.currentPage2 }} to {{ pagination.perPage2 }} of
          {{ pagination.total }}

          <l-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </l-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  Popover,
  Tag,
  Descriptions,
  DescriptionsItem,
  Button,
  Dialog,
  Input,
  MessageBox,
  Card,
  Checkbox,
  Switch
} from "element-ui";
import { Loading, Pagination as LPagination } from "src/components/index";
import axios from "axios";
import XLSX from "xlsx";
import dayjs from "dayjs";
require("dayjs/locale/es");
export default {
  name: "ProgramarTeleconsulta",
  components: {
    Loading,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Popover.name]: Popover,
    [Tag.name]: Tag,
    [Descriptions.name]: Descriptions,
    [DescriptionsItem.name]: DescriptionsItem,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [MessageBox.name]: MessageBox,
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [Switch.name]: Switch
  },
  data() {
    return {
      checkedPrecarga: false,
      pagination_pre_carga: true,
      visible_checkbox_precarga: false,
      etiqueta_text: "de los ultimos 3 meses",
      precargado_data: 0,
      precargado_data_ultimo_3_mes: true,
      teleconsultas: [],
      date: new Date(),
      currentDay: dayjs(this.date).format("MM-DD"),
      currentMonth: dayjs(this.date).format("MM"),
      currentYear: dayjs(this.date).format("YYYY"),
      date_from: null,
      date_to: null,
      pre_carga_date_from: null,
      pre_carga_date_to: null,
      isLoading: false,
      company: "",
      channel: "",
      doctor: "",
      daterange: null,
      companies: [],
      channels: [
        {
          value: "SD",
          label: "SD"
        },
        {
          value: "ZOOM",
          label: "ZOOM"
        },
        {
          value: "CALL",
          label: "CALL"
        }
      ],
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [10, 15, 25, 50],
        total: 0,
        currentPage2: 1,
        perPage2: 20
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "01 semana",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "01 mes",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "03 meses",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      cargando: ""
    };
  },
  watch: {
    "pagination.currentPage"() {
      if (this.pagination_pre_carga == true) {
        this.getTeleconsultasPrecarga();
        this.Showingpagination();
      } else {
        this.getTeleconsultas();
        this.Showingpagination();
      }
    }
  },
  computed: {},
  mounted() {
    if (!sessionStorage.isLogged) {
      this.$router.go(-1);
      return;
    }

    var today = new Date();
    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);

    let mes_anterior = this.currentMonth - 3;
    let ini_dia = this.currentYear + "-" + "0" + mes_anterior + "-" + "01";

    // this.date_from = ini_dia;
    // this.date_to = dayjs(lastDayOfMonth).format("YYYY-MM-DD");
    this.pre_carga_date_from = ini_dia;
    this.pre_carga_date_to = dayjs(lastDayOfMonth).format("YYYY-MM-DD");
    this.buscar_teleconsultas();
    this.getCompanies();
  },
  methods: {
    async changePrecarga() {
      if (
        this.precargado_data_ultimo_3_mes == false &&
        !this.daterange &&
        !this.company &&
        !this.doctor &&
        !this.channel
      ) {
        this.etiqueta_text = "de los ultimos 3 meses";
        this.isLoading = true;
        this.pagination.currentPage = 1;
        await this.getTeleconsultasPrecarga();
        await this.Showingpagination();
        this.isLoading = false;
        //this.precargado_data = 0;
        this.precargado_data_ultimo_3_mes = false;
        this.date_from = this.pre_carga_date_from;
        this.date_to = this.pre_carga_date_to;
        this.visible_checkbox_precarga = false;
        this.pagination_pre_carga = true;

        this.checkedPrecarga = false;
      }
    },
    async buscar_teleconsultas() {
      this.isLoading = true;
      this.pagination.currentPage = 1;

      if (this.precargado_data == 1) {
        this.precargado_data_ultimo_3_mes = false;
      }

      if (this.precargado_data_ultimo_3_mes == true) {
        await this.getTeleconsultasPrecarga();
        this.precargado_data = 1;
        this.visible_checkbox_precarga = false;
        this.pagination_pre_carga = true;
      } else {
        this.etiqueta_text = "";
        this.pagination_pre_carga = false;
        if (
          this.precargado_data_ultimo_3_mes == false &&
          !this.daterange &&
          !this.company &&
          !this.doctor &&
          !this.channel
        ) {
          this.visible_checkbox_precarga = true;
        } else {
          this.visible_checkbox_precarga = false;
        }
        await this.getTeleconsultas();
      }
      this.isLoading = false;
      this.Showingpagination();
    },
    async download_excel() {
      this.isLoading = true;
      await this.getTeleconsultasDownload();
    },
    capitalize: function(str) {
      let nombre = "";
      nombre = str.toLocaleLowerCase();
      return nombre
        .toLowerCase()
        .trim()
        .split(" ")
        .map(v => v[0].toUpperCase() + v.substr(1))
        .join(" ");
    },

    async getCompanies() {
      let country_id = null;
      let name = null;
      let code = null;

      let token = null;
      token = sessionStorage.token;

      try {
        const res = await axios.get(`v1/companies`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: 1,
            size: 100,
            name: name,
            code: code,
            country_id,
            "exclude-is-demo": ""
          }
        });
        this.companies = res.data.data.rows.map(el => ({
          ...el,
          name: this.capitalize(el.name)
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async getTeleconsultasPrecarga() {
      try {
        let company,
          doctor,
          channel = null;

        let token = null;
        token = sessionStorage.token;
        this.cargando = "";

        const res = await axios.get(`v1.1/pro/medical-attention`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: this.pagination.currentPage,
            limit: 20,
            company: company,
            channel: channel,
            doctor: doctor,
            "date-start": this.pre_carga_date_from,
            "date-end": this.pre_carga_date_to,
            "type-attention": 1,
            "report-d": "",
            status: "attended"
          }
        });
        if (res.data.count > 0) {
          this.pagination.total = res.data.count;
          this.teleconsultas = res.data.results;
        } else {
          this.pagination.total = 0;
          this.teleconsultas = [];
        }
      } catch (error) {
        console.error(error);
      }
      if (this.pagination.total <= 20) {
        console.log(this.pagination.total);
        this.pagination.perPage2 = this.pagination.total;
      }
    },
    async getTeleconsultas() {
      try {
        let company,
          doctor,
          channel = null;

        let token = null;
        token = sessionStorage.token;

        if (this.precargado_data_ultimo_3_mes == false) {
          this.date_from = null;
          this.date_to = null;
        } else {
          this.date_from = dayjs(this.daterange[0]).format("YYYY-MM-DD");
          this.date_to = dayjs(this.daterange[1]).format("YYYY-MM-DD");
        }

        if (this.daterange) {
          this.precargado_data_ultimo_3_mes = false;
          this.date_from = dayjs(this.daterange[0]).format("YYYY-MM-DD");
          this.date_to = dayjs(this.daterange[1]).format("YYYY-MM-DD");
        }

        if (this.company) {
          this.precargado_data_ultimo_3_mes = false;
          // this.date_from = null;
          // this.date_to = null;
          company = this.company;
        }

        if (this.doctor) {
          this.precargado_data_ultimo_3_mes = false;
          // this.date_from = null;
          // this.date_to = null;
          doctor = this.doctor;
        }

        if (this.channel) {
          this.precargado_data_ultimo_3_mes = false;
          // this.date_from = null;
          // this.date_to = null;
          channel = this.channel;
        }
        this.cargando = "";

        const res = await axios.get(`v1.1/pro/medical-attention`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: this.pagination.currentPage,
            limit: 20,
            company: company,
            channel: channel,
            doctor: doctor,
            "date-start": this.date_from,
            "date-end": this.date_to,
            "type-attention": 1,
            "report-d": "",
            status: "attended"
          }
        });
        if (res.data.count > 0) {
          this.pagination.total = res.data.count;
          this.teleconsultas = res.data.results;
        } else {
          this.pagination.total = 0;
          this.teleconsultas = [];
        }
      } catch (error) {
        console.error(error);
      }
      if (this.pagination.total <= 20) {
        console.log(this.pagination.total);
        this.pagination.perPage2 = this.pagination.total;
      }
    },
    async getTeleconsultasDownload() {
      try {
        // let company,
        //   doctor,
        //   channel = null;

        let token = null;
        token = sessionStorage.token;

        console.log('precargado_data_ultimo_3_mes', this.precargado_data_ultimo_3_mes);

        if (this.precargado_data_ultimo_3_mes == false) {
          if (this.daterange) {
            this.date_from = dayjs(this.daterange[0]).format("YYYY-MM-DD");
            this.date_to = dayjs(this.daterange[1]).format("YYYY-MM-DD");
          } else {
            this.date_from = null;
            this.date_to = null;
          }
        } else {
          if (this.daterange) {
            this.date_from = dayjs(this.daterange[0]).format("YYYY-MM-DD");
            this.date_to = dayjs(this.daterange[1]).format("YYYY-MM-DD");
          } else {
            this.date_from = this.pre_carga_date_from;
            this.date_to = this.pre_carga_date_to;
          }
          // else {
          //   this.date_from = null;
          //   this.date_to = null;
          // }
        }

        // if (this.daterange) {
        //   this.precargado_data_ultimo_3_mes = false;
        //   this.date_from = dayjs(this.daterange[0]).format("YYYY-MM-DD");
        //   this.date_to = dayjs(this.daterange[1]).format("YYYY-MM-DD");
        // }

        // if (this.company) {
        //   this.precargado_data_ultimo_3_mes = false;
        //   this.date_from = null;
        //   this.date_to = null;
        //   company = this.company;
        // }

        // if (this.doctor) {
        //   this.precargado_data_ultimo_3_mes = false;
        //   this.date_from = null;
        //   this.date_to = null;
        //   doctor = this.doctor;
        // }

        // if (this.channel) {
        //   this.precargado_data_ultimo_3_mes = false;
        //   this.date_from = null;
        //   this.date_to = null;
        //   channel = this.channel;
        // }
        const limit_down = this.pagination.total
        const page_down = 200;
        let json_down = [];
        let percentage = 0;
        for (let index = 1; index <= Math.ceil(limit_down/page_down); index++) { //25/10=2.5, 
          let interval = setInterval(() => {
            if (percentage < (index * 100) / Math.ceil(limit_down/page_down)) {
              percentage += 1;
              this.cargando = "Cargando " + percentage + "%";
            } else clearInterval(interval);
          }, 300);

          const res = await axios.get(`v1.1/pro/medical-attention`, {
            params: {
              page: index,
              limit: page_down,
              company: this.company,
              channel: this.channel,
              doctor: this.doctor,
              "date-start": this.date_from,
              "date-end": this.date_to,
              "type-attention": 1,
              "report-d": "",
              status: "attended"
            },
            headers: {
              Authorization: `token ${token}`
            }
          });

          json_down = [...json_down, ...res.data.results];
          percentage = Math.ceil((index * 100) / (limit_down/page_down));
          console.log('json_down', json_down);
          if (index == Math.ceil(limit_down/page_down)) {
            this.download_json_excel(json_down, "reporte-b2b-medicos");
            this.isLoading = false;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    convertFile(dataFile, fileName) {
      let newBlob = new Blob([dataFile], {
        type: "application/vnd.ms-excel; charset=UTF-8"
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = fileName;
      link.click();
      setTimeout(function() {
        window.URL.revokeObjectURL(data);
      }, 100);
    },
    download_json_excel(dataFile, fileName) {
      let data = XLSX.utils.json_to_sheet(dataFile);
      data["A1"].v = "ID";
      data["B1"].v = "FECHA CREACION";
      data["C1"].v = "FECHA DE ATENCION";
      data["D1"].v = "NOMBRES";
      data["E1"].v = "DNI";
      data["F1"].v = "CORREO ELECTRONICO";
      data["G1"].v = "NÚMERO TELEFONICO";
      data["H1"].v = "PUESTO LABORAL";
      data["I1"].v = "EDAD";
      data["J1"].v = "¿PRESENTA ALGUNA MOLESTIA ACTUAL?";
      data["K1"].v = "ANTECEDENTES FAMILIARES";
      data["L1"].v = "ANTECEDENTES PERSONALES";
      data["M1"].v = "ALERGIAS";
      data["N1"].v = "PESO (Kg)";
      data["O1"].v = "TALLA (m)";
      data["P1"].v = "IMC";
      data["Q1"].v = "MOTIVO DE LA CONSULTA";
      data["R1"].v = "SIGNOS Y SINTOMAS";
      data["S1"].v = "IMPRESIÓN DX (si presentara al momento algún posible Dx)";
      data["T1"].v = "DIAGNOSTICO FINAL (cie 10)";
      data["U1"].v = "INDICACIONES";
      data["V1"].v = "DESTINO DE PACIENTE";
      data["W1"].v = "OBSERVACIONES";
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, data, fileName);
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    },
    Showingpagination() {
      this.pagination.perPage2 =
        this.pagination.perPage * this.pagination.currentPage;

      if (this.pagination.currentPage == 1) {
        this.pagination.currentPage2 = 1;
      } else if (this.pagination.currentPage >= 2) {
        this.pagination.currentPage2 = this.pagination.perPage2 - 19;
      }

      if (this.pagination.perPage2 > this.pagination.total) {
        this.pagination.perPage2 = this.pagination.total;
      }
    }
  }
};
</script>

<style lang="css">
.ellipsis {
  width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
