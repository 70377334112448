<template>
  <!-- <div class="container"> -->
  <div>
    <loading
      :height="60"
      :width="60"
      color="#1873EE"
      background-color="white"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
    ></loading>
    <!-- <div class="employee" v-for="employee in employees" :key="employee.id">
      <table>
        <tr>
          <td>
            <router-link
              :to="{
                name: 'Empleados',
                params: {
                  id: $route.params.empresa,
                  code: $route.params.codeempresa
                }
              }"
            >
              <img
                src="image/img_atras.png"
                alt="atras"
                width="35px"
                class="q-mb-xl"
                style="cursor:pointer;"
              /> </router-link
            >{{ companiename }} > {{ employee.code }}
          </td>
        </tr>
        <tr>
          <td>
            <b
              ><label for="Nombre"
                >{{ employee.first_name }} {{ employee.last_name }}</label
              ></b
            >
          </td>
        </tr>
      </table>

      <div class="row">
        <div class="col-md-3">
          <small><b>Tipo Doc:</b> {{ employee.tipo_doc_display }}</small>
        </div>
        <div class="col-md-3">
          <small><b>Nº Doc:</b> {{ employee.num_doc }}</small>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <small><b>Sede/Filial:</b> {{ employee.campus_name }}</small>
        </div>
        <div class="col-md-3">
          <small><b>Puesto:</b> {{ employees.position_name }}</small>
        </div>
        <div class="col-md-6">
          <div class="text-right">
            <button
              type="submit"
              class="btn btn-fill btn-info btn-wd btn-sm"
              @click="
                dialogCreateFamilyVisible = true;
                dialogEditFamilyVisible = false;
              "
            >
              AGREGAR FAMILIAR
            </button>
          </div>
        </div>
      </div>
    </div> -->
    <el-card shadow="always" style="border-radius:25px">
      <div class="employee" v-for="employee in employees" :key="employee.id">
        <el-descriptions
          :title="`${companiename}`"
          :column="3"
          size="small"
          border
        >
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              Código
            </template>
            <!-- <el-link type="primary" @click="retornarPaginaEmpleados">{{
              employee.code
            }}</el-link> -->
            {{ employee.code }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-paperclip"></i>
              Colaborador
            </template>

            {{ employee.first_name }}
            {{ employee.last_name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              Sede/Filial
            </template>
            {{ employee.campus_name }}</el-descriptions-item
          >
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-notebook-2"></i>
              Nº Doc
            </template>
            {{ employee.num_doc }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-office-building"></i>
              Puesto
            </template>
            {{ employees.position_name }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-card>
    <br />
    <!-- <div class="row">
      <div class="col-6">
        <div class="text-left">
          <span>Familiares de {{ employeename }}</span>
        </div>
      </div>
      <div class="col-6">
        <div class="text-right">
          <button
            type="submit"
            class="btn btn-fill btn-info btn-wd btn-sm"
            @click="
              dialogCreateFamilyVisible = true;
              dialogEditFamilyVisible = false;
            "
          >
            AGREGAR FAMILIAR
          </button>
        </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-md-12">
        <el-card shadow="always" style="border-radius:25px">
          <div slot="header" class="clearfix" v-if="employeename">
            <span
              >Familiares de <b>{{ employeename }}</b></span
            >
            <!-- <el-button style="float: right; padding: 3px 0" type="text"
              >Operation button</el-button
            > -->
            <button
              type="submit"
              class="btn btn-fill btn-info btn-wd btn-sm"
              style="float: right; padding: 3px 0"
              @click="
                dialogCreateFamilyVisible = true;
                dialogEditFamilyVisible = false;
              "
            >
              AGREGAR FAMILIAR
            </button>
          </div>
          <div v-if="Divtablavacia">
            <div class="table-responsive table-full-width">
              <el-table
                :data="familiares_empleado"
                style="width: 100%; overflow: auto"
                border
                empty-text="Este colaborador aun no tiene familiares asociados"
              >
                <el-table-column
                  v-if="show"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
                <el-table-column
                  v-for="column in tableColumns2"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
                <el-table-column min-width="85" fixed="right" label="Acciones">
                  <template slot-scope="props_id">
                    <div class="row">
                      <el-dropdown trigger="click">
                        <span
                          class="el-dropdown-link"
                          style="margin-left: 40px"
                        >
                          <i class="el-icon-caret-bottom el-icon-more"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item class="clearfix">
                            <el-button
                              type="text"
                              @click="
                                getFamiliarId(
                                  props_id.row.num_doc,
                                  false,
                                  props_id.row.index
                                )
                              "
                              >Ver perfil</el-button
                            >
                          </el-dropdown-item>
                          <el-dropdown-item class="clearfix">
                            <el-button
                              type="text"
                              @click="
                                getFamiliarId(
                                  props_id.row.num_doc,
                                  true,
                                  props_id.row.index
                                )
                              "
                              >Editar</el-button
                            >
                          </el-dropdown-item>
                          <el-dropdown-item class="clearfix">
                            <el-button
                              type="text"
                              @click="
                                deleteFamiliar(
                                  props_id.row.id,
                                  props_id.row.index
                                )
                              "
                              >Eliminar</el-button
                            >
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                      <!-- <div class="col-md-1">
                        <img
                          src="image/img_profile.png"
                          @click="
                            getFamiliarId(
                              props_id.row.num_doc,
                              false,
                              props_id.row.index
                            )
                          "
                          title="View Profile"
                          width="18px"
                          class="q-mb-xl"
                          style="cursor:pointer;"
                        />
                      </div>
                      <div class="col-md-1">
                        <img
                          src="image/img_editar.png"
                          title="Edit"
                          width="18px"
                          class="q-mb-xl"
                          style="cursor:pointer;"
                          @click="
                            getFamiliarId(
                              props_id.row.num_doc,
                              true,
                              props_id.row.index
                            )
                          "
                        />
                      </div>
                      <div class="col-md-1">
                        <img
                          src="image/img_delete.png"
                          @click="
                            deleteFamiliar(props_id.row.id, props_id.row.index)
                          "
                          title="Delete"
                          width="23px"
                          class="q-mb-xl"
                          style="cursor:pointer;"
                        />
                      </div> -->
                    </div>
                  </template>
                </el-table-column>
              </el-table>

              <el-dialog
                :title="`Editar Informacion`"
                :visible.sync="dialogEditFamilyVisible"
                :close-on-click-modal="false"
                top="1%"
                width="40%"
              >
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form
                    class="row"
                    @submit.prevent="handleSubmit(updateFamiliar)"
                  >
                    <div class="col-6">
                      <div class="form-group">
                        <label for="nombre" class="form-label">NOMBRE</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="formUpdate.first_name"
                          placeholder="NOMBRE"
                          @keypress="soloLetras"
                          required="true"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="appellido" class="form-label"
                          >APELLIDO</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="formUpdate.last_name"
                          placeholder="APELLIDO"
                          @keypress="soloLetras"
                          required="true"
                        />
                      </div>
                    </div>
                    
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="numerodocumento" class="form-label"
                          >Nº DOC</label
                        >
                        <input
                          type="text"
                          v-model="formUpdate.num_doc"
                          class="form-control"
                          required="true"
                          :maxlength="maxLength"
                          @keypress="onlyNumberCaracterUpdate"
                          ref="num_doc_update"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="genero" class="form-label">GÉNERO</label>
                        <select
                          v-model="formUpdate.genre"
                          clearable
                          placeholder="Género"
                          class="form-control"
                        >
                          <option label="MUJER" value="1">
                            MUJER
                          </option>
                          <option label="HOMBRE" value="2">
                            HOMBRE
                          </option>
                          <option label="OTRO" value="3">
                            OTRO
                          </option>
                          <option label="PREFIERO NO DECIRLO" value="4">
                            PREFIERO NO DECIRLO
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="relacion" class="form-label"
                          >RELACIÓN</label
                        >
                        <select
                          v-model="formUpdate.relation_id"
                          clearable
                          placeholder="Relacion"
                          class="form-control"
                        >
                          <option
                            v-for="item in relations"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="fechanacimiento" class="form-label"
                          >F. NACIMIENTO</label
                        >
                        <input
                          type="date"
                          v-model="formUpdate.birthdate"
                          class="form-control"
                          required="true"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="celular" class="form-label">CELULAR</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="formUpdate.phone"
                          @keypress="onlyNumber"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="correo" class="form-label">CORREO</label>
                        <input
                          type="email"
                          class="form-control"
                          v-model="formUpdate.email"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="text-center">
                        <button
                          type="submit"
                          class="btn btn-fill btn-info btn-wd btn-sm"
                        >
                          GUARDAR
                        </button>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </el-dialog>
              <el-dialog
                :title="``"
                :visible.sync="dialogViewFamilyVisible"
                id="dialogs.viewDialog"
                width="30%"
              >
                <label for="trabajodor">Colaborador:</label>
                {{ employeename }}
                <br />
                <strong
                  ><label for="Nombre" class="text-right"></label
                  >{{ form.first_name }} {{ form.last_name }}</strong
                >
                <br />
                <label for="relacion"></label>{{ form.relation_name }}
                <hr />
                <div class="row">
                  <hr />
                  <div class="col-md-6">
                    <label for="numerodocumento">Nº de Doc:</label>
                  </div>
                  <div class="col-md-6">
                    <div class="text-right">
                      {{ form.num_doc }}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="genero">Género:</label>
                  </div>
                  <div class="col-md-6">
                    <div class="text-right">
                      {{ form.genre_display }}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="fechanacimiento">F. NACIMIENTO:</label>
                  </div>
                  <div class="col-md-6">
                    <div class="text-right">
                      {{ form.birthdate }}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="celular">Celular</label>
                  </div>
                  <div class="col-md-6">
                    <div class="text-right">{{ form.phone }}</div>
                  </div>
                  <div class="col-md-6">
                    <label for="correo">Correo:</label>
                  </div>
                  <div class="col-md-6">
                    <div class="text-right">{{ form.email }}</div>
                  </div>
                </div>
                <!--<div class="card-footer text-center">
                               <button
                            type="button"
                            class="btn-info btn-sm pull-left"
                            @click="
                              dialogEditFamilyVisible = true;
                              dialogViewFamilyVisible = false;
                              getFamiliarId(
                                props_id.row.id,
                                props_id.row.first_name,
                                props_id.row.last_name,
                                props_id.row.relation_id,
                                props_id.row.tipo_doc,
                                props_id.row.num_doc,
                                props_id.row.genre,
                                props_id.row.phone,
                                props_id.row.email,
                                props_id.row.birthdate
                              );
                            "
                          >
                            EDITAR
                          </button>
                            <button
                            type="button"
                            v-on:click="
                              deleteFamiliar(
                                props_id.row.id,
                                props_id.row.index
                              )
                            "
                            @click="dialogViewFamilyVisible = false"
                            class="btn btn-default btn-sm pull-right"
                          >
                            ELIMINAR
                          </button>
                       </div>-->
                <hr />
                <div class="row">
                  <div class="col-md-6">
                    <button
                      type="button"
                      class="btn-info btn-sm pull-left"
                      @click="
                        dialogEditFamilyVisible = true;
                        dialogViewFamilyVisible = false;
                        getFamiliarId(form.num_doc, true, form.index);
                        /*getFamiliarId(
                          props_id.row.id,
                          props_id.row.first_name,
                          props_id.row.last_name,
                          props_id.row.relation_id,
                          props_id.row.tipo_doc,
                          props_id.row.num_doc,
                          props_id.row.genre,
                          props_id.row.phone,
                          props_id.row.email,
                          props_id.row.birthdate
                        );*/
                      "
                    >
                      EDITAR
                    </button>
                  </div>
                  <div class="col-md-6">
                    <div class="text-right">
                      <button
                        type="button"
                        v-on:click="deleteFamiliar(form.id, form.index)"
                        @click="dialogViewFamilyVisible = false"
                        class="btn btn-default btn-sm pull-right"
                      >
                        ELIMINAR
                      </button>
                    </div>
                  </div>
                </div>
              </el-dialog>
            </div>
            <div
              slot="footer"
              class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              Showing {{ pagination2.currentPage2 }} to
              {{ pagination2.perPage2 }} of
              {{ pagination2.total }}
              <l-pagination
                class="pagination-no-border"
                v-model="pagination2.currentPage"
                :per-page="pagination2.perPage"
                :total="pagination2.total"
              >
              </l-pagination>
            </div>
          </div>
          <el-dialog
            :title="`Agregar Familiar`"
            :visible.sync="dialogCreateFamilyVisible"
            :close-on-click-modal="false"
            top="1%"
            width="40%"
          >
            <ValidationObserver v-slot="{ handleSubmit }">
              <form class="row" @submit.prevent="handleSubmit(createFamiliar)">
                <div class="col-6">
                  <div class="form-group">
                    <label for="" class="form-label">NOMBRE</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="formCreate.first_name"
                      placeholder="NOMBRE"
                      @keypress="soloLetras"
                      required="true"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="" class="form-label">APELLIDO</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="formCreate.last_name"
                      placeholder="APELLIDO"
                      @keypress="soloLetras"
                      required="true"
                    />
                  </div>
                </div>
                
                <div class="col-6">
                  <div class="form-group">
                    <label for="" class="form-label">Nº DOC</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="formCreate.num_doc"
                      required="true"
                      :maxlength="maxLength"
                      :minlength="maxLength"
                      @keypress="onlyNumberCaracterCreate"
                      ref="num_doc_create"
                      id="num_doc_create"
                      @keyup="limpia()"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="" class="form-label">GÉNERO</label>
                    <select
                      clearable
                      placeholder="Género"
                      v-model="formCreate.genre"
                      required
                      class="form-control"
                    >
                      <option key="1" label="MUJER" value="1">MUJER</option>
                      <option key="2" label="HOMBRE" value="2">HOMBRE</option>
                      <option key="3" label="OTRO" value="3">OTRO</option>
                      <option key="4" label="PREFIERO NO DECIRLO" value="4">PREFIERO NO DECIRLO</option>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="" class="form-label">RELACIÓN</label>
                    <br />
                    <select
                      clearable
                      placeholder="Relacion"
                      v-model="formCreate.relation_id"
                      required
                      class="form-control"
                    >
                      <option
                        v-for="item in relations"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      ></option>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="" class="form-label">F. NACIMIENTO</label>
                    <input
                      type="date"
                      class="form-control"
                      v-model="formCreate.birthdate"
                      required="true"
                      min="1920-01-01"
                      :max="dateMax"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="" class="form-label">CELULAR</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="formCreate.phone"
                      @keypress="onlyNumber"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="" class="form-label">CORREO</label>
                    <input
                      type="email"
                      class="form-control"
                      v-model="formCreate.email"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="text-center">
                    <button
                      type="submit"
                      class="btn btn-fill btn-info btn-wd btn-sm"
                    >
                      GUARDAR
                    </button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </el-dialog>
          <div v-if="mostrarMensaje" class="text-center text-info">
            ESTE COLABORADOR AUN NO TIENE FAMILIARES ASOCIADOS
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  Popover,
  Tag,
  Descriptions,
  DescriptionsItem,
  Button,
  Dialog,
  Card,
  Link,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Badge
} from "element-ui";
import {
  Loading,
  Pagination as LPagination,
  FormGroupInput as FgInput,
  StatsCard
} from "src/components/index";
import axios from "axios";
import Swal from "sweetalert2";

import dayjs from "dayjs";
require("dayjs/locale/es");

export default {
  name: "FamiliaresEmpleados",
  components: {
    Loading,
    LPagination,
    FgInput,
    StatsCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Popover.name]: Popover,
    [Tag.name]: Tag,
    [Descriptions.name]: Descriptions,
    [DescriptionsItem.name]: DescriptionsItem,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Card.name]: Card,
    [Link.name]: Link,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Badge.name]: Badge
  },
  data() {
    return {
      date: new Date(),
      currentDay: dayjs(this.date).format("DD"),
      currentMonth: dayjs(this.date).format("MM"),
      currentYear: dayjs(this.date).format("YYYY"),
      dateMax: "",
      isLoading: false,
      companiename: "",
      Divtablavacia: false,
      mostrarMensaje: false,
      show: false,
      form: {
        index: "",
        family_id: "",
        first_name: "",
        last_name: "",
        tipo_doc: "",
        tipo_doc_display: "",
        num_doc: "",
        genre: "",
        genre_display: "",
        birthdate: "",
        relation_id: "",
        relation_name: "",
        email: "",
        phone: ""
      },
      formUpdate: {
        family_id: "",
        first_name: "",
        last_name: "",
        tipo_doc: "",
        num_doc: "",
        genre: "",
        birthdate: "",
        relation_id: "",
        email: "",
        phone: ""
      },
      formCreate: {
        relation_id: "",
        tipo_doc: "",
        num_doc: "",
        first_name: "",
        last_name: "",
        genre: "",
        birthdate: "",
        phone: "",
        email: ""
      },
      pagination2: {
        perPage: 4,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
        currentPage2: 1,
        perPage2: 4
      },
      tableColumns: [
        {
          prop: "birthdate",
          label: "birthdate"
        },
        {
          prop: "index",
          label: "index"
        }
      ],
      tableColumns2: [
        {
          prop: "num_doc",
          label: "Nº Doc",
          minWidth: 120
        },
        {
          prop: "first_name",
          label: "Nombres",
          minWidth: 140
        },
        {
          prop: "last_name",
          label: "Apellidos",
          minWidth: 140
        },
        {
          prop: "genre_display",
          label: "Género",
          minWidth: 90
        },
        {
          prop: "relation_name",
          label: "Relación",
          minWidth: 90
        }
      ],
      relations: [],
      employees: [],
      employeename: null,
      dialogViewFamilyVisible: false,
      dialogEditFamilyVisible: false,
      dialogCreateFamilyVisible: false,
      familiares_empleado: [],
      maxLength: null
    };
  },
  watch: {
    "pagination2.currentPage"() {
      this.getFamiliaresEmpleado();
      this.Showingpagination();
    }
  },
  mounted() {
    this.dateMax =
      this.currentYear + "-" + this.currentMonth + "-" + this.currentDay;

    if (localStorage.getItem("panel_companie_name")) {
      this.companiename = localStorage.getItem("panel_companie_name");
    }
    if (!sessionStorage.isLogged) {
      this.$router.go(-1);
      return;
    }

    this.isLoading = true;
    setTimeout(() => (this.isLoading = false), 2 * 1000);

    this.getFamiliaresEmpleado();
    this.getEmpleado();
    this.getRelations();
  },
  methods: {
    validaNumeroDocumentoEdit() {
      if (this.formUpdate.tipo_doc == 1) {
        this.maxLength = 8;
      } else if (this.formUpdate.tipo_doc == 2) {
        this.maxLength = 14;
      } else if (this.formUpdate.tipo_doc == 3) {
        this.maxLength = 12;
      }
      this.$refs.num_doc_update.focus();
    },
    onlyNumberCaracterUpdate($e) {
      if (this.formUpdate.tipo_doc == 1 || this.formUpdate.tipo_doc == 3) {
        let keyCode = $e.keyCode ? $e.keyCode : $e.which;
        if (keyCode < 48 || keyCode > 57) {
          $e.preventDefault();
        }
      } else {
        var key = $e.keyCode || $e.which;
        var tecla = String.fromCharCode(key).toLowerCase();
        var letras = " áéíóúabcdefghijklmnñopqrstuvwxyz1234567890";
        var especiales = [8, 37];

        var tecla_especial = false;
        for (var i in especiales) {
          if (key == especiales[i]) {
            tecla_especial = true;
            break;
          }
        }

        if (letras.indexOf(tecla) == -1 && !tecla_especial) {
          $e.preventDefault();
          return false;
        }
      }
    },
    onlyNumberCaracterCreate($e) {
      if (this.formCreate.tipo_doc == 1 || this.formCreate.tipo_doc == 3) {
        let keyCode = $e.keyCode ? $e.keyCode : $e.which;
        if (keyCode < 48 || keyCode > 57) {
          $e.preventDefault();
        }
      } else {
        var key = $e.keyCode || $e.which;
        var tecla = String.fromCharCode(key).toLowerCase();
        var letras = " áéíóúabcdefghijklmnñopqrstuvwxyz1234567890";
        var especiales = [8, 37];

        var tecla_especial = false;
        for (var i in especiales) {
          if (key == especiales[i]) {
            tecla_especial = true;
            break;
          }
        }

        if (letras.indexOf(tecla) == -1 && !tecla_especial) {
          $e.preventDefault();
          return false;
        }
      }
    },
    limpia: function() {
      if (this.formCreate.tipo_doc == "1" || this.formCreate.tipo_doc == "3") {
        var val = document.getElementById("num_doc_create").value;
        var is_num = isNaN(val);
        if (is_num == true) {
          this.formCreate.num_doc = "";
        }
      }
    },
    soloLetras($e) {
      var key = $e.keyCode || $e.which;
      var tecla = String.fromCharCode(key).toLowerCase();
      var letras = " áéíóúabcdefghijklmnñopqrstuvwxyz";
      var especiales = [8, 37];

      var tecla_especial = false;
      for (var i in especiales) {
        if (key == especiales[i]) {
          tecla_especial = true;
          break;
        }
      }

      if (letras.indexOf(tecla) == -1 && !tecla_especial) {
        $e.preventDefault();
        return false;
      }
    },
    retornarPaginaEmpleados() {
      this.$router.push({
        name: "Empleados",
        params: {
          id: this.$route.params.empresa,
          code: this.$route.params.codeempresa
        }
      });
    },
    Showingpagination() {
      this.pagination2.perPage2 =
        this.pagination2.perPage * this.pagination2.currentPage;

      if (this.pagination2.perPage2 > this.pagination2.total) {
        this.pagination2.perPage2 = this.pagination2.total;
      }

      if (this.pagination2.currentPage == 1) {
        this.pagination2.currentPage2 = 1;
      } else if (this.pagination2.currentPage >= 2) {
        this.pagination2.currentPage2 = this.pagination2.perPage2 - 3;
      }
    },
    onlyNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot && keyCode !== 46
        $event.preventDefault();
      }
    },
    validaNumeroDocumento() {
      // if (this.formCreate.tipo_doc == 1) {
      //   this.maxLength = 8;
      // } else if (this.formCreate.tipo_doc == 2) {
      //   this.maxLength = 12;
      // } else if (this.formCreate.tipo_doc == 3) {
      //   this.maxLength = 12;
      // }

      if (this.formCreate.tipo_doc == 1) {
        this.maxLength = 8;
      } else if (this.formCreate.tipo_doc == 2) {
        this.maxLength = 14;
      } else if (this.formCreate.tipo_doc == 3) {
        this.maxLength = 12;
      }
      this.formCreate.num_doc = "";
      this.$refs.num_doc_create.focus();
    },
    resetFormCreate() {
      this.formCreate.relation_id = "";
      this.formCreate.tipo_doc = "";
      this.formCreate.num_doc = "";
      this.formCreate.first_name = "";
      this.formCreate.last_name = "";
      this.formCreate.genre = "";
      this.formCreate.birthdate = "";
      this.formCreate.phone = "";
      this.formCreate.email = "";
    },
    async getFamiliaresEmpleado() {
      try {
        let token = null;
        token = sessionStorage.token;

        let empleado_id = null;
        empleado_id = this.$route.params.idempleado;

        const res = await axios.get(
          `v1/employee/${empleado_id}/family`,
          //`https://devapi.smartdoctor.pe/v1/employee/${empleado_id}/family`,
          {
            headers: {
              ///Authorization: "token 89fa41e80e065fb925ee88f11809f38eb03e8e0a"
              Authorization: `token ${token}`
            },
            params: {
              page: this.pagination2.currentPage,
              size: 4
            }
          }
        );
        this.pagination2.total = res.data.data.total_rows;
        this.familiares_empleado = res.data.data.rows.map((el, index) => ({
          ...el,
          index: index + 1
        }));
      } catch (error) {
        console.error(error);
      }

      if (this.pagination2.total == 0) {
        this.Divtablavacia = false;
        this.mostrarMensaje = true;
      } else if (this.pagination2.total > 0) {
        this.Divtablavacia = true;
        this.mostrarMensaje = false;
      }

      if (this.pagination2.total <= 4) {
        this.pagination2.perPage2 = this.pagination2.total;
      }
    },
    async getEmpleado() {
      try {
        let token = null;
        token = sessionStorage.token;

        let code = null;
        let empresa_id = null;

        empresa_id = this.$route.params.empresa;
        code = this.$route.params.code;
        const res = await axios.get(
          `v1/company/${empresa_id}/employees?code=${code}`,
          //`https://devapi.smartdoctor.pe/v1/company/${empresa_id}/employees?code=${code}`,
          {
            headers: {
              //Authorization: "token 85ba358db6697d27c1487e1b2a8552852dbf16dd"
              Authorization: `token ${token}`
            },
            params: {}
          }
        );
        this.employees = res.data.data.rows.map(el => ({
          ...el
        }));
        this.employeename =
          res.data.data.rows[0].first_name +
          " " +
          res.data.data.rows[0].last_name;
      } catch (error) {
        console.error(error);
      }
    },
    async getRelations() {
      let token = null;
      token = sessionStorage.token;
      try {
        const res = await axios.get(
          `v1/patient/relations`,
          //`https://devapi.smartdoctor.pe/v1/patient/relations`,
          {
            headers: {
              //Authorization: "token 89fa41e80e065fb925ee88f11809f38eb03e8e0a"
              Authorization: `token ${token}`
            },
            params: {}
          }
        );
        this.relations = res.data.data.map(el => ({
          ...el
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async getFamiliarId(num_doc, editView, index) {
      try {
        let token = null;
        token = sessionStorage.token;
        let empleado_id = null;
        let num_doc_empleado = null;
        empleado_id = this.$route.params.idempleado;
        num_doc_empleado = num_doc;

        const res = await axios.get(
          `v1/employee/${empleado_id}/family`,
          //`https://devapi.smartdoctor.pe/v1/employee/${empleado_id}/family`,
          {
            headers: {
              //Authorization: "token 85ba358db6697d27c1487e1b2a8552852dbf16dd"
              Authorization: `token ${token}`
            },
            params: { num_doc: num_doc_empleado }
          }
        );
        this.listFamily = res.data.data.rows;

        if (editView) {
          this.formUpdate.family_id = this.listFamily[0].id;
          this.formUpdate.first_name = this.listFamily[0].first_name;
          this.formUpdate.last_name = this.listFamily[0].last_name;
          this.formUpdate.tipo_doc = this.listFamily[0].tipo_doc;
          this.formUpdate.num_doc = this.listFamily[0].num_doc;
          this.formUpdate.genre = this.listFamily[0].genre;
          this.formUpdate.birthdate = this.listFamily[0].birthdate;
          this.formUpdate.relation_id = this.listFamily[0].relation_id;
          this.formUpdate.email = this.listFamily[0].email;
          this.formUpdate.phone = this.listFamily[0].phone;

          this.dialogEditFamilyVisible = true;
          this.dialogViewFamilyVisible = false;

          if (this.formUpdate.tipo_doc == 1) {
            this.maxLength = 8;
          } else if (this.formUpdate.tipo_doc == 2) {
            this.maxLength = 14;
          } else if (this.formUpdate.tipo_doc == 3) {
            this.maxLength = 12;
          }
        } else {
          //console.log(this.listFamily);
          this.form.index = index;
          this.form.family_id = this.listFamily[0].id;
          this.form.first_name = this.listFamily[0].first_name;
          this.form.last_name = this.listFamily[0].last_name;
          this.form.tipo_doc = this.listFamily[0].tipo_doc;
          this.form.tipo_doc_display = this.listFamily[0].tipo_doc_display;
          this.form.num_doc = this.listFamily[0].num_doc;
          this.form.genre = this.listFamily[0].genre;
          this.form.genre_display = this.listFamily[0].genre_display;
          this.form.birthdate = this.listFamily[0].birthdate;
          this.form.relation_id = this.listFamily[0].relation_id;
          this.form.relation_name = this.listFamily[0].relation_name;
          this.form.email = this.listFamily[0].email;
          this.form.phone = this.listFamily[0].phone;
          this.dialogViewFamilyVisible = true;
        }
      } catch (error) {
        console.error(error);
      }
    },
    /*async getFamiliarId(
      ID,
      Nombres,
      Apellidos,
      Relacion,
      TipoDocumento,
      NumeroDocumento,
      Genero,
      Celular,
      Correo,
      fechanacimiento
    ) {
      try {
        this.form.family_id = ID;
        this.form.first_name = Nombres;
        this.form.last_name = Apellidos;
        this.form.relation_id = Relacion;
        this.form.tipo_doc = TipoDocumento;
        this.form.num_doc = NumeroDocumento;
        this.form.genre = Genero;
        this.form.phone = Celular;
        this.form.email = Correo;
        this.form.birthdate = fechanacimiento;
      } catch (error) {
        console.error(error);
      }
    },*/
    async updateFamiliar() {
      let token = null;
      token = sessionStorage.token;

      let family_id = this.formUpdate.family_id;

      try {
        await axios
          .put(
            `v1/family/${family_id}`,
            ////`https://devapi.smartdoctor.pe/v1/family/${family_id}`,
            this.formUpdate,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                //Authorization: "token 85ba358db6697d27c1487e1b2a8552852dbf16dd"
                Authorization: `token ${token}`
              },
              params: {}
            }
          )
          .then(response => {
            if (response.data.success === true) {
              Swal.fire("Actualizado!", response.data.message, "success");
              this.getFamiliaresEmpleado();
              this.dialogEditFamilyVisible = false;
            } else {
              Swal.fire("Error!", response.data.message, "warning");
              this.dialogEditFamilyVisible = false;
            }
          });

        /*Swal.fire('Actualizado!', 'El  Familiar ha sido actualizado.','success');
         this.getFamiliaresEmpleado();*/
      } catch (error) {
        console.error(error);
      }
    },
    async createFamiliar() {
      let empleado_id = this.$route.params.idempleado;

      let token = null;
      token = sessionStorage.token;

      try {
        await axios
          .post(
            ///`https://devapi.smartdoctor.pe/v1/employee/${empleado_id}/family/`,
            `v1/employee/${empleado_id}/family/`,
            this.formCreate,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                //Authorization: "token 85ba358db6697d27c1487e1b2a8552852dbf16dd"
                Authorization: `token ${token}`
              },
              params: {}
            }
          )
          .then(response => {
            if (response.data.success === true) {
              Swal.fire("Registrado!", response.data.message, "success");
              this.resetFormCreate();
              this.getFamiliaresEmpleado();
              this.dialogCreateFamilyVisible = false;
            } else {
              Swal.fire("Error!", response.data.message, "warning");
              this.dialogCreateFamilyVisible = false;
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    async deleteFamiliar(familiar_id, index) {
      let token = null;
      token = sessionStorage.token;

      let indexArray = index - 1;
      try {
        Swal.fire({
          title: "¿Desea eliminar Familiar?",
          text:
            "Una vez eliminado el familiar, toda su informacion desaparecerá de su perfil",
          ///icon: 'warning',
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "CONFIRMAR",
          cancelButtonText: "CANCELAR",
          showCancelButton: true
        }).then(result => {
          if (result.value) {
            axios
              .delete(
                `v1/family/${familiar_id}`,
                // `https://devapi.smartdoctor.pe/v1/family/${familiar_id}`,
                {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    //Authorization: "token 85ba358db6697d27c1487e1b2a8552852dbf16dd"
                    Authorization: `token ${token}`
                  },
                  params: {}
                }
              )
              .then(response => {
                if (response.data.success === true) {
                  this.familiares_empleado.splice(indexArray, 1);
                  Swal.fire("¡Eliminado!", response.data.message, "success");
                } else {
                  Swal.fire("Error!", response.data.message, "warning");
                }
              });
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    indexMethod(index) {
      return (
        (this.pagination2.currentPage - 1) * this.pagination2.perPage +
        (index + 1)
      );
    }
  }
};
</script>
<style lang="css">
.el-table__body-wrapper {
  height: auto;
  overflow: auto;
}
.swal2-icon .swal2-icon-content {
  font-size: 90px !important;
}
</style>
