<template>
  <div>
    <loading
      :height="60"
      :width="60"
      color="#1873EE"
      style="color: gray; font-weight: 500;"
      background-color="white"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
    >{{cargando}}</loading>
    <el-card shadow="always" style="border-radius:25px">
      <div class="row">
        <div class="col-4">
          <span class="demonstration" style="font-size:13px"
            >Fecha de Programación</span
          >
          <el-date-picker
            v-model="daterange"
            unlink-panels
            type="daterange"
            range-separator="-"
            start-placeholder="Desde"
            end-placeholder="Hasta"
            size="small"
            format="dd-MM-yyyy"
            @change="changePrecarga"
          >
          </el-date-picker>
        </div>
        <div class="col-4">
          <span class="demonstration" style="font-size:13px">Empresa</span>
          <br />
          <el-select
            v-model="company"
            style="width:100%"
            placeholder="Empresa"
            size="small"
            filterable
            clearable
            @clear="changePrecarga"
          >
            <el-option
              v-for="item in companies"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-4">
          <span class="demonstration" style="font-size:13px"
            >Canal de Atención</span
          >
          <br />
          <el-select
            v-model="channel"
            style="width:100%"
            placeholder="Canal de Atención"
            size="small"
            clearable
            @clear="changePrecarga"
          >
            <el-option
              v-for="item in channels"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row" style="margin-top:10px">
        <div class="col-4">
          <span class="demonstration" style="font-size:13px">País</span>
          <br />
          <el-select
            v-model="country"
            style="width:100%"
            placeholder="País"
            size="small"
            filterable
            clearable
            @keypress.native="soloLetras"
            @clear="changePrecarga"
          >
            <el-option
              v-for="item in countries"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-4">
          <span class="demonstration" style="font-size:13px">Paciente</span>
          <br />
          <el-input
            :placeholder="placeholder"
            v-model="filter"
            class="input-with-select"
            clearable
            size="small"
            ref="filter"
            @clear="changePrecarga"
            suffix-icon="el-icon-search"
          >
            <template slot="prepend">
              <el-dropdown @command="handleCommand" placement="bottom">
                <span class="el-dropdown-link">
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="filtros"
                    ><b>Filtros</b></el-dropdown-item
                  >

                  <el-dropdown-item command="name" :style="stylename"
                    >Apellido</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="documentcode"
                    :style="styledocumentcode"
                    >Doc identidad</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
            <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
          </el-input>
        </div>
        <div class="col-1">
          <br />
          <el-button
            style="background:#23CCEF; color:white;border:0px"
            size="small"
            icon="el-icon-search"
            @click="buscar_teleconsultas"
          >
            Buscar</el-button
          >
        </div>
        <div class="col-3">
          <br />
          <!-- <el-checkbox
            v-model="checkedPrecarga"
            v-if="visible_checkbox_precarga"
            @change="changePrecarga"
            size="mini"
            >Precarga</el-checkbox
          > -->
          <el-switch
            v-model="checkedPrecarga"
            v-if="visible_checkbox_precarga"
            active-text="Precarga"
            @change="changePrecarga"
          >
          </el-switch>
        </div>
      </div>
    </el-card>
    <br />
    <el-card shadow="always" style="border-radius:25px">
      <div slot="header" class="clearfix">
        <span
          >Lista de atenciones realizadas <b>{{ etiqueta_text }}</b></span
        >

        <el-button
          style="background:#23CCEF; color:white;border:0px;float: right;"
          size="small"
          icon="el-icon-download"
          @click="download_excel"
        >
          Descargar Excel</el-button
        >
      </div>
      <div>
        <el-table style="width: 100%" :data="teleconsultas" border stripe>
          <el-table-column
            prop="month_admission_support"
            min-width="135"
            label="Mes atención"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="scheduled_date"
            min-width="170"
            label="Fecha programación"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="reevaluation_date"
            min-width="160"
            label="Fecha reevaluación"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="company_name"
            min-width="180"
            label="Empresa"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="employee_name"
            min-width="310"
            label="Titular"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="patient_name"
            min-width="310"
            label="Paciente"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="employee_phone"
            min-width="150"
            label="Celular titular"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="patient_phone"
            min-width="150"
            label="Celular paciente"
            align="center"
          >
          </el-table-column>  

          <el-table-column
            prop="diagnosis_name"
            min-width="170"
            label="Diagnostico wellness"
            align="center"
          >
          </el-table-column>

          <el-table-column
            align="center"
            fixed="right"
            prop="speciality"
            min-width="190"
            label="Especialidad"
          >
          </el-table-column>
        </el-table>
        <br />
        <div
          v-if="pagination_pre_carga == true"
          slot="footer"
          class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
        >
          Showing {{ pagination.currentPage2 }} to {{ pagination.perPage2 }} of
          {{ pagination.total }}

          <l-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </l-pagination>
        </div>
        <div
          v-else
          slot="footer"
          class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
        >
          Showing {{ pagination2.currentPage2 }} to
          {{ pagination2.perPage2 }} of
          {{ pagination2.total }}

          <l-pagination
            class="pagination-no-border"
            v-model="pagination2.currentPage"
            :per-page="pagination2.perPage"
            :total="pagination2.total"
          >
          </l-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  Popover,
  Tag,
  Descriptions,
  DescriptionsItem,
  Button,
  Dialog,
  Input,
  MessageBox,
  Card,
  Checkbox,
  Switch,
  Dropdown,
  DropdownMenu,
  DropdownItem
} from "element-ui";
import { Loading, Pagination as LPagination } from "src/components/index";
import axios from "axios";
import XLSX from "xlsx";
import dayjs from "dayjs";
require("dayjs/locale/es");
export default {
  name: "ProgramarTeleconsulta",
  components: {
    Loading,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Popover.name]: Popover,
    [Tag.name]: Tag,
    [Descriptions.name]: Descriptions,
    [DescriptionsItem.name]: DescriptionsItem,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [MessageBox.name]: MessageBox,
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [Switch.name]: Switch,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem
  },
  data() {
    return {
      placeholder: "Apellido",
      filter: null,
      filtro_select: "",
      stylename: "color:#167FE2;border-radius:8px",
      styledocumentcode: "",
      channel: "",
      channels: [
        {
          value: "SD",
          label: "SD"
        },
        {
          value: "ZOOM",
          label: "ZOOM"
        },
        {
          value: "CALL",
          label: "CALL"
        }
      ],
      country: "",
      countries: [],
      pagination_pre_carga: true,
      visible_checkbox_precarga: false,
      checkedPrecarga: false,
      etiqueta_text: "del último mes",
      precargado_data: 0,
      precargado_data_ultimo_mes: true,
      date: new Date(),
      currentDay: dayjs(this.date).format("MM-DD"),
      currentMonth: dayjs(this.date).format("MM"),
      currentYear: dayjs(this.date).format("YYYY"),
      date_from: null,
      date_to: null,
      pre_carga_date_from: null,
      pre_carga_date_to: null,
      isLoading: false,
      popover_companies: false,
      company: "",
      daterange: null,
      companies: [],
      teleconsultas: [],
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [10, 15, 25, 50],
        total: 0,
        currentPage2: 1,
        perPage2: 20
      },
      pagination2: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [10, 15, 25, 50],
        total: 0,
        currentPage2: 1,
        perPage2: 20
      },
      cargando: ""
    };
  },
  watch: {
    "pagination.currentPage"() {
      this.getTeleconsultasPrecarga();
      this.Showingpagination();
    },
    "pagination2.currentPage"() {
      this.getTeleconsultas();
      this.Showingpagination2();
    }
  },
  computed: {},
  mounted() {
    if (!sessionStorage.isLogged) {
      this.$router.go(-1);
      return;
    }
    var today = new Date();
    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);

    let mes_anterior = this.currentMonth - 1;
    let ini_dia = this.currentYear + "-" + "0" + mes_anterior + "-" + "01";

    this.pre_carga_date_from = ini_dia;
    this.pre_carga_date_to = dayjs(lastDayOfMonth).format("YYYY-MM-DD");
    this.getCompanies();
    this.buscar_teleconsultas();
    this.getCountries();
  },
  methods: {
    soloLetras($e) {
      var key = $e.keyCode || $e.which;
      var tecla = String.fromCharCode(key).toLowerCase();
      var letras = " áéíóúabcdefghijklmnñopqrstuvwxyz";
      var especiales = [8, 13];
      //console.log(key);
      var tecla_especial = false;
      for (var i in especiales) {
        if (key == especiales[i]) {
          tecla_especial = true;
          break;
        }
      }

      if (letras.indexOf(tecla) == -1 && !tecla_especial) {
        $e.preventDefault();
        return false;
      }
    },
    async getCountries() {
      try {
        const res = await axios.get(`v1/countries`, {
          params: {}
        });
        this.countries = res.data.data.map(el => ({
          ...el
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async changePrecarga() {
      if (
        this.precargado_data_ultimo_mes == false &&
        !this.daterange &&
        !this.company &&
        !this.country &&
        !this.channel &&
        !this.filter
      ) {
        this.etiqueta_text = "del último mes";
        this.isLoading = true;
        this.pagination.currentPage = 1;
        await this.getTeleconsultasPrecarga();
        await this.Showingpagination();
        this.isLoading = false;
        //this.precargado_data = 0;
        this.precargado_data_ultimo_mes = false;
        this.date_from = this.pre_carga_date_from;
        this.date_to = this.pre_carga_date_to;
        this.visible_checkbox_precarga = false;
        this.pagination_pre_carga = true;

        this.checkedPrecarga = false;
      }
    },
    async buscar_teleconsultas() {
      this.isLoading = true;
      this.pagination2.currentPage = 1;

      if (this.precargado_data == 1) {
        this.precargado_data_ultimo_mes = false;
      }

      if (this.precargado_data_ultimo_mes == true) {
        await this.getTeleconsultasPrecarga();
        this.precargado_data = 1;
        this.visible_checkbox_precarga = false;
        this.pagination_pre_carga = true;
      } else {
        this.etiqueta_text = "";
        this.pagination_pre_carga = false;
        if (
          this.precargado_data_ultimo_mes == false &&
          !this.daterange &&
          !this.company &&
          !this.country &&
          !this.channel &&
          !this.filter
        ) {
          this.visible_checkbox_precarga = true;
        } else {
          this.visible_checkbox_precarga = false;
        }
        await this.getTeleconsultas();
      }
      this.isLoading = false;
      this.Showingpagination();
    },
    async download_excel() {
      this.isLoading = true;
      await this.getTeleconsultasDownload();
    },

    range_date() {
      let date_from = null;
      let date_to = null;
      if (this.daterange) {
        date_from = dayjs(this.daterange[0]).format("YYYY-MM-DD");
        date_to = dayjs(this.daterange[1]).format("YYYY-MM-DD");
      }
      //console.log(date_from, date_to);
    },

    capitalize: function(str) {
      let nombre = "";
      nombre = str.toLocaleLowerCase();
      return nombre
        .toLowerCase()
        .trim()
        .split(" ")
        .map(v => v[0].toUpperCase() + v.substr(1))
        .join(" ");
    },
    handleCommand(filtro_select) {
      if (filtro_select == "documentcode") {
        this.styledocumentcode = "color:#167FE2;border-radius:8px";
        this.stylename = "";
        this.placeholder = "Doc identidad";
      } else if (filtro_select == "name") {
        this.styledocumentcode = "";
        this.stylename = "color:#167FE2;border-radius:8px";
        this.placeholder = "Apellido";
      }

      this.$refs.filter.focus();
      this.filtro_select = filtro_select;
    },
    async getCompanies() {
      let country_id = null;
      let name = null;
      let code = null;

      let token = null;
      token = sessionStorage.token;

      try {
        const res = await axios.get(`v1/companies`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: 1,
            size: 100,
            name: name,
            code: code,
            country_id,
            "exclude-is-demo": ""
          }
        });
        this.companies = res.data.data.rows.map(el => ({
          ...el,
          name: this.capitalize(el.name)
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async getTeleconsultasPrecarga() {
      try {
        let company;

        let token = null;
        token = sessionStorage.token;

        const res = await axios.get(`v1.1/pro/medical-attention`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: this.pagination.currentPage,
            limit: 20,
            company: company,
            "date-start": this.pre_carga_date_from,
            "date-end": this.pre_carga_date_to,
            "report-global": "report-global",
            "type-attention": 1,
            status: "attended",
            channel: "",
            country: "",
            document_code: "",
            "patient-or-employee": ""
          }
        });
        if (res.data.count > 0) {
          this.pagination.total = res.data.count;
          this.teleconsultas = res.data.results.map(el => ({
            ...el,
            company_name: this.capitalize(el.company_name)
          }));
        } else {
          this.pagination.total = 0;
          this.teleconsultas = [];
        }
      } catch (error) {
        console.error(error);
      }
      if (this.pagination.total <= 20) {
        this.pagination.perPage2 = this.pagination.total;
      } else {
        this.pagination.perPage2 = 20;
      }
    },
    async getTeleconsultas() {
      try {
        let company, channel, country, document_code, patient_or_employee;

        let token = null;
        token = sessionStorage.token;

        if (this.precargado_data_ultimo_mes == false) {
          this.date_from = null;
          this.date_to = null;
        } else {
          this.date_from = dayjs(this.daterange[0]).format("YYYY-MM-DD");
          this.date_to = dayjs(this.daterange[1]).format("YYYY-MM-DD");
        }

        if (this.daterange) {
          this.precargado_data_ultimo_mes = false;
          this.date_from = dayjs(this.daterange[0]).format("YYYY-MM-DD");
          this.date_to = dayjs(this.daterange[1]).format("YYYY-MM-DD");
        }

        if (this.company) {
          this.precargado_data_ultimo_mes = false;
          company = this.company;
        }

        if (this.channel) {
          this.precargado_data_ultimo_mes = false;
          channel = this.channel;
        }

        if (this.country) {
          this.precargado_data_ultimo_mes = false;
          country = this.country;
        }

        if (this.filter) {
          this.precargado_data_ultimo_mes = false;
          if (this.filtro_select == "documentcode") {
            document_code = this.filter;
          } else if (this.filtro_select == "name") {
            patient_or_employee = this.filter;
          } else if (this.filtro_select == "") {
            patient_or_employee = this.filter;
          }
        }

        const res = await axios.get(`v1.1/pro/medical-attention`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: this.pagination2.currentPage,
            limit: 20,
            company: company,
            "date-start": this.date_from,
            "date-end": this.date_to,
            "report-global": "report-global",
            "type-attention": 1,
            status: "attended",
            channel: channel,
            country: country,
            document_code: document_code,
            "patient-or-employee": patient_or_employee
          }
        });
        if (res.data.count > 0) {
          this.pagination2.total = res.data.count;

          this.teleconsultas = res.data.results.map(el => ({
            ...el,
            company_name: this.capitalize(el.company_name)
          }));
        } else {
          this.pagination2.total = 0;
          this.teleconsultas = [];
        }
      } catch (error) {
        console.error(error);
      }

      if (this.pagination2.total <= 20) {
        this.pagination2.perPage2 = this.pagination2.total;
      } else {
        this.pagination2.perPage2 = 20;
      }
    },
    async getTeleconsultasDownload() {
      try {
        let document_code, patient_or_employee;

        let token = null;
        token = sessionStorage.token;
        console.log("company", this.company);
        console.log("channel", this.channel);
        console.log("country", this.country);
        console.log("filter", this.filter);

        if (this.precargado_data_ultimo_mes == false) {
          if (this.daterange) {
            this.date_from = dayjs(this.daterange[0]).format("YYYY-MM-DD");
            this.date_to = dayjs(this.daterange[1]).format("YYYY-MM-DD");
          } else if (
            (this.daterange == null &&
              !this.company &&
              !this.channel &&
              !this.country &&
              this.filter == null) ||
            this.filter == ""
          ) {
            this.date_from = this.pre_carga_date_from;
            this.date_to = this.pre_carga_date_to;
          } else {
            this.date_from = null;
            this.date_to = null;
          }
        } else {
          if (this.daterange) {
            this.date_from = dayjs(this.daterange[0]).format("YYYY-MM-DD");
            this.date_to = dayjs(this.daterange[1]).format("YYYY-MM-DD");
          } else {
            this.date_from = this.pre_carga_date_from;
            this.date_to = this.pre_carga_date_to;
          }
        }
        if (this.filter) {
          this.precargado_data_ultimo_mes = false;
          if (this.filtro_select == "documentcode") {
            document_code = this.filter;
          } else if (this.filtro_select == "name") {
            patient_or_employee = this.filter;
          } else if (this.filtro_select == "") {
            patient_or_employee = this.filter;
          }
        }

        const limit_down = this.pagination_pre_carga
              ? this.pagination.total
              : this.pagination2.total;
        const page_down = 200;
        let json_down = [];
        let percentage = 0;
        for (let index = 1; index <= Math.ceil(limit_down/page_down); index++) { //25/10=2.5, 
          let interval = setInterval(() => {
            if (percentage < (index * 100) / Math.ceil(limit_down/page_down)) {
              percentage += 1;
              this.cargando = "Cargando " + percentage + "%";
            } else clearInterval(interval);
          }, 400);
          const res = await axios.get(`v1.1/pro/medical-attention`, {
            params: {
              page: index,
              limit: page_down,
              company: this.company,
              "date-start": this.date_from,
              "date-end": this.date_to,
              "report-global": "",
              "type-attention": 1,
              status: "attended",
              channel: this.channel,
              country: this.country,
              document_code: document_code,
              "patient-or-employee": patient_or_employee
            },
            headers: {
              Authorization: `token ${token}`
            }
          })

          json_down = [...json_down, ...res.data.results];
          percentage = Math.ceil((index * 100) / (limit_down/page_down));
          console.log('json_down', json_down);
          if (index == Math.ceil(limit_down/page_down)) {
            this.download_json_excel(json_down, "reporte-b2b-globales");
            this.isLoading = false;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    download_json_excel(dataFile, fileName) {
      let data = XLSX.utils.json_to_sheet(dataFile);
      //data["A1"].v = "ID DE LA SESION";
      data["A1"].v = "ID DE LA SESION";
      data["B1"].v = "FECHA DE PROGRAMACION";
      data["C1"].v = "MES DE ATENCION";
      data["D1"].v = "FECHA DE ATENCION";
      data["E1"].v = "INICIO DE CONSULTA (HORA)";
      data["F1"].v = "FIN DE CONSULTA (HORA)";
      data["G1"].v = "CANAL";
      data["H1"].v = "NOMBRE DE LA EMPRESA";
      data["I1"].v = "SEDE DE LA EMPRESA";
      data["J1"].v = "ID DEL SMARTER";
      data["K1"].v = "FECHA DE REGISTRO DEL SMARTER";
      data["L1"].v = "DOCUMENTO DEL SMARTER";
      data["M1"].v = "NOMBRE DEL SMARTER";
      data["N1"].v = "GENERO DEL SMARTER";
      data["O1"].v = "FECHA NACIMIENTO DEL SMARTER";
      data["P1"].v = "EDAD DEL SMARTER";
      data["Q1"].v = "CORREO DEL SMARTER";
      data["R1"].v = "CELULAR DEL SMARTER";
      data["S1"].v = "CARGO DEL SMARTER";
      data["T1"].v = "PAIS DEL SMARTER";
      data["U1"].v = "NOMBRE DEL PACIENTE";
      data["V1"].v = "GENERO DEL PACIENTE";
      data["W1"].v = "FECHA NACIMIENTO DEL PACIENTE";
      data["X1"].v = "EDAD DEL PACIENTE";
      data["Y1"].v = "CORREO DEL PACIENTE";
      data["Z1"].v = "CELULAR DEL PACIENTE";
      data["AA1"].v = "PARENTESCO CON EL TITULAR";
      data["AB1"].v = "NOMBRE DEL ESPECIALISTA";
      data["AC1"].v = "ESPECIALIDAD";
      data["AD1"].v = "COSTO DEL ESPECIALISTA";
      data["AE1"].v = "PAIS DEL ESPECIALISTA";
      data["AF1"].v = "MOTIVO DE LA CONSULTA";
      data["AG1"].v = "RELATO";
      data["AH1"].v = "ANTECEDENTES FAMILIARES";
      data["AI1"].v = "ANTECEDENTES PERSONALES";
      data["AJ1"].v = "PESO (KG)";
      data["AK1"].v = "TALLA (CM)";
      data["AL1"].v = "IMC";
      data["AM1"].v = "CIRCUNFERENCIA ABDOMINAL (CM)";
      data["AN1"].v = "TIEMPO DE SUEÑO";
      data["AO1"].v = "CONSUMO DE AGUA";
      data["AP1"].v = "ACTIVIDAD FISICA (MIN x DIA)";
      data["AQ1"].v = "ACTIVIDAD FISICA (DIAS x SEM)";
      data["AR1"].v = "TIEMPO ACTIVIDAD RECREATIVA (MIN x DIA)";
      data["AS1"].v = "CUMPLIMIENTO DEL PLAN DE TRABAJO";
      data["AT1"].v = "HALLAZGOS DURANTE LA ENTREVISTA";
      data["AU1"].v = "FACTORES DESENCADENANTES";
      data["AV1"].v = "TIPO DE DIAGNOSTICO";
      data["AW1"].v = "DIAGNOSTICO WELLNESS";
      data["AX1"].v = "IMPRESION DIAGNOSTICA CIE10";
      data["AY1"].v = "TIPO IMPRESION DIAGNOSTICA CIE10";
      data["AZ1"].v = "SINTOMATOLOGIA";
      data["BA1"].v = "EXAMEN DE LABORATORIO";
      data["BB1"].v = "EXAMEN DE IMAGENES";
      data["BC1"].v = "OTROS EXAMENES";
      data["BD1"].v = "INDICACIONES";
      data["BE1"].v = "DESTINO";
      data["BF1"].v = "DESCANSO MÉDICO";
      data["BG1"].v = "RECETAS FARMACEUTICAS";
      data["BH1"].v = "FECHA DE REEVALUACION";
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, data, fileName);
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    },

    convertFile(dataFile, fileName) {
      let newBlob = new Blob([dataFile], {
        type: "application/vnd.ms-excel; charset=UTF-8"
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = fileName;
      link.click();
      setTimeout(function() {
        window.URL.revokeObjectURL(data);
      }, 100);
    },

    Showingpagination() {
      this.pagination.perPage2 =
        this.pagination.perPage * this.pagination.currentPage;

      if (this.pagination.currentPage == 1) {
        this.pagination.currentPage2 = 1;
      } else if (this.pagination.currentPage >= 2) {
        this.pagination.currentPage2 = this.pagination.perPage2 - 19;
      }

      if (this.pagination.perPage2 > this.pagination.total) {
        this.pagination.perPage2 = this.pagination.total;
      }
    },
    Showingpagination2() {
      this.pagination2.perPage2 =
        this.pagination2.perPage * this.pagination2.currentPage;

      if (this.pagination2.currentPage == 1) {
        this.pagination2.currentPage2 = 1;
      } else if (this.pagination2.currentPage >= 2) {
        this.pagination2.currentPage2 = this.pagination2.perPage2 - 19;
      }

      if (this.pagination2.perPage2 > this.pagination2.total) {
        this.pagination2.perPage2 = this.pagination2.total;
      }
    }
  }
};
</script>

<style lang="css"></style>
