<template>
  <div>
    <!-- <div class="row">
      <div class="col-xl-4 col-md-6">
        <h5>
          <b>
            <img
              src="image/img_atras.png"
              alt="atras"
              width="35px"
              style="cursor:pointer;"
              @click="regresarListTeleconsultas"
            />
            Editar teleconsulta
          </b>
        </h5>
        <small>Completa los siguientes datos</small>
      </div>
    </div> -->
    <br />
    <div class="row">
      <div class="col-md-6">
        <div class="card stacked-form">
          <!-- <div class="card-header ">
            <h5 class="card-title">información de teleconsulta</h5>
          </div> -->
          <div class="card-body ">
            <ValidationObserver v-slot="{ handleSubmit }">
              <el-form label-position="top" ref="formUpdate">
                <ValidationProvider
                  rules="required"
                  name="Usuario"
                  vid="information_user"
                  v-slot="provider"
                >
                  <label> Datos Usuario</label>
                  <el-input
                    placeholder="Datos Usuario"
                    v-model="information_user"
                    class="input-with-select"
                    size="small"
                    style="margin-bottom:10px"
                    ref="information_user"
                    readonly
                  />
                  <div
                    style="color:red; font-size:10px;bottom:10px;position: relative"
                  >
                    {{ provider.errors[0] }}
                  </div>
                </ValidationProvider>

                <ValidationProvider
                  rules="required"
                  name="Especialista"
                  vid="doctor"
                  v-slot="provider"
                >
                  <label>Especialista</label>
                  <el-input
                    placeholder="Especialista"
                    v-model="doctor"
                    class="input-with-select"
                    size="small"
                    style="margin-bottom:10px"
                    readonly
                  />
                  <div
                    style="color:red; font-size:10px;bottom:10px;position: relative"
                  >
                    {{ provider.errors[0] }}
                  </div>
                </ValidationProvider>

                <ValidationProvider
                  rules="required"
                  name="Fecha"
                  vid="fecha"
                  v-slot="provider"
                >
                  <label>Fecha</label>
                  <el-input
                    placeholder="Fecha"
                    v-model="fecha"
                    size="small"
                    style="margin-bottom:10px"
                    readonly
                  >
                    <template slot="append"
                      ><el-popover
                        placement="right"
                        width="450"
                        trigger="manual"
                        v-model="popover_visible"
                      >
                        <el-calendar id="calendar" style="height:auto">
                          <template slot="dateCell" slot-scope="{ data }">
                            <div @click="calendar_mes(data)">
                              <div
                                style=" text-align: center;                                         
                                          font-size: 12px;"
                                :class="remark(data)"
                              >
                                {{
                                  data.day
                                    .split("-")
                                    .slice(2)
                                    .join("-")
                                }}
                                <div
                                  v-if="
                                    data.day
                                      .split('-')
                                      .slice(1)
                                      .join('-') == currentDay
                                  "
                                ></div>
                              </div>

                              <div
                                v-for="item in schedules_map"
                                :key="item.day"
                              >
                                <div
                                  v-if="
                                    item.months.indexOf(
                                      data.day.split('-').slice(1)[0]
                                    ) != -1
                                  "
                                >
                                  <div
                                    v-if="
                                      item.days.indexOf(
                                        data.day
                                          .split('-')
                                          .slice(2)
                                          .join('-')
                                      ) != -1
                                    "
                                  >
                                    <div class="text-center">
                                      <el-button
                                        type="text"
                                        @click="calendarClick(data)"
                                      >
                                        <div
                                          style="bottom:10px;position:relative"
                                        >
                                          🔴
                                        </div>
                                      </el-button>
                                    </div>
                                  </div>
                                  <div v-else></div>
                                </div>
                                <div v-else></div>
                              </div>
                            </div>
                          </template>
                        </el-calendar>
                        <i
                          reanonly="true"
                          slot="reference"
                          @click="validatePopover"
                          class="el-icon-date"
                          style="cursor:pointer"
                        ></i> </el-popover
                    ></template>
                  </el-input>
                  <div
                    style="color:red; font-size:10px;bottom:10px;position: relative"
                  >
                    {{ provider.errors[0] }}
                  </div>
                </ValidationProvider>

                <ValidationProvider
                  rules="required"
                  name="Horario"
                  vid="horario"
                  v-slot="provider"
                >
                  <label> HORARIO</label>
                  <el-input
                    v-if="horarios == 0"
                    v-model="hora"
                    size="small"
                    readonly
                    style="margin-bottom:10px"
                  />
                  <el-input
                    v-else
                    placeholder="Horario"
                    v-model="horario"
                    size="small"
                    style="margin-bottom:10px"
                    readonly
                  >
                    <template slot="append"
                      ><el-popover
                        title="Horarios"
                        placement="right"
                        width="auto"
                        trigger="manual"
                        v-model="popover_horarios"
                      >
                         <div :style="stylehorarios">
                          <div
                            v-for="(horario, index) in horarios"
                            :key="'horario-' + index"
                          >
                            <!-- <span style="font-size: 12px">{{ horario }}</span> -->
                            <!-- <el-button
                            type="text"
                            @click="selecthorario(horario)"
                            >{{ horario }}</el-button
                          > -->
                            <el-button
                              type="primary"
                              plain
                              @click="selecthorario(horario)"
                              size="mini"
                              >{{ horario }}</el-button
                            >
                          </div>
                        </div>
                        <i
                          reanonly="true"
                          slot="reference"
                          @click="validatePopoverHorarios"
                          class="el-icon-time"
                          style="cursor:pointer"
                        ></i> </el-popover
                    ></template>
                  </el-input>
                  <div
                    style="color:red; font-size:10px;bottom:10px;position: relative"
                  >
                    {{ provider.errors[0] }}
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  name="Sintomas / Motivo teleconsulta"
                  vid="formCreate.subject"
                  v-slot="provider"
                >
                  <label>SINTOMAS / MOTIVO TELECONSULTA</label>
                  <el-input
                    style="margin-bottom:10px;width:100%"
                    size="small"
                    placeholder="Sintomas / Motivo teleconsulta"
                    v-model="formUpdate.subject"
                  ></el-input>
                  <div
                    style="color:red; font-size:10px;bottom:10px;position: relative"
                  >
                    {{ provider.errors[0] }}
                  </div>
                </ValidationProvider>

                <div class="row">
                  <div class="col-6 text-left">
                    <el-button
                      style="background:#23CCEF; color:white;border:0px"
                      @click="handleSubmit(saveAtencion)"
                      :disabled="formSaveDisabled"
                      size="small"
                    >
                      GUARDAR TELECONSULTA</el-button
                    >

                    <!-- <button
                      type="submit"
                      class="btn btn-fill btn-info"
                      @click="handleSubmit(saveAtencion)"
                    >
                      GUARDAR TELECONSULTA
                    </button> -->
                  </div>
                  <div class="col-6 text-right">
                    <el-button
                      type="info"
                      size="small"
                      style="border:0px"
                      @click="regresarListTeleconsultas"
                      >CANCELAR EDICION</el-button
                    >
                    <!-- <button
                      type="button"
                      class="btn btn-default"
                      @click="regresarListTeleconsultas"
                    >
                      CANCELAR EDICION
                    </button> -->
                  </div>
                </div>
              </el-form>
            </ValidationObserver>
            <!-- <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(saveAtencion)">
                <div class="form-group">
                  <label>Datos Usuario</label>

                  <div class="input-group">
                    <input
                      v-model="information_user"
                      type="text"
                      placeholder="Datos Usuario"
                      class="form-control"
                      readonly
                      required="true"
                      style="background:white"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i
                          class="nc-icon nc-zoom-split"
                          style="cursor:pointer"
                          @click="searchUsers"
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Especialista</label>
                  <div class="input-group">
                    <input
                      v-model="doctor"
                      type="text"
                      placeholder="Especialista"
                      class="form-control"
                      readonly
                      style="background:white"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i
                          class="nc-icon nc-zoom-split"
                          style="cursor:pointer"
                          @click="searchDoctors"
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Fecha</label>
                  <div class="input-group">
                    <input
                      v-model="fecha"
                      type="text"
                      placeholder="Pick a day"
                      required
                      readonly
                      class="form-control"
                      style="background:white"
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <el-popover
                          placement="right"
                          width="450"
                          trigger="manual"
                          v-model="popover_visible"
                        >
                          <el-calendar id="calendar">
                            <template slot="dateCell" slot-scope="{ data }">
                              <div @click="calendar_mes(data)">
                                <div
                                  style=" text-align: center;                                         
                                          font-size: 12px;"
                                  :class="remark(data)"
                                >
                                  {{
                                    data.day
                                      .split("-")
                                      .slice(2)
                                      .join("-")
                                  }}
                                  <div
                                    class="currentDay"
                                    v-if="
                                      data.day
                                        .split('-')
                                        .slice(1)
                                        .join('-') == currentDay
                                    "
                                  ></div>
                                </div>

                                <div
                                  v-for="item in schedules_map"
                                  :key="item.day"
                                >
                                  <div
                                    v-if="
                                      item.months.indexOf(
                                        data.day.split('-').slice(1)[0]
                                      ) != -1
                                    "
                                  >
                                    <div
                                      v-if="
                                        item.days.indexOf(
                                          data.day
                                            .split('-')
                                            .slice(2)
                                            .join('-')
                                        ) != -1
                                      "
                                    >
                                      <div class="text-center">
                                        <el-button
                                          type="text"
                                          @click="calendarClick(data)"
                                        >
                                          🔴
                                        </el-button>
                                      </div>
                                    </div>
                                    <div v-else></div>
                                  </div>
                                  <div v-else></div>
                                </div>
                              </div>
                            </template>
                          </el-calendar>
                          <i
                            reanonly="true"
                            slot="reference"
                            @click="validatePopover"
                            class="el-icon-date"
                            style="cursor:pointer"
                          ></i>
                        </el-popover>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Horario</label>
                  <input
                    v-if="horarios == 0"
                    v-model="hora"
                    type="text"
                    class="form-control"
                    readonly
                    style="background:white"
                  />

                  <select
                    v-if="horarios != 0"
                    v-model="horario"
                    clearable
                    placeholder="Horario"
                    class="form-control"
                    required
                  >
                    <option
                      v-for="item in horarios"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Sintomas / Motivo teleconsulta</label>

                  <input
                    v-model="formUpdate.subject"
                    type="text"
                    placeholder="Sintomas / Motivo teleconsulta"
                    class="form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-6 text-left">
                      <button type="submit" class="btn btn-fill btn-info">
                        GUARDAR TELECONSULTA
                      </button>
                    </div>
                    <div class="col-6 text-right">
                      <button
                        type="button"
                        class="btn btn-default"
                        @click="regresarListTeleconsultas"
                      >
                        CANCELAR EDICION
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver> -->
          </div>
        </div>
      </div>
    </div>
    <!--:before-close="handleClose"-->
    <el-dialog
      title="Usuarios"
      :visible.sync="dialogTableUsuarios"
      width="71%"
      :close-on-click-modal="false"
    >
      <div class="row">
        <div class="col-md-6">
          <form>
            <div class="form-group">
              <el-input
                :placeholder="placeholder"
                v-model="filterUsers"
                clearable
                ref="filterUsers"
                size="small"
              >
                <template slot="prepend">
                  <el-dropdown
                    @command="handleCommand"
                    placement="bottom-start"
                  >
                    <span class="el-dropdown-link">
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="filtros"
                        ><b>Filtros</b></el-dropdown-item
                      >
                      <el-dropdown-item command="name"
                        >Nombre o Apellido</el-dropdown-item
                      >
                      <el-dropdown-item command="num_doc"
                        >Num Doc</el-dropdown-item
                      >
                      <el-dropdown-item command="email">Email</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>

                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
              <!-- <div class="input-group">
                <input
                  v-model="filterUsers"
                  type="text"
                  placeholder="Escribe aquí.."
                  class="form-control"
                />
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="nc-icon nc-zoom-split"></i>
                  </span>
                  <drop-down
                    icon="nc-icon nc-bullet-list-67"
                    id="ddCommodity"
                    name="ddCommodity"
                    v-model="ddTestVm.ddTestSelectedOption"
                    text="Select Item"
                    variant="primary"
                  >
                    <li class="dropdown-item disabled" value="0">
                      <b>Filtros</b>
                    </li>
                    <li
                      class="dropdown-item"
                      v-for="option in ddTestVm.options"
                      :key="option.value"
                      :value="option.value"
                      @click="ddTestVm.ddTestSelectedOption = option.value"
                    >
                      {{ option.text }}
                    </li>
                  </drop-down>
                </div>
              </div> -->
            </div>
          </form>
        </div>
      </div>
      <el-table
        :data="users"
        style="width: 100%"
        height="250"
        @expand-change="expandChange"
      >
        <!-- <el-table-column label="FECHA" width="117" prop="date_birthday">
        </el-table-column> -->
        <el-table-column label="CORREO" width="250" prop="email">
        </el-table-column>
        <el-table-column label="NOMBRE" width="200" prop="first_name">
        </el-table-column>
        <el-table-column label="APELLIDO" width="250" prop="last_name">
        </el-table-column>
        <el-table-column label="DOCUMENTO" width="120" prop="num_doc">
        </el-table-column>
        <!-- <el-table-column label="UBICACIÓN" width="150" prop="geoname">
        </el-table-column> -->
        <el-table-column type="expand" align="right" width="75">
          <template slot-scope="props">
            <table style="width: 100%" border="0px">
              <tr>
                <td style="width:100px">{{ props.row.date_birthday }}</td>
                <td style="width:100px"><b>TITULAR</b></td>
                <td style="width:100px">{{ props.row.first_name }}</td>
                <td style="width:100px">{{ props.row.last_name }}</td>
                <td style="width:100px">{{ props.row.num_doc }}</td>

                <td style="width:100px">
                  <el-button
                    size="mini"
                    type="primary"
                    @click="
                      handleSelectUser(
                        props.row.id,
                        props.row.first_name,
                        props.row.last_name,
                        props.row.employee_id,
			props.row.email
                      )
                    "
                    >Elegir</el-button
                  >
                </td>
              </tr>
            </table>

            <div v-for="item in patient" :key="item.id">
              <table style="width: 100%" border="0px">
                <tr>
                  <td style="width:100px">{{ item.birthdate }}</td>
                  <td style="width:100px">
                    <b>{{ item.relation_name }}</b>
                  </td>
                  <td style="width:100px">{{ item.first_name }}</td>
                  <td style="width:100px">{{ item.last_name }}</td>
                  <td style="width:100px">{{ item.num_doc }}</td>

                  <td style="width:100px">
                    <el-button
                      size="mini"
                      type="primary"
                      @click="
                        handleSelectFamily(
                          props.row.id,
                          item.id,
                          item.first_name,
                          item.last_name,
                          props.row.employee_id
                        )
                      "
                      >Elegir</el-button
                    >
                  </td>
                </tr>
              </table>
            </div>
            <!--<el-table :data="patient" style="width: 100%">
              <el-table-column width="117" prop="date_birthday">
              </el-table-column>
              <el-table-column width="117" prop="relation_name">
              </el-table-column>
              <el-table-column  width="220" prop="email">
              </el-table-column>
              <el-table-column width="125" prop="first_name">
              </el-table-column>
              <el-table-column  width="125" prop="last_name">
              </el-table-column>
              <el-table-column  width="100" prop="num_doc">
              </el-table-column>
              <el-table-column width="150" prop="geoname">
              </el-table-column>
            </el-table>-->

            <!--<el-table>
              <el-table-column> </el-table-column>
              <el-table-column prop="email"> </el-table-column>
              <el-table-column prop="first_name"> </el-table-column>
              <el-table-column prop="last_name"> </el-table-column>
              <el-table-column prop="num_doc"> </el-table-column>
              <el-table-column prop="geoname"> </el-table-column>
            </el-table>-->
          </template>
        </el-table-column>
        <!--<el-table-column align="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleSelectUser(scope.$index, scope.row)"
              >Elegir</el-button
            >
          </template>
        </el-table-column>--->
      </el-table>
    </el-dialog>
    <el-dialog
      title="Especialistas"
      :visible.sync="dialogTableDoctor"
      width="52%"
      :close-on-click-modal="false"
    >
      <div class="row">
        <div class="col-md-6">
          <el-input
            placeholder="Datos Usuario"
            v-model="filterDoctors"
            class="input-with-select"
            size="small"
            style="margin-bottom:10px"
            clearable
          >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
          <!-- <form>
            <div class="form-group">
              <div class="input-group">
                <input
                  v-model="filterDoctors"
                  type="text"
                  placeholder="Escribe aquí.."
                  class="form-control"
                />
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="nc-icon nc-zoom-split"></i>
                  </span>
                </div>
              </div>
            </div>
          </form> -->
        </div>
      </div>
      <el-table
        :data="doctors_appointment"
        style="width: 100%"
        height="250"
        border
      >
        <el-table-column label="DOCTOR" width="250" prop="doctor_name">
        </el-table-column>
        <el-table-column label="ESPECIALIDAD" width="290" prop="speciality">
        </el-table-column>
        <el-table-column width="100" align="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleSelectDoctor(scope.$index, scope.row)"
              >Elegir</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  TimeSelect,
  Autocomplete,
  Popover,
  Tag,
  Descriptions,
  DescriptionsItem,
  Button,
  Dialog,
  Calendar,
  Tooltip,
  Input,
  MessageBox,
  Dropdown,
  Loading,
  DropdownMenu,
  DropdownItem,
  Form
} from "element-ui";
import {
  Pagination as LPagination,
  FormGroupInput as FgInput,
  StatsCard
} from "src/components/index";
import axios from "axios";
import Swal from "sweetalert2";

//Librayr
import dayjs from "dayjs";
require("dayjs/locale/es");

import { extend } from "vee-validate";
import { required, email, min, max } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);
extend("min", min);
extend("max", max);

export default {
  name: "EditarTeleconsulta",
  components: {
    LPagination,
    FgInput,
    StatsCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Popover.name]: Popover,
    [Tag.name]: Tag,
    [Descriptions.name]: Descriptions,
    [DescriptionsItem.name]: DescriptionsItem,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [TimeSelect.name]: TimeSelect,
    [Autocomplete.name]: Autocomplete,
    [Calendar.name]: Calendar,
    [Tooltip.name]: Tooltip,
    [Input.name]: Input,
    [MessageBox.name]: MessageBox,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Form.name]: Form
  },
  props: {
    edit_teleconsulta: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      stylehorarios: "",
      popover_horarios: false,
      placeholder: "Email",
      hora: "",
      hora2: "",
      number_mes: "",
      medical_attention_id: 0,
      teleconsulta_files: [],
      popover_visible: false,
      date: new Date(),
      currentDay: dayjs(this.date).format("MM-DD"),
      currentMonth: dayjs(this.date).format("MM"),
      currentYear: dayjs(this.date).format("YYYY"),
      doctor: "",
      information_user: "",
      fecha: "",
      horario: "",
      filterUsers: null,
      filterDoctors: null,
      dialogTableDoctor: false,
      dialogTableUsuarios: false,
      //search: "",
      //state: "",
      //timeout: null,
      countries: [],
      patient: [],
      schedules: [],
      schedules_map: [],
      currentSchedules: [],
      currentSchedulesSelectedDay: [],
      horarios: [],
      doctors_appointment: [],
      users: [],
      formUpdate: {
        doctor: 0,
        user: null,
        patient: null,
        employee: null,
        scheduled_date: "",
        subject: ""
        //status: "scheduled"
      },
      formUpdateLoad: {
        doctor: 0,
        user: null,
        patient: null,
        employee: null,
        scheduled_date: "",
        subject: ""
        //status: "scheduled"
      },
      formGetSchedules: {
        doctor_id: "",
        date: null,
        employee_id: ""
      },
      ddTestVm: {
        originalValue: [],
        ddTestSelectedOption: "",
        disabled: false,
        readonly: false,
        visible: true,
        color: "",
        options: [
          {
            value: "name",
            text: "Nombres/Apellidos"
          },
          {
            value: "num_doc",
            text: "Número de Documento"
          },
          {
            value: "email",
            text: "email"
          }
        ]
      }
    };
  },
  created: function() {
    this.$nextTick(() => {
      //  Click on the previous month
      let title_calendar_prev = document.querySelector(".el-calendar__title");
      let cadena_previous = "";
      let prevBtn = document.querySelector(
        ".el-calendar__button-group .el-button-group>button:nth-child(1)"
      );
      prevBtn.addEventListener("click", () => {
        cadena_previous = title_calendar_prev.innerHTML;
        let mes = cadena_previous.substr(11, 15);
        this.getNumberMesCalendar(mes.replace(/\s+/g, ""));

        let day =
          cadena_previous.substr(4, 7) + "-" + this.number_mes + "-" + "01";
        this.formGetSchedules.date = day.replace(/\s+/g, "");
        this.getSchedulesSiguienteMes(day.replace(/\s+/g, ""));
      });

      //Click Next
      let title_calendar_next = document.querySelector(".el-calendar__title");
      let cadena_next = "";
      let nextBtn = document.querySelector(
        ".el-calendar__button-group .el-button-group>button:nth-child(3)"
      );
      nextBtn.addEventListener("click", () => {
        cadena_next = title_calendar_next.innerHTML;

        let mes = cadena_next.substr(11, 15);
        this.getNumberMesCalendar(mes.replace(/\s+/g, ""));

        let day = cadena_next.substr(4, 7) + "-" + this.number_mes + "-" + "01";
        this.formGetSchedules.date = day.replace(/\s+/g, "");
        this.getSchedulesSiguienteMes(day.replace(/\s+/g, ""));
      });

      //Click today
      let title_calendar_today = document.querySelector(".el-calendar__title");
      let cadena_today = "";
      let todayBtn = document.querySelector(
        ".el-calendar__button-group .el-button-group>button:nth-child(2)"
      );
      todayBtn.addEventListener("click", () => {
        cadena_today = title_calendar_today.innerHTML;

        let mes = cadena_today.substr(11, 15);
        this.getNumberMesCalendar(mes.replace(/\s+/g, ""));

        let day =
          cadena_today.substr(4, 7) + "-" + this.number_mes + "-" + "01";
        this.formGetSchedules.date = day.replace(/\s+/g, "");
        this.getSchedulesSiguienteMes(day.replace(/\s+/g, ""));
      });
    });
  },
  watch: {
    filterUsers() {
      this.getUsersPatients();
    },
    /*filterDoctors() {
      this.getDoctorsForAppointment();
    }*/
  },
  computed: {
    formSaveDisabled() {
      var status = true;
      if (this.formUpdate.employee != this.formUpdateLoad.employee) {
        status = false;
      }
      if (this.formUpdate.subject != this.formUpdateLoad.subject) {
        status = false;
      }
      if (this.formUpdate.patient != this.formUpdateLoad.patient) {
        status = false;
      }
      if (this.formUpdate.doctor != this.formUpdateLoad.doctor) {
        status = false;
      }
      if (this.fecha != this.formUpdateLoad.fecha) {
        status = false;
      }
      if (this.horario != this.formUpdateLoad.horario) {
        status = false;
      }
      
      return status;
      
    }
  },
  mounted() {
    this.getTeleconsultaById();
    //this.setupFileInput();
    this.getCountries();
    //this.getDoctorsForAppointment();
    this.getUsersPatients();

    if (!sessionStorage.isLogged) {
      this.$router.go(-1);
      return;
    }
  },
  methods: {
    selecthorario(horario) {
      this.horario = horario;
      this.popover_horarios = false;
      console.log(horario);
    },
    validatePopoverHorarios() {
      if (this.formUpdate.doctor != 0) {
        if (this.popover_horarios) {
          this.popover_horarios = false;
        } else {
          this.popover_horarios = true;
        }
      }
    },
    handleCommand(filtro_select) {
      if (filtro_select == "name") {
        this.placeholder = "Nombre o Apellido";
      } else if (filtro_select == "num_doc") {
        this.placeholder = "Num Doc";
      } else if (filtro_select == "email") {
        this.placeholder = "Email";
      }

      this.$refs.filterUsers.focus();
      this.filtro_select = filtro_select;
    },
    getTeleconsultaById() {
      if (this.edit_teleconsulta.patient_detail == null) {
        this.information_user =
          this.edit_teleconsulta.user_detail.first_name +
          " " +
          this.edit_teleconsulta.user_detail.last_name;
      } else {
        this.information_user =
          this.edit_teleconsulta.patient_detail.first_name +
          " " +
          this.edit_teleconsulta.patient_detail.last_name;
        this.formUpdate.patient = this.edit_teleconsulta.patient_detail.id;
        
        this.formUpdateLoad.patient = this.edit_teleconsulta.patient_detail.id;
      }

      this.doctor =
        this.edit_teleconsulta.doctor_detail.first_name +
        " " +
        this.edit_teleconsulta.doctor_detail.last_name;
      this.fecha = this.edit_teleconsulta.scheduled_date;
      console.log("this.fecha", this.fecha);
      this.hora = this.edit_teleconsulta.hora;
      console.log("this.hora", this.hora);
      this.hora2 = this.edit_teleconsulta.hora2;

      this.formUpdate.subject = this.edit_teleconsulta.subject_detail.subject;
      this.formUpdate.doctor = this.edit_teleconsulta.doctor_detail.id;
      this.formUpdate.user = this.edit_teleconsulta.user_detail.id;

      this.formUpdateLoad.subject = this.edit_teleconsulta.subject_detail.subject;
      this.formUpdateLoad.doctor = this.edit_teleconsulta.doctor_detail.id;
      this.formUpdateLoad.user = this.edit_teleconsulta.user_detail.id;
      this.formUpdateLoad.fecha = this.fecha;
      this.formUpdateLoad.horario = this.horario;

      if (this.edit_teleconsulta.employee_detail != null) {
        this.formUpdate.employee = this.edit_teleconsulta.employee_detail.id;

        this.formUpdateLoad.employee = this.edit_teleconsulta.employee_detail.id;
      }
      console.log("this.formUpdateLoad", this.formUpdateLoad);

      //this.formUpdate.patient = this.edit_teleconsulta.patient_detail.id;

      this.medical_attention_id = this.edit_teleconsulta.id;

      this.formGetSchedules.employee_id = this.edit_teleconsulta.employee_detail.id;

      this.calendarEdit(this.formUpdate.doctor);
    },
    calendarEdit(doctor_id) {
      this.horario = "";

      if (this.horarios.length != 0) {
        this.horarios = [];
      }

      this.formUpdate.doctor = doctor_id;
      this.dialogTableDoctor = false;

      let date_shedules = dayjs(this.date).format("YYYY-MM-DD");

      this.formGetSchedules.doctor_id = doctor_id;
      this.formGetSchedules.date = date_shedules;

      this.getSchedules();
    },
    getNumberMesCalendar(mes) {
      switch (mes) {
        case "enero":
          this.number_mes = "01";
          break;
        case "febrero":
          this.number_mes = "02";
          break;
        case "marzo":
          this.number_mes = "03";
          break;
        case "abril":
          this.number_mes = "04";
          break;
        case "mayo":
          this.number_mes = "05";
          break;
        case "junio":
          this.number_mes = "06";
          break;
        case "julio":
          this.number_mes = "07";
          break;
        case "agosto":
          this.number_mes = "08";
          break;
        case "septiembre":
          this.number_mes = "09";
          break;
        case "octubre":
          this.number_mes = "10";
          break;
        case "noviembre":
          this.number_mes = "11";
          break;
        case "diciembre":
          this.number_mes = "12";
          break;
      }
      //return number_mes;
    },
    remark(data) {
      let str = "";
      if (data.isSelected) {
        str = "is-selected";
      }
      return str;
    },
    calendar_mes(data) {
      this.formGetSchedules.date = data.day;
      this.getSchedulesSiguienteMes(data.day);
      //console.log(data.day);
    },
    // addNewFile() {
    //   const inputFile = document.getElementById("files");
    //   inputFile.click();
    // },
    // setupFileInput() {
    //   document
    //     .getElementById("files")
    //     .addEventListener("change", this.fileUpload, false);
    // },
    // fileUpload(evt) {
    //   this.teleconsulta_files.push(evt.target.files[0]);
    // },
    // deleteFile(index) {
    //   this.teleconsulta_files.splice(index, 1);
    // },
    // async uploadFile(file) {
    //   try {
    //     let token = null;
    //     token = sessionStorage.token;

    //     let fd = new FormData();
    //     fd.append("file", file);
    //     fd.append("medical_attention_id", this.medical_attention_id);
    //     const res = await axios.post(
    //       `https://devapi.smartdoctor.pe/v1/pro/medical_attention/file`,
    //       fd,
    //       {
    //         headers: {
    //           Accept: "application/json",
    //           "Content-Type": "application/json",
    //           Authorization: `token ${token}`
    //         }
    //       }
    //     );
    //   } catch (error) {
    //     console.dir(error);
    //     return false;
    //   }
    // },
    // async loadFiles() {
    //   try {
    //     this.teleconsulta_files.forEach(file => {
    //       this.uploadFile(file);
    //     });
    //   } catch (error) {
    //     console.dir(error);
    //     return false;
    //   }
    // },
    addSchedules() {
      if (this.horarios.length != 0) {
        this.horarios = [];

        this.currentSchedulesSelectedDay.forEach(child => {
          child.schedule.forEach(detalle => {
            this.horarios.push(detalle);
          });
        });
      } else {
        this.currentSchedulesSelectedDay.forEach(child => {
          child.schedule.forEach(detalle => {
            this.horarios.push(detalle);
          });
        });
      }

      if (this.horarios.length <= 5) {
        this.stylehorarios =
          "display: grid;  grid-template-columns: repeat(" +
          this.horarios.length +
          ", 1fr); grid-gap: 12px;";
      } else {
        this.stylehorarios =
          "display: grid;  grid-template-columns: repeat(6, 1fr); grid-gap: 12px;";
      }
    },
    validatePopover() {
      if (this.formUpdate.doctor != 0) {
        if (this.popover_visible) {
          this.popover_visible = false;
        } else {
          this.popover_visible = true;
        }
      }
    },
    handleClose(done) {
      MessageBox.confirm("¿Estás segura de cerrar este diálogo?")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    expandChange(row, expandedRows) {
      if (expandedRows.length > 0) {
        this.getFamiliaresByUsuario(row.id);
      }
    },
    currentSchedulesList(date) {
      var currentSchedules = this.currentSchedules;
      var results = dayjs(date).format("DD")
        ? currentSchedules.filter(this.createFilter(dayjs(date).format("DD")))
        : currentSchedules;
      this.currentSchedulesSelectedDay = results;
    },
    calendarClick(event) {
      this.popover_visible = false;
      this.horario = "";
      this.evento_horario();
      this.currentSchedulesList(event.day);
      this.addSchedules();
      this.fecha = event.day;
    },
    handleSelectUser(id, nombre, apellido, employee_id,email) {
      this.formUpdate.user = id;
      this.formUpdate.patient = null;

      if (
        this.edit_teleconsulta.attention_type == "Teleconsulta Programada B2C"
      ) {
        this.formUpdate.employee = null;
      } else {
        this.formUpdate.employee = employee_id;
      }

      console.log(this.formUpdate.employee);

       if (nombre != "" && apellido != "") {
        this.information_user = nombre + " " + apellido;
      } else if (nombre != "") {
        this.information_user = nombre;
      } else if (apellido != "") {
        this.information_user = apellido;
      } else {
        this.information_user = email;
      }

      this.dialogTableUsuarios = false;
    },
    handleSelectFamily(user_id, id, nombre, apellido, employee_id) {
      this.formUpdate.user = user_id;
      this.formUpdate.patient = id;

      if (
        this.edit_teleconsulta.attention_type == "Teleconsulta Programada B2C"
      ) {
        this.formUpdate.employee = null;
      } else {
        this.formUpdate.employee = employee_id;
      }

      this.information_user = nombre + " " + apellido;

      this.dialogTableUsuarios = false;
    },
    handleSelectDoctor(index, row) {
      this.horario = "";
      this.fecha = "";

      if (this.horarios.length != 0) {
        this.horarios = [];
      }

      this.formUpdate.doctor = row.doctor_id;
      this.doctor = row.doctor_name;
      this.dialogTableDoctor = false;

      let date_shedules = dayjs(this.date).format("YYYY-MM-DD");

      this.formGetSchedules.doctor_id = row.doctor_id;
      this.formGetSchedules.date = date_shedules;

      this.getSchedules();

      console.log(index, this.formUpdate.doctor);
    },
    searchUsers() {
      this.dialogTableUsuarios = true;
    },
    searchDoctors() {
      this.dialogTableDoctor = true;
    },
    createFilter(queryString) {
      return currentSchedules => {
        return (
          currentSchedules.day
            .toString()
            .padStart(2, "0")
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      console.log(item.doctor_id);
    },

    async getCountries() {
      try {
        const res = await axios.get(`v1/countries`, {
          params: {}
        });
        this.countries = res.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getSchedules() {
      let token = null;
      token = sessionStorage.token;

      try {
        const res = await axios.post(
          `v1/pro/user/doctors/schedule`,
          this.formGetSchedules,
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              authorization: `token ${token}`
            }
          }
        );
        this.schedules = res.data.data;
        this.evento_calendario();
      } catch (error) {
        console.error(error);
      }
    },
    evento_calendario() {
      let date = new Date();
      let mes_actual = dayjs(date).format("MM");

      const schedulesMap = this.schedules;
      this.schedules_map = schedulesMap.map(sch => {
        return {
          months: mes_actual,
          days: sch.day.toString().padStart(2, "0"),
          things: "Horarios"
        };
      });
    },
    async getSchedulesSiguienteMes(day) {
      let token = null;
      token = sessionStorage.token;

      try {
        const res = await axios.post(
          `v1/pro/user/doctors/schedule`,
          this.formGetSchedules,
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              authorization: `token ${token}`
            }
          }
        );
        this.schedules = res.data.data;
        this.evento_calendario_Siguiente_Mes(day);
      } catch (error) {
        console.error(error);
      }
    },
    evento_calendario_Siguiente_Mes(date) {
      console.log(date);
      let mes_actual = dayjs(date).format("MM");

      console.log("Mes Siguiente: " + date);
      console.log(mes_actual);

      const schedulesMap = this.schedules;
      this.schedules_map = schedulesMap.map(sch => {
        return {
          months: mes_actual,
          days: sch.day.toString().padStart(2, "0"),
          things: "Horarios"
        };
      });
    },
    evento_horario() {
      const daily_schedule = this.schedules;
      this.currentSchedules = daily_schedule;
      /*this.currentSchedules = daily_schedule.map(sch => {
        return {
          time_init: sch.schedule[0],
          day: sch.day.toString().padStart(2, "0"),
          time_end: sch.schedule[sch.schedule.length - 1]
        };
      });*/
    },
    async getDoctorsForAppointment() {
      let doctor_name = null;
      let token = null;
      token = sessionStorage.token;

      if (this.filterDoctors) {
        doctor_name = this.filterDoctors;
      }

      try {
        const res = await axios.get(`v1/pro/user/doctors/appointment`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: 1,
            size: 15,
            doctor_name: doctor_name
          }
        });
        this.doctors_appointment = res.data.data.rows;
        console.log('this.doctors_appointment ', this.doctors_appointment )
      } catch (error) {
        console.error(error);
      }
    },
    async getFamiliaresByUsuario(id_usuario) {
      let token = null;
      token = sessionStorage.token;
      try {
        const res = await axios.get(`v1/user/${id_usuario}/family`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            //id: id_usuario
          }
        });
        this.patient = res.data.data.rows;
      } catch (error) {
        console.error(error);
      }
    },
    async getUsersPatients() {
      let token = null;
      let email = null;
      let num_doc = null;
      let name = null;
      token = sessionStorage.token;

      if (this.filterUsers) {
        if (this.filtro_select == "email") {
          email = this.filterUsers;
        } else if (this.filtro_select == "num_doc") {
          num_doc = this.filterUsers;
        } else if (this.filtro_select == "name") {
          name = this.filterUsers;
        } else {
          email = this.filterUsers;
        }
      }

      try {
        const res = await axios.get(`v1/users`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: 1,
            size: 15,
            name: name,
            num_doc: num_doc,
            email: email
          }
        });
        this.users = res.data.data.rows;
      } catch (error) {
        console.error(error);
      }
    },
    async PayServiceActualizado() {
      let token = null;
      token = sessionStorage.token;
      try {
        const res = await axios.get(`v1/pro/medical_attention/pay/`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            token: 1,
            service_code: "002",
            status: "scheduled"
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    saveAtencion() {
      this.UpdateMedicalAttention();
    },
    async UpdateMedicalAttention() {
      let token = null;
      token = sessionStorage.token;

      if (this.horarios == 0) {
        this.formUpdate.scheduled_date = this.fecha + " " + this.hora2;
      } else {
        this.formUpdate.scheduled_date = this.fecha + " " + this.horario;
      }
      let id = this.medical_attention_id;

      try {
        Swal.fire({
          title: "¿Desea actualizar la cita?",
          text:
            "Una vez programada la cita, la fecha y horario serán visibles para el paciente.",
          ///icon: 'warning',
          confirmButtonColor: "#23CCEF",
          cancelButtonColor: "#AEB6BF",
          cancelButtonText: "CANCELAR",
          confirmButtonText: "CONFIRMAR",

          showCancelButton: true
        }).then(result => {
          if (result) {
            if (result.value) {
              let loadingInstance1 = Loading.service({
                fullscreen: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
              });
              axios
                .put(`v1.1/pro/medical-attention/${id}`, this.formUpdate, {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `token ${token}`
                  }
                })
                .then(response => {
                  ///console.log(response.status);
                  if (response.status == 200) {
                    setTimeout(() => {
                      loadingInstance1.close();
                      localStorage.setItem("success_teleconsulta", true);
                      this.$router.go();
                    }, 500);
                  } else {
                    loadingInstance1.close();
                    Swal.fire("Error", response.data.message, "error");
                  }
                }).catch(error => {
                  loadingInstance1.close();
                  console.log('error', error);
                  //this.listError = error.response.data.errors.scheduled_date;
                  Swal.fire("Error", error.response.data.errors.scheduled_date.toString(), "error");
                });
            }
          }
        });
      } catch (error) {
        loadingInstance1.close();
        console.error(error);
      }
    },
    regresarListTeleconsultas() {
      this.$router.go();
    }
  }
};
</script>
<style lang="css">
.horario {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 8px;
}
.el-calendar-table__row .current .calendar-day {
  text-align: center;
  color: #202535;
  line-height: 30px;
  font-size: 36px;
  font-family: " Ses blackbody CN Bold", " Ses blackbody CN Regular",
    " Ses blackbody CN";
  font-weight: 700;
  margin-top: 15px;
}
.el-calendar-table__row .prev .calendar-day,
.el-calendar-table__row .next .calendar-day {
  text-align: center;
  /* color: #202535; */
  line-height: 30px;
  font-size: 36px;
  font-family: " Ses blackbody CN Bold", " Ses blackbody CN Regular",
    " Ses blackbody CN";
  font-weight: 700;
  margin-top: 15px;
}
/* .el-calendar-table__row .current,.el-calendar-table__row .prev,.el-calendar-table__row .next{
height: 100px;
} */
​ .is-selected {
  font-family: " Ses blackbody CN Bold", " Ses blackbody CN Regular",
    " Ses blackbody CN";
  font-weight: 700;
  font-size: 24px;
  margin-top: 5px;
  text-decoration: none;
  /* color: rgb(104, 143, 235); */
}
.is-selected-select {
  color: rgb(104, 143, 235);
}
.is-selected-warn {
  color: rgb(240, 178, 12);
}
.is-selected-error {
  color: rgb(255, 0, 0);
}
/* .el-calendar-table tr td:first-child {
   border-left: none;
}
.el-calendar-table tr:first-child td {
   border-top: none;
} */
.el-calendar-table td {
  vertical-align: top;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
}
.el-calendar-table .el-calendar-day {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px 0 15px 0;
  height: 50px;
}
.el-calendar-table .current .el-calendar-day:hover {
  cursor: pointer;
  background-color: rgb(104, 143, 235);
  color: #fff;
  border-radius: 10px;
}
.el-calendar-table .prev .el-calendar-day:hover,
.el-calendar-table .next .el-calendar-day:hover {
  background-color: #fff;
}
.el-calendar-table .current .el-calendar-day:hover .calendar-day,
.el-calendar-table .current .el-calendar-day:hover .is-selected {
  color: #fff;
}

.lista {
  background-color: #f1ece9;
}
.el-table__expand-column .cell {
  border: 0px solid;
  background-color: #f1ece9;
  border-radius: 50px;
}
</style>
