<template>
  <div>
    <div class="row">
      <div class="col-xl-6 col-md-6">
        <div class="card">
          <el-date-picker
            v-model="filterDates"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="-"
            start-placeholder="Desde"
            end-placeholder="Hasta"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card">
          <el-select v-model="filterCountry" clearable placeholder="País">
            <el-option
              v-for="item in countries"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-3 col-md-6">
        <stats-card :title="`${pagination.total}`" subTitle="Usuarios">
          <div slot="header" class="icon-warning">
            <i class="nc-icon nc-chart text-warning"></i>
          </div>
          <template slot="footer">
            <i class="fa fa-refresh"></i>Updated now
          </template>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card :title="`${pagination2.total}`" subTitle="Teleconsultas">
          <div slot="header" class="icon-success">
            <i class="nc-icon nc-light-3 text-success"></i>
          </div>
          <template slot="footer">
            <i class="fa fa-calendar-o"></i>Last day
          </template>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card :title="`${pagination3.total}`" subTitle="Transacciones">
          <div slot="header" class="icon-danger">
            <i class="nc-icon nc-vector text-danger"></i>
          </div>
          <template slot="footer">
            <i class="fa fa-clock-o"></i>Last day
          </template>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card :title="`${anticipation.hours}`" subTitle="Anticipación">
          <div slot="header" class="icon-danger">
            <i class="nc-icon nc-favourite-28 text-danger"></i>
          </div>
          <template slot="footer">
            <i class="fa fa-clock-o"></i>Last day
          </template>
        </stats-card>
      </div>
      
    </div>

    <div class="row">
      <div class="col-md-6">
        <card
          title="Medios de pago"
        >
          <div>
            <div class="table-responsive table-full-width">
              <el-table
                stripe
                style="width: 100%; overflow: auto;"
                :data="medios_pago"                 
                empty-text="Sin datos"
              >
                <el-table-column width="80"
                  type="index" label="#"
                  :index="indexMethod">
                </el-table-column>
                <el-table-column
                  v-for="column in tableColumns4"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
        </card>
      </div>

      <div class="col-md-6">
        <card
          title="Horarios"
        >
          <div>
            <div class="table-responsive table-full-width">
              <el-table
                stripe
                style="width: 100%; overflow: auto;"
                :data="horarios"                 
                empty-text="Sin datos"
              >
                <el-table-column width="80"
                  type="index" label="#"
                  :index="indexMethod">
                </el-table-column>
                <el-table-column
                  v-for="column in tableColumns5"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <card
          title="Usuarios Smart Doctor"
          :subTitle="`N° de registros totales: ${pagination.total}`"
        >
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="flex cursor-pointer" style="cursor: pointer;" @click="download_excel_1">
                <img src="static/icons/excel.svg" alt="download_excel" />
                <span style="margin-left:10px;">Exportar</span>
              </div>
              <!--<fg-input>
                <el-date-picker
                  v-model="registrosDates"
                  type="daterange"
                  placeholder="Datetime picker here"
                >
                </el-date-picker>
              </fg-input>-->
            </div>
            <div class="table-responsive table-full-width">
              <el-table
                stripe
                style="width: 100%; overflow: auto;"
                :data="registros"
                border 
                empty-text="Sin datos"
              >
                <!--<el-table-column type="expand">
                  <template slot-scope="props">
                      <el-descriptions class="" title="Más información" :column="3" border>

                        <el-descriptions-item label="DNI">{{ props.row.dni }}</el-descriptions-item>
                        <el-descriptions-item label="Sexo">{{ props.row.genre }}</el-descriptions-item>
                        <el-descriptions-item label="Ubicación">{{ props.row.geoname }}</el-descriptions-item>
                      </el-descriptions>
                  </template>          
                </el-table-column>-->
                <el-table-column width="80"
                  type="index" label="#"
                  :index="indexMethod">
                </el-table-column>
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
                <el-table-column
                  label="..."
                  width="80">
                  <template slot-scope="props">
                    <el-popover trigger="click" placement="left">                      
                        <el-descriptions class="" title="Más información" :column="1" border>
                        <el-descriptions-item label="DNI">{{ props.row.dni }}</el-descriptions-item>
                        <el-descriptions-item label="Sexo">{{ props.row.genre }}</el-descriptions-item>
                        <el-descriptions-item label="Ubicación">{{ props.row.geoname }}</el-descriptions-item>
                      </el-descriptions>
                      <div slot="reference" class="name-wrapper">
                        <el-button size="small" type="text">Ver más</el-button>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <!-- <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div> -->
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <card
          title="Teleconsultas Smart Doctor"
          :subTitle="`N° de teleconsultas : ${pagination2.total}`"
        >
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="flex cursor-pointer" style="cursor: pointer;" @click="download_excel_2">
                <img src="static/icons/excel.svg" alt="download_excel" />
                <span style="margin-left:10px;">Exportar</span>
              </div>
              <!--<fg-input>
                <el-date-picker
                  v-model="teleconsultasDates"
                  type="daterange"
                  placeholder="Datetime picker here"
                >
                </el-date-picker>
              </fg-input>-->
            </div>
            <div class="table-responsive table-full-width">
              <el-table
                stripe
                style="width: 100%; overflow: auto;"
                :data="teleconsultas"
                border
                empty-text="Sin datos"
              >
                <el-table-column width="80"
                  type="index" label="#"
                  :index="indexMethod2">
                </el-table-column>
                <el-table-column
                  v-for="column in tableColumns2"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
                <el-table-column
                  label="..."
                  width="80">
                  <template slot-scope="props">
                    <el-popover trigger="click" placement="left">                      
                        <el-descriptions class="" title="Más información" :column="1" border>
                        <el-descriptions-item label="Correo">{{ props.row.user_email }}</el-descriptions-item>
                        <el-descriptions-item label="Celular">{{ props.row.user_phone }}</el-descriptions-item>
                        <el-descriptions-item label="DNI">{{ props.row.patient_dni }}</el-descriptions-item>
                        <el-descriptions-item label="Sexo">{{ props.row.patient_genre }}</el-descriptions-item>
                        <el-descriptions-item label="Edad">{{ props.row.patient_age }}</el-descriptions-item>
                        <el-descriptions-item label="Parentesco">{{ props.row.relation }}</el-descriptions-item>
                        <el-descriptions-item label="Ubicación">{{ props.row.patient_geoname }}</el-descriptions-item>
                      </el-descriptions>
                      <div slot="reference" class="name-wrapper">
                        <el-button size="small" type="text">Ver más</el-button>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <!-- <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div> -->
            <l-pagination
              class="pagination-no-border"
              v-model="pagination2.currentPage"
              :per-page="pagination2.perPage"
              :total="pagination2.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <card
          title="Transacciones Smart Doctor"
          :subTitle="`N° de pagos totales: ${pagination3.total}`"
        >
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="flex cursor-pointer" style="cursor: pointer;" @click="download_excel_3">
                <img src="static/icons/excel.svg" alt="download_excel" />
                <span style="margin-left:10px;">Exportar</span>
              </div>
              <!--<fg-input>
                <el-date-picker
                  v-model="transaccionesDates"
                  type="daterange"
                  placeholder="Datetime picker here"
                >
                </el-date-picker>
              </fg-input>-->
            </div>
            <div class="table-responsive table-full-width">
              <el-table
                stripe
                style="width: 100%; overflow: auto;"
                :data="transacciones"
                border
                empty-text="Sin datos"
              >
                <el-table-column width="80"
                  type="index" label="#"
                  :index="indexMethod3">
                </el-table-column>
                <el-table-column
                  v-for="column in tableColumns3"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
                <el-table-column
                  label="..."
                  width="80">
                  <template slot-scope="props">
                    <el-popover trigger="click" placement="left">                      
                        <el-descriptions class="" title="Más información" :column="1" border>
                        <el-descriptions-item label="Nombres">{{ props.row.user_first_name }}</el-descriptions-item>
                        <el-descriptions-item label="Apellidos">{{ props.row.user_last_name }}</el-descriptions-item>
                        <el-descriptions-item label="Celular">{{ props.row.user_phone }}</el-descriptions-item>
                        <el-descriptions-item label="Sexo">{{ props.row.patient_genre }}</el-descriptions-item>
                        <el-descriptions-item label="Edad">{{ props.row.patient_age }}</el-descriptions-item>
                      </el-descriptions>
                      <div slot="reference" class="name-wrapper">
                        <el-button size="small" type="text">Ver más</el-button>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <!-- <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div> -->
            <l-pagination
              class="pagination-no-border"
              v-model="pagination3.currentPage"
              :per-page="pagination3.perPage"
              :total="pagination3.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, DatePicker, Popover, Tag, Descriptions, DescriptionsItem, Button } from "element-ui";
import {
  Pagination as LPagination,
  FormGroupInput as FgInput,
  StatsCard
} from "src/components/index";
import axios from "axios";
var dayjs = require("dayjs");

export default {
  name: "Dashboard",
  components: {
    LPagination,
    FgInput,
    StatsCard,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Popover.name]: Popover,
    [Tag.name]: Tag,
    [Descriptions.name]: Descriptions,
    [DescriptionsItem.name]: DescriptionsItem,
    [Button.name]: Button
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      anticipation: {
        hours: 0
      },
      tableColumns: [
        /*{
          prop: "id",
          label: "N°",
          minWidth: 80
        },*/
        {
          prop: "created",
          label: "Fecha registro",
          minWidth: 100
        },
        {
          prop: "first_name",
          label: "Nombres",
          minWidth: 150
        },
        {
          prop: "last_name",
          label: "Apellidos",
          minWidth: 150
        },
        /*{
          prop: "dni",
          label: "DNI",
          minWidth: 100
        },*/
        {
          prop: "email",
          label: "Correo",
          minWidth: 250
        },
        {
          prop: "phone",
          label: "Celular",
          minWidth: 120
        },
        /*{
          prop: "genre",
          label: "Sexo",
          minWidth: 100
        },*/
        {
          prop: "age",
          label: "Edad",
          minWidth: 70
        },
        /*{
          prop: "geoname",
          label: "Ubicacion",
          minWidth: 250
        },*/
        {
          prop: "country",
          label: "Pais",
          minWidth: 120
        }
      ],
      pickerOptions: {
          shortcuts: [{
            text: '01 semana',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '01 mes',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '03 meses',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },      
      registros: [],
      registrosDates: null,
      //Teleconsultas
      pagination2: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      tableColumns2: [
        /*{
          prop: "id",
          label: "N°",
          minWidth: 80
        },*/
        {
          prop: "scheduled_date",
          label: "Fecha teleconsulta",
          minWidth: 100
        },
        /*{
          prop: "user_email",
          label: "Correo usuario",
          minWidth: 250
        },*/
        {
          prop: "patient_first_name",
          label: "Nombre Paciente",
          minWidth: 110
        },
        {
          prop: "patient_last_name",
          label: "Apellido Paciente",
          minWidth: 110
        },
        /*{
          prop: "user_phone",
          label: "Celular",
          minWidth: 120
        },
        {
          prop: "patient_dni",
          label: "DNI paciente",
          minWidth: 100
        },
        {
          prop: "patient_genre",
          label: "Sexo paciente",
          minWidth: 100
        },
        {
          prop: "patient_age",
          label: "Edad paciente",
          minWidth: 90
        },
        {
          prop: "relation",
          label: "Parentesco",
          minWidth: 120
        },*/
        {
          prop: "doctor_name",
          label: "Médico",
          minWidth: 160
        },
        {
          prop: "doctor_speciality",
          label: "Especialidad",
          minWidth: 150
        },
        /*{
          prop: "patient_geoname",
          label: "Ubicación",
          minWidth: 220
        },*/
        {
          prop: "country",
          label: "País",
          minWidth: 100
        }
      ],
      teleconsultas: [],
      teleconsultasDates: null,
      //Transacciones
      pagination3: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      tableColumns3: [
        /*{
          prop: "id",
          label: "N°",
          minWidth: 80
        },*/
        {
          prop: "created",
          label: "Fecha pago",
          minWidth: 110
        },
        {
          prop: "user_email",
          label: "Correo usuario",
          minWidth: 240
        },
        /*{
          prop: "user_first_name",
          label: "Nombre",
          minWidth: 150
        },
        {
          prop: "user_last_name",
          label: "Apellido",
          minWidth: 150
        },
        {
          prop: "user_phone",
          label: "Celular",
          minWidth: 120
        },
        {
          prop: "user_genre",
          label: "Sexo",
          minWidth: 90
        },
        {
          prop: "user_age",
          label: "Edad",
          minWidth: 80
        },*/
        {
          prop: "gateway",
          label: "Medio de pago",
          minWidth: 120
        },
        {
          prop: "amount",
          label: "Monto",
          minWidth: 110
        },
        {
          prop: "promotion_code",
          label: "Cod. promoción",
          minWidth: 170
        },
        {
          prop: "subscription",
          label: "Subscripción",
          minWidth: 120
        },
        {
          prop: "doctor_discount",
          label: "DSCTO (%)",
          minWidth: 90
        },
        {
          prop: "total_cost",
          label: "Monto total",
          minWidth: 110
        },
        {
          prop: "country",
          label: "País",
          minWidth: 100
        }
      ],
      transacciones: [],
      transaccionesDates: null,
      tableColumns4: [
        {
          prop: "gateway_name",
          label: "Medio de pago",
          minWidth: 150
        },
        {
          prop: "total",
          label: "Total",
          minWidth: 80
        }
      ],
      tableColumns5: [
        {
          prop: "hour",
          label: "Hora",
          minWidth: 100
        },
        {
          prop: "total",
          label: "Total",
          minWidth: 80
        }
      ],
      medios_pago: [],
      filterDates: null,
      filterCountry: null,
      horarios: [],
      countries: []
    };
  },
  watch: {
    "pagination.currentPage"() {
      this.getRegistros();
    },
    registrosDates() {
      this.pagination.currentPage = 1;
      this.getRegistros();
    },
    "pagination2.currentPage"() {
      this.getTeleconsultas();
    },
    teleconsultasDates() {
      this.pagination2.currentPage = 1;
      this.getRegistros();
    },
    "pagination3.currentPage"() {
      this.getTransactions();
    },
    transaccionesDates() {
      this.pagination3.currentPage = 1;
      this.getTransactions();
    },
    filterDates() {
      this.pagination.currentPage = 1;
      this.getRegistros();
      this.pagination2.currentPage = 1;
      this.getTeleconsultas();
      this.pagination3.currentPage = 1;
      this.getTransactions();
      this.getAnticipation();
      this.getMediosPago();
      this.getHoras();
    },
    filterCountry() {
      this.pagination.currentPage = 1;
      this.getRegistros();
      this.pagination2.currentPage = 1;
      this.getTeleconsultas();
      this.pagination3.currentPage = 1;
      this.getTransactions();
      this.getAnticipation();
      this.getMediosPago();
      this.getHoras();
    },
  },
  mounted() {
    if (!sessionStorage.isLogged) {
      this.$router.go(-1);
      return;
    }
    this.getRegistros();
    this.getTeleconsultas();
    this.getTransactions();
    this.getAnticipation();
    this.getMediosPago();
    this.getHoras();
    this.getCountries();
  },
  methods: {
    async getRegistros() {
      try {
        let created_from = null;
        let created_to = null;
        if (this.filterDates) {
          created_from = dayjs(this.filterDates[0]).format("YYYY-MM-DD");
          created_to = dayjs(this.filterDates[1]).format("YYYY-MM-DD");
        }
        let country_id = null;
        if (this.filterCountry) {
          country_id = this.filterCountry;
        }
        const res = await axios.get(
          `https://api.smartdoctor.pe/v1/dashboard/users`,
          {
            params: {
              page: this.pagination.currentPage,
              size: 10,
              created_from,
              created_to,
              country_id
            }
          }
        );
        this.pagination.total = res.data.data.total_rows;
        this.registros = res.data.data.rows.map(el => ({
          ...el,
          created: dayjs(el.created).format("YYYY-MM-DD")
        }));;
      } catch (error) {
        console.error(error);
      }
    },
    async getTeleconsultas() {
      try {
        let date_from = null;
        let date_to = null;
        if (this.filterDates) {
          date_from = dayjs(this.filterDates[0]).format("YYYY-MM-DD");
          date_to = dayjs(this.filterDates[1]).format("YYYY-MM-DD");
        }
        let country_id = null;
        if (this.filterCountry) {
          country_id = this.filterCountry;
        }
        const res = await axios.get(
          `https://api.smartdoctor.pe/v1/dashboard/medical_attentions`,
          {
            params: {
              page: this.pagination2.currentPage,
              size: 10,
              date_from,
              date_to,
              country_id
            }
          }
        );
        this.pagination2.total = res.data.data.total_rows;
        this.teleconsultas = res.data.data.rows.map(el => ({
          ...el,
          scheduled_date: dayjs(el.scheduled_date).format("YYYY-MM-DD")
        }));;
      } catch (error) {
        console.error(error);
      }
    },
    async getTransactions() {
      try {
        let date_from = null;
        let date_to = null;
        if (this.filterDates) {
          date_from = dayjs(this.filterDates[0]).format("YYYY-MM-DD");
          date_to = dayjs(this.filterDates[1]).format("YYYY-MM-DD");
        }
        let country_id = null;
        if (this.filterCountry) {
          country_id = this.filterCountry;
        }
        const res = await axios.get(
          `https://api.smartdoctor.pe/v1/dashboard/payments`,
          {
            params: {
              page: this.pagination3.currentPage,
              size: 10,
              date_from,
              date_to,
              country_id
            }
          }
        );
        this.pagination3.total = res.data.data.total_rows;
        this.transacciones = res.data.data.rows.map(el => ({
          ...el,
          created: dayjs(el.created).format("YYYY-MM-DD"),
          amount: el.currency + " " + el.amount,
          total_cost: el.currency + " " + el.total_cost
        }));;
      } catch (error) {
        console.error(error);
      }
    },
    async getAnticipation() {
      try {
        let date_from = null;
        let date_to = null;
        if (this.filterDates) {
          date_from = dayjs(this.filterDates[0]).format("YYYY-MM-DD");
          date_to = dayjs(this.filterDates[1]).format("YYYY-MM-DD");
        }
        let country_id = null;
        if (this.filterCountry) {
          country_id = this.filterCountry;
        }
        const res = await axios.get(
          `https://api.smartdoctor.pe/v1/dashboard/payments/anticipation_time`,
          {
            params: {
              date_from,
              date_to,
              country_id
            }
          }
        );
        this.anticipation.hours = Math.round(res.data.data.average * 100) / 100 + " horas";
      } catch (error) {
        console.error(error);
      }
    },
    async getMediosPago() {
      try {
        let date_from = null;
        let date_to = null;
        if (this.filterDates) {
          date_from = dayjs(this.filterDates[0]).format("YYYY-MM-DD");
          date_to = dayjs(this.filterDates[1]).format("YYYY-MM-DD");
        }
        let country_id = null;
        if (this.filterCountry) {
          country_id = this.filterCountry;
        }
        const res = await axios.get(
          `https://api.smartdoctor.pe/v1/dashboard/payments/methods`,
          {
            params: {
              date_from,
              date_to,
              country_id
            }
          }
        );
        this.medios_pago = res.data.data.map(el => ({
          ...el
        }));;
      } catch (error) {
        console.error(error);
      }
    },
    async getHoras() {
      try {
        let date_from = null;
        let date_to = null;
        if (this.filterDates) {
          date_from = dayjs(this.filterDates[0]).format("YYYY-MM-DD");
          date_to = dayjs(this.filterDates[1]).format("YYYY-MM-DD");
        }
        let country_id = null;
        if (this.filterCountry) {
          country_id = this.filterCountry;
        }
        const res = await axios.get(
          `https://api.smartdoctor.pe/v1/dashboard/medical_attentions/schedules`,
          {
            params: {
              date_from,
              date_to,
              country_id
            }
          }
        );
        this.horarios = res.data.data.map(el => ({
          ...el,
          hour: el.hour + ":00"
        }));;
      } catch (error) {
        console.error(error);
      }      
    },
    async getCountries() {
      try {
        const res = await axios.get(
          `https://api.smartdoctor.pe/v1/countries`,
          {
            params: {}
          }
        );
        this.countries = res.data.data.map(el => ({
          ...el
        }));;
      } catch (error) {
        console.error(error);
      }      
    },
    indexMethod(index) {
      return (this.pagination.currentPage - 1) * this.pagination.perPage + (index + 1);
    },
    indexMethod2(index) {
      return (this.pagination2.currentPage - 1) * this.pagination2.perPage + (index + 1);
    },
    indexMethod3(index) {
      return (this.pagination3.currentPage - 1) * this.pagination3.perPage + (index + 1);
    },
    encodeQueryData(data) {
        let result = [];
        for (let d in data) {
          if (data[d] != null && data[d]!="")
            result.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        }
          
        return result.join('&');
    },
    download_excel_1() { 
      try {
        let created_from = null;
        let created_to = null;
        if (this.filterDates) {
          created_from = dayjs(this.filterDates[0]).format("YYYY-MM-DD");
          created_to = dayjs(this.filterDates[1]).format("YYYY-MM-DD");
        }
        let country_id = null;
        if (this.filterCountry) {
          country_id = this.filterCountry;
        }
        var data = { 'created_from': created_from, 'created_to': created_to, 'country_id': country_id };
        var querystring = this.encodeQueryData(data);
        window.open('https://api.smartdoctor.pe/v1/excel//users?' + querystring, '_blank');
      } catch (error) {
        console.error(error);
      }
    },
    download_excel_2() { 
      try {
        let date_from = null;
        let date_to = null;
        if (this.filterDates) {
          date_from = dayjs(this.filterDates[0]).format("YYYY-MM-DD");
          date_to = dayjs(this.filterDates[1]).format("YYYY-MM-DD");
        }
        let country_id = null;
        if (this.filterCountry) {
          country_id = this.filterCountry;
        }
        var data = { 'date_from': date_from, 'date_to': date_to, 'country_id': country_id };
        var querystring = this.encodeQueryData(data);
        window.open('https://api.smartdoctor.pe/v1/excel/medical_attentions?' + querystring, '_blank');
      } catch (error) {
        console.error(error);
      }
    },
    download_excel_3() { 
      try {
        let date_from = null;
        let date_to = null;
        if (this.filterDates) {
          date_from = dayjs(this.filterDates[0]).format("YYYY-MM-DD");
          date_to = dayjs(this.filterDates[1]).format("YYYY-MM-DD");
        }
        let country_id = null;
        if (this.filterCountry) {
          country_id = this.filterCountry;
        }
        var data = { 'date_from': date_from, 'date_to': date_to, 'country_id': country_id };
        var querystring = this.encodeQueryData(data);
        window.open('https://api.smartdoctor.pe/v1/excel/payments?' + querystring, '_blank');
      } catch (error) {
        console.error(error);
      }
    },
  }
};
</script>
<style lang="css">
.el-table__body-wrapper {
  height: auto;
  overflow: auto;
}
</style>
