<template>
  <div>
    <!-- <el-card shadow="always" style="border-radius:25px">
      <div slot="header">
        <span>DOCTOR</span>
        <el-input
          placeholder="Search"
          class="input-with-select"
          v-model="filterDoctors"
          size="small"
          style="float: right; padding: 3px 0;width:261px"
          clearable
        >
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <div>
        <el-table style="width: 100%" :data="doctors_appointment" stripe>
          <el-table-column min-width="50" label="N°" align="center">
            <template slot-scope="props">
              <span
                style="text-decoration:line-through;"
                v-if="props.row.inhabilitado == true"
              >
                {{ props.row.item }}
              </span>

              <span v-else>
                {{ props.row.item }}
              </span>
            </template>
          </el-table-column>
          <el-table-column min-width="125" label="Doctor" align="center">
            <template slot-scope="props">
              <span
                style="text-decoration:line-through;"
                v-if="props.row.inhabilitado == true"
              >
                {{ props.row.doctor }}
              </span>

              <span v-else>
                {{ props.row.doctor }}
              </span>
            </template>
          </el-table-column>

          <el-table-column min-width="150" label="Especialidad" align="center">
            <template slot-scope="props">
              <span
                style="text-decoration:line-through;"
                v-if="props.row.inhabilitado == true"
              >
                {{ props.row.especialidad }}
              </span>

              <span v-else>
                {{ props.row.especialidad }}
              </span>
            </template>
          </el-table-column>

          <el-table-column min-width="150" label="País" align="center">
            <template slot-scope="props">
              <span
                style="text-decoration:line-through;"
                v-if="props.row.inhabilitado == true"
              >
                {{ props.row.pais }}
              </span>

              <span v-else>
                {{ props.row.pais }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            min-width="150"
            label="Horarios Programados"
            align="center"
          >
            <template slot-scope="props">
              <span
                style="text-decoration:line-through;"
                v-if="props.row.inhabilitado == true"
              >
                {{ props.row.horario }}
              </span>

              <span v-else>
                {{ props.row.horario }}
              </span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="Opción" align="center">
            <template slot-scope="props">
              <el-checkbox
                v-if="props.row.inhabilitado == true"
                checked
                @change="evento_checkbox(props.row)"
                size="small"
              ></el-checkbox>

              <el-checkbox
                v-else
                @change="evento_checkbox(props.row)"
                 size="small"
              ></el-checkbox>
            </template>
          </el-table-column>
        </el-table>
        <br />
        <div
          slot="footer"
          class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
        >
          Showing {{ pagination.currentPage2 }} to {{ pagination.perPage2 }} of
          {{ pagination.total }}

          <l-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </l-pagination>
        </div>
      </div>
    </el-card> -->
    <div class="table-responsive table-full-width">
      <table>
        <thead>
          <tr>
            <th
              scope="col"
              style="font-size:12px;color:white;background:black;"
            >
              Indicadores Operativos Bases
            </th>
            <th
              scope="col"
              style="font-size:12px;text-align: center"
              v-for="date in dates_week"
              :key="date.day"
              v-text="date"
            ></th>
            <th scope="col" style="font-size:12px">
              Mes
            </th>
            <th scope="col" style="font-size:12px">
              Mes
            </th>
            <th scope="col" style="font-size:12px">
              Meta
            </th>
            <th scope="col" style="font-size:12px">
              Avance
            </th>
          </tr>
          <tr>
            <th scope="col" style="font-size:12px;color:white;background:red;">
              País: Peru
            </th>
            <th
              scope="col"
              style="font-size:12px;text-align: center"
              v-for="date in dates_number"
              :key="date.day"
              v-text="date"
            ></th>
            <th scope="col" style="font-size:12px">
              Actual
            </th>
            <th scope="col" style="font-size:12px">
              Anter
            </th>
            <th scope="col" style="font-size:12px">
              Mes
            </th>
            <th scope="col" style="font-size:12px">
              vs Meta
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="font-size:12px;font-weight: bold;">
              Consultas programadas
            </td>
            <td
              style="font-size:12px;text-align: center"
              v-for="date in dates_number"
              :key="date.day"
              v-text="date"
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Card, Input, Button, Checkbox } from "element-ui";
import { Pagination as LPagination } from "src/components/index";

import dayjs from "dayjs";
require("dayjs/locale/es");
export default {
  name: "InhabilitarHorario",
  components: {
    LPagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Card.name]: Card,
    [Input.name]: Input,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox
  },
  data() {
    return {
      dates_week: [],
      dates_number: [],
      currentMonth: dayjs(this.date).format("MM"),
      currentYear: dayjs(this.date).format("YYYY"),
      filterDoctors: null,
      doctors_appointment: [
        {
          item: "1",
          doctor: "Roberto Carlos",
          especialidad: "Medicina general",
          pais: "México",
          horario: "12/06/2022  -  10:00 - 11:00",
          inhabilitado: true
        },
        {
          item: "2",
          doctor: "Roberto Carlos",
          especialidad: "Medicina general",
          pais: "México",
          horario: "12/06/2022  -  11:00 - 12:00",
          inhabilitado: false
        },
        {
          item: "3",
          doctor: "Roberto Carlos",
          especialidad: "Medicina general",
          pais: "México",
          horario: "12/06/2022  -  12:00 - 13:00",
          inhabilitado: true
        },
        {
          item: "4",
          doctor: "Roberto Carlos",
          especialidad: "Medicina general",
          pais: "México",
          horario: "12/06/2022  -  13:00 - 14:00",
          inhabilitado: false
        }
      ],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 15, 25, 50],
        total: 0,
        currentPage2: 1,
        perPage2: 10
      }
    };
  },
  watch: {
    "pagination.currentPage"() {
      this.Showingpagination();
    },
    filterDoctors() {}
  },
  mounted() {
    this.load_date_mes();
  },
  methods: {
    load_date_mes() {
      var año = this.currentYear;
      var mes = this.currentMonth;

      var diasMes = new Date(año, mes, 0).getDate();
      var diasSemana = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];

      for (var dia = 1; dia <= diasMes; dia++) {
        // Ojo, hay que restarle 1 para obtener el mes correcto
        var indice = new Date(año, mes - 1, dia).getDay();
        // console.log(
        //   `El día número ${dia} del mes ${mes} del año ${año} es ${diasSemana[indice]}`
        // );

        this.dates_week.push(diasSemana[indice]);
        this.dates_number.push(dia);
      }
    },
    evento_checkbox(datos) {
      console.log(datos);
    },
    Showingpagination() {
      this.pagination.perPage2 =
        this.pagination.perPage * this.pagination.currentPage;

      if (this.pagination.currentPage == 1) {
        this.pagination.currentPage2 = 1;
      } else if (this.pagination.currentPage >= 2) {
        this.pagination.currentPage2 = this.pagination.perPage2 - 9;
      }

      if (this.pagination.perPage2 > this.pagination.total) {
        this.pagination.perPage2 = this.pagination.total;
      }
    }
  }
};
</script>

<style>
/* table {
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
} */
/* td {
  border: 1px solid black;
}
tr {
  border: 1px solid black;
}

th {
  border: 1px solid black;
} */
</style>
