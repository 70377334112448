<template>
  <!-- <div>
    <card>
    <h3>Bienvenidos</h3>
    <h4>
      Esta sección está en construcción. Próximamente estará disponible.
    </h4>
    <div class="text-left">
      <img src="image/img_logo1.png" style="width:100px" />
    </div>
    </card>
  </div> -->
  <div>
    <div class="row">
      <div class="col-md-9">
        <h6>REGISTRAR EMPRESAS</h6>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card stacked-form">
          <div class="card-body ">
            <ValidationObserver v-slot="{ handleSubmit }">
              <el-form label-position="top" ref="formCreate">
                <ValidationProvider
                  rules="required"
                  name="Nombre o Razón Social"
                  vid="formCreate.name"
                  v-slot="provider"
                >
                  <label
                    >Nombre o Razón Social
                    <label class="text-danger"> * </label></label
                  >
                  <el-input
                    placeholder="Nombre o Razón Social"
                    v-model="formCreate.name"
                    size="small"
                    style="margin-bottom:10px"
                    ref="formCreate.name"
                  >
                  </el-input>
                  <div
                    style="color:red; font-size:10px;bottom:10px;position: relative"
                  >
                    {{ provider.errors[0] }}
                  </div>
                </ValidationProvider>

                <label>Dirección </label>
                <el-input
                  placeholder="Dirección"
                  v-model="formCreate.address"
                  size="small"
                  style="margin-bottom:10px"
                  ref="formCreate.address"
                >
                </el-input>

                <ValidationProvider
                  rules="required"
                  name="Categoría"
                  vid="formCreate.category"
                  v-slot="provider"
                >
                  <label
                    >Categoría
                    <label class="text-danger"> * </label>
                  </label>
                  <el-select
                    v-model="formCreate.category"
                    style="width:100%;margin-bottom:10px"
                    placeholder="Categoría"
                    size="small"
                    clearable
                  >
                    <el-option
                      v-for="item in categories"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>

                  <div
                    style="color:red; font-size:10px;bottom:10px;position: relative"
                  >
                    {{ provider.errors[0] }}
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  rules="required|min:11"
                  name="RUC"
                  vid="formCreate.ruc"
                  v-slot="provider"
                >
                  <label>Tax ID <label class="text-danger"> * </label></label>
                  <el-input
                    placeholder="Tax ID"
                    v-model="formCreate.ruc"
                    size="small"
                    @keypress.native="onlyNumber"
                    style="margin-bottom:10px"
                    maxlength="11"
                  />

                  <div
                    style="color:red; font-size:10px;bottom:10px;position: relative"
                  >
                    {{ provider.errors[0] }}
                  </div>
                </ValidationProvider>

                <label>Adjuntar imagen</label>
                <input
                  type="file"
                  id="files"
                  name="empresa_files[]"
                  style="display: none"
                  accept="image/png, image/jpeg"
                />

                <el-button
                  size="small"
                  @click="addNewFile"
                  style="width:100%;margin-bottom:10px"
                >
                  AGREGA TU IMAGEN</el-button
                >

                <div
                  class="row text-center"
                  v-for="(file, index) in empresa_files"
                  :key="'file-' + index"
                >
                  <div class="col-xl-10 col-md-6">
                    <span style="font-size: 12px">{{ file.name }}</span>
                  </div>
                  <div class="col-xl-2 col-md-6">
                    <el-button
                      size="mini"
                      type="text"
                      title="Eliminar"
                      @click="deleteFile(index)"
                      ><i class="el-icon-delete"></i
                    ></el-button>
                  </div>
                </div>

                <ValidationProvider
                  rules="required"
                  name="País"
                  vid="formCreate.country"
                  v-slot="provider"
                >
                  <label
                    >País
                    <label class="text-danger"> * </label>
                  </label>
                  <el-select
                    v-model="formCreate.country"
                    style="width:100%;margin-bottom:10px"
                    placeholder="País"
                    size="small"
                    clearable
                  >
                    <el-option
                      v-for="item in countries"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>

                  <div
                    style="color:red; font-size:10px;bottom:10px;position: relative"
                  >
                    {{ provider.errors[0] }}
                  </div>
                </ValidationProvider>

                <div class="form-group text-center">
                  <el-button
                    style="background:#23CCEF; color:white;border:0px"
                    size="small"
                    @click="handleSubmit(saveCompany)"
                  >
                    REGISTRAR EMPRESA</el-button
                  >
                </div>
              </el-form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="" :visible.sync="dialogErrorStatus" width="30%" top="1%">
      <div class="col">
        <el-result
          icon="warning"
          title="¡ATENCIÓN!"
          subTitle="Hubo un error al procesar su solicitud"
        >
          <template slot="extra">
            <el-button
              type="primary"
              size="medium"
              @click="dialogErrorStatus = false"
              >Cerrar</el-button
            >
          </template>
        </el-result>
        <div style="font-size:16px;">
          {{ resulterrorsStatus }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import {
  Notification,
  Button,
  Loading,
  Result,
  Dialog,
  Form,
  Popover,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Select,
  Option
} from "element-ui";

export default {
  name: "RegistrarEmpresas",
  components: {
    [Button.name]: Button,
    [Notification.name]: Notification,
    [Result.name]: Result,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [Popover.name]: Popover,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      resulterrorsStatus: "",
      dialogErrorStatus: false,
      countries: [],
      categories: [],
      empresa_files: [],
      formCreate: {
        name: "",
        category: "",
        ruc: "",
        country: "",
        address: "",
        image: ""
      }
    };
  },
  mounted() {
    this.setupFileInput();
    this.getCountries();
    this.getCategory();
  },
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    async getCategory() {
      let token = null;
      token = sessionStorage.token;
      try {
        const res = await axios.get(`v1.1/business/company-category`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {}
        });
        this.categories = res.data.results;
      } catch (error) {
        console.error(error);
      }
    },
    async getCountries() {
      try {
        const res = await axios.get(`v1/countries`, {
          params: {}
        });
        this.countries = res.data.data.map(el => ({
          ...el
        }));
      } catch (error) {
        console.error(error);
      }
    },
    addNewFile() {
      const inputFile = document.getElementById("files");
      inputFile.click();
    },
    setupFileInput() {
      document
        .getElementById("files")
        .addEventListener("change", this.fileUpload, false);
    },
    fileUpload(evt) {
      if (
        evt.target.files[0].type == "image/png" ||
        evt.target.files[0].type == "image/jpeg"
      ) {
        if (this.empresa_files.length == 0) {
          if (evt.target.files[0].size > 0) {
            this.empresa_files.push(evt.target.files[0]);
            this.formCreate.image = evt.target.files[0];
          } else {
            Swal.fire(
              "Error",
              "Lo sentimos, se detecto un problema con la imagen.",
              "error"
            );
          }
        } else {
          Swal.fire(
            "Error",
            "Lo sentimos, solo se permite cargar una imagen por empresa.",
            "error"
          );
        }
      } else {
        Swal.fire(
          "Error",
          "Lo sentimos, este tipo de archivo no está permitido por motivos de seguridad",
          "error"
        );
      }
    },
    deleteFile(index) {
      this.empresa_files.splice(index, 1);
    },
    async saveCompany() {
      let token = null;
      token = sessionStorage.token;
      let company_name = this.formCreate.name;
      let trim_company_name = company_name.replace(/^\s+|\s+$|\s+(?=\s)/g, "");

      try {
        Swal.fire({
          title: "¿Confirma los datos?",
          text:
            "Por favor, revisa la información que ingresaste. Al seleccionar Confirmar, no podrás realizar ningún cambio posterior.",
          confirmButtonColor: "#23CCEF",
          cancelButtonColor: "#AEB6BF",
          cancelButtonText: "CANCELAR",
          confirmButtonText: "CONFIRMAR",
          showCancelButton: true
        }).then(result => {
          if (result) {
            if (result.value) {
              let fd = new FormData();
              fd.append("name", trim_company_name);
              fd.append("category", this.formCreate.category);
              fd.append("ruc", this.formCreate.ruc);
              fd.append("country", this.formCreate.country);
              fd.append("address", this.formCreate.address);
              fd.append("image", this.formCreate.image);

              let loadingInstance1 = Loading.service({
                fullscreen: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
              });

              axios
                .post(`v1.1/business/company`, fd, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `token ${token}`
                  }
                })
                .then(response => {
                  console.log(response);
                  if (response.data.status == "ok") {
                    setTimeout(() => {
                      loadingInstance1.close();
                      Swal.fire(
                        "Empresa registrada",
                        "La empresa fue registrada correctamente, gracias.",
                        "success"
                      );
                      // this.$router.go(0);
                      this.$router.push({
                        name: "PANEL EMPRESA"
                      });
                    }, 3000);
                  } else {
                    console.log(response);
                    loadingInstance1.close();
                    Swal.fire("Error", response.data.message, "error");
                  }
                });
            }
          }
        });
      } catch (error) {
        console.log(error);
        this.dialogErrorStatus = true;
        this.resulterrorsStatus =
          "Estado de servicio: " + error.response.status;
        loadingInstance1.close();
        console.error(error);
      }
    },
    async loadFiles() {
      try {
        this.empresa_files.forEach(file => {
          this.uploadFile(file);
        });
      } catch (error) {
        console.dir(error);
        return false;
      }
    },
    async uploadFile(file) {
      try {
        let token = null;
        token = sessionStorage.token;

        let fd = new FormData();
        fd.append("file", file);
        fd.append("medical_attention_id", this.medical_attention_id);
        const res = await axios.post(``, fd, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `token ${token}`
          }
        });
      } catch (error) {
        console.dir(error);
        return false;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.category {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
}
.country {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px;
  grid-auto-flow: dense;
  list-style: none;
  margin: 1em auto;
  padding: 0;
  max-width: 600px;
}
.s-hidden {
  visibility: hidden;
  padding-right: 10px;
}

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font: normal 12px/22px Arial, Sans-Serif;
  color: black;
  border: 1px solid #ccc;
}

.styledSelect {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  padding: 0 10px;
  font-weight: bold;
}

.styledSelect:after {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: black transparent transparent transparent;
  position: absolute;
  top: 9px;
  right: 6px;
}

.styledSelect:active,
.styledSelect.active {
  background-color: #eee;
}

.options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0 0;
  padding: 0 0;
  list-style: none;
  border: 1px solid #ccc;
  background-color: white;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.options li {
  padding: 0 6px;
  margin: 0 0;
  padding: 0 10px;
}

.options li:hover {
  background-color: #39f;
  color: white;
}
</style>
