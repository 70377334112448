<template>
  <div>
    <div class="col-md-3 col-lg-4 bg-fondo"></div>
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-7 login">
        <ValidationObserver v-slot="{ handleSubmit }">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->

          <form @submit.prevent="handleSubmit(postlogin)">
            <fade-render-transition>
              <el-card shadow="always" style="width:110%">
                <div>
                  <!-- <h3 class="card-title text-left" style="font-family: arial;" >Iniciar sesión</h3> -->
                  <h3 style="font-family: Calibri;font-size: 32px;">
                    Iniciar sesión
                  </h3>
                </div>
                <div class="form-group">
                  <label style="font-family: Calibri;font-size: 18px;"
                    >Correo de Usuario</label
                  >
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    class="form-control"
                    v-model="dataLogin.email"
                    required
                    maxlength="40"
                  />
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="text-left">
                        <label style="font-family: Calibri;font-size: 18px;"
                          >Contraseña</label
                        >
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="text-right">
                        <img
                          src="image/img_ver.png"
                          alt="atras"
                          class="q-mb-xl"
                          style="cursor:pointer; position: relative;right: 5px;"
                          @click="showPassword"
                        /><span
                          style="font-family: Calibri;font-size: 14px; color: #23CCEF"
                          >Mostrar
                        </span>
                      </div>
                    </div>
                  </div>
                  <input
                    :type="type"
                    placeholder="Password"
                    class="form-control"
                    name="password"
                    v-model="dataLogin.password"
                    required
                    maxlength="30"
                  />
                </div>
                <!--<div
                  class="text-center"
                  style="font-family: Calibri;font-size: 16px;"
                >
                  <input
                    type="checkbox"
                    v-model="consentimientoAcepted"
                    style="position: relative;top: 1px;"
                  />
                  Acepto el tratamiento de mis datos por smart doctor
                  <div v-if="advertise" class="text-center">
                    <span
                      style="font-family: Calibri;font-size: 15px; color: #fc3864"
                      >{{ advertise }}</span
                    >
                  </div>
                </div>-->
                <br />
                <div class="text-center">
                  <button
                    type="submit"
                    style="border-radius: 30px;font-family: Calibri;font-size: 16px;"
                    class="btn btn-fill btn-info btn-block btn-wd;"
                  >
                    INGRESAR
                    <!-- <i class="nc-icon nc-stre-right"></i> -->
                  </button>
                </div>
              </el-card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Checkbox as LCheckbox,
  FadeRenderTransition
} from "src/components/index";
import { Card } from "element-ui";
import AuthLayout from "./AuthLayout.vue";
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";

import axios from "axios";
import Swal from "sweetalert2";

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    LCheckbox,
    FadeRenderTransition,
    AuthLayout,
    [Card.name]: Card
  },
  data() {
    return {
      routerPushName: "",
      consentimientoAcepted: false,
      type: "password",
      btnText: "Show Password",
      dataLogin: {
        email: "",
        password: "",
        is_business: true
      },
      advertise: null
    };
  },
  watch: {
    loginFields() {
      this.advertise = null;
    }
  },
  computed: {
    loginFields() {
      return `${this.consentimientoAcepted}`;
    }
  },
  methods: {
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
        this.btnText = "Hide Password";
      } else {
        this.type = "password";
        this.btnText = "Show Password";
      }
    },
    async postlogin() {
      try {
        //if (!this.consentimientoAcepted) {
          //this.advertise = "Para ingresar debes aceptar las condiciones";
          //return;
        //}
        await axios
          .post(
            `v1/account/signin`,
            //`https://devapi.smartdoctor.pe/v1/account/signin`,
            this.dataLogin,
            {
              headers: {
                "content-type": "application/json",
                Accept: "application/json"
              },
              params: {}
            }
          )
          .then(data => {
            if (data.data.success === true) {
              localStorage.setItem("email", data.data.data.email);
              localStorage.setItem("identity", data.data.data.first_name);
              localStorage.setItem("photo", data.data.data.photo);
              localStorage.setItem("usuario_id", data.data.data.id);

              sessionStorage.token = data.data.data.token;
              sessionStorage.photo = data.data.data.photo;
              sessionStorage.isLogged = true;
              //this.cargaRouter(data.data.data.id);
              Swal.fire("Bienvenido!", data.data.data.first_name, "success");
              this.$router.push({
                name: "PANEL EMPRESA"
              });
            } else {
              Swal.fire("Error!", data.data.message, "error");
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    async cargaRouter(usuario_id) {
      await this.loadMenu(usuario_id);
    },
    async loadMenu(usuario_id) {
      try {
        let token = null;
        token = sessionStorage.token;

        const res = await axios.get(`v1.1/menu/profile`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            "user-id": usuario_id
          }
        });

        if (res.data.count > 0) {
          console.log(res.data);
          res.data.results.forEach(child => {
            child.menu.forEach(detalle => {
              detalle.menu.forEach(menu => {
                if (menu.permission) {
                  menu.children.forEach(name => {
                    if (name.permission) {
                      if (this.routerPushName == "") {
                        let arr = name.url.split("/");
                        this.routerPushName = arr[1];
                        console.log(this.routerPushName);
                      }
                    }
                  });
                }
              });
            });
          });
          this.$router.push({
            path: "admin/" + this.routerPushName
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    /*submit() {
        alert("Form has been submitted!");
      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      }*/
  },

  beforeDestroy() {
    this.closeMenu();
  }
};
</script>

<style lang="scss" scoped>
.bg-fondo {
  /* Ruta relativa o completa a la imagen */
  background-image: url(/image/img_logo.png);
  /* Centramos el fondo horizontal y verticalmente */
  background-position: center top;
  /* El fonde no se repite */
  background-repeat: no-repeat;
  /* El fonde se re-escala automáticamente */
  background-size: cover;
  /* Color de fondo si la imagen no se encuentra o mientras se está cargando */
  background-color: #fff;
  height: calc(100vh);
}

.login {
  position: absolute;
  right: 20%;
  top: 180px;
}
@media (max-width: 767px) {
  .login {
    display: block !important;
    position: absolute;
    left: auto;
  }
  .bg-fondo {
    display: none !important;
  }
}
.terminos-condiciones {
  text-align: left;
}
@media (max-width: 1299px) {
  .terminos-condiciones {
    text-align: center;
  }
}
</style>
