<template>
  <div class="user">
    <div class="photo">
      <!--<img src="static/img/default-avatar.png" alt="avatar" />-->
      <img
        :src="photo == 'null' ? 'image/sin_imagen.png' : `${photo}`"
        alt="avatar"
      />
    </div>
    <div class="info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        href="#"
      >
        <span>
          {{ identity }}
          <!-- <b class="caret"></b> -->
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <!-- <el-collapse-transition>
          <ul class="nav" v-show="!isClosed">
            <slot>
              <li>
                <a class="profile-dropdown" href="#pablo">
                  <span class="sidebar-mini">MP</span>
                  <span class="sidebar-normal">My Profile</span>
                </a>
              </li>
              <li>
                <a class="profile-dropdown" href="#pablo">
                  <span class="sidebar-mini">EP</span>
                  <span class="sidebar-normal">Edit Profile</span>
                </a>
              </li>
              <li>
                <a class="profile-dropdown" href="#pablo">
                  <span class="sidebar-mini">S</span>
                  <span class="sidebar-normal">Settings</span>
                </a>
              </li>
            </slot>
          </ul>
        </el-collapse-transition> -->
      </div>
    </div>
  </div>
</template>
<script>
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";

export default {
  components: {
    [CollapseTransition.name]: CollapseTransition
  },
  props: {
    title: {
      type: String,
      default: localStorage.getItem("identity")
    }
  },
  data() {
    return {
      identity: null,
      photo: null,
      isClosed: true
    };
  },
  mounted() {
    if (localStorage.getItem("identity")) {
      this.identity = localStorage.getItem("identity");
      this.photo = localStorage.getItem("photo");     
    }
  },
  methods: {
    toggleMenu() {
      this.isClosed = !this.isClosed;
    }
  }
};
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
</style>
