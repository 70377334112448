<template>
  <div>
    <el-page-header @back="goBack" content="Información de horario">
    </el-page-header>
    <br />
    <div class="row">
      <div class="col-7">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span style="font-size:15px;font-weight: bold;"
              >EDITAR HORARIO A UN MÉDICO</span
            >
          </div>
          <div class="card stacked-form">
            <div class="card-body ">
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <el-form label-position="top" ref="formUpdate">
                  <ValidationProvider
                    rules="required"
                    name="Doctor"
                    vid="formUpdate.doctor"
                    v-slot="provider"
                  >
                    <label>Doctor <label class="text-danger"> * </label></label>
                    <el-input
                      placeholder="Doctor"
                      v-model="doctor"
                      class="input-with-select"
                      size="small"
                      style="margin-bottom:10px"
                      readonly
                    >
                      <el-button
                        slot="append"
                        icon="el-icon-s-custom"
                        @click="searchDoctors"
                      ></el-button>
                    </el-input>
                    <div
                      style="color:red; font-size:10px;bottom:10px;position: relative"
                    >
                      {{ provider.errors[0] }}
                    </div>
                  </ValidationProvider>

                  <label>Día <label class="text-danger"> * </label></label>

                  <div style="bottom:42px">
                    <el-date-picker
                      type="date"
                      v-model="dateSelect"
                      id="date"
                      placeholder="Elige una o más fechas"
                      size="small"
                      style="margin-bottom:10px"
                      align="right"
                      ref="datePicker"
                      @change="leerDate"
                      :picker-options="pickerOptions"
                    >
                    </el-date-picker>

                    <el-input
                      placeholder="Elige una fecha"
                      v-model="dateInput"
                      class="input-with-select"
                      size="small"
                      style="bottom:42px"
                      readonly
                    >
                      <el-button
                        slot="append"
                        icon="el-icon-date"
                        @click="pickerVisibleOpen"
                      ></el-button>
                    </el-input>
                  </div>

                  <div class="div_top">
                    <ValidationProvider
                      rules="required"
                      name="Hora inicio"
                      vid="formUpdate.time_init"
                      v-slot="provider"
                    >
                      <label
                        >Hora de inicio
                        <label class="text-danger"> * </label></label
                      >
                      <el-input
                        placeholder="Hora inicio"
                        v-model.trim="formUpdate.time_init"
                        size="small"
                        style="margin-bottom:10px"
                        readonly
                      >
                        <template slot="append"
                          ><el-popover
                            title="Horarios"
                            placement="right"
                            width="auto"
                            trigger="click"
                            v-model="popover_horarios_ini"
                          >
                            <div class="horarios_mes">
                              <div
                                v-for="(horario, index) in horarios"
                                :key="'horario-' + index"
                              >
                                <el-button
                                  type="primary"
                                  plain
                                  @click="selecthorarioinicio(horario)"
                                  size="mini"
                                  >{{ horario }}</el-button
                                >
                              </div>
                            </div>                         
                            <el-button
                              slot="reference"
                              icon="el-icon-time"
                              :disabled="disable_horarios"
                            ></el-button> </el-popover
                        ></template>
                      </el-input>

                      <div
                        style="color:red; font-size:10px;bottom:10px;position: relative"
                      >
                        {{ provider.errors[0] }}
                      </div>
                    </ValidationProvider>

                    <ValidationProvider
                      rules="required"
                      name="Hora fin"
                      vid="formUpdate.time_end"
                      v-slot="provider"
                    >
                      <label
                        >Hora de fin
                        <label class="text-danger"> * </label></label
                      >

                      <el-input
                        placeholder="Hora fin"
                        v-model.trim="formUpdate.time_end"
                        size="small"
                        style="margin-bottom:10px"
                        readonly
                      >
                        <template slot="append"
                          ><el-popover
                            title="Horarios"
                            placement="right"
                            width="auto"
                            trigger="click"
                            v-model="popover_horarios_fin"
                          >
                            <div class="horarios_mes">
                              <div
                                v-for="(horario, index) in horarios"
                                :key="'horario-' + index"
                              >
                                <el-button
                                  type="primary"
                                  plain
                                  @click="selecthorariofin(horario)"
                                  size="mini"
                                  >{{ horario }}</el-button
                                >
                              </div>
                            </div>
                            <!-- <i
                          reanonly="true"
                          slot="reference"
                          @click="validatePopoverHorarioFin"
                          class="el-icon-time"
                          style="cursor:pointer"
                        ></i>  -->
                            <el-button
                              slot="reference"
                              icon="el-icon-time"
                              :disabled="disable_horarios"
                            ></el-button> </el-popover
                        ></template>
                      </el-input>

                      <div
                        style="color:red; font-size:10px;bottom:10px;position: relative"
                      >
                        {{ provider.errors[0] }}
                      </div>
                    </ValidationProvider>
                  </div>
                  <div
                    class="form-group text-center"
                    style="bottom:15px;position:relative"
                  >
                    <el-button
                      style="background:#23CCEF; color:white;border:0px"
                      size="small"
                      @click="handleSubmit(saveProgrammingSchedule)"
                    >
                      ACTUALIZAR HORARIO</el-button
                    >
                  </div>
                </el-form>
              </ValidationObserver>
            </div>
          </div>
        </el-card>
      </div>
      <div class="col-5">
        <el-calendar
          id="calendar"
          style="height:auto"
          v-loading="loading"
          :first-day-of-week="firstDayOfWeek"
        >
          <template slot="dateCell" slot-scope="{ data }">
            <div @click="calendar_mes(data)">
              <div
                style=" text-align: center;                                         
                                          font-size: 12px;"
                :class="remark(data)"
              >
                {{
                  data.day
                    .split("-")
                    .slice(2)
                    .join("-")
                }}
                <div
                  v-if="
                    data.day
                      .split('-')
                      .slice(1)
                      .join('-') == currentDay
                  "
                ></div>
              </div>

              <div v-for="item in schedules_map" :key="item.day">
                <div
                  v-if="
                    item.months.indexOf(data.day.split('-').slice(1)[0]) != -1
                  "
                >
                  <div
                    v-if="
                      item.days.indexOf(
                        data.day
                          .split('-')
                          .slice(2)
                          .join('-')
                      ) != -1
                    "
                  >
                    <div class="text-center">
                      <el-button type="text">
                        <div style="bottom:10px;position:relative">
                          🔵
                        </div>
                      </el-button>
                    </div>
                  </div>
                  <div v-else></div>
                </div>
                <div v-else></div>
              </div>
            </div>
          </template>
        </el-calendar>
      </div>
    </div>
    <el-dialog
      title="DOCTOR"
      :visible.sync="dialogTableDoctor"
      width="65%"
      :close-on-click-modal="false"
      top="1%"
    >
      <div class="row">
        <div class="col-md-6">
          <el-input
            placeholder="Datos Doctor"
            class="input-with-select"
            v-model="filterDoctors"
            size="small"
            style="margin-bottom:10px"
            clearable
          >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </div>
      <el-table :data="doctors_appointment" stripe style="width: 100%">
        <!-- <el-table-column type="index" label="Nº" min-width="50">
        </el-table-column> -->

        <el-table-column min-width="240" label="Doctor" align="left">
          <template slot-scope="props">
            <span
              style="cursor:pointer"
              @click="
                selectDoctor(
                  props.row.id,
                  props.row.doctor_name,
                  props.row.duration
                )
              "
            >
              {{ props.row.doctor_name }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="Especialidad" min-width="220" prop="speciality">
        </el-table-column>
        <el-table-column label="Correo" min-width="280" prop="email">
        </el-table-column>
        <el-table-column label="Celular" min-width="140" prop="phone">
        </el-table-column>
        <el-table-column
          label="País"
          min-width="120"
          prop="country"
          fixed="right"
        >
        </el-table-column>
      </el-table>
      <div
        slot="footer"
        class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
      >
        Showing {{ pagination.currentPage2 }} to {{ pagination.perPage2 }} of
        {{ pagination.total }}

        <l-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="pagination.total"
        >
        </l-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  Popover,
  Tag,
  Descriptions,
  DescriptionsItem,
  Button,
  Dialog,
  Input,
  MessageBox,
  Card,
  Form,
  TimePicker,
  Tooltip,
  Calendar,
  Loading,
  PageHeader
} from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import axios from "axios";
import Swal from "sweetalert2";
import dayjs from "dayjs";
require("dayjs/locale/es");
export default {
  name: "EditarHorarioMes",
  components: {
    Loading: Loading.directive,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Popover.name]: Popover,
    [Tag.name]: Tag,
    [Descriptions.name]: Descriptions,
    [DescriptionsItem.name]: DescriptionsItem,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [MessageBox.name]: MessageBox,
    [Card.name]: Card,
    [Form.name]: Form,
    [TimePicker.name]: TimePicker,
    [Tooltip.name]: Tooltip,
    [Calendar.name]: Calendar,
    [PageHeader.name]: PageHeader
  },
  data() {
    return {
      dateSelect: "",
      dateInput: "",
      doctor_duration: 0,
      disable_horarios: true,
      horarios: [],
      firstDayOfWeek: 0,
      loading: false,
      formGetSchedules: {
        doctor_id: "",
        date: null
      },
      schedules: [],
      schedules_map: [],
      date: new Date(),
      currentDay: dayjs(this.date).format("MM-DD"),
      listError: [],
      pickerOptions: {
        disabledDate(time) {
          var date = new Date();
          return time.getTime() < date.setDate(date.getDate() - 1);
        }
      },
      filterDoctors: null,
      doctors_appointment: [],
      dialogTableDoctor: false,
      popover_horarios_ini: false,
      popover_horarios_fin: false,
      popover_dates: false,
      horarios_duration_15: [
        "08:00",
        "08:15",
        "08:30",
        "08:45",
        "09:00",
        "09:15",
        "09:30",
        "09:45",
        "10:00",
        "10:15",
        "10:30",
        "10:45",
        "11:00",
        "11:15",
        "11:30",
        "11:45",
        "12:00",
        "12:15",
        "12:30",
        "12:45",
        "13:00",
        "13:15",
        "13:30",
        "13:45",
        "14:00",
        "14:15",
        "14:30",
        "14:45",
        "15:00",
        "15:15",
        "15:30",
        "15:45",
        "16:00",
        "16:15",
        "16:30",
        "16:45",
        "17:00",
        "17:15",
        "17:30",
        "17:45",
        "18:00",
        "18:15",
        "18:30",
        "18:45",
        "19:00",
        "19:15",
        "19:30",
        "19:45",
        "20:00",
        "20:15",
        "20:30",
        "20:45",
        "21:00",
        "21:15",
        "21:30",
        "21:45",
        "22:00",
        "22:15",
        "22:30",
        "22:45",
        "23:00"
      ],
      horarios_duration_20: [
        "08:00",
        "08:20",
        "08:40",
        "09:00",
        "09:20",
        "09:40",
        "10:00",
        "10:20",
        "10:40",
        "11:00",
        "11:20",
        "11:40",
        "12:00",
        "12:20",
        "12:40",
        "13:00",
        "13:20",
        "13:40",
        "14:00",
        "14:20",
        "14:40",
        "15:00",
        "15:20",
        "15:40",
        "16:00",
        "16:20",
        "16:40",
        "17:00",
        "17:20",
        "17:40",
        "18:00",
        "18:20",
        "18:40",
        "19:00",
        "19:20",
        "19:40",
        "20:00",
        "20:20",
        "20:40",
        "21:00",
        "21:20",
        "21:40",
        "22:00",
        "22:20",
        "22:40",
        "23:00"
      ],
      horarios_duration_30: [
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00"
      ],
      horarios_duration_40: [
        "08:00",
        "08:40",
        "09:20",
        "10:00",
        "10:40",
        "11:20",
        "12:00",
        "12:40",
        "13:20",
        "14:00",
        "14:40",
        "15:20",
        "16:00",
        "16:40",
        "17:20",
        "18:00",
        "18:40",
        "19:20",
        "20:00",
        "20:40",
        "21:20",
        "22:00",
        "22:40",
        "23:00"
      ],
      horarios_duration_45: [
        "08:00",
        "08:45",
        "09:30",
        "10:15",
        "11:00",
        "11:45",
        "12:30",
        "13:15",
        "14:00",
        "14:45",
        "15:30",
        "16:15",
        "17:00",
        "17:45",
        "18:30",
        "19:15",
        "20:00",
        "20:45",
        "21:30",
        "22:15",
        "23:00"
      ],
      horarios_duration_60: [
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00"
      ],
      datesSelect: [],
      idhorario:0,
      formUpdate: {        
        doctor: 0,
        date: null,
        time_init: "",
        time_end: ""
      },
      doctor: "",
      startTime: "",
      endTime: "",
      pagination: {
        perPage: 4,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
        currentPage2: 1,
        perPage2: 4
      },
      dates_array: []
    };
  },
  created: function() {
    this.$nextTick(() => {
      //  Click on the previous month
      let title_calendar_prev = document.querySelector(".el-calendar__title");
      let cadena_previous = "";
      let prevBtn = document.querySelector(
        ".el-calendar__button-group .el-button-group>button:nth-child(1)"
      );
      prevBtn.addEventListener("click", () => {
        cadena_previous = title_calendar_prev.innerHTML;
        let mes = cadena_previous.substr(11, 15);
        this.getNumberMesCalendar(mes.replace(/\s+/g, ""));

        let day =
          cadena_previous.substr(4, 7) + "-" + this.number_mes + "-" + "01";
        this.formGetSchedules.date = day.replace(/\s+/g, "");
        this.getSchedulesSiguienteMes(day.replace(/\s+/g, ""));
      });

      //Click Next
      let title_calendar_next = document.querySelector(".el-calendar__title");
      let cadena_next = "";
      let nextBtn = document.querySelector(
        ".el-calendar__button-group .el-button-group>button:nth-child(3)"
      );
      nextBtn.addEventListener("click", () => {
        cadena_next = title_calendar_next.innerHTML;

        let mes = cadena_next.substr(11, 15);
        this.getNumberMesCalendar(mes.replace(/\s+/g, ""));

        let day = cadena_next.substr(4, 7) + "-" + this.number_mes + "-" + "01";
        this.formGetSchedules.date = day.replace(/\s+/g, "");
        this.getSchedulesSiguienteMes(day.replace(/\s+/g, ""));
      });

      //Click today
      let title_calendar_today = document.querySelector(".el-calendar__title");
      let cadena_today = "";
      let todayBtn = document.querySelector(
        ".el-calendar__button-group .el-button-group>button:nth-child(2)"
      );
      todayBtn.addEventListener("click", () => {
        cadena_today = title_calendar_today.innerHTML;

        let mes = cadena_today.substr(11, 15);
        this.getNumberMesCalendar(mes.replace(/\s+/g, ""));

        let day =
          cadena_today.substr(4, 7) + "-" + this.number_mes + "-" + "01";
        this.formGetSchedules.date = day.replace(/\s+/g, "");
        this.getSchedulesSiguienteMes(day.replace(/\s+/g, ""));
      });
    });
  },
  watch: {
    "pagination.currentPage"() {
      this.getDoctorsForAppointment();
      this.Showingpagination();
    },
    filterDoctors() {
      this.pagination.currentPage = 1;
      this.getDoctorsForAppointment();
      this.Showingpagination();
    }
  },
  computed: {},
  mounted() {
    if (!sessionStorage.isLogged) {
      this.$router.go(-1);
      return;
    }
    this.loadFormulario();
    this.getDoctorsForAppointment();
  },
  methods: {
    leerDate() {
      this.formUpdate.date = dayjs(this.dateSelect).format("YYYY-MM-DD");
      this.dateInput = dayjs(this.dateSelect).format("YYYY-MM-DD");
      console.log(this.formUpdate.date);
    },
    goBack() {
      this.$router.push({
        path: "horariosprogramados"
      });
    },
    loadFormulario() {
      try {
        this.disable_horarios = false;
        let datos = this.$route.query.row;

        let date,
          horaIni,
          horaFin = null;

        date = datos.schedule.substr(0, 10);
        horaIni = datos.schedule.substr(13, 5);
        horaFin = datos.schedule.substr(21, 6);

        this.formUpdate.date = dayjs(date).format("YYYY-MM-DD");
        this.dateInput = dayjs(date).format("YYYY-MM-DD");
        //this.dateSelect = dayjs(date).format("YYYY-MM-DD");
        this.formUpdate.time_init = horaIni;
        this.formUpdate.time_end = horaFin;
        console.log(datos,horaIni);
        this.idhorario = datos.id;
        this.selectDoctor(datos.doctor, datos.doctor_name, datos.duration);
      } catch (error) {
        this.goBack();
      }

      //console.log(datos, horaFin);
    },
    generar_rango_horario() {
      var progresion = this.doctor_duration;
      this.horarios = [];

      if (progresion == 15) {
        this.horarios = this.horarios_duration_15;
      } else if (progresion == 20) {
        this.horarios = this.horarios_duration_20;
      } else if (progresion == 30) {
        this.horarios = this.horarios_duration_30;
      } else if (progresion == 40) {
        this.horarios = this.horarios_duration_40;
      } else if (progresion == 45) {
        this.horarios = this.horarios_duration_45;
      } else if (progresion == 60) {
        this.horarios = this.horarios_duration_60;
      } else {
        this.horarios = this.horarios_duration_60;
      }
      console.log(progresion);
    },

    horaEnSegundos: function(q) {
      return q * 60 * 60;
    },

    minutosEnSegundos: function(q) {
      return q * 60;
    },

    sumarDias: function(fecha, dias) {
      fecha.setDate(fecha.getDate() + dias);

      return dayjs(fecha).format("YYYY/MM/DD");
    },
    abrir_calendario() {
      const inputCalendar = document.getElementById("dates");
      inputCalendar.pickerVisible = true;
    },
    pickerVisibleOpen() {
      this.$refs.datePicker.pickerVisible = true;
      this.popover_horarios_ini = false;
      this.popover_horarios_fin = false;
    },
    selectDoctor(id, name, duration) {
      this.disable_horarios = false;

      if (duration == null || duration == 0) {
        this.doctor_duration = 30;
      } else {
        this.doctor_duration = duration;
      }
      this.generar_rango_horario();
      this.formUpdate.doctor = id;
      this.doctor = name;
      this.dialogTableDoctor = false;

      this.horario = "";
      //this.fecha = "";

      let date_shedules = dayjs(this.date).format("YYYY-MM-DD");

      this.formGetSchedules.doctor_id = id;
      this.formGetSchedules.date = date_shedules;

      this.loading = true;

      this.getSchedules();
    },

    async getDoctorsForAppointment() {
      let doctor_name = null;
      let token = null;
      token = sessionStorage.token;

      if (this.filterDoctors) {
        doctor_name = this.filterDoctors;
      }

      try {
        const res = await axios.get(`v1.1/pro/doctor`, {
          headers: {
            Authorization: `token ${token}`
          },
          params: {
            page: this.pagination.currentPage,
            limit: 4,
            name: doctor_name,
            "report-a": "",
            "search-schedule": ""
          }
        });
        if (res.data.count > 0) {
          this.pagination.total = res.data.count;
          this.doctors_appointment = res.data.results.map(el => ({
            ...el,
            doctor_name: el.first_name + " " + el.last_name
          }));
        }
      } catch (error) {
        console.error(error);
      }

      if (this.pagination.total <= 4) {
        this.pagination.perPage2 = this.pagination.total;
      }
    },
    searchDoctors() {
      this.dialogTableDoctor = true;
    },
    validatePopoverHorarioIni() {
      if (this.popover_horarios_ini) {
        this.popover_horarios_ini = false;
      } else {
        this.popover_horarios_ini = true;
        this.popover_horarios_fin = false;
      }
    },
    validatePopoverHorarioFin() {
      if (this.popover_horarios_fin) {
        this.popover_horarios_fin = false;
      } else {
        this.popover_horarios_fin = true;
        this.popover_horarios_ini = false;
      }
    },
    validatePopoverDias() {
      if (this.datesSelect.length > 0) {
        if (this.popover_dates) {
          this.popover_dates = false;
        } else {
          this.popover_dates = true;
        }
      }
    },
    selecthorarioinicio(horario) {
      this.formUpdate.time_init = horario;
      this.popover_horarios_ini = false;
    },
    selecthorariofin(horario) {
      this.formUpdate.time_end = horario;
      this.popover_horarios_fin = false;
    },
    async saveProgrammingSchedule() {
      let token = null;
      token = sessionStorage.token;

      Swal.fire({
        title: "¿Desea editar el horario?",
        text: "Por favor verifique la información, gracias.",
        confirmButtonColor: "#23CCEF",
        cancelButtonColor: "#AEB6BF",
        cancelButtonText: "CANCELAR",
        confirmButtonText: "CONFIRMAR",

        showCancelButton: true
      }).then(result => {
        if (result.value) {
          let loadingInstance1 = Loading.service({
            fullscreen: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          try {
            axios
              .put(
                `v1.1/pro/doctor-schedule/${this.idhorario}`,
                this.formUpdate,
                {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `token ${token}`
                  }
                }
              )
              .then(response => {
                if (response.status === 200) {
                  loadingInstance1.close();
                  Swal.fire(
                    "¡Horario Actualizado!",
                    "El horario fue actualizado, con éxito",
                    "success"
                  );

                  this.goBack();
                } else {
                  console.log(response);
                  loadingInstance1.close();
                }
              })
              .catch(error => {
                loadingInstance1.close();
                this.listError = error.response.data.errors.date;
                console.log(error.response.data.errors.date);
                Swal.fire("Error", this.listError.toString(), "error");
              });
          } catch (error) {
            console.error(error);
          }
        }
      });
    },
    Showingpagination() {
      this.pagination.perPage2 =
        this.pagination.perPage * this.pagination.currentPage;

      if (this.pagination.currentPage == 1) {
        this.pagination.currentPage2 = 1;
      } else if (this.pagination.currentPage >= 2) {
        this.pagination.currentPage2 = this.pagination.perPage2 - 3;
      }

      if (this.pagination.perPage2 > this.pagination.total) {
        this.pagination.perPage2 = this.pagination.total;
      }
    },
    remark(data) {
      let str = "";
      if (data.isSelected) {
        str = "is-selected";
      }
      return str;
    },
    async getSchedules() {
      let token = null;
      token = sessionStorage.token;

      try {
        const res = await axios.post(
          `v1/pro/user/doctors/schedule`,
          //`https://devapi.smartdoctor.pe/v1/pro/user/doctors/schedule`,
          this.formGetSchedules,
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              authorization: `token ${token}`
            }
          }
        );
        if (res.data.success == true) {
          this.schedules = res.data.data;
          this.evento_calendario();
        } else {
          this.schedules = [];
          this.schedules_map = [];
          this.loading = false;
          //console.log("False");
        }
      } catch (error) {
        console.error(error);
      }
    },
    evento_calendario() {
      let date = new Date();
      let mes_actual = dayjs(date).format("MM");

      const schedulesMap = this.schedules;
      this.schedules_map = schedulesMap.map(sch => {
        return {
          months: mes_actual,
          days: sch.day.toString().padStart(2, "0"),
          things: "Horarios"
        };
      });
      this.loading = false;
    },
    calendar_mes(data) {
      this.formGetSchedules.date = data.day;
      this.getSchedulesSiguienteMes(data.day);
    },
    async getSchedulesSiguienteMes(day) {
      this.loading = true;
      let token = null;
      token = sessionStorage.token;

      try {
        const res = await axios.post(
          `v1/pro/user/doctors/schedule`,
          //`https://devapi.smartdoctor.pe/v1/pro/user/doctors/schedule`,
          this.formGetSchedules,
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              authorization: `token ${token}`
            }
          }
        );
        if (res.data.success == true) {
          this.schedules = res.data.data;
          this.evento_calendario_Siguiente_Mes(day);
        } else {
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    evento_calendario_Siguiente_Mes(date) {
      let mes_actual = dayjs(date).format("MM");

      const schedulesMap = this.schedules;
      this.schedules_map = schedulesMap.map(sch => {
        return {
          months: mes_actual,
          days: sch.day.toString().padStart(2, "0"),
          things: "Horarios"
        };
      });
      this.loading = false;
    },
    getNumberMesCalendar(mes) {
      switch (mes) {
        case "enero":
          this.number_mes = "01";
          break;
        case "febrero":
          this.number_mes = "02";
          break;
        case "marzo":
          this.number_mes = "03";
          break;
        case "abril":
          this.number_mes = "04";
          break;
        case "mayo":
          this.number_mes = "05";
          break;
        case "junio":
          this.number_mes = "06";
          break;
        case "julio":
          this.number_mes = "07";
          break;
        case "agosto":
          this.number_mes = "08";
          break;
        case "septiembre":
          this.number_mes = "09";
          break;
        case "octubre":
          this.number_mes = "10";
          break;
        case "noviembre":
          this.number_mes = "11";
          break;
        case "diciembre":
          this.number_mes = "12";
          break;
      }
      //return number_mes;
    }
  }
};
</script>

<style lang="css" scope>
.el-button
  el-picker-panel__link-btn
  el-button--default
  el-button--mini
  is-plain {
  background: red;
}
.div_top {
  bottom: 30px;
  position: relative;
}
.el-date-picker_el-popper {
  position: absolute;
  top: 100px;
  right: 68px;
}
.horarios_mes {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 12px;
}
.dias_mes {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 12px;
}
.box-card {
  width: 480px;
}
</style>
